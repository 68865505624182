import { Component, ViewChild, ElementRef, OnInit, Injectable, forwardRef, HostBinding, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Router } from '@angular/router';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-new-files',
  templateUrl: './upload-new-files.component.html',
  styleUrls: ['./upload-new-files.component.css']
})
export class UploadNewFilesComponent implements OnInit {

  displayAddNewDropdownContent = false;
  displayDocumentTypeDropdownContent = false;
  message: any;
  title: any;
  description: any;
  profileFirstHeaderText = "My Documents";
  profileSecondHeaderText = "";
  displayClientFirstNameLastName = false;
  retrievedClientUser: any;
  corporateUser = false;
  public allocatedStorageSizeInGB: any;
  corporateInstitutionUser = false;

  @HostBinding("style.--percentage")
  // set percentage of storage used here
  public value = '65%';
  public lawFirmStorageValue = '15%';
  public clientStorageValue = '50%';
  public usedStorage = this.value.split('%')[0] + ' GB';
  selectedDocumentType = null;
  documentTypeArray: any[] = [];
  documentTypes: any;
  legitSelectedDocumentType = "eg : Agreement";
  legitSelectedDocumentTypeId;
  dropdownButtonTextColor = "#a6a6a6";
  legalDocumentFiles: any;
  uploadNewFilesBoxHeight = "40vh";
  extractedUserId: any;
  extractedUserItem: any;
  disableUploadLegalDocumentButton = false;
  displayLoadingMessage = false;
  displayResponseMessage = false;
  responseType: any;
  responseMessageText: any;
  responseTypeColor: any;
  responseMessageIconImageURL: any;
  successMessageIconImageURL = "/assets/revamp/successGreenIconNew.png";
  errorMessageIconImageURL = "/assets/revamp/redExclamationMark.png";
  displaySessionMessage = false;
  messageToBeDisplayed = "";
  errorType = "";
  isDocumentTypeValid: any;
  isTitleValid: any;
  isDescriptionValid: any;
  isUploadFileValid = "";

  isDocTypeValid: string = "";
  isDocTitleValid: string = "";
  isDocDescValid: string = "";
  isDocFileValid: string = "";

  isSharedbyID: any = null;

  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    console.log("isSmallScreen from Home Component: " + this.isSmallScreen);
  }

  @HostBinding("attr.style")
  public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(`--percentage: ${this.value}`);
  }


  constructor(private router: Router, private sanitizer: DomSanitizer, private userService: UserService,
    private tokenStorageService: TokenStorageService, private documentTypeService: DocumentTypeService) {
    this.title = "";
    this.description = "";
  }

  ngOnInit(): void {
    this.messageToBeDisplayed = "";
    this.errorType = "";
    this.isUploadFileValid = "";
    this.displaySessionMessage = false;
    if (history.state.origin == "userDocumentsComponent") {
      this.profileFirstHeaderText = "My Documents";
      this.displayClientFirstNameLastName = false;
    }
    else if (history.state.origin == "viewSpecificClientComponent") {
      this.profileFirstHeaderText = "Clients";
      this.profileSecondHeaderText = history.state.clientFirstName + " " + history.state.clientLastName;
      this.displayClientFirstNameLastName = true;
    }
    this.retrieveDocumentTypes();
    this.extractedUserItem = this.tokenStorageService.getUser();
    this.extractedUserId = this.extractedUserItem.userId;
    if (environment.debug) {
      console.log("retrievedUser.userType:" + this.extractedUserItem);
    }
    if (this.extractedUserItem.userType == "business" || this.extractedUserItem.userType == "individual") {
      this.corporateUser = true;
    }
    if (this.extractedUserItem.userType == "business") {
      this.corporateInstitutionUser = true;
    }
    this.getUserStorage(this.extractedUserItem.userId);



  }

  onSelected(value): void {
    this.legitSelectedDocumentType = value;
    this.legitSelectedDocumentTypeId = value;
  }




  retrieveDocumentTypes(): void {
    this.documentTypeService.getAll()
      .subscribe(
        data => {
          this.documentTypes = data;
          for (let i = 0; i < this.documentTypes.length; i++) {
            this.documentTypeArray.push(this.documentTypes[i]);
          }
          if (environment.debug) {
            console.log(data);
          }
        },
        error => {
          console.error(error);
        });
  }

  uploadNewFiles() {
    this.displayLoadingMessage = true;

    let userId = this.extractedUserId;
    let fileToUpload = this.files[0];
    let uploadDestination;
    let clientId = "";
    if (environment.debug) {
      console.log("history.state.origin: " + history.state.origin);
    }
    if (history.state.origin == "userDocumentsComponent") {

      // if (this.extractedUserItem.userType == "lawFirm") {
      //   uploadDestination = "lawFirmFolder";
      // }
      // else 
      if (this.extractedUserItem.userType == "business") {
        uploadDestination = "businessFolder"
      }
      else if (this.extractedUserItem.userType == "individual") {
        uploadDestination = "individualFolder"
      }
      this.isSharedbyID = null;
    }
    else if (history.state.origin == "viewSpecificClientComponent") {
      uploadDestination = "clientFolder"
      clientId = history.state.clientId;
      this.isSharedbyID = history.state.isSharedbyID;
    }
    if (environment.debug) {
      console.log("Extracteed id: " + this.extractedUserId);
    }
    this.userService.uploadNewFiles(
      fileToUpload,
      userId.toString() + ";" + this.title + ";" + Date.now().toString() + "." + fileToUpload.name.split('.').pop(),
      uploadDestination,
      userId,
      clientId,
      this.title,
      fileToUpload.size,
      this.description,
      this.legitSelectedDocumentTypeId,
      this.isSharedbyID
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.ipfsHash:" + event.body.ipfsHash);
            console.log("event.body.pinataGateway:" + event.body.pinataGateway);
            console.log("event.body.transactionHash:" + event.body.transactionHash);
            console.log("event.body.blockExplorerURL:" + event.body.blockExplorerURL);
          }
          // alert(event.body);

          if (event.body.status == 500) {
            this.displayLoadingMessage = false;
            this.responseTypeColor = "#EE4E55";
            this.responseMessageIconImageURL = this.errorMessageIconImageURL;
            this.responseType = "Error";
            this.responseMessageText = event.body.message;
            this.displayResponseMessage = true;
            this.disableUploadLegalDocumentButton = false;
          } else if (event.body.status == 406) {
            this.displayLoadingMessage = false;
            this.responseTypeColor = "#0BB831";
            this.responseMessageIconImageURL = this.successMessageIconImageURL;
            this.responseType = "Success";
            this.responseMessageText = "Pending upload to the Polygon Blockchain!";
            this.displayResponseMessage = true;
            this.disableUploadLegalDocumentButton = true;
          } else {
            this.displayLoadingMessage = false;
            this.responseTypeColor = "#0BB831";
            this.responseMessageIconImageURL = this.successMessageIconImageURL;
            this.responseType = "Success";
            this.responseMessageText = "File successfully uploaded to the Polygon Blockchain!";
            this.displayResponseMessage = true;
            this.disableUploadLegalDocumentButton = false;
          }

        }
      },
      err => {
        // this.progress = 0;
        // this.message = 'Could not create law firm user!';
        this.displayLoadingMessage = false;
        this.responseTypeColor = "#EE4E55";
        this.responseMessageIconImageURL = this.errorMessageIconImageURL;
        this.responseType = "Error";
        this.responseMessageText = err.error.message;
        this.displayResponseMessage = true;
        this.disableUploadLegalDocumentButton = false;
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  retrieveUserIDByClientID(clientId) {

    this.userService.retrieveUserIDByClientID(
      clientId
    ).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          // this.progress = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.message:" + event.body.message);
          }
          this.message = event.body.message;
          this.extractedUserId = event.body.userId;
          this.getUser(this.extractedUserId);

        }
      },
      err => {
        // this.progress = 0;
        // this.message = 'Could not create law firm user!';
        // alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  getUser(id): void {
    this.userService.get(id)
      .subscribe(
        data => {
          if (environment.debug) {
            console.log(data);
          }
          this.retrievedClientUser = data;
         
          if(this.retrievedClientUser.username == null) {
            this.displaySessionMessage = true;
            this.messageToBeDisplayed = "You are not allowed to upload files for a client that has not signed up yet!";
            this.errorType = "genericError";
            this.disableUploadLegalDocumentButton = true;
          }

        },
        error => {
          console.error(error);
        });
  }

  getUserStorage(id): void {
    this.userService.get(id)
      .subscribe(
        data => {
          if (environment.debug) {
            console.log(data);
          }
          this.allocatedStorageSizeInGB = data.allocatedStorageSizeInGB;

        },
        error => {
          console.error(error);
        });
  }

  toggleAddNewDropdownContentDisplay() {
    this.displayAddNewDropdownContent = !this.displayAddNewDropdownContent;
  }

  toggleDocumentTypeDisplay() {
    this.displayDocumentTypeDropdownContent = !this.displayDocumentTypeDropdownContent;
  }

  changeDropdownButtonTextColor() {
    this.dropdownButtonTextColor = 'black';
  }

  changeSelectedDocumentType(selectedDocumentType, selectedDocumentTypeId) {
    this.legitSelectedDocumentType = selectedDocumentType;
    this.legitSelectedDocumentTypeId = selectedDocumentTypeId;
    if (environment.debug) {
      console.log("legitSelectedDocumentTypeId: " + this.legitSelectedDocumentTypeId);
    }
  }

  redirectToAddNewClient() {
    this.router.navigate(['AddNewClient']);
  }

  redirectToUploadNewFiles() {
    this.router.navigate(['UploadNewFiles']);
  }

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped(event: any) {
    if (this.files.length == 0) {
      // console.log("fileBrowseHandler test: ");
      // check if file size exceeds 20 MB
      if (environment.debug) {
        console.log(event[0].size);
      }
      if (event[0].size <= 20971520) {
        this.isUploadFileValid = "";
        this.uploadNewFilesBoxHeight = "50vh";
        this.prepareFilesList(event);
      }
      else {
        this.displaySessionMessage = true;
        this.messageToBeDisplayed = "Maximum file size allowed for upload is 20MB!";
        this.errorType = "genericError";
      }
    }
    else if (this.files.length > 0) {
      this.displaySessionMessage = true;
      this.messageToBeDisplayed = "You may only upload one file at a time!";
      this.errorType = "genericError";
    }
    // console.log("onFileDropped test: ");
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event: any) {
    if (this.files.length > 0) {
      this.displaySessionMessage = true;
      this.messageToBeDisplayed = "You may only upload one file at a time!";
      this.errorType = "genericError";
    }
    else if (this.files.length == 0) {

      // check if file size exceeds 20 MB
      if (environment.debug) {
        console.log(event.target.files[0].size);
      }
      if (event.target.files[0].size <= 20971520) {
        // console.log("fileBrowseHandler test: ");
        this.isUploadFileValid = "";
        this.uploadNewFilesBoxHeight = "50vh";
        this.prepareFilesList(event.target.files);
      }
      else {
        this.displaySessionMessage = true;
        this.messageToBeDisplayed = "Maximum file size allowed for upload is 20MB!";
        this.errorType = "genericError";
      }

    }

  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      if (environment.debug) {
        console.log("Upload in progress.");
      }
      return;
    }
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    if (environment.debug) {
      for (let arrayIndex = 0; arrayIndex < this.files.length; arrayIndex++) {
        console.log("this.files: " + this.files[arrayIndex].name);
        // console.log("this.files: " + this.files[arrayIndex].name);
      }
    }

    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  uploadLegalDocument() {
    this.disableUploadLegalDocumentButton = true;
    if (this.legitSelectedDocumentType.trim() == "eg : Agreement") {
      this.isDocumentTypeValid = "is-invalid";
      this.disableUploadLegalDocumentButton = false;
    }
    else if (this.title.trim() == "") {
      this.isTitleValid = "is-invalid";
      this.disableUploadLegalDocumentButton = false;
    }
    else if (this.description.trim() == "") {
      this.isDescriptionValid = "is-invalid";
      this.disableUploadLegalDocumentButton = false;
    }
    else if (this.files.length == 0) {
      this.isUploadFileValid = "is-invalid";
      this.disableUploadLegalDocumentButton = false;
    }

    if (this.legitSelectedDocumentType.trim() != "eg : Agreement") {
      this.isDocumentTypeValid ="is-valid";

      if (this.title.trim() != "") {
        this.isTitleValid = "is-valid";

        if (this.description.trim() != "") {
          this.isDescriptionValid = "is-valid";

          if (this.files.length > 0) {
            this.isUploadFileValid = "is-valid";
            this.uploadNewFiles();
          }

        }

      }

    } 
    
  }

  closeResponseMessage() {
    this.displayResponseMessage = false;
    if (history.state.origin == "userDocumentsComponent") {
      this.router.navigate(['UserDocuments']);
    }
    else if (history.state.origin == "viewSpecificClientComponent") {
      this.router.navigate(['ViewSpecificClient/' + history.state.clientId + "/" + history.state.clientFirstName + "/" + history.state.clientLastName]);
    }
  }


}
