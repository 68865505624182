import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { countries } from 'src/country-data-store';
import { Bank } from 'src/Bank.model'
import { CurlecService } from 'src/app/services/curlec.service';
import { PricingPlanService } from 'src/app/services/pricing-plan.service';
import { UserService } from 'src/app/services/user.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from 'src/app/services/token-storage.service';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  public countries: any = countries
  bankList: Bank[];
  showBuyerBankList: boolean = false;
  monthlyAmount: any;
  yearlyAmount: any;
  discountedMonthlyAmount: any;
  selectedBillingCycle = "yearly";
  selectedPaymentMethod = "creditDebitCard";
  selectedBank: any;
  selectedBankId = "";
  selectedBankDropdownDisplayName = "";
  selectedPricingPlan: any;
  selectedPricingPlanPrice: any;
  selectedPricingPlanStorageSizeInGB: any;
  selectedFiles: FileList;
  currentFile: File;
  currentBusinessRegistrationCertificateFile: File;
  currentForm24OrSection51FormFile: File;
  currentForm49OrSection58FormFile: File;
  progress = 0;
  message = '';
  userType: any;

  fileInfos: Observable<any>;

  constructor(private curlecService: CurlecService, private pricingPlanService: PricingPlanService, private tokenStorageService: TokenStorageService,
    private userService: UserService, private router: Router) { }

  retrieveSpecificPricingPlan(): void {
    this.pricingPlanService.get(history.state.selectedPricingPlanId)
      .subscribe(
        data => {
          this.selectedPricingPlan = data.name;
          this.selectedPricingPlanStorageSizeInGB = data.storageSizeInGB;
          this.selectedPricingPlanPrice = data.monthlyAmount;
          if (environment.debug) {
            console.log("checkout pricing plan data:" + data);
            console.log("userId: " + history.state.userId);
          }
          // start retrieve specific user
          this.userService.get(history.state.userId)
            .subscribe(
              data => {
                this.userType = data.userType;
                if (environment.debug) {
                  console.log("checkout user data:" + data);

                  // start display billing cycle, payment method and pricing information
                  console.log("selectedBillingCycle: " + this.selectedBillingCycle);
                  console.log("selectedPaymentMethod: " + this.selectedPaymentMethod);
                }
                this.monthlyAmount = parseFloat(this.selectedPricingPlanPrice).toFixed(2);
                this.yearlyAmount = parseFloat(this.monthlyAmount) * 10;
                this.yearlyAmount = parseFloat(this.yearlyAmount).toFixed(2);
                this.discountedMonthlyAmount = this.yearlyAmount / 12;
                this.discountedMonthlyAmount = parseFloat(this.discountedMonthlyAmount).toFixed(2);

                // method = [Method Code: 00 - mandate, 01 - instant pay]
                // msgToken = [Value Code: 01 - Personal account, 02 - Business account]
                let msgToken;
                if (environment.debug) {
                  console.log("userId: " + history.state.userId);
                  console.log("userType: " + this.userType);
                }
                if (this.userType == "business") {
                  msgToken = "02";
                }
                else if (this.userType == "individual") {
                  msgToken = "01";
                }

                this.retrieveFPXParticipatingBanks("00", msgToken);
                // end display billing cycle, payment method and pricing information

              },
              error => {
                console.error(error);
              });
          // end retrieve specific user


        },
        error => {
          console.error(error);
        });
  }


  ngOnInit() {
    this.retrieveSpecificPricingPlan();
  }

  toggleBuyerBankListDisplay() {
    this.showBuyerBankList = !this.showBuyerBankList;
  }

  changeSelectedBank(bankId: any) {
    this.bankList.forEach(bank => {
      if (bankId == bank.id) {
        this.selectedBank = bank;
        if (environment.debug) {
          console.log("chosen bank: ", bank);
        }
      }
    });
    this.selectedBankId = this.selectedBank.id[0];
    // console.log("chosen bank2: ", this.selectedBankId);
    this.selectedBankDropdownDisplayName = this.selectedBank.display_name;
  }

  changeSelectedBillingCycle(e) {
    if (environment.debug) {
      console.log(e.target.value);
    }
    this.selectedBillingCycle = e.target.value.toString();
  }

  changeSelectedPaymentMethod(e) {
    if (environment.debug) {
      console.log(e.target.value);
    }
    this.selectedPaymentMethod = e.target.value.toString();
  }

  createUserMandate() {
    // payment method radio button selected is online banking? next step: proceed to createUserMandate
    if (this.selectedPaymentMethod == "fpx") {

    }
    // user selected at least one bank from dropdown? proceed to createUserMandate: prompt error message
    if (environment.debug) {
      console.log("history.state.userId: " + history.state.userId);
      console.log("history.state.selectedPricingPlanId: " + history.state.selectedPricingPlanId);
      console.log("this.selectedBillingCycle: " + this.selectedBillingCycle);
      console.log("this.selectedPaymentMethod: " + this.selectedPaymentMethod);
      console.log("this.selectedBankId: " + this.selectedBankId);
    }
    this.userService.createUserMandate(
      history.state.userId,
      history.state.selectedPricingPlanId,
      this.selectedBillingCycle,
      this.selectedPaymentMethod,
      this.selectedBankId,
    ).subscribe(
      async event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            // console.log("event.body.message:" + event.body.message);
            // this.message = event.body.message;
          }
          if (event.status != 200) {
            alert(event.body.message);
          } else {
            this.router.navigate(['CheckoutProcessing'], {
              state: {
                userId: history.state.userId,
                userType: this.userType,
                newMandateAuthorizationURL: event.body.newMandateAuthorizationURL
              }
            });
          }


          // return event.body.message;
        }
      },
      err => {
        this.progress = 0;
        this.message = 'Could not create user!';
        alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  retrieveFPXParticipatingBanks(_method, _msgToken): void {
    const data = {
      method: _method,
      msgToken: _msgToken
    };

    this.curlecService.getBuyerBankLatestList(data)
      .subscribe(
        response => {
          if (environment.debug) {
            console.log(response.bankList);
          }
          this.bankList = response.bankList;
          if (environment.debug) {
            console.log("curlecService bankList: ", this.bankList[0]);
          }
        },
        error => {
          console.error(error);
        });
  }


}
