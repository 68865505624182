<section name="aboutUsSection1" class="pb-5 custom-Big-Screen-margin-top-negative" [ngClass]="isSmallScreen? 'custom-AboutUs-Section1-Small-Screen-Bg':'custom-AboutUs-Section1-Bg'">
  <div class="container-xl font-plus-jakarta-sans mt-5 pt-5 pb-5">

    <!-- // Start First Row -->
    <div class="row mt-0 mt-md-5 pt-0 pt-md-5" [ngClass]="isSmallScreen? 'custom-Large-padding-top':''">
      <div class="col-12 col-lg-12 mt-0 mt-md-5 pt-0 pt-md-5 text-center" >
        <h1 class=" text-white fw-bold mt-5" [ngClass]="isSmallScreen? 'display-6':'display-3'">
          LegalBox :
        </h1>
      </div>
    </div>
    <!-- // End First Row -->

     <!-- // Start Second Row -->
     <div class="row">
      <div class="col-12 col-lg-12 text-center" >
        <h1 class="text-white fw-bold" [ngClass]="isSmallScreen? 'display-6':'display-3'">
          Where Security Meets Simplicity
        </h1>
      </div>
    </div>
    <!-- // End Second Row -->

     <!-- // Start Third Row -->
     <div class="row pb-5">
      <div class="col-12 col-lg-12 pb-5 text-center" >
        <h1 class="text-white fw-bold" [ngClass]="isSmallScreen? 'display-6':'display-3'">
          <!-- in Blockchain Storage -->
        </h1>
      </div>
    </div>
    <!-- // End Third Row -->


  </div>
</section>

<section name="aboutUsSection2" class="mt-0 mt-md-5 pt-5 pb-5">
  <div class="container-xl pt-4 font-plus-jakarta-sans px-5">

    <!-- // Start First Row -->
    <div class="row">

      <div class="col-lg-6">
        <img src="/assets/revamp/aboutUsSection2ImgNew.png" class="img-responsive" >
      </div>

      <div class="col-lg-5 ms-0 ms-md-5">
       
        <!-- // Start First Row Inner First Row -->
        <div class="row">
          <h2 class="fw-bold text-dark-emphasis">
            Mission
          </h2>
          <div class="col-12 mt-2">
            <p class="text-body-tertiary fw-medium custom-text-justify fs-6">
              To empower individuals and organizations with a revolutionary document deposition solution through blockchain technology.
            </p>
          </div>
        </div>
        <!-- // End First Row Inner First Row -->

         <!-- // Start First Row Inner Second Row -->
         <div class="row mt-5">
          <h2 class="fw-bold text-dark-emphasis">
            Vision
          </h2>
          <div class="col-12 mt-2">
            <p class="text-body-tertiary fw-medium custom-text-justify fs-6">
              To become Asia's leading top platform for secure document storage and sharing, empowering users in their digital journeys.
            </p>
          </div>
        </div>
        <!-- // End First Row Inner Second Row -->

        <!-- // Start First Row Inner Third Row -->
        <div class="row mt-5">
          <h2 class="fw-bold text-dark-emphasis">
            Values
          </h2>
          <div class="col-12 mt-2">
            <p class="text-body-tertiary fw-medium custom-text-justify fs-6">
              Empowering you with secure and innovative solutions.
            </p>
          </div>
        </div>
        <!-- // End First Row Inner Third Row -->

      </div>

    </div>
    <!-- // End First Row -->

  </div>
</section>

<section name="aboutUsSection3" class="pb-5">
  <div class="container-xl-fluid pt-4 font-plus-jakarta-sans">

    <!-- // Start First Row -->
    <div class="row g-0">

      <div class="d-block d-md-none">
        <img src="/assets/revamp/aboutUsSection3SmallScreenBgImg.png" class="img-responsive">
      </div>

      <div class="col-md-8 p-5 custom-bg-light-blue">
        <h1 class="p-0 p-md-5 mb-0 pb-0">Empowering Your Digital World Through Security and Trust</h1>
        <p class="p-0 p-md-5 pt-md-0 m-5 mt-3 mt-md-0 mx-0 lh-lg custom-text-justify text-secondary fw-medium">
          Imagine a place where you do not have to worry about losing or misplacing your documents.
          In an increasingly interconnected and digitalized world, the importance of security cannot be overstated.
          Empowering your digital world through security is not just a choice; it's a necessity.
          <br><br>
          We are leading a movement to keep your sensitive information protected.
          Integrity and Privacy of the document is your right, and it is what we are here for.
          <br><br>
          Our journey started with a simple question: "How can we create a place where people can trust their sensitive information?"
          From that question, we have developed a solution that goes beyond traditional storage systems.
          We are not just changing document management, we are redefining control over your digital life.
          <br><br>
          Join us on this mission that is reshaping how the world secures and handles its confidential documents. 
        </p>
      </div>

      <div class="col-md-4 d-none d-md-block">
        <img src="/assets/revamp/aboutUsSection3BgImgNew.png" class="img-responsive">
      </div>

    </div>
    <!-- // End First Row -->

  </div>
</section>

<section name="aboutUsSection4" class="pb-5">

  <div class="container-xl container-flip-cards mx-auto mb-0 mb-md-5 pb-3">

     <!-- // Start First Row -->
     <div class="row mt-5">
      <h1 class="text-center display-6 fw-bold">Blockchain For Document Storage</h1>
    </div>
    <!-- // End First Row -->

    <!-- // Start Second Row -->
    <div class="row mt-5 d-flex justify-content-center">
      <div class="col-md-2">
      <div class="box float-none float-md-start mx-auto">
        <div class="body">
            <div class="imgContainer rounded-5 shadow custom-bd-top-light-blue custom-bd-left-light-blue d-flex justify-content-center ">

                <img src="/assets/revamp/aboutUsSection4Card1ImgNew.png" alt="" class="mt-5" width="106" height="83">
                <h6 class="fixed-bottom text-center mb-5 pb-2 px-1">Decentralization</h6>

            </div>

            <div class="content d-flex flex-column align-items-center justify-content-center rounded-5 shadow custom-bd-top-light-blue custom-bd-left-light-blue">

                <div class="rounded-5 custom-bd-top-light-blue custom-bd-left-light-blue d-flex align-items-center">

                    <p class="text-secondary text-center" [ngClass]="isSmallScreen? 'fs-5':'fs-6'"> 
                      <small>
                        Blockchain tech employs decentralized nodes to store and manage data, erasing single-point failure risk.
                      </small>
                     </p>

                   

                </div>

            </div>

        </div>

    </div>
  </div>
 

  <div class="col-md-2">
    <div class="box float-none float-md-start mx-auto">
      <div class="body">
          <div class="imgContainer rounded-5 shadow custom-bd-top-light-blue custom-bd-left-light-blue  d-flex justify-content-center">

              <img src="/assets/revamp/aboutUsSection4Card2ImgNew.png" alt="" class="mt-5" width="65" height="78" >
              <h6 class="fixed-bottom text-center mb-4 pb-2 px-1">Immutable
                Record Keeping</h6>

          </div>

          <div class="content d-flex flex-column align-items-center justify-content-center rounded-5 shadow custom-bd-top-light-blue custom-bd-left-light-blue">

              <div class="rounded-5 custom-bd-top-light-blue custom-bd-left-light-blue d-flex align-items-center">

                  <p class="text-secondary text-center " [ngClass]="isSmallScreen? 'fs-5':'fs-6'">
                    <small>
                      Once a document is stored on the blockchain, it cannot be altered or deleted.
                    </small>
                   </p>

              </div>

          </div>

      </div>

  </div>
</div>

<div class="col-md-2">
  <div class="box float-none float-md-start mx-auto">
    <div class="body">
        <div class="imgContainer rounded-5 shadow custom-bd-top-light-blue custom-bd-left-light-blue d-flex justify-content-center">

            <img src="/assets/revamp/aboutUsSection4Card3ImgNew.png" alt="" class="mt-5" width="64" height="81" >
            <h6 class="fixed-bottom text-center mb-5 pb-1 px-1">Enhanced Security</h6>

        </div>

        <div class="content d-flex flex-column align-items-center justify-content-center rounded-5 shadow custom-bd-top-light-blue custom-bd-left-light-blue">

            <div class="rounded-5 custom-bd-top-light-blue custom-bd-left-light-blue d-flex align-items-center">

                <p class="text-secondary text-center" [ngClass]="isSmallScreen? 'fs-5':'fs-6'">
                  <small>
                    Blockchain deploys advanced cryptography to secure network-stored data.
                  </small>
                 </p>

               

            </div>

        </div>

    </div>

</div>
</div>
 
<div class="col-md-2">
<div class="box float-none float-md-start mx-auto">
  <div class="body">
      <div class="imgContainer rounded-5 shadow custom-bd-top-light-blue custom-bd-left-light-blue d-flex justify-content-center">

          <img src="/assets/revamp/aboutUsSection4Card4ImgNew.png" alt="" class="mt-5" width="76" height="80" >
          <h6 class="fixed-bottom text-center mb-4 pb-2 px-1">Increased Transparency</h6>

      </div>

      <div class="content d-flex flex-column align-items-center justify-content-center rounded-5 shadow custom-bd-top-light-blue custom-bd-left-light-blue">

          <div class="rounded-5 custom-bd-top-light-blue custom-bd-left-light-blue d-flex align-items-center">

              <p class="text-secondary text-center " [ngClass]="isSmallScreen? 'fs-5':'fs-6'">
                <small>
                  Blockchain technology provides a transparent and auditable trail of all activities.
                </small>
               </p>

             

          </div>

      </div>

  </div>

</div>
</div>
 
<div class="col-md-2">
<div class="box float-none float-md-start mx-auto">
  <div class="body">
      <div class="imgContainer rounded-5 shadow custom-bd-top-light-blue custom-bd-left-light-blue d-flex justify-content-center">

          <img src="/assets/revamp/aboutUsSection4Card5ImgNew.png" alt="" class="mt-5" width="72" height="78" >
          <h6 class="fixed-bottom text-center mb-5 px-1">Cost
            Effective</h6>

      </div>

      <div class="content d-flex flex-column align-items-center justify-content-center rounded-5 shadow custom-bd-top-light-blue custom-bd-left-light-blue">

          <div class="rounded-5 custom-bd-top-light-blue custom-bd-left-light-blue d-flex align-items-center">

              <p class="text-secondary text-center" [ngClass]="isSmallScreen? 'fs-5':'fs-6'">
                <small>
                  Blockchain document storage employs peer-to-peer model, removing intermediaries.
                </small>
               </p>

             

          </div>

      </div>

  </div>

</div>

</div>

    </div>

    <!-- // End Second Row -->

 

 

 

</div>

</section>

<app-get-started></app-get-started>

