<section name="signUpSelectionSection1" class="pb-5 mb-5">
  <div class="container-xl pt-4 font-plus-jakarta-sans">

    <!-- // Start First Row -->
    <div class="row mt-3 d-flex justify-content-center">

      <div class="col-md-5 mt-4 mt-md-2 d-flex align-items-stretch">
        <div class="card rounded-5 border-0 py-5 px-4 mb-3 bd-top-light-blue bd-left-light-blue shadow mx-3 mx-md-1">
          <img
            src="/assets/revamp/signUpSelectionSection1BusinessImg.png"
            class="mx-auto mt-5"
            alt="..."
            width="106"
            height="60"
          />
          <div class="card-body text-center line-height-2 d-flex flex-column h-100">
            <h4 class="card-title fw-bold mt-3">Business</h4>
            <p class="card-text mt-4 text-secondary line-height-1_5 mb-2">
              For businesses (i.e. commercial, professional or legal) that are looking to store documents for both your business
              and clients.
            </p>
            <!-- <p class="mt-4 fst-italic text-body-secondary custom-text-responsive">e.g., financial records and sensitive reports</p> -->
            <div class="d-grid gap-2 mt-auto ms-5 me-4">
              <button type="button" class="btn btn-primary px-5 mt-3" (click)="businessSignUp()">Sign Up</button>
            </div>
            
          </div>
        </div>
      </div>

      <div class="col-md-5 mt-4 mt-md-2 d-flex align-items-stretch">
        <div class="card rounded-5 border-0 py-5 px-4 mb-3 bd-top-light-blue bd-left-light-blue shadow mx-3 mx-md-1">
          <img
            src="/assets/revamp/signUpSelectionSection1IndividualImg.png"
            class="mx-auto mt-4"
            alt="..."
            width="66"
            height="76"
          />
          <div class="card-body text-center line-height-2 d-flex flex-column h-100">
            <h4 class="card-title fw-bold mt-3">Individual</h4>
            <p class="card-text mt-4 text-secondary line-height-1_5 mb-2">
              For individuals who are looking to store and manage your personal documents.
            </p>
            <!-- <p class="mt-4 fst-italic text-body-secondary custom-text-responsive px-1">e.g., legal documents, cherished photos, and important notes.</p> -->
            <div class="d-grid gap-2 mt-auto ms-5 me-4">
              <button type="button" class="btn btn-primary px-5" (click)="individualSignUp()">Sign Up</button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- // End First Row -->

  </div>
</section>