<link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" />
<link
  href="https://fonts.googleapis.com/css?family=Montserrat"
  rel="stylesheet"
/>
<link
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css"
  rel="stylesheet"
  integrity="sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9"
  crossorigin="anonymous"
/>
<script
  src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js"
  integrity="sha384-HwwvtgBNo3bZJJLYd8oVXjrBZt8cqVSpeBNS5n7C8IVInixGAoxmnlMuBnhbgrkm"
  crossorigin="anonymous"
></script>

<section>
  <div class="container pb-5">
    <div
      class="alert alert-danger"
      role="alert"
      [style.display]="displayBackendErrorMessage ? 'block' : 'none'"
    >
      {{ errorMessage }}
    </div>

    <div class="row d-flex justify-content-center">
      <div
        class="col-lg-4 col-sm-12"
        [style.display]="
          !displayBackendErrorMessage && checkingVerify ? 'block' : 'none'
        "
      >
        <div class="row d-flex justify-content-center">
          <img
            class="img-fluid w-50"
            src="/assets/scratchImg/loadingGifIcon.gif"
            alt="Checking"
          />
        </div>
        <div class="p-3">
          <h2 class="d-flex fw-bold justify-content-center text-center">
            Checking . . .
          </h2>
        </div>
      </div>
    </div>
    <!-- Verify Your Email Content -->
    <div class="row d-flex justify-content-center">
      <div
        class="col-lg-4 col-sm-12"
        [style.display]="checkingVerify ? 'none' : 'block'"
      >
        <div class="row d-flex justify-content-center">
          <img
            class="img-fluid w-50"
            src="{{ emailContextImage }}"
            alt="Verify Email"
          />
        </div>
        <div class="p-3">
          <h2 class="d-flex fw-bold justify-content-center text-center">
            {{ emailTitleText }}
          </h2>
          <p
            class="lead text-center"
            [style.display]="verifiedEmail ? 'none' : 'block'"
          >
            A verification email is sent to {{ retrievedEmail }}. <br />
            Click the link in the email to verify.
          </p>
          <p
            class="lead text-center"
            [style.display]="verifiedEmail ? 'block' : 'none'"
          >
            You'll be redirected to select a pricing plan. <br />
            If you're not redirected, click
            <a (click)="redirectToSelectPlan()">here</a>.
          </p>
        </div>
      </div>

    </div>
  </div>
</section>

<app-error-pop-up
  [(displaySessionMessage)]="displaySessionMessage"
  [messageToBeDisplayed]="messageToBeDisplayed"
  [errorType]="errorType"
>
</app-error-pop-up>
