import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ServicesComponent } from './components/services/services.component';
import { PricingPlanComponent } from './components/pricing-plan/pricing-plan.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FaqComponent } from './components/faq/faq.component';
import { TermAndPoliciesComponent } from './components/term-and-policies/term-and-policies.component';
import { SignUpSelectionComponent } from './components/sign-up-selection/sign-up-selection.component';
import { SignUpStepsComponent } from './components/sign-up-steps/sign-up-steps.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CheckoutProcessingComponent } from './components/checkout-processing/checkout-processing.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UserDashboardComponent } from './components/user-dashboard/user-dashboard.component';
import { UserDocumentsComponent } from './components/user-documents/user-documents.component';
import { UserArchiveComponent } from './components/user-archive/user-archive.component';
import { ViewAllClientsComponent } from './components/view-all-clients/view-all-clients.component';
import { AddNewClientComponent } from './components/add-new-client/add-new-client.component';
import { UploadNewFilesComponent } from './components/upload-new-files/upload-new-files.component';
import { ViewSpecificClientComponent } from './components/view-specific-client/view-specific-client.component';
import { ViewSpecificClientArchiveComponent } from './components/view-specific-client-archive/view-specific-client-archive.component';
import { ActivateAccountComponent } from './components/activate-account/activate-account.component';
import { SelectPlanComponent } from './components/select-plan/select-plan.component';
import { ErrorComponent } from './components/error/error.component';
import { PageNotFoundComponent } from './components/404-page-not-found/404-page-not-found.component';
import { ViewShareddocumentsBusinessesComponent } from './components/view-shareddocuments-businesses/view-shareddocuments-businesses.component';
import { ViewShareddocumentsSpecificBusinessComponent } from './components/view-shareddocuments-specific-business/view-shareddocuments-specific-business.component';
import { LinkExpiredComponent } from './components/link-expired/link-expired.component';

const routes: Routes = [
  { path: '', redirectTo: 'Home', pathMatch: 'full' },
  { path: 'Home', component: HomeComponent },
  { path: 'AboutUs', component: AboutUsComponent },
  { path: 'Services', component: ServicesComponent },
  { path: 'PricingPlan', component: PricingPlanComponent },
  { path: 'ContactUs', component: ContactUsComponent },
  { path: 'FAQ', component: FaqComponent },
  { path: 'TermAndPolicies', component: TermAndPoliciesComponent },
  { path: 'SignUpSelection', component: SignUpSelectionComponent },
  { path: 'SignUpSteps', component: SignUpStepsComponent },
  { path: 'ViewSpecificClient/:clientId/:clientFirstName/:clientLastName', component: ViewSpecificClientComponent },
  { path: 'ViewSpecificClientArchive/:clientId/:clientFirstName/:clientLastName', component: ViewSpecificClientArchiveComponent },
  { path: 'Checkout', component: CheckoutComponent },
  { path: 'CheckoutProcessing', component: CheckoutProcessingComponent },
  // { path: 'SignIn?/:curlecMandateStatusURL', component: SignInComponent },
  { path: 'SignIn', component: SignInComponent },
  { path: 'ForgotPassword', component: ForgotPasswordComponent },
  { path: 'ChangePassword', component: ChangePasswordComponent },
  { path: 'UserDashboard', component: UserDashboardComponent },
  { path: 'UserDocuments', component: UserDocumentsComponent },
  { path: 'UserArchive', component: UserArchiveComponent },
  { path: 'ViewAllClients', component: ViewAllClientsComponent },
  { path: 'AddNewClient', component: AddNewClientComponent },
  { path: 'UploadNewFiles', component: UploadNewFilesComponent },
  { path: 'ActivateAccount', component: ActivateAccountComponent },
  { path: 'SelectPlan', component: SelectPlanComponent },
  { path: 'ForgotPassword', component: ForgotPasswordComponent },
  { path: 'ChangePassword', component: ChangePasswordComponent },
  { path: 'UserArchive', component: UserArchiveComponent },
  { path: 'Error', component: ErrorComponent },
  { path: 'ViewShareddocumentsBusinesses', component: ViewShareddocumentsBusinessesComponent },
  { path: 'ViewShareddocumentsSpecificBusiness', component: ViewShareddocumentsSpecificBusinessComponent },
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent},
  { path: 'LinkExpired', component: LinkExpiredComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
