import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MandateService {
  private baseUrl = environment.backendURL + '/api/mandates';

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  updateMandateStatusAndEnableAutoCollection(fpxDebitAuthCode, mandateReferenceNumber): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("fpxDebitAuthCode", fpxDebitAuthCode);
    formData.append("mandateReferenceNumber", mandateReferenceNumber);
    if (environment.debug) {
      console.log("entered updateMandateStatusAndEnableAutoCollection service");
    }

    let headers = new HttpHeaders().set('Access-Token', this.tokenStorageService.getToken());

    const req = new HttpRequest('POST', `${this.baseUrl}/updateMandateStatusAndEnableAutoCollection`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }    
}
