import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() isServices: boolean = false;
  @Input() isSmallScreen;
  @Input() showHeaderLinks;
  @Input() showHeader;
  localShowHeader = false;

  ngOnChanges(changes: SimpleChanges): void {
    // This method is called whenever there are changes to input properties

    if (changes.showHeader) {
      this.localShowHeader = changes.showHeader.currentValue;
      if (environment.debug) {
        console.log("this.showHeader: " + this.showHeaderLinks);
        console.log("changes.showHeader.currentValue:" + changes.showHeader.currentValue);
      }
    }
  }

  headerLinksMap = new Map();
  isShowDivIf = false;
  toggleDisplayDivIf() {
    this.isShowDivIf = !this.isShowDivIf;
  }

  constructor(private router: Router) {

  }




  ngOnInit(): void {
    this.isShowDivIf = false;
    this.headerLinksMap.set("Home", false);
    this.headerLinksMap.set("AboutUs", false);
    this.headerLinksMap.set("Login", false);
    this.headerLinksMap.set("SignUp", false);
    if (environment.debug) {
      console.log("showHeader:" + this.showHeaderLinks);
    }
  }

  getHeaderLinkValueFromKey(headerLinkKey) {
    return this.headerLinksMap.get(headerLinkKey);
  }

  makeSpecificRouterLinkActive(headerLinkKey) {
    for (let key of this.headerLinksMap.keys()) {
      if (key == headerLinkKey) {
        this.headerLinksMap.set(key, true);
      }
      else {
        this.headerLinksMap.set(key, false);
      }
    }
  }

  changeRoute(routeValue) {
    this.isShowDivIf = false;
    this.router.navigate([routeValue]);
    // you have to check this out by passing required route value.
    // this line will redirect you to your destination. By reaching to destination you can close your loader service.
    // please note this implementation may vary according to your routing code.

  }

}
