import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.css']
})
export class GetStartedComponent implements OnInit {

  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    if (environment.debug) {
      console.log("isSmallScreen from Home Component: " + this.isSmallScreen);
    }
  }

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  redirectToSignUpSelection() {
    this.router.navigate(['SignUpSelection']);
  }

}
