<section name="sideNavigationBarSection1" [ngClass]="isSmallScreen? (isShowDivIf? 'sideNavigationBarExpandedSmallScreenSection':'sideNavigationBarCollapsedSmallScreenSection'):'sideNavigationBarBigScreenSection'" class="">
    <div class="container-xl-fluid font-plus-jakarta-sans">
      <!-- // Start First Row -->
      <div class="row">

        
        <!-- <div class="col-2 col-md-12 d-md-flex justify-content-center mt-2 ms-4 ms-md-0" >
           
        </div>

        <div class="col-6 col-md-12 d-md-flex justify-content-center mt-3 mt-md-3 pt-2 pt-md-0 gy-0">
            
        </div>

        <div class="d-none d-md-block col-md-12 d-md-flex justify-content-center">
            <p class="text-center fs-6 text-secondary custom-font-size-14 mt-1 d-none d-md-block">
                <small>
                    {{retrievedUserUserType}} User
                </small>
            </p>
        </div> -->

        <!-- // Start Responsive Vertical Navigation Bar -->
            <nav class="navbar navbar-expand-md">
                <div class="container-xl"  [ngClass]="isSmallScreen? '':'flex-wrap flex-column'" >
                    
                        <a class="navbar-brand navbar-brand-custom" [ngClass]="pageTitle=='Profile'?'':'mt-3 mt-md-0'">
                            
                            <div class="mt-5 d-none d-md-flex justify-content-center align-middle">
                                <img class="mt-5 mx-auto" src="/assets/scratchImg/legalboxHeaderLogo.png" width="150" height="33.75">
                            </div>

                            <!-- // Start Profile Img, Username, User Type -->
                           <div [style.display]="pageTitle=='Profile'? 'block':(isSmallScreen? 'none':'block')">

                            <div class="d-block float-start float-md-none d-md-flex justify-content-center align-middle ms-4 ms-md-0" (click)="redirectToUserSideLink('Profile')">
                                <img class="mt-1 mt-md-5"
                                [ngClass]="isSmallScreen? 'custom-img-width-8':'w-50'" 
                                src="/assets/revamp/dashboardProfileIconImg.png" > 
                            </div>

                            <div class="d-block float-start float-md-none d-md-flex justify-content-center">
                                <p class="mb-0 fw-bold text-start text-md-center fs-6 ps-2 ps-md-0 pt-2 pt-md-4">
                                    {{retrievedUserUsername}}
                                </p>
                                <p class="text-start fs-6 text-secondary custom-font-size-14 ps-2 ps-md-0 mt-0 d-block d-md-none">
                                    <small>
                                        {{retrievedUserUserType}} User
                                    </small>
                                </p>
                            </div>

                           </div>
                            <!-- // End Profile Img, Username, User Type -->


                            <!-- // Start Page Title  -->
                            <div [style.display]="pageTitle=='Profile'? 'none':(isSmallScreen? 'block':'none')">

                                <div class="d-block float-start float-md-none d-md-flex justify-content-center align-middle ms-4 ms-md-0" (click)="redirectToUserSideLink('Profile')">
                                    <p class="fw-bold">
                                        {{pageTitle}}
                                    </p>
                                </div>
    
                                <!-- <div class="d-block float-start float-md-none d-md-flex justify-content-center">
                                    <p class="mb-0 fw-bold text-start text-md-center fs-6 ps-2 ps-md-0 pt-2 pt-md-4">
                                        {{retrievedUserUsername}}
                                    </p>
                                    <p class="text-start fs-6 text-secondary custom-font-size-14 ps-2 ps-md-0 mt-0 d-block d-md-none">
                                        <small>
                                            {{retrievedUserUserType}} User
                                        </small>
                                    </p>
                                </div> -->
    
                               </div>
                                <!-- // End Page Title -->
                          

                            <div class="d-block float-start float-md-none d-md-flex justify-content-center">
                                <p class="text-center fs-6 text-secondary custom-font-size-14 d-none d-md-block">
                                    <small>
                                        {{retrievedUserUserType}} User
                                    </small>
                                </p>
                            </div>
                           
                        </a>

    
                            <button (click)="toggleDisplayDivIf()" class="ms-auto navbar-toggler float-sm-end border-0 mt-0 custom-no-box-sizing" type="button" data-toggle="collapse" data-target="#navbarResponsive"
                            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                            <div [style.display]="isShowDivIf? 'none':'block'" class="mt-0 pt-0">
                              <span class="navbar-toggler-icon"></span>
                            </div>
                            <div  [style.display]="isShowDivIf? 'block':'none'">
                              <span class="navbar-close-icon fw-bold text-black">X</span>
                            </div>
                           
                        </button>
                   
               
                   
                    <!-- *ngIf="!isShowDivIf" --- hide and show -->
                   
                    <div [ngClass]="isShowDivIf? (isSmallScreen? 'show increase-Height' : 'show') : ''" class="collapse navbar-collapse float-end" id="navbarResponsive">
                        <ul class="navbar-nav me-auto nav-pills custom-cursor-pointer flex-column" >

                            <li class="nav-item text-start mt-0 mt-md-1 pt-0 pt-md-1 me-0 me-md-5" routerLinkActive="active" 
                            [style.display]="isSmallScreen && isShowDivIf? 'block':'none'">
                                <a class="nav-link fs-5">
                                  <div class="d-flex justify-content-center justify-content-md-start mt-5" 
                                  (click)="redirectToUserSideLink('UserDashboard')" >

                                      <img class="ms-4" [style.display]="isSmallScreen && isShowDivIf? 'none':'block'"
                                      src="/assets/revamp/myDocumentsIcon.png" width="22" height="22">
                                      <p class="ms-0 ms-md-2 fs-6 text-dark fw-bold" >DASHBOARD</p>

                                  </div>
                                </a>
                              </li>
  

                            <li class="nav-item text-start mt-0 mt-md-1 pt-0 pt-md-1 me-0 me-md-5" routerLinkActive="active" >
                              <a class="nav-link fs-5">
                                <div class="d-flex justify-content-center justify-content-md-start mt-0 mt-md-5" 
                                [style.filter]="isSmallScreen && isShowDivIf? 'opacity(100%)': (selectedNavBarLink=='myDocuments'? 'opacity(100%)':'opacity(50%)')"
                                (click)="redirectToUserSideLink('myDocuments')" >

                                    <img class="ms-4" [style.display]="isSmallScreen && isShowDivIf? 'none':'block'"
                                    src="/assets/revamp/myDocumentsIcon.png" width="22" height="22">
                                    <p class="ms-0 ms-md-2 fs-6" [ngClass]="isSmallScreen && isShowDivIf? 'text-uppercase text-dark fw-bold':''">My Documents</p>

                                </div>
                              </a>
                            </li>

                            <li class="nav-item text-start p-1 mt-0 mt-md-1 me-0 me-md-5" routerLinkActive="active" 
                            [style.display]="displayClientTabLink? 'block':'none'">
                              <a class="nav-link fs-5 ">
                                <div class="d-flex justify-content-center justify-content-md-start mt-0" 
                                [style.display]="displayClientTabLink? 'block':'none'"
                                [style.filter]="isSmallScreen && isShowDivIf? 'opacity(100%)': (selectedNavBarLink=='Clients'? 'opacity(100%)':'opacity(50%)')"
                                (click)="redirectToUserSideLink('Clients')" >

                                    <img [style.display]="displayClientTabLink? (isSmallScreen && isShowDivIf? 'none':'block'):'none'" class="ms-4" 
                                    src="/assets/revamp/clientsSideNavIconNew.png" width="22" height="22">
                                    <p [style.display]="displayClientTabLink? 'block':'none'" [ngClass]="isSmallScreen && isShowDivIf? 'text-uppercase text-dark fw-bold':''"
                                     class="ms-0 ms-md-2 fs-6">Clients</p>
                               
                                </div>
                              </a>
                            </li>

                            <li class="nav-item text-start p-1 mt-0 mt-md-1 me-0 me-md-5" routerLinkActive="active"  
                            [style.display]="displaySharedFilesTabLink? 'block':'none'">
                                <a class="nav-link fs-5 ">

                                    <div class="d-flex justify-content-center justify-content-md-start" [ngClass]="displayClientTabLink? 'mt-4' : 'mt-0'"
                                    [style.display]="displaySharedFilesTabLink? 'block':'none'"
                                    [style.filter]="isSmallScreen && isShowDivIf? 'opacity(100%)': (selectedNavBarLink=='SharedFiles'? 'opacity(100%)':'opacity(50%)')"
                              
                                    (click)="redirectToUserSideLink('SharedFiles')" >

                                        <img [style.display]="displaySharedFilesTabLink? (isSmallScreen && isShowDivIf? 'none':'block'):'none'" class="ms-4"
                                         src="/assets/revamp/sharedFilesBusinessFolderIconNew.png" width="22" height="22">
                                        <p [style.display]="displaySharedFilesTabLink? 'block':'none'" [ngClass]="isSmallScreen && isShowDivIf? 'text-uppercase text-dark fw-bold':''"
                                        class="ms-0 ms-md-2 fs-6">Shared Files</p>
                                    
                                    </div>

                                </a>
                              </li>

                              <li class="nav-item text-start p-1 mt-0 mt-md-1 me-0 me-md-5" routerLinkActive="active" >
                                <a class="nav-link fs-5 ">

                                    <div class="d-flex justify-content-center justify-content-md-start" [ngClass]="displayClientTabLink? 'mt-0' : 'mt-4'"
                                    [style.display]="displayArchiveTabLink? 'block':'none'"
                                    [style.filter]="isSmallScreen && isShowDivIf? 'opacity(100%)': (selectedNavBarLink=='UserArchive'? 'opacity(100%)':'opacity(50%)')"
                              
                                    (click)="redirectToUserSideLink('UserArchive')" >

                                        <img class="ms-4 mt-1" [style.display]="isSmallScreen && isShowDivIf? 'none':'block'"
                                        src="/assets/revamp/archiveSideNavIcon.png" width="17" height="17">
                                        <p class="ms-0 ms-md-3 fs-6" [ngClass]="isSmallScreen && isShowDivIf? 'text-uppercase text-dark fw-bold':''">Archive</p>

                                    </div>

                                </a>
                              </li>

                              <li class="nav-item text-start p-1 mt-1 me-0 me-md-5" routerLinkActive="active" >
                                <a class="nav-link fs-5 ">

                                    <div class="d-flex justify-content-center justify-content-md-start mt-5" 
                                    [style.top]="displayArchiveTabLink? '0vh':'-7vh'"
                                    [style.filter]="'opacity(100%)'" 
                                    (click)="showLogOutConfirmation()" 
                                    >
                                        <img class="ms-4 mt-1" [style.display]="isSmallScreen && isShowDivIf? 'none':'block'"
                                        src="/assets/revamp/logOutSideNavIcon.png" width="20" height="20">
                                        <p class="ms-3 fs-6" [ngClass]="isSmallScreen && isShowDivIf? 'text-uppercase text-danger fw-bold':''">Log Out</p>
                                    </div>

                                </a>
                              </li>

                            

                        </ul>
                    </div>

                </div>
            </nav>
      
        <!-- // End Responsive Vertical Navigation Bar -->

       
   

     


    </div>
    <!-- // End First Row -->
  </div>
</section>


  
  <!-- Modal -->
  <div class="modal fade show" [style.display]="displayLogOutConfirmation?'block':'none'" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" style="display:block;">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content p-2 border-0">
        <div class="modal-header border-bottom-0">
          <h1 class="modal-title fs-6 text-center fw-bold" id="exampleModalLabel">Are you sure to log out from your account?</h1>
        </div>
        <div class="modal-body">
            <div class="d-grid gap-2"  (mouseenter)="addShadowMethod(true)" (mouseleave)="addShadowMethod(false)">
                <button type="button" class="btn btn-light" [ngClass]="{'shadow': addShadow}" data-bs-dismiss="modal"
                (click)="hideLogOutConfirmation()">Cancel</button>
            </div>
            <div class="d-grid gap-2 mt-4"  (mouseenter)="addShadowMethod2(true)" (mouseleave)="addShadowMethod2(false)">
                <button type="button" class="btn btn-danger" [ngClass]="{'shadow': addShadow2}" data-bs-dismiss="modal"
                (click)="logOutOfAccount()">Log out</button>
            </div>
        </div>
      </div>
    </div>
  </div>

  