<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet"
  integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"
  integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL" crossorigin="anonymous"></script>

<div class="section">
  <div class="container">

    <!-- Main Content -->
    <div class="row justify-content-center">
      <div class="col-12">


        <!-- sub title -->
        <div class="row pb-3">
          <div class="col-10">
            <h2 class="fw-bold">
              What is your personal info?
            </h2>
          </div>
          <div class="col-2">
            <p class="fs-4">
              1/2
            </p>
          </div>
        </div>

        <!-- sub content questions - Stage 1 - Personal -->
        <div class="row">
          <div class="col-12 text-left">
            <label class="form-label fw-semibold" for="firstName">First Name</label>
            <input type="text" class="form-control" id="firstName" name="firstName" [ngClass]="isFirstNameValid"
              placeholder="eg. John" required [(ngModel)]="firstName" (focus)="resetValidation()">
            <div class="valid-feedback"></div>
            <div class="invalid-feedback">
              Please enter your first name!
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 text-left">
            <label class="form-label fw-semibold" for="lastName">Last Name</label>
            <input type="text" class="form-control" id="lastName" name="lastName" [ngClass]="isLastNameValid"
              placeholder="eg. Smith" required [(ngModel)]="lastName" (focus)="resetValidation()">
            <div class="valid-feedback"></div>
            <div class="invalid-feedback">
              Please enter your last name!
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
            <label for="selectID" class="form-label fw-semibold">ID Type</label>
            <select #selectID class="form-select" id="selectID" required [(ngModel)]="selectingIDType"
              [ngClass]="isIDTypeValid" name="selectingIDType" (change)="selectedIDType(selectID.value)">
              <option selected disabled value="" hidden="hidden">Select your ID type</option>
              <option *ngFor="let IDType of listOfIDTypes, index as i" [value]="i"
                [selected]="i.toString()==selectingIDType">{{IDType}}</option>
            </select>
            <div class="valid-feedback"></div>
            <div class="invalid-feedback">
              Please choose your ID Type!
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 text-left">
            <label class="form-label fw-semibold" for="IDNumber">ID Number {{idNumberText}}</label>
            <input type="text" class="form-control" id="IDNumber" name="IDNumber" [ngClass]="isIDNumberValid"
              placeholder={{placeholderText}} required [(ngModel)]="IDNumber" (focus)="resetValidation()" [disabled]="isIDNumberActive">
            <div class="valid-feedback"></div>
            <div class="invalid-feedback">
              Please enter your ID number!
            </div>
          </div>
        </div>

        <!-- <div class="spacer d-none d-md-block"></div> -->

        <div class="row">
          <div class="col-12 d-grid gap-1 mt-5">
            <button class="btn btn-primary" (click)="goToNext()">
              Next
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>