<section name="homeSection1" class="pb-5 mt-5 pt-2">
  <div class="container-xl pt-4 font-plus-jakarta-sans mt-5">
    <!-- // Start First Row -->
    <div class="row gx-5">

      <h3 class="text-center fw-bold">Checkout</h3>

      <!-- // Start Checkout Left Division -->
      <div class="col-md-6 mt-5">

        <p class="fs-6 fw-bold">Select your Billing Cycle</p>

        <!-- // Start Checkout Left Division First Inner Row -->
        <div class="row">

          <div class="col-md-6">

            <!-- // Start Billed Yearly Div -->
            <div class="custom-bd-top-light-blue-2 custom-bd-left-light-blue-2 p-3 pt-4 pb-2 rounded-4 shadow-sm">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked="checked" name="radio" value="yearly"
                (change)="changeSelectedBillingCycle($event)">
                <label class="form-check-label mt-0 ms-2" for="flexRadioDefault1">
                  <p class="mb-0 custom-margin-top-negative-1rem">Billed Yearly</p>
                  <p class="mb-0 fw-semibold mt-1">RM{{yearlyAmount}} / year</p>
                  <p class="mb-0 custom-font-size-13">(RM{{discountedMonthlyAmount}} / month)</p>
                </label>
              </div>
            </div>
            <!-- // End Billed Yearly Div -->

          </div>

          <div class="col-md-6">

             <!-- // Start Billed Monthly Div -->
            <div class="custom-bd-top-light-blue-2 mt-3 mt-md-0 custom-bd-left-light-blue-2 p-4 pb-3 rounded-4 shadow-sm">
              <div class="form-check">
                <input class="form-check-input mt-2" type="radio" name="radio" value="monthly"
                (change)="changeSelectedBillingCycle($event)">
                <label class="form-check-label mt-2 ms-2" for="flexRadioDefault1">
                  <p class="mb-0 custom-margin-top-negative-1rem">Billed Monthly</p>
                  <p class="mb-0 fw-semibold mt-1">RM{{monthlyAmount}} / month</p>
                </label>
              </div>
            </div>
            <!-- // End Billed Monthly Div -->

          </div>
        </div>
        <!-- // End Checkout Left Division First Inner Row -->

        <p class="fs-6 fw-bold mt-5">Select your Payment Method</p>

         <!-- // Start Checkout Left Division Second Inner Row -->
         <div class="row mt-4">

          <div class="col-md-6">

            <!-- // Start Visa Mastercard Radio Option -->
            <div class="form-check ms-3">
              <input class="form-check-input" type="radio" checked="checked" name="radio2" value="creditDebitCard" (change)="changeSelectedPaymentMethod($event)">
              <label class="form-check-label ms-2" for="flexRadioDefault1">
                <img class="custom-margin-top-negative-0P7rem" src="/assets/revamp/visaIconNew.png" width="59" height="40">
                <img class="custom-margin-top-negative-0P7rem ms-2" src="/assets/revamp/mastercardIconNew.png" width="58" height="37">
              </label>
            </div>
            <!-- // End Visa Mastercard Radio Option -->

          </div>

          <div class="col-md-6">
            
             <!-- // Start Online Banking Radio Option -->
             <div class="form-check ms-3 ms-md-4 mt-2 mt-md-0">
              <input class="form-check-input" type="radio" name="radio2" value="fpx" (change)="changeSelectedPaymentMethod($event)">
              <label class="form-check-label ms-2" for="flexRadioDefault1">
                <p class="custom-margin-top-negative-1rem">Online Banking</p>
              </label>
            </div>
            <!-- // End Online Banking Radio Option -->

          </div>
          
        </div>
        <!-- // End Checkout Left Division Second Inner Row -->

        <!-- // Start Select Bank Dropdown and Label -->
        <div  [style.display]="selectedPaymentMethod == 'fpx'? 'block':'none'" class="p-3 p-md-0">
          <label for="selectDocumentType" class="form-label fw-semibold mt-0 mt-md-4 custom-font-size-14">
            Select Bank
          </label>
          <select class="form-select bg-light text-secondary border-0 p-3" 
          aria-label="Default select example" #bankTypes id="bankTypes" (change)="changeSelectedBank(bankTypes.value)">
            <option value="" disabled selected hidden>eg. AmBank</option>
            <option *ngFor="let Bank of bankList" value={{Bank.id}}>
              <img src={{Bank.logo}}>
              <span>{{Bank.display_name}}</span>
            </option>
          </select>
        </div>
        <!-- // End Select Bank Dropdown and Label -->

        <!-- // Start Terms and Conditions Checkbox -->
        <div class="form-check mt-3 ms-1">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
          <label class="form-check-label custom-font-size-14" for="flexCheckDefault">
            I agree to the LegalBox Terms. Learn more about our 
            <span class="text-primary">Privacy Policy</span>
            .
          </label>
        </div>
        <!-- // End Terms and Conditions Checkbox -->

      </div>
      <!-- // End Checkout Left Division -->

      <!-- // Start Checkout Right Division -->
      <div class="col-md-6 mt-5 ps-4 ps-md-5">

        <p class="fs-6 fw-bold">Order Summary</p>

        <div class="custom-bd-top-light-blue-2 custom-bd-left-light-blue-2 p-4 rounded-4 shadow-sm">

          <p class="fs-6 fw-bold">{{selectedPricingPlan}} {{selectedPricingPlanStorageSizeInGB}}GB</p>
          <p>
            <span class="float-start">{{selectedBillingCycle == 'yearly'? 'Annual':'Monthly'}} subscription</span>
            <span class="float-end">RM{{selectedBillingCycle == 'yearly'? yearlyAmount: selectedBillingCycle == 'monthly'? monthlyAmount: ''}}</span>
          </p>

          <hr class="custom-margin-4rem" style="clear:both;">

          <p class="fw-bold mt-1 mb-5">
            <span class="float-start">Subtotal</span>
            <span class="float-end">RM{{selectedBillingCycle == 'yearly'? yearlyAmount: selectedBillingCycle == 'monthly'? monthlyAmount: ''}}</span>
          </p>

          <p class="custom-margin-top-8rem custom-font-size-14" style="clear:both;">
            You’ll be charged RM{{selectedBillingCycle == 'yearly'? yearlyAmount: selectedBillingCycle == 'monthly'? monthlyAmount: ''}} {{selectedBillingCycle}} until you cancel your subscription. 
            All amounts are shown in Ringgit Malaysia (RM). 
          </p>

          <div class="d-grid gap-2">
            <button class="btn btn-primary p-3" type="button" (click)="createUserMandate();">Confirm and Pay</button>
          </div>

        </div>

      </div>
      <!-- // End Checkout Right Division -->

    </div>
    <!-- // End First Row -->
  </div>
</section>