import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up-step1-industry',
  templateUrl: './sign-up-step1-industry.component.html',
  styleUrls: ['./sign-up-step1-industry.component.css']
})

export class SignUpStep1IndustryComponent implements OnInit {
  isValidated: boolean;
  isBusinessNameValid: string;
  isBusinessRegNoValid: string;
  businessName: any;
  businessRegNo: any;
  isBaseOptionSelected: boolean;
  isBusinessIndustryValid: string;
  businessIndustry: number;
  isOtherIndustryTextValid: string;
  otherIndustry: boolean;
  otherIndustryText: String;

  @Input() industryData: any;
  @Output() stage1IndustryData = new EventEmitter<any>();
  selectingIndustry: String;

  constructor(private router: Router) {
    this.isValidated = false;
    this.businessName = "";
    this.businessRegNo = "";
    this.businessIndustry = null;
    this.otherIndustry = false;
    this.otherIndustryText = "";

  }

  listOfIndustries = [
    'Select your industry',
    '1 - Agriculture',
    '2 - Construction',
    '3 - Consulting Services',
    '4 - Creative Industries',
    '5 - Education',
    '6 - Financial Services',
    '7 - Healthcare',
    '8 - Hospitality, Food, Leisure and Travel',
    '9 - IT Services',
    '10 - Law Firm',
    '11 - Logistics',
    '12 - Manufacturing',
    '13 - Professional Services',
    '14 - Retail / E-commerce',
    '15 - Telecommunications',
    '16 - Others'
  ];

  ngOnInit(): void {

    if (this.industryData) {
      this.isBaseOptionSelected = false;
      this.businessName = this.industryData.businessName;
      this.businessIndustry = this.industryData.businessIndustry;
      this.businessRegNo = this.industryData.businessRegNo;
      this.selectingIndustry = this.industryData.businessIndustry;
      if ('otherIndustryText' in this.industryData) {
        this.otherIndustry = true;
        this.otherIndustryText = this.industryData.otherIndustryText;
      }
    } else {
      this.selectingIndustry = '0';
    }
  }

  redirectToSignIn() {
    this.router.navigate(['SignIn']);
  }


  selectedIndustry(option: String) {
    let index: number = +option;
    if (index == 16) {
      this.otherIndustry = true;
    } else {
      this.otherIndustry = false;
    }
    this.businessIndustry = index;
  }

  goToNext() {
    if (this.businessName != "") {
      this.isBusinessNameValid = "is-valid";
      if (this.businessIndustry != null) {
        this.isBusinessIndustryValid = "is-valid";
        if (this.businessRegNo != "") {
          this.isBusinessRegNoValid = "is-valid";
          if (this.businessIndustry == 16) {
            if (this.otherIndustryText != "") {
              this.isOtherIndustryTextValid = "is-valid";
              this.industryData = {
                businessName: this.businessName,
                businessIndustry: this.businessIndustry,
                businessRegNo: this.businessRegNo,
                otherIndustryText: this.otherIndustryText,
                origin: "industryData"
              }
              this.stage1IndustryData.emit(this.industryData);
            } else {
              this.isOtherIndustryTextValid = "is-invalid";
            }
          } else {
            this.industryData = {
              businessName: this.businessName,
              businessIndustry: this.businessIndustry,
              businessRegNo: this.businessRegNo,
              origin: "industryData"
            }
            this.stage1IndustryData.emit(this.industryData);
          }
        } else {
          this.isBusinessRegNoValid = "is-invalid";
        }
      } else {
        this.isBusinessIndustryValid = "is-invalid";
      }
    } else {
      this.isBusinessNameValid = "is-invalid";
    }
  }

}
