

<section class="font-plus-jakarta-sans" [style.display]="showHeader? 'block':'none'">
    <nav class="navbar navbar-expand-md pb-5" [ngClass]="showHeaderLinks? (isServices? (isSmallScreen? 'navbar-dark bg-transparent':'navbar-light bg-transparent'): 'bg-white') : 'bg-transparent'">
      <div class="container-xl container-custom">
          <a class="navbar-brand navbar-brand-custom" href="#" >
            <img [src]="isServices? (isShowDivIf? '/assets/scratchImg/legalboxHeaderLogo.png':'/assets/scratchImg/legalboxWhiteHeaderLogo.png'):'/assets/scratchImg/legalboxHeaderLogo.png'" alt="LegalBox Logo" class="mt-1 w-50">
          </a>
          <button (click)="toggleDisplayDivIf()" class="navbar-toggler float-sm-end border-0" type="button" data-toggle="collapse" data-target="#navbarResponsive"
              aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              <div [style.display]="isShowDivIf? 'none':'block'">
                <span class="navbar-toggler-icon"></span>
              </div>
              <div  [style.display]="isShowDivIf? 'block':'none'">
                <span class="navbar-close-icon fw-bold text-black">X</span>
              </div>
              
          </button>
          <!-- *ngIf="!isShowDivIf" --- hide and show -->
          
          <div [ngClass]="isShowDivIf? (isSmallScreen? 'show increase-Height' : 'show') : ''" class="collapse navbar-collapse float-end" id="navbarResponsive">
              <ul class="navbar-nav me-auto nav-pills custom-cursor-pointer" >
                  <li class="nav-item text-left p-1  mt-5 mt-md-1 pt-5 pt-md-1 me-0 me-md-5" routerLinkActive="active" (click)="makeSpecificRouterLinkActive('Home')">
                    <a class="nav-link fs-5" (click)="changeRoute('Home')" [style.display]="showHeaderLinks || isSmallScreen?'block':'none'" [ngClass]="getHeaderLinkValueFromKey('Home')? 'fw-bold':(isServices? (isSmallScreen? 'fw-medium text-dark' : 'fw-medium text-white'): 'fw-medium text-dark')">
                      HOME
                    </a>
                  </li>
                  <li class="nav-item text-left p-1 mt-1 me-0 me-md-5" routerLinkActive="active" (click)="makeSpecificRouterLinkActive('AboutUs')">
                    <a class="nav-link text-dark fs-5 " (click)="changeRoute('AboutUs')" [style.display]="showHeaderLinks || isSmallScreen?'block':'none'" [ngClass]="isServices? (isSmallScreen? 'fw-bold text-dark': 'fw-bold text-white'): 'fw-medium text-dark'">
                      ABOUT US
                    </a>
                  </li>
                  <li class="nav-item text-left p-1 mt-1 me-0 me-md-5" routerLinkActive="active" (click)="makeSpecificRouterLinkActive('Login')">
                    <a class="nav-link text-dark fs-5" (click)="changeRoute('SignIn')" [style.display]="showHeaderLinks || isSmallScreen?'block':'none'" [ngClass]="getHeaderLinkValueFromKey('Login')? 'fw-bold':(isServices? (isSmallScreen? 'fw-medium text-dark' : 'fw-medium text-white'): 'fw-medium text-dark')"> 
                      LOGIN
                    </a>
                  </li>
                  <li class="nav-item text-left d-grid gap-2 p-1" routerLinkActive="active" (click)="makeSpecificRouterLinkActive('SignUp')">
                    <button routerLink="SignUpSelection" class="fw-medium btn btn-sm rounded px-4 py-2 fs-5"
                    [ngClass]="showHeaderLinks? (isServices? 'btn-outline-primary bg-white border-0 d-none d-md-block':'btn-primary d-none d-md-block'):'d-none'">
                      SIGN UP
                    </button>
                    <a class="nav-link fs-5 d-md-none d-lg-none d-xl-none d-xxl-none me-0 me-md-5"  (click)="changeRoute('SignUpSelection')"
                    [ngClass]="getHeaderLinkValueFromKey('SignUp')? 'fw-bold':(isServices? (isSmallScreen? 'fw-medium text-dark' : 'fw-medium text-white'): 'fw-medium text-dark')">
                      SIGN UP
                    </a>
                  </li>
                  
              </ul>
          </div>
      </div>
  </nav>
  
  
    
  </section>
        
