import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseUrl = environment.backendURL + '/api/users';

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  createNewIndividualUser(personalData: any, userName: any, emailAddress: any, password: string, clientId: string, reqToken: string): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("firstName", personalData.firstName);
    formData.append("lastName", personalData.lastName);
    formData.append("IDNumber", personalData.IDNumber);
    formData.append("userName", userName);
    formData.append("emailAddress", emailAddress);
    formData.append("password", password);
    formData.append("entityType", "individual");
    formData.append("clientId", clientId);


    formData.append("userId", "");
    formData.append("individualId", "");
    if (personalData.IDType == "0") {
      formData.append("IDType", "NRIC");
    } else if (personalData.IDType == "1") {
      formData.append("IDType", "Passport");
    }
    

    // For debug purposed enbale the debug value in environment file
    if (environment.debug) {
      console.log("entered createNewIndividualUser service");
    }
    
    let headers = new HttpHeaders()
    .set('Access-Token', reqToken); 

    const req = new HttpRequest('POST', `${this.baseUrl}/createNewIndividualUser`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }

  createNewBusinessUser(industryData: any ,userName: any, emailAddress: any, password: string, reqToken): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("businessName", industryData.businessName);
    formData.append("businessIndustry", industryData.businessIndustry);
    formData.append("businessRegistrationNumber", industryData.businessRegNo);
    formData.append("userName", userName);
    formData.append("emailAddress", emailAddress);
    formData.append("password", password);
    formData.append("entityType", "business");
    formData.append("userId", "");
    formData.append("businessId", "");
    if ('otherIndustryText' in industryData) {
      formData.append("otherIndustryText", industryData.otherIndustryText);
    }
    // For debug purposed enbale the debug value in environment file
    if (environment.debug) {
      console.log("entered createNewBusinessUser service");
    }

    let headers = new HttpHeaders()
    .set('Access-Token', reqToken); 

    const req = new HttpRequest('POST', `${this.baseUrl}/createNewBusinessUser`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }

  forgotPassword(email: any): Observable<HttpEvent<any>> {

    const formData: FormData = new FormData();
    formData.append("emailAddress", email);
    if (environment.debug) {
      console.log("entered forgotPassword service");
    }

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    const req = new HttpRequest('POST', `${this.baseUrl}/forgotPassword`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
    
  }

  checkAuthChangePassword(username: string, authToken: string): Observable<HttpEvent<any>> {
    let _username = username;
    let _token = authToken;
    if (environment.debug) {
      console.info("entered checkAuthChangePassword service");
    }
    
    const req = new HttpRequest('GET', `${this.baseUrl}/changePassword/${_username}/${_token}`, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
    
  }

  selectPricingPlan(userId, pricingPlanId): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("userId", userId);
    formData.append("pricingPlanId", pricingPlanId);
    if (environment.debug) {
      console.log("entered selectPricingPlan service");
    }

    let headers = new HttpHeaders()
    .set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/selectPricingPlan`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }

  sendActivationEmail(username: string, email: string, uid: string, uuid: string): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("userName", username);
    formData.append("emailAddress", email);
    formData.append("userId", uid);
    formData.append("userUuid", uuid);

    if (environment.debug) {
      console.log("entered sendActivationEmail service");
    }

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    const req = new HttpRequest('POST', `${this.baseUrl}/sendActivationLink`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }
  
  activateAccount(username, authLink): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("userName", username);
    formData.append("authLink", authLink);

    if (environment.debug) {
      console.log("entered activateAccount service");
    }

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    const req = new HttpRequest('POST', `${this.baseUrl}/activateAccount`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }

   retrieveUserDashboardClientStorage(userId): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("userId", userId);
    if (environment.debug) {
      console.log("entered retrieveUserDashboardClientStorage service");
    }

    let headers = new HttpHeaders()
    .set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/retrieveUserDashboardClientStorage`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }    

  displayUserSpecificLegalDocument(userId, documentTitle, isClient, sharedBy): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("userId", userId);
    // formData.append("lawFirmID", "");
    formData.append("businessID", "");
    formData.append("individualID", "");
    formData.append("clientID", "");
    formData.append("documentTitle", documentTitle);
    formData.append("CIDHash", "");
    formData.append("initVectorCipherText", "");
    formData.append("securityKeyCipherText", "");
    formData.append("fileUUID", "");
    formData.append("fileExtension", "");
    formData.append("dateNow", "");
    formData.append("encryptedLegalDocumentFileContent", "");
    formData.append("pinataDownloadUltimateFilePath", "");
    formData.append("temporaryCacheUltimateFilePath", "");
    formData.append("isClient", isClient);
    formData.append("isSharedBy", sharedBy);
    if (environment.debug) {
      console.log("entered displayUserSpecificLegalDocument service");
    }

    let headers = new HttpHeaders().set('Access-Token', this.tokenStorageService.getToken());

    const req = new HttpRequest('POST', `${this.baseUrl}/displayUserSpecificLegalDocument`, formData, {
      reportProgress: true,
      responseType: 'blob',
      headers: headers,
    });

    return this.http.request(req);
  }    

  getUserLegalDocumentsList(userId, introId, isClient): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("userId", userId);
    // formData.append("lawFirmID", "");
    formData.append("businessID", "");
    formData.append("individualID", "");
    formData.append("clientID", "");
    formData.append("isValidBID", introId);
    formData.append("isClient", isClient);
    if (environment.debug) {
      console.log("entered getUserLegalDocumentsList service");
      console.log(introId);
    }

    let headers = new HttpHeaders().set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/getUserLegalDocumentsList`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }

  getSharedBusinessLegalDocumentsList(userId, sharedByID): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("userId", userId);
    formData.append("isSharedBy", sharedByID);
    formData.append("clientID", "");
    formData.append("isClient", "true");
    if (environment.debug) {
      console.log("entered getSharedBusinessLegalDocumentsList service");
    }

    let headers = new HttpHeaders().set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/getSharedBusinessLegalDocumentsList`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }
  
  getUserArchivedDocumentsList(userId, isClient): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("userId", userId);
    // formData.append("lawFirmID", "");
    formData.append("businessID", "");
    formData.append("individualID", "");
    formData.append("clientID", "");
    formData.append("isClient", isClient);
    if (environment.debug) {
      console.log("entered getUserArchivedDocumentsList service");
    }

    let headers = new HttpHeaders().set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/getUserArchiveLegalDocumentsList`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }

  archivedDocument(introUserId ,user, isClient, docId: string | Blob): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append("userId", user); 
    formData.append("documentID", docId);
    formData.append("isClient", isClient);
    console.log("entered user service");
    if (introUserId == null) {
      formData.append("introducerId", "")
    } else {
      formData.append("introducerId", introUserId)
    }

    if (environment.debug) {
      console.log("entered archivedDocument service");
    }

    let headers = new HttpHeaders().set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/archiveDocument`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }

  restoreArchivedDocument(user, isClient, docId: string | Blob): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("userId", user);
    formData.append("documentID", docId);
    formData.append("isClient", isClient);
    if (environment.debug) {
      console.log("entered restoreArchivedDocument service");
    }

    let headers = new HttpHeaders().set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/restoreDocument`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }

  retrieveUserIDByClientID(clientId): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("clientId", clientId);
    if (environment.debug) {
      console.log("entered retrieveUserIDByClientID service");
    }

    let headers = new HttpHeaders().set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/retrieveUserIDByClientID`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }    

  uploadNewFiles(file:File, fileName, uploadDestination, userId, clientID, documentTitle,
    fileSizeInBytes, documentDescription, documentTypeId, isSharedBy): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append('fileToBeUploaded', file, "legalDocuments;" + fileName);
    formData.append("uploadDestination", uploadDestination);
    formData.append("userId", userId);
    // formData.append("lawFirmID", "");
    formData.append("businessID", "");
    formData.append("individualID", "");
    formData.append("clientID", clientID);
    formData.append("documentTitle", documentTitle);
    formData.append("fileSizeInBytes", fileSizeInBytes);
    formData.append("documentDescription", documentDescription);
    formData.append("documentTypeID", documentTypeId);
    formData.append("fileId", "");
    formData.append("fileUUID", "");
    formData.append("ipfsHash", "");
    formData.append("transactionHash", "");
    formData.append("smartContractFunction", "");
    formData.append("isSharedBy", isSharedBy);
    if (environment.debug) {
      console.log("entered uploadNewFiles service");
    }

    let headers = new HttpHeaders().set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/uploadNewFiles`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }

  retryUploadToBlockchain(userId, docID, documentTitle, documentDescription, documentIPFSHash): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("userId", userId);
    formData.append("documentTitle", documentTitle);
    formData.append("documentDescription", documentDescription);
    formData.append("ipfsHash", documentIPFSHash);
    formData.append("fileId", docID);
    formData.append("transactionHash", "");
    formData.append("smartContractFunction", "");
    if (environment.debug) {
      console.log("entered retryUploadToBlockchain service");
    }

    let headers = new HttpHeaders().set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/uploadToBlockchain`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }  

  viewAllClients(userId): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("userId", userId);
    // formData.append("lawFirmId", "");
    formData.append("businessId", "");
    if (environment.debug) {
      console.log("entered viewAllClients service");
    }

    let headers = new HttpHeaders()
    .set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/viewAllClients`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }

  viewAllBusiness(userId): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("userId", userId);
    if (environment.debug) {
      console.log("entered viewAllBusiness service");
    }

    let headers = new HttpHeaders()
    .set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/viewAllBusiness`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  } 

  checkClientUser(emailAddress: string | Blob, businessId: string | Blob): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("emailAddress", emailAddress);
    formData.append("businessId", businessId);
    if (environment.debug) {
      console.log("entered checkClientUser service");
    }

    let headers = new HttpHeaders().set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/checkClientUser`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }
  
  checkClientAuthToken(authToken: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append("authToken", authToken);
    if (environment.debug) {
      console.log("entered check auth for client service");
    }
    
    // let headers = new HttpHeaders().set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/retrieveEmail`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }
  
  createNewClientUser(emailAddress: string | Blob, introducingUserId: string | Blob, clientAllocatedStorageSizeInGB: string | Blob): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("emailAddress", emailAddress);
    formData.append("introducingUserId", introducingUserId);
    formData.append("clientAllocatedStorageSizeInGB", clientAllocatedStorageSizeInGB);
    formData.append("userId", "");
    formData.append("clientId", "");
    if (environment.debug) {
      console.log("entered createNewClientUser service");
    }

    let headers = new HttpHeaders().set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/createNewClientUser`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  } 

  signInVerifyUser(username, password, token): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("environment", "demoEnvironment");
    formData.append("userId", "");
    formData.append("mandateId", "");
    formData.append("userType", "");
    if (environment.debug) {
      console.log("entered signInVerifyUser service");
    }

    let headers = new HttpHeaders()
    .set('Access-Token', token); 

    const req = new HttpRequest('POST', `${this.baseUrl}/signInVerifyUser`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }

  changePassword(username: string, sauthToken: string, hashedPass: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append("username", username);
    formData.append("authToken", sauthToken);
    formData.append("hashPass", hashedPass);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    const req = new HttpRequest('POST', `${this.baseUrl}/changePassword`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
    
  }

 
  createUserMandate(userId, pricingPlanId, selectedBillingCycle, selectedPaymentMethod, selectedBankId): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("userId", userId);
    formData.append("pricingPlanId", pricingPlanId);
    formData.append("selectedBillingCycle", selectedBillingCycle);
    formData.append("selectedPaymentMethod", selectedPaymentMethod);
    formData.append("selectedBankId", selectedBankId);
    formData.append("mandateId", "");
    if (environment.debug) {
      console.log("entered createUserMandate service");
    }

    let headers = new HttpHeaders().set('Access-Token', this.tokenStorageService.getToken()); 

    const req = new HttpRequest('POST', `${this.baseUrl}/createUserMandate`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }

  get(id): Observable<any> {

    const header ={
      'Access-Token':this.tokenStorageService.getToken()
    }

    const opt = {
      headers: new HttpHeaders(header),
    }
    
    return this.http.get(`${this.baseUrl}/${id}`, opt);
  }

}