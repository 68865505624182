import { Component, OnInit, HostBinding, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { HttpResponse } from '@angular/common/http';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
import { TransactionService } from 'src/app/services/transaction.service';


@Component({
  selector: 'app-user-documents',
  templateUrl: './user-documents.component.html',
  styleUrls: ['./user-documents.component.css']
})
export class UserDocumentsComponent implements OnInit {
  pageTitle = 'My Documents';
  displayAddNewDropdownContent = false;
  message: any;
  corporateUser = false;
  corporateInstitutionUser = false;
  retrievedLegalDocuments: any[] = [];
  legitUserId: any;
  retrievedUser: any;
  userType: any;
  finalSumOfClientStorageInGB: any;
  finalSumOfClientStorageInMB: any;
  finalSumOfCorporateInstitutionUsageInGB: any;
  finalSumOfCorporateInstitutionUsageInMB: any;
  storageBalanceInGB: any;
  storageBalanceInMB: any;
  corporateInstitutionStorageValue: any;
  corporateInstitutionStorageValueInPercentage: any;
  clientStorageValueInPercentage: any;
  showMoreOptions: boolean;
  displayDeleteMessage: boolean;
  documentTitle: string;
  dropdownDocumentID: string;
  documentID: any;
  displayLoadingMessage: boolean;
  tryingBCUpload: boolean;
  isPopupVisible: any;
  longPressDocumentID: any;
  documentTitleColspan: any;

  displayResponseMessage = false;
  responseType: any;
  responseMessageText: any;
  responseTypeColor: any;
  responseMessageIconImageURL: any;
  successMessageIconImageURL = "/assets/revamp/successGreenIconNew.png";
  errorMessageIconImageURL = "/assets/revamp/redExclamationMark.png";
  displaySessionMessage = false;
  messageToBeDisplayed = "";
  errorType = "";
  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    console.log("isSmallScreen from Home Component: " + this.isSmallScreen);
  }

  @HostBinding("style.--percentage")
  // set percentage of storage used here
  public allocatedStorageSizeInGB: any;
  public allocatedStorageSizeInMB: any;
  public usedStorageSizeInGB: any;
  public usedStorageSizeInMB: any;
  public value = '65%';
  public lawFirmStorageValue = '15%';
  public clientStorageValue = '50%';
  public usedStorage = this.value.split('%')[0] + ' GB';

  @HostBinding("attr.style")
  public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(`--percentage: ${this.value}`);
  }


  constructor(private router: Router, private sanitizer: DomSanitizer, private userService: UserService,
    private tokenStorageService: TokenStorageService, private transactionService: TransactionService) {

  }

  ngOnInit(): void {
    this.pageTitle = 'My Documents';
    this.displaySessionMessage = false;
    this.messageToBeDisplayed = "";
    this.errorType = "";
    this.displayLoadingMessage = false;
    this.showMoreOptions = false;
    this.displayDeleteMessage = false;
    this.tryingBCUpload = false;
    let retrievedUser = this.tokenStorageService.getUser();
    if (environment.debug) {
      console.log("retrievedUser.userType:", retrievedUser);
    }
    if (retrievedUser.userType == "business" || retrievedUser.userType == "individual") {
      this.corporateUser = true;
    }
    this.getUser(retrievedUser.userId);
    this.getUserLegalDocumentsList(retrievedUser.userId);
  }

  localFormatDate(_date) {
    let monthNumericForm = formatDate(_date, 'MM', 'en-US');
    let monthStringForm = this.monthNumberToString(monthNumericForm);
    // console.log("monthStringForm: " + monthStringForm);
    let date_Year = formatDate(_date, 'dd, yyyy', 'en-US');
    let final_date = monthStringForm + " " + date_Year;
    return final_date;
  }

  private timeout: any;

  onLongPress(event: Event, legalDocumentID: any): void {
    this.longPressDocumentID = legalDocumentID;
    console.log('Long press detected!', event);
    // Your logic for long press handling goes here
  }

  showPopup() {
    this.isPopupVisible = true;
  }

  hidePopup() {
    this.isPopupVisible = false;
  }

  monthNumberToString(_month) {
    var returnMonth;

    switch (_month) {
      case "01": returnMonth = "January"; break;
      case "02": returnMonth = "February"; break;
      case "03": returnMonth = "March"; break;
      case "04": returnMonth = "April"; break;
      case "05": returnMonth = "May"; break;
      case "06": returnMonth = "June"; break;
      case "07": returnMonth = "July"; break;
      case "08": returnMonth = "August"; break;
      case "09": returnMonth = "September"; break;
      case "10": returnMonth = "October"; break;
      case "11": returnMonth = "November"; break;
      case "12": returnMonth = "December"; break;
    }

    return returnMonth;
  }

  viewAllClients(userId, userUsedStorageSizeInGB, userAllocatedStorageSizeInGB) {

    this.userService.viewAllClients(
      userId,
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.message:" + event.body.message);
          }
          this.message = event.body.message;
          let clientArray = event.body.clientArray;
          if (environment.debug) {
            console.log("clientArray: " + clientArray);
          }
          let sumOfClientStorage = 0.00;
          if (environment.debug) {
            console.log("sumOfClientStorageBeforeLoop: " + sumOfClientStorage);
          }
          for (let i = 0; i < clientArray.length; i++) {
            if (environment.debug) {
              console.log("entered loop");
              console.log(clientArray[i]);
            }
            sumOfClientStorage = sumOfClientStorage + parseFloat(clientArray[i].allocatedStorageSizeInGB);
            if (environment.debug) {
              console.log("sumOfClientStorageAfterLoop " + i + ":" + sumOfClientStorage);
            }
          }
          if (environment.debug) {
            console.log("sumOfClientStorageAfterLoop: " + sumOfClientStorage);
          }
          let userAllocatedStorageSizeInMB = userAllocatedStorageSizeInGB * 1000;

          this.finalSumOfClientStorageInGB = sumOfClientStorage;
          this.finalSumOfCorporateInstitutionUsageInGB = parseFloat(userUsedStorageSizeInGB) - parseFloat(this.finalSumOfClientStorageInGB);
          this.storageBalanceInGB = userAllocatedStorageSizeInGB - this.finalSumOfCorporateInstitutionUsageInGB - this.finalSumOfClientStorageInGB;

          if (userAllocatedStorageSizeInGB < 1) { // start if statement
            this.finalSumOfCorporateInstitutionUsageInMB = this.finalSumOfCorporateInstitutionUsageInGB * 1000;
            this.finalSumOfCorporateInstitutionUsageInMB = parseFloat(this.finalSumOfCorporateInstitutionUsageInMB).toFixed(2);
            this.corporateInstitutionStorageValue = ((this.finalSumOfCorporateInstitutionUsageInMB / userAllocatedStorageSizeInMB) * 100).toString();
            this.corporateInstitutionStorageValueInPercentage = this.corporateInstitutionStorageValue + "%";

            this.finalSumOfClientStorageInMB = this.finalSumOfClientStorageInGB * 1000;
            this.finalSumOfClientStorageInMB = parseFloat(this.finalSumOfClientStorageInMB).toFixed(2);
            this.clientStorageValue = ((this.finalSumOfClientStorageInMB / userAllocatedStorageSizeInMB) * 100).toString();
            this.clientStorageValueInPercentage = this.clientStorageValue + "%";

            this.storageBalanceInMB = this.storageBalanceInGB * 1000;
            this.storageBalanceInMB = parseFloat(this.storageBalanceInMB).toFixed(2);
          } // end if statement
          else if (userAllocatedStorageSizeInGB >= 1) { // start else if statement
            this.finalSumOfCorporateInstitutionUsageInGB = parseFloat(this.finalSumOfCorporateInstitutionUsageInGB).toFixed(2);
            this.corporateInstitutionStorageValue = ((this.finalSumOfCorporateInstitutionUsageInGB / userAllocatedStorageSizeInGB) * 100).toString();
            this.corporateInstitutionStorageValueInPercentage = this.corporateInstitutionStorageValue + "%";

            this.finalSumOfClientStorageInGB = parseFloat(this.finalSumOfClientStorageInGB).toFixed(2);
            this.clientStorageValue = ((this.finalSumOfClientStorageInGB / userAllocatedStorageSizeInGB) * 100).toString();
            this.clientStorageValueInPercentage = this.clientStorageValue + "%";

            this.storageBalanceInGB = parseFloat(this.storageBalanceInGB).toFixed(2);
          } // end else if statement

          //  console.log("clientArray[i]: " + Object.keys(this.clientArray[0]));

          // return event.body.message;
        }
        else {
          if (environment.debug) {
            console.log("none from User Dashboard Component");
          }
          let userAllocatedStorageSizeInMB = userAllocatedStorageSizeInGB * 1000;

          this.finalSumOfClientStorageInGB = 0;
          this.finalSumOfCorporateInstitutionUsageInGB = parseFloat(userUsedStorageSizeInGB) - parseFloat(this.finalSumOfClientStorageInGB);
          this.storageBalanceInGB = userAllocatedStorageSizeInGB - this.finalSumOfCorporateInstitutionUsageInGB - this.finalSumOfClientStorageInGB;

          if (userAllocatedStorageSizeInGB < 1) { // start if statement
            this.finalSumOfCorporateInstitutionUsageInMB = this.finalSumOfCorporateInstitutionUsageInGB * 1000;
            this.finalSumOfCorporateInstitutionUsageInMB = parseFloat(this.finalSumOfCorporateInstitutionUsageInMB).toFixed(2);
            this.corporateInstitutionStorageValue = ((this.finalSumOfCorporateInstitutionUsageInMB / userAllocatedStorageSizeInMB) * 100).toString();
            this.corporateInstitutionStorageValueInPercentage = this.corporateInstitutionStorageValue + "%";

            this.finalSumOfClientStorageInMB = this.finalSumOfClientStorageInGB * 1000;
            this.finalSumOfClientStorageInMB = parseFloat(this.finalSumOfClientStorageInMB).toFixed(2);
            this.clientStorageValue = ((this.finalSumOfClientStorageInMB / userAllocatedStorageSizeInMB) * 100).toString();
            this.clientStorageValueInPercentage = this.clientStorageValue + "%";

            this.storageBalanceInMB = this.storageBalanceInGB * 1000;
            this.storageBalanceInMB = parseFloat(this.storageBalanceInMB).toFixed(2);
          } // end if statement
          else if (userAllocatedStorageSizeInGB >= 1) { // start else if statement
            this.finalSumOfCorporateInstitutionUsageInGB = parseFloat(this.finalSumOfCorporateInstitutionUsageInGB).toFixed(2);
            this.corporateInstitutionStorageValue = ((this.finalSumOfCorporateInstitutionUsageInGB / userAllocatedStorageSizeInGB) * 100).toString();
            this.corporateInstitutionStorageValueInPercentage = this.corporateInstitutionStorageValue + "%";

            this.finalSumOfClientStorageInGB = parseFloat(this.finalSumOfClientStorageInGB).toFixed(2);
            this.clientStorageValue = ((this.finalSumOfClientStorageInGB / userAllocatedStorageSizeInGB) * 100).toString();
            this.clientStorageValueInPercentage = this.clientStorageValue + "%";

            this.storageBalanceInGB = parseFloat(this.storageBalanceInGB).toFixed(2);
          } // end else if statement


        }
      },
      err => {
        // this.progress = 0;
        this.message = 'Could not create law firm user!';
        // alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }


  /**
 * format bytes
 * @param bytes (File size in bytes)
 * @param decimals (Decimals point)
 */

  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  displayUserSpecificLegalDocument(_documentTitle) {

    this.userService.displayUserSpecificLegalDocument(
      this.legitUserId,
      _documentTitle,
      "false",
      null
    ).subscribe(
      event => {


        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event):" + Object.keys(event));
            console.log("event.body.size: " + event.body.size);
            console.log("event.body.type: " + event.body.type);
          }
          let fileURL = window.URL.createObjectURL(event.body);
          if (environment.debug) {
            console.log("fileURL: " + fileURL);
          }
          // window.open(fileURL, '_self');
          window.open(fileURL, '_blank');
          // console.log("event.body.message:" + event.body.message);
          // this.message = event.body.message;



          // return event.body.message;
        }
      },
      err => {
        // this.progress = 0;
        // this.message = 'Could not create law firm user!';
        // alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  getUser(id): void {
    this.userService.get(id)
      .subscribe(
        data => {
          if (environment.debug) {
            console.log(data);
          }
          if (data.status == 403) {
            alert("Your session has expired. Please login again!");
            this.tokenStorageService.signOut();
            this.router.navigate(['SignIn']);
          }
          this.retrievedUser = data;
          this.legitUserId = this.retrievedUser.id;

          if (this.retrievedUser.userType == "business") {
            this.corporateInstitutionUser = true;
            this.userType = "Business";
            // retrieve user's lawFirmID
            // retrieve clientIDs based on lawFirmID
            // retrieve userIDs based on clientIDs
            // retrieve user's allocatedStorageSizeInGB for each userID and return sum of all allocatedStorageSizeInGB
            // parse value to float with 0 decimal places
            if (environment.debug) {
              console.log("getUser function lawFirm / business checking if statement!");
            }
            this.viewAllClients(this.retrievedUser.id, this.retrievedUser.usedStorageSizeInGB, this.retrievedUser.allocatedStorageSizeInGB);

          }


          this.allocatedStorageSizeInGB = data.allocatedStorageSizeInGB;
          this.usedStorageSizeInGB = data.usedStorageSizeInGB;

          if (this.allocatedStorageSizeInGB < 1) {
            this.allocatedStorageSizeInMB = this.allocatedStorageSizeInGB * 1000;
            this.allocatedStorageSizeInMB = parseFloat(this.allocatedStorageSizeInMB).toFixed(2);
            this.usedStorageSizeInMB = this.usedStorageSizeInGB * 1000;
            this.usedStorageSizeInMB = parseFloat(this.usedStorageSizeInMB).toFixed(2);
            this.value = ((this.usedStorageSizeInMB / this.allocatedStorageSizeInMB) * 100).toString();
            this.value = this.value + "%";
          }
          else if (this.allocatedStorageSizeInGB >= 1) {
            this.allocatedStorageSizeInGB = parseFloat(this.allocatedStorageSizeInGB).toFixed(2);
            this.usedStorageSizeInGB = parseFloat(this.usedStorageSizeInGB).toFixed(2);
            this.value = ((this.usedStorageSizeInGB / this.allocatedStorageSizeInGB) * 100).toString();
            this.value = this.value + "%";
          }
        },
        error => {
          console.error(error);
        });
  }


  getUserLegalDocumentsList(userId) {

    this.userService.getUserLegalDocumentsList(
      userId,
      "",
      "false"
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.retrievedLegalDocuments:" + event.body.retrievedLegalDocuments);
          }
          // this.message = event.body.message;
          this.retrievedLegalDocuments = event.body.retrievedLegalDocuments;

          // return event.body.message;
        }
      },
      err => {
        // this.progress = 0;
        // this.message = 'Could not create law firm user!';
        // alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  toggleAddNewDropdownContentDisplay() {
    this.displayAddNewDropdownContent = !this.displayAddNewDropdownContent;
  }

  redirectToAddNewClient() {
    this.router.navigate(['AddNewClient']);
  }

  redirectToUploadNewFiles() {
    // this.router.navigate(['UploadNewFiles']);
    this.router.navigate(['UploadNewFiles'],
      {
        state:
        {
          origin: "userDocumentsComponent",
        }
      }
    );
  }

  retrieveSmartContractFunctionTransactionHash(legalDocumentID, smartContractFunction) {

    this.transactionService.retrieveSmartContractFunctionTransactionHash(
      legalDocumentID,
      smartContractFunction,
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
          }
          // console.log("event.body.message:" + event.body.message);
          // this.message = event.body.message;
          let transaction = JSON.parse(JSON.stringify(event.body.transaction));
          if (environment.debug) {
            console.log("transaction: " + Object.keys(transaction));
          }
          let legalDocumentTransactionHashURL = environment.blockExplorerURL + transaction.transactionHash;
          window.open(legalDocumentTransactionHashURL, "_blank");

          // return event.body.message;
        }
      },
      err => {
        // this.progress = 0;
        // alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  redirectToBlockExplorerURL(legalDocumentID) {
    this.retrieveSmartContractFunctionTransactionHash(legalDocumentID, "storeLegalDocumentIPFSHash");
  }

  redirectToIPFSHashURL(legalDocumentCIDHash) {
    let ipfsHashURL = environment.pinataGateway + legalDocumentCIDHash;
    window.open(ipfsHashURL, "_blank");
  }

  dropdownOptions(docID) {
    this.dropdownDocumentID = docID;
    if (!this.showMoreOptions) {
      this.showMoreOptions = true;
    } else {
      this.showMoreOptions = false;
    }
  }

  cancelDel() {
    this.displayDeleteMessage = false;
  }

  deleteDocumentPopUp(docID: any, docTitle: string) {
    this.documentTitle = docTitle;
    this.documentID = docID;
    this.displayDeleteMessage = true;
  }

  archiveDocument() {
    // Archive the document
    this.displayLoadingMessage = true;
    this.userService.archivedDocument(null, this.retrievedUser.id, "false", this.documentID).subscribe({
      next: (event) => {
        if (event instanceof HttpResponse) {
          if (event.body.status == 500) {
            alert("Error: " + event.body.message);
          } else if (event.body.status == 404) {
            alert("Error: " + event.body.message);
          } else {
            this.displayDeleteMessage = false;
            this.responseTypeColor = "#0BB831";
            this.responseMessageIconImageURL = this.successMessageIconImageURL;
            this.responseType = "Success";
            this.responseMessageText = "File successfully deleted!";
            this.displayResponseMessage = true;
            this.displayLoadingMessage = false;
          }
        }
      },
      error: (err) => {
        // Response error from backend / Fail to send forgot password request
        this.responseTypeColor = "#EE4E55";
        this.responseMessageIconImageURL = this.errorMessageIconImageURL;
        this.responseType = "Error";
        this.responseMessageText = err.error.message;
        this.displayResponseMessage = true;
        this.displayLoadingMessage = false;
      }
    });

  }

  retryUploadToBlockchain(docID, docTitle, docDescription, docIPFSHash) {
    this.tryingBCUpload = true;
    this.userService.retryUploadToBlockchain(
      this.retrievedUser.id,
      docID,
      docTitle,
      docDescription,
      docIPFSHash).subscribe({
        next: (event) => {
          if (event instanceof HttpResponse) {
            if (environment.debug) {
              console.log("event:", event);
              console.log("Object.keys(event.body):" + Object.keys(event.body));
              console.log("event.body.message:", event.body.message);
            }
            if (event.body.status == 500) {
              alert("Error: " + event.body.message);
            } else if (event.body.status == 404) {
              alert("Error: " + event.body.message);
            } else {
              this.displayDeleteMessage = false;
              this.responseTypeColor = "#0BB831";
              this.responseMessageIconImageURL = this.successMessageIconImageURL;
              this.responseType = "Success";
              this.responseMessageText = "File successfully uploaded to Polygon";
              this.displayResponseMessage = true;
              this.displayLoadingMessage = false;
            }
          }
        },
        error: (err) => {
          this.responseTypeColor = "#EE4E55";
          this.responseMessageIconImageURL = this.errorMessageIconImageURL;
          this.responseType = "Error";
          this.responseMessageText = err.error.message;
          this.displayResponseMessage = true;
          this.displayLoadingMessage = false;
        }
      })
  }

  closeResponseMessage() {
    this.displayResponseMessage = false;
    const url = self ? this.router.url : '/UserDocuments';
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/${url}`]).then(() => {
        if (environment.debug) {
          console.log(`After navigation I am on:${this.router.url}`)
        }
      })
    });
  }

}
