<section name="errorSection1" class="pb-5">
    <div class="container-xl pt-4 font-plus-jakarta-sans">
      <!-- // Start First Row -->
      <div class="row">

        <!-- // Start Error Page Left Section -->
        <div class="col-md-6">
            <h1 class="display-3 fw-bold">
                <span class="d-inline-block text-primary">Error 404</span>
                <span class="d-inline-block">Page not found</span>
            </h1>
        </div>
        <!-- // End Error Page Left Section -->

        <!-- // Start Error Page Right Section -->
        <div class="col-md-6">

        </div>
        <!-- // End Error Page Right Section -->

    </div>
    <!-- // End First Row -->
  </div>
</section>

