

<section class="footer" [style.display]="showFooter? 'block':'none'">
    <div class="container-xl">
    <div class="row justify-content-center py-5 font-roboto custom-letter-spacing">

        <div class="col-md-4 col-sm-3 pt-3 text-light d-flex justify-content-center" >
            <div [ngClass]="isBigScreen?'big-screen-padding-left':'pl-2'"> 
                <img routerLink="Home" src="/assets/scratchImg/legalboxFooterLogo.png" alt="LegalBox Logo" class="pb-4 w-50" >
                <p class="fs-6">
                    LegalBox is a document storage platform that utilises blockchain to safeguard your digital assets by creating
                    a sanctuary for your documents, ensuring its security and privacy.
                </p>
            </div>
        </div>

        <div class="col-md-3 pt-3 text-light d-md-flex justify-content-center mx-auto pl-4">
            <div [ngClass]="isBigScreen?'big-screen-padding-left-large':''">
                <p class="pb-3 fs-4 font-weight-bold">
                    Company Info
                </p>
                <ul class="nav flex-column fs-6">
                    <li class="nav-item pb-3" routerLink="AboutUs">
                      About Us
                    </li>
                    <li class="nav-item pb-3" (click)="goToLink('https://legalbox.gitbook.io/legalbox-faq/')">
                      FAQ
                    </li>
                    <li class="nav-item pb-3" routerLink="TermAndPolicies">
                      Terms & Policies
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-3 pt-3 text-light d-md-flex justify-content-center mx-auto pl-4">
            <div [ngClass]="isBigScreen?'big-screen-padding-left-large':''">
                <p class="pb-3 fs-4 font-weight-bold">
                    Get In Touch
                </p>
                <ul class="nav flex-column fs-6">
                    <li class="nav-item pb-3" (click)="goToLink('https://www.instagram.com/legalbox23/')">
                        <img src="/assets/scratchImg/instagramFooterIcon.svg" class="pe-1">
                        Instagram
                    </li>
                    <li class="nav-item pb-3" (click)="goToLink('https://www.tiktok.com/@_legalbox?is_from_webapp=1&sender_device=pc')">
                        <img src="/assets/scratchImg/tiktokFooterIcon.png" class="smicon pe-1">
                        TikTok
                    </li>
                    <li class="nav-item pb-3" (click)="goToLink('https://www.facebook.com/LegalBox-101368672997713/')">
                        <img src="/assets/scratchImg/facebookFooterIcon.png" class="smicon pe-1">
                        Facebook
                    </li>
                </ul>

            </div>
        </div>

        <div class="col-md-2 col-sm-3 pt-3 text-light d-md-flex justify-content-center">
            <div [ngClass]="isBigScreen?'big-screen-padding-right':'pl-2'">
                <p class="pb-3 fs-4 font-weight-bold">
                    Easy and Safe Payment
                </p>
                <div class="row">
                    <div class="col-3 col-md-4 col-sm-3">
                        <img src="/assets/scratchImg/paypalFooterLogo.png" alt="Paypal Footer Logo" width="30" height="30">
                    </div>
                    <div class="col-3 col-md-4 col-sm-3">
                        <img src="/assets/scratchImg/visaFooterLogo.png" alt="Visa Footer Logo" width="30" height="30">
                    </div>
                    <div class="col-3 col-md-4 col-sm-3">
                        <img src="/assets/scratchImg/mastercardFooterLogo.png" alt="Mastercard Footer Logo" width="30" height="30">
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12 pt-3 mt-5 text-white-50 d-md-flex justify-content-center fs-6" [ngClass]="isBigScreen?'big-screen-padding-right':'ml-2'">
            © 2019 - 2023 LegalBox. All rights reserved. 
        </div>

    </div>
</div>
</section>
