<section name="homeSection1" class="pb-5">
  <div class="container-xl pt-4 font-plus-jakarta-sans">

    <!-- // Start First Row -->
    <div class="row">
     <h1 class="display-6 fw-bold text-center">
      Terms & Policies
     </h1>
     <p class="text-center text-secondary fs-5 mt-2">
      Last update : May 16, 2022
     </p>
    </div>
    <!-- // End First Row -->

    <!-- // Start Second Row -->
    <div class="row mt-3">

      <!-- // Start Second Row Inner First Row -->
      <div class="row">
        <div class="col-md-4 offset-md-3">
          <ul class="nav nav-underline d-flex justify-content-start text-secondary" id="myTab" role="tablist">
            <li class="nav-item ps-5 ps-md-0" role="presentation">
              <button class="nav-link" id="privacy-policy-tab" data-bs-toggle="tab" data-bs-target="#privacy-policy-tab-pane" type="button" role="tab" aria-controls="privacy-policy-tab-pane" aria-selected="false" (click)="makeSpecificRouterLinkActive('showPrivacyPolicy')">Privacy Policy</button>
            </li>
            <li class="nav-item ms-3" role="presentation">
              <button class="nav-link" id="term-of-service-tab" data-bs-toggle="tab" data-bs-target="#term-of-service-tab-pane" type="button" role="tab" aria-controls="term-of-service-tab-pane" aria-selected="true" (click)="makeSpecificRouterLinkActive('showTermOfService')">Term of Service</button>
            </li>
          </ul>
        </div>
      </div>
      <!-- // End Second Row Inner First Row -->

      <hr class="mt-1">

      <div class="tab-content" id="myTabContent">

        <!-- // Start Privacy Policy Tab Content -->
        <div class="tab-pane fade" [ngClass]="getHeaderLinkValueFromKey('showPrivacyPolicy')? 'show active':''" id="privacy-policy-tab-pane" role="tabpanel" aria-labelledby="privacy-policy-tab" tabindex="0">

          <!-- // Start Second Row Inner Second Row -->
          <div class="row">

            <div class="col-md-3">
              <nav class="navbar navbar-expand-lg navbar-light bg-transparent">
                <a class="navbar-brand d-block d-lg-none px-3" href="#">{{privacyPolicyActiveHeaderLink}}</a>
                <button (click)="toggleDisplayDivIf()" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                  aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div [ngClass]="{ 'show': isShowDivIf }" class="collapse navbar-collapse" id="navbarNav">
                  <ul class="navbar-nav flex-column">
                    <li class="nav-item" *ngFor="let privacyPolicyHeaderLinkKey of getPrivacyPolicyHeaderLinksMapKeys(); let i = index"
                      [attr.data-index]="i">
                      <a class="nav-link "
                        [ngClass]="getPrivacyPolicyHeaderLinkValueFromKey(privacyPolicyHeaderLinkKey)? 'text-primary fw-bold':'text-secondary'"
                        (click)="getPrivacyPolicyArrayKeyViaIndex(i)">{{privacyPolicyHeaderLinkKey}}</a>
                    </li>
                  </ul>
                </div>
              </nav>
              
            </div>

            <div class="vr g-0" ></div>

            <div class="col-md-8 px-4">
                    <!-- // Start target div -->
    <div #target>
      <div id="LegalboxPrivacyPolicy" class="" #LegalboxPrivacyPolicy>
        <h3>Legalbox Privacy Policy</h3>
        <p>Thanks for using Legalbox! Here we describe how we collect, use, and handle your personal data when you use our websites,
           software, and services (“Services”). For more information and details, please see our Frequent Asked Questions. </p>
      </div>
      
      <div id="WhatAndWhy" class="" #WhatAndWhy>
        <h3>What & Why</h3>
        <p>We collect and use the following information to provide, improve, protect, and promote our Services.</p> 
      </div>
      
      <div id="AccountInformation" class="" #AccountInformation>
        <h3>Account information</h3>
        <p>We collect, and associate with your account, the information you provide to us when you do things such as sign up for your 
          account, upgrade your subscription plan, and/or set up two-factor authentication (like your name, email address, phone number,
           payment info, and physical address).</p>
      </div>
  
      <div id="YourStuff" class="" #YourStuff>
        <h3>Your Stuff</h3>
        <p>Our Services are designed as a simple and personalized way for you to store your electronic files, documents, photos, comments,
           messages (“Your Stuff”), We collaborate with others, and work across multiple devices and services. To make that possible, we 
           store, process, and transmit Your Stuff as well as information related to it. This related information includes your profile
           information that makes it easier to collaborate and share Your Stuff with others, as well as things like the size of the file,
           the time it was uploaded, collaborators, and usage activity.</p> 
      </div>
  
      <div id="UsageInformation" class="" #UsageInformation>
        <h3>Usage information</h3>
        <p>We collect information related to how you use the Services, including actions you take in your account (like sharing, editing,
           viewing and moving files or folders, and sending and other transactions).</p> 
      </div>
  
      <div id="DeviceInformation" class="" #DeviceInformation>
        <h3>Device information</h3>
        <p>We also collect information from and about the devices you use to access the Services. This includes things like IP addresses,
           the type of browser and device you use, the web page you visited before coming to our sites, and identifiers associated with
            your devices. Your devices (depending on their settings) may also transmit location information to the Services. For example,
             we use device information to detect abuse and identify and troubleshoot bugs.</p> 
      </div>
  
      <div id="CookiesAndOtherTechnologies" class="" #CookiesAndOtherTechnologies>
        <h3>Cookies and other technologies</h3>
        <p>We use technologies like cookies and pixel tags to provide, improve, protect, and promote our Services. For example, cookies 
          help us with things like remembering your username for your next visit, understanding how you are interacting with our Services,
           and improving them based on that information. You can set your browser to not accept cookies, but this may limit your ability
            to use the Services. We may also use third-party service providers that set cookies and similar technologies to promote
             Legalbox services. You can learn more about how cookies and similar technologies work, as well as how to opt out of the use
              of them for advertising purposes.</p> 
      </div>
  
      <div id="LegalboxAnalytics" class="" #LegalboxAnalytics>
        <h3>Legalbox analytics</h3>
        <p>When you use Legalbox, we do collect information to analyse a portion of our Services, we collect information including your 
          use and device information, such as email addresses, IP addresses, and device identifiers of devices you use the Services on. </p> 
      </div>
  
      <div id="Marketing" class="" #Marketing>
        <h3>Marketing</h3>
        <p>We give users the option to use some of our Services free of charge. These free Services are made possible by the fact that
           some users upgrade to one of our paid Services. If you register for our Services, we will, from time to time, send you
            information about upgrades when permissible. Users who receive these marketing materials can opt out at any time. If you
            don’t want to receive a particular type of marketing material from us, click the ‘unsubscribe’ link in the corresponding
            emails. We may also collect information from you if you interact with Legalbox representatives at an event, download 
            marketing or educational materials from our website, or contact a Legalbox representative. We may use the information you
            provide to send you additional marketing materials. We sometimes contact people who don’t have a Legalbox account. For
            recipients in the EU, we or a third party will obtain consent before reaching out. If you receive an email and no longer 
            wish to be contacted by Legalbox, you can unsubscribe and remove yourself from our contact list via the message itself.
          </p> 
      </div>
  
      <div id="BasesForProcessingYourData" class="" #BasesForProcessingYourData>
        <h3>Bases for processing your data</h3>
        <p>We collect and use the personal data described above in order to provide you with the Services in a reliable and secure
           manner. We also collect and use personal data for our legitimate business needs. To the extent we process your personal data
            for other purposes, we ask for your consent in advance or require that our partners obtain such consent.</p> 
      </div>
  
      <div id="WithWhom" class="" #WithWhom>
        <h3>With Whom</h3>
        <p>We may share information as discussed below, but we won’t sell it to advertisers or other third parties.</p> 
      </div>
  
      <div id="OthersWorkingForAndWithLegalbox" class="" #OthersWorkingForAndWithLegalbox>
        <h3>Others working for and with Legalbox</h3>
        <p>Legalbox uses certain trusted third parties (for example, providers of customer support and IT services) for the business
           purposes of helping us provide, improve, protect, and promote our Services. These third parties will access your information
           to perform tasks on our behalf, and we will remain responsible for their handling of your information per our instructions. </p> 
      </div>
  
      <div id="OtherUsers" class="" #OtherUsers>
        <h3>Other users</h3>
        <p>Our Services are designed to help you collaborate with others. If you register your Legalbox account with an email address on
           a domain owned by your organisation, join a Legalbox Team, or collaborate with other Legalbox users, we may suggest you or your
           team as a potential collaborator to other users or teams. For example, if you interact with a person at a company, and that 
           person frequently works with one of their co workers, we may suggest you as a potential collaborator for that organisation. 
           Collaborators and potential collaborators may see some of your basic information, like your name, device, email address, and
           usage information. This helps you sync up with the organisation which provide you with the Legalbox account and helps other
           users share files and folders with you. Certain features let you make additional information available to others. For example,
           if you view a file or folder shared from Legalbox, we will share your identifying information such as name and email address,
           information on the device you used to view the content, and for how long you viewed content and what portion of the content
           you viewed with the owner of the file or folder.
          </p> 
      </div>
  
      <div id="OtherApplications" class="" #OtherApplications>
        <h3>Other applications</h3>
        <p>You can choose to connect your Legalbox account with third-party services eg, like your corporate internal website. By doing
           so, you are enabling Legalbox and those third parties to exchange information about you and data in your account so that 
           Legalbox and those third parties can provide, improve, protect, and promote their services. Please remember that third parties’
           use of your information will be governed by their own privacy policies and terms of service.</p> 
      </div>
  
      <div id="Admins" class="" #Admins>
        <h3>Admins</h3>
        <p>If you are a user of a Legalbox, your administrator may have the ability to access and control your Legalbox account. 
          Please refer to your organization’s internal policies if you have questions about this. If you are not a Legalbox user but
           interact with a Legalbox user (for example, by joining a shared folder or accessing stuff shared by that user), members of that organization may be able to view information about you (such as your name, email address, and profile picture) and your interaction with the Legalbox user (such as your IP address). If you share Your Stuff with a Legalbox user, the administrator of the team account may have the ability to access and edit what you shared.</p> 
      </div>
  
      <div id="LawAndOrderAndThePublicInterest" class="" #LawAndOrderAndThePublicInterest>
        <h3>Law & Order and the Public Interest</h3>
        <p>We may disclose your information to third parties if we determine that such disclosure is reasonably necessary to: (a) comply
           with any applicable law, regulation, legal process, or appropriate government or authorities request; (b) protect any person
            from death or serious bodily injury; (c) prevent fraud or abuse of Legalbox or our users; (d) protect Legalbox’s rights,
             intellectual property, safety, or interest; or (e) perform a task carried out in the public interest.
          Stewardship of your data is critical to us and a responsibility that we embrace. We believe that your data should receive the
           same legal protections regardless of whether it’s stored on our Services or on your home computer’s hard drive. We will abide
            by the following Malaysia regulations when receiving, and responding to government requests (including national security
             requests) for your data:
          • Be transparent
          • Fight blanket requests
          • Protect all users, and
          • Provide trusted services.
          </p> 
      </div>
  
      <div id="Security" class="" #Security>
        <h3>Security</h3>
        <p>We have a team dedicated to keeping your information secure and testing for vulnerabilities. We continue to work on features 
          to keep your information safe in addition to things like two-factor authentication, encryption of files at rest, and alerts
           when new devices and apps are linked to your account. We deploy automated technologies to detect abusive behaviour and 
           content that may harm our Services, you, or other users.</p> 
      </div>
  
      <div id="UserControls" class="" #UserControls>
        <h3>User Controls</h3>
        <p>You can access, amend, download, and delete your personal information by logging into your Legalbox account and going to your
           account setting page.</p> 
      </div>
  
      <div id="Retention" class="" #Retention>
        <h3>Retention</h3>
        <p>When you sign up for an account with us, we will retain information you store on our Services for as long as your account
           exists or as long as we need it to provide you  and your organisation the Services. If you or your organisation delete your
            account, we will initiate deletion of this information after 30 days. If you have also stopped subscribing to our services
             and have failed to pay for your subscription to our services after 30 days of the last payment, we will also initiate
              deletion of the information. But please note: (1) there might be some latency in deleting these information from our 
              servers and back-up storage; and (2) we may retain these information if necessary to comply with our legal obligations,
               resolve disputes, or enforce our agreements.</p> 
      </div>
  
      <div id="AroundTheWorld" class="" #AroundTheWorld>
        <h3>Around the world</h3>
        <p>To provide you with the Services, we may store, process, and transmit data in the Malaysia and locations around the 
          world—including those outside your country. Data may also be stored locally on the devices you use to access the Services.</p> 
      </div>
  
      <div id="YourControlAndAccessOfYourData" class="" #YourControlAndAccessOfYourData>
        <h3>Your Control and Access of Your Data</h3>
        <p>You have control over your personal data and how it’s collected, used, and shared. For example, you can:
          • Delete Your Stuff in your Legalbox account. 
          • Change or correct personal data. You can manage your account and Your Stuff contained in it, as well as edit some of your
           personal data, through your account setting. 
          • Access and take your data elsewhere. You can access your personal data from your Legalbox account and you can download a copy
           of Your Stuff in a machine readable format. 
          If you would like to submit a data access request or object to the processing of your personal data, please email us at 
          support@legalbox.io. To request that your personal data be deleted, send us an written instruction to do so and we may request
           more information from you to verify your identity. 
          </p> 
      </div>
  
      <div id="LegalboxAsControllerOrProcessor" class="" #LegalboxAsControllerOrProcessor>
        <h3>Legalbox as controller or processor</h3>
        <p>If you reside in North America (the United States, Canada, and Mexico), Legalbox acts as your service provider. For all other
           users, Legalbox acts as a controller of your personal data. Outside of North America, if you are a Legalbox customer or use the
            Legalbox Services, Legalbox acts as a processor of your data.</p> 
      </div>
  
      <div id="Changes" class="" #Changes>
        <h3>Changes</h3>
        <p>If we are involved in a reorganization, merger, acquisition, or sale of our assets, your data may be transferred as part of
           that deal. We will notify you (for example, via a message to the email address associated with your account) of any such deal
            and outline your choices in that event.
          We may revise this Privacy Policy from time to time and will post the most current version on our website. If a revision 
          meaningfully reduces your rights, we will notify you.
          </p> 
      </div>
  
      <div id="Contact" class="" #Contact>
        <h3>Contact</h3>
        <p>Have questions or concerns about Legalbox, our Services, and privacy, please Contact our Data Protection Officer at 
          support@legalbox.io. If they can’t answer your question, you have the right to contact your local data protection supervisory
           authority.</p> 
      </div>
    </div>
    <!-- // End target div -->
            </div>
           
          </div>
          <!-- // End Second Row Inner Second Row -->

        </div>
        <!-- // End Privacy Policy Tab Content -->

        <!-- // Start Term of Service Tab Content -->
        <div class="tab-pane fade" [ngClass]="getHeaderLinkValueFromKey('showTermOfService')? 'show active':''" id="term-of-service-tab-pane" role="tabpanel" aria-labelledby="term-of-service-tab" tabindex="0">

          <!-- // Start Second Row Inner Third Row -->
          <div class="row">

            <!-- // Start Div - To Be Adjusted and Kept -->
            <div class="col-md-3">
              <nav class="navbar navbar-expand-lg navbar-light bg-transparent">
                <a class="navbar-brand d-block d-lg-none px-3 col-9 text-wrap" href="#">{{termOfServiceActiveHeaderLink}}</a>
                <button (click)="toggleDisplayTermOfServiceDivIf()" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                  aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div [ngClass]="{ 'show': isShowTermOfServiceDivIf }" class="collapse navbar-collapse" id="navbarNav">
                  <ul class="navbar-nav flex-column px-3">
                    <li class="nav-item" *ngFor="let termOfServiceHeaderLinkKey of getTermOfServiceHeaderLinksMapKeys(); let i = index"
                      [attr.data-index]="i">
                      <a class="nav-link "
                        [ngClass]="getTermOfServiceHeaderLinkValueFromKey(termOfServiceHeaderLinkKey)? 'text-primary fw-bold':'text-secondary'"
                        (click)="getTermOfServiceArrayKeyViaIndex(i)">{{termOfServiceHeaderLinkKey}}</a>
                    </li>
                  </ul>
                </div>
              </nav>
              
            </div>
            <!-- // End Div - To Be Adjusted and Kept -->

            <div class="vr g-0" ></div>

            <div class="col-md-8 px-4">
                   <!-- // Start target2 div -->
     <div #target2>
      <div id="WelcomeToLegalbox" class="verticalTabcontent" #WelcomeToLegalbox>
        <h3>Welcome to Legalbox</h3>
        <p>Welcome to Legalbox. Our mission is to create a secure environment to enable you to keep and retrieve all your documents all 
          in one place with ease. Why the need for it? Simple, it is to give you a peace of mind knowing that all your documents are securely
          kept. These terms and conditions (“Terms”) cover your use and access to the Legalbox service, software and websites ("Services").
          Our Privacy Policy explains how we collect and use your information while our Acceptance Use Policy outlines your responsibilities
          when using our Services. By using and your continuous use of our Services, you’re agreeing to be bound by these Terms, our·Privacy
          Policy and Acceptance Use Policy. 
        </p>
      </div>

      <div id="YourDocuments" class="verticalTabcontent" #YourDocuments>
        <h3>Your Documents</h3>
        <p>When you use our Services, you upload your files, documents, contracts, any legal documents and so on our Portal (“Your Documents”).
           Your Documents remain as yours. These Terms do not give us any rights to Your Documents except for the limited rights that allows
           us to offer the Services. You need to register for an account to be able to access the Services. An account will be created for you.
           Once the account is created, you can then create, make use and interact with the Services. Your consent is needed to allow us to host
           Your Documents, to back it up and to share it when you require  us to do so. Our Services also provides and allows you to sort out,
           arrange, organize, personalize and categorize (among other things) Your Documents. The consent given by you shall extend to our
           affiliates and trusted third parties we work with.
        </p>
      </div>  

      <div id="YourResponsibilities" class="verticalTabcontent" #YourResponsibilities>
        <h3>Your Responsibilities</h3>
        <p>In order to use our Services you must comply with our Acceptance Use Policy. Content in the Services may be protected by other
          intellectual property rights. You are prohibited from making copies, uploading, downloading or sharing of any content unless you have
          the right to do so. Legalbox may review your conduct and content for purposes of compliance with these Terms and our Acceptance Use
          Policy. We are not responsible for any content stored and shared via the Services. Help us keep Your Documents protected. Safeguard
          your password and private key to the Services and keep your account information current. Do not share with anyone your account
          credentials, private key or give others access to your account. You may use our Services only as permitted by applicable regulations,
          directives, codes or law. Finally, in order to use our Services, you must be at least 18 years of age. The age requirement shall
          apply depending on the laws of the country you reside in order for Legalbox to lawfully provide the Services to you without parental
          consent (including use of your personal data).
        </p>
      </div>

      <div id="BlockchainPlatform" class="verticalTabcontent" #BlockchainPlatform>
        <h3>Blockchain Platform</h3>
        <p>By you using and continuous usage of our Services you consent to us uploading Your Documents on the InterPlanetary File System (IPFS). IPFS is a file sharing system which relies on cryptographic hashes that is stored on blockchain. Blockchain is an established technology where a distributed database records all the transactions that have happened in a peer-to-peer network, so in the blockchain network, several nodes collaborate among them to secure and maintain a set of shared transaction records in a distributed way. Unless any of the above is prohibited by law, you agree not to reverse engineer or decompile the Services, attempt to do so, or assist anyone in doing so.  </p>
      </div>

      <div id="BetaServices" class="verticalTabcontent" #BetaServices>
        <h3>Beta Services  </h3>
        <p>We sometimes release products and features that we’re still testing and evaluating (“Beta Services”). Beta Services are labelled “alpha,” “beta,” “preview,” “early access,” or “evaluation” (or with words or phrases with similar meanings) and may not be as reliable as Legalbox’s other services. Beta Services are made available so that we can collect user feedback, and by using our Beta Services, you agree that we may contact you to collect such feedback. 
          Beta Services are confidential until official launch. If you use any Beta Services, you agree not to disclose any information about those Services to anyone else without our permission. </p>
      </div>

      <div id="ElectronicRecords" class="verticalTabcontent" #ElectronicRecords>
        <h3>Electronic Records </h3>
        <p>By using a part of the Services that facilitates electronic documents,  you agree to use electronic records and signatures. There may be laws where you reside governing the types of documents and transactions which are appropriate for such records. It is your responsibility to ensure that the electronic records functionality provided by the Services is appropriate for your scenario.  </p>
      </div>

      <div id="AdditionalFeatures" class="verticalTabcontent" #AdditionalFeatures>
        <h3>Additional Features</h3>
        <p>From time to time, Legalbox will add additional features to enhance the user experience of our storage service for a trial period or at a minimal cost. However, these free features may be withdrawn without further notice. </p>
      </div>

      <div id="ThirdPartyFeatures" class="verticalTabcontent" #ThirdPartyFeatures>
        <h3>Third-Party Features</h3>
        <p>The Services may give you the option to link to third-party features and integration. Legalbox does not own or operate any such features or integration. If you access or use any third-party features or integration, you are responsible for this access and use, and Legalbox is not responsible for any acts or omissions of the third party or the availability, accuracy, related content, products or services of the third party. </p>
      </div>

      <div id="OurStuff" class="verticalTabcontent" #OurStuff>
        <h3>Our Stuff</h3>
        <p>The Services are protected by copyright, trademark, and other intellectual property rights. These Terms do not grant you any rights, title, or interest in the Services, other content in the Services, Legalbox trademarks, logos and other brand features.  </p>
      </div>

      <div id="Copyright" class="verticalTabcontent" #Copyright>
        <h3>Copyright</h3>
        <p>We respect the intellectual property of others and ask that you do the same. We respond to notices of alleged copyright infringement if they comply with the law, and such notices should be reported to us. We reserve the right to delete or disable content alleged to be infringing and terminate the accounts of the infringers. Copyright infringement claims should be submitted to our designated agents for our Services at: support@legalbox.io </p>
      </div>

      <div id="PaidAccounts" class="verticalTabcontent" #PaidAccounts>
        <h3>Paid Accounts</h3>
        <p>Billing.·You can increase your storage space and add paid features to your account at any time. We will automatically bill you from the date you subscribed to our Services and on each periodic renewal until cancellation. You agree that you will sign the electronic mandate upon signing up for your subscription plan with Legalbox. If you sign up for our annual plan, we will within a reasonable period of time, prior to your renewal date send you a reminder notice by email to renew your annual plan. You are responsible for all applicable taxes, and we will charge tax when required to do so. Some countries have mandatory local laws regarding cancellation rights and this paragraph does not override these laws. </p>
        <p>Cancellation.·You may cancel your·account at any time. No refunds will be issued for unutilised period of your subscription. </p>
        <p>Downgrades.·Your account will remain in effect until it is cancelled or terminated under these Terms or by you. If you are on a Legalbox Corporate plan, only the authorised person is allowed to downgrade your account at any time. If you do not pay for your account on time, we reserve the right to suspend or remove your account features. </p>
        <p>Changes.·We may change the fees in effect on renewal of your subscription, to reflect factors such as changes to our product offerings, changes to our business, or changes in economic conditions. We will give you no less than 30 days’ advance notice of these changes via a message to the email address associated with your account and you will have the opportunity to cancel your subscription before the new fee comes into effect. </p>
      </div>

      <div id="Termination" class="verticalTabcontent" #Termination>
        <h3>Termination</h3>
        <p>You may choose to terminate or  stop using our Services at any time. We reserve the right to suspend or terminate your access to the Services with notice to you if Legalbox reasonably believes: 
          You are in breach of these Terms; or 
          Your use of the Services would cause a real risk of harm or loss to us or other users. 
          We will provide you with reasonable advance notice via the email address associated with your account to remedy the activity that prompted us to contact you and give you the opportunity to export Your Documents from our Services. If after such notice you fail to take the steps we ask of you, we’ll terminate or suspend your access to the Services. 
          We will not provide any notice or allow you an opportunity to export Your Documents before termination or suspension of your access to the Services where Legalbox reasonably believes: 
          You are in material breach of these Terms, which includes, but is not limited to, violating our·Acceptance Use Policy; 
          doing so would cause us legal liability or compromise our ability to provide the Services to our other users; or 
          we are prohibited from doing so by law. 
          Once we suspend or terminate your access to the Services, you will not be able to access or export Your Documents. Legalbox does not provide refunds if we suspend or terminate your access to the Services, unless required by law. </p>
      </div>

      <div id="DiscontinuationOfServices" class="verticalTabcontent" #DiscontinuationofServices>
        <h3>Discontinuation of Services</h3>
        <p>We may decide to discontinue the Services in response to exceptional unforeseen circumstances, events beyond Legalbox’s control (for example a natural disaster, fire, or explosion), or to comply with a legal requirement. If we do so, we will give you reasonable prior notice so that you can export Your Documents from our systems (we will give you no less than 30 days’ notice where possible under the circumstances). If we discontinue the Services before the end of any fixed or minimum term you have paid us for, we will refund the portion of the fees you have pre-paid for the Services you did not receive.  </p>
      </div>

      <div id="ServicesASIS" class="verticalTabcontent" #ServicesASIS>
        <h3>Services "AS IS"</h3>
        <p>We strive to provide great Services, but there are certain things that we cannot guarantee. TO THE FULLEST EXTENT PERMITTED BY LAW, LEGALBOX AND ITS AFFILIATES, SUPPLIERS AND DISTRIBUTORS MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES. THE SERVICES ARE PROVIDED "AS IS." WE ALSO DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. Some places do not allow the disclaimers in this paragraph, so they may not apply to you.  </p>
      </div>

      <div id="LimitationOfLiability" class="verticalTabcontent" #LimitationofLiability>
        <h3>Limitation of Liability</h3>
        <p>WE DO NOT EXCLUDE OR LIMIT OUR LIABILITY TO YOU WHERE IT WOULD BE ILLEGAL TO DO SO—THIS INCLUDES ANY LIABILITY FOR LEGALBOX’S OR ITS AFFILIATES’ FRAUD OR FRAUDULENT MISREPRESENTATION IN PROVIDING THE SERVICES. IN COUNTRIES WHERE THE FOLLOWING TYPES OF EXCLUSIONS ARE NOT ALLOWED, WE ARE RESPONSIBLE TO YOU ONLY FOR LOSSES AND DAMAGES THAT ARE A REASONABLY FORESEEABLE RESULT OF OUR FAILURE TO USE REASONABLE CARE AND SKILL OR OUR BREACH OF OUR CONTRACT WITH YOU. THIS PARAGRAPH DOES NOT AFFECT CONSUMER RIGHTS THAT CANNOT BE WAIVED OR LIMITED BY ANY CONTRACT OR AGREEMENT. IF YOU ARE AN EU OR UK CONSUMER, THESE TERMS DO NOT EXCLUDE LEGALBOX’S LIABILITY FOR LOSSES AND DAMAGES THAT ARE A RESULT OF OUR FAILURE TO USE REASONABLE CARE AND SKILL IN PROVIDING THE SERVICES OR OF OUR BREACH OF OUR CONTRACT WITH YOU, AS LONG AS THOSE LOSSES AND DAMAGES ARE REASONABLY FORESEEABLE. 
          IN COUNTRIES WHERE EXCLUSIONS OR LIMITATIONS OF LIABILITY ARE ALLOWED, LEGALBOX, ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS WILL NOT BE LIABLE FOR: 
          ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, OR 
          ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS, REGARDLESS OF LEGAL THEORY. 
          THESE EXCLUSIONS OR LIMITATIONS WILL APPLY REGARDLESS OF WHETHER OR NOT LEGALBOX OR ANY OF ITS AFFILIATES HAVE BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES. 
          IF YOU USE THE SERVICES FOR ANY COMMERCIAL, BUSINESS, OR RE-SALE PURPOSE, LEGALBOX, ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS WILL HAVE NO LIABILITY TO YOU FOR ANY LOSS OF PROFIT, LOSS OF BUSINESS, BUSINESS INTERRUPTION, OR LOSS OF BUSINESS OPPORTUNITY. LEGALBOX AND ITS AFFILIATES ARE NOT RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE SERVICES. 
          OTHER THAN FOR THE TYPES OF LIABILITY WE CANNOT LIMIT BY LAW (AS DESCRIBED IN THIS SECTION), WE LIMIT OUR LIABILITY TO YOU TO THE GREATER OF RM100 OR 100% OF ANY AMOUNT YOU HAVE PAID UNDER YOUR CURRENT SERVICE PLAN WITH LEGALBOX. THIS PROVISION DOES NOT APPLY TO EU CONSUMERS, WHERE PROHIBITED BY APPLICABLE LAW. </p>
      </div>

      <div id="ResolvingDisputes" class="verticalTabcontent" #ResolvingDisputes>
        <h3>Resolving Disputes</h3>
        <p>Let’s Try to Sort Things Out First.·We want to address your concerns without needing a formal legal case. Before filing a claim against Legalbox, you agree to try to resolve the dispute informally by sending us a written Notice of Dispute at support@legalbox.com that includes your name, a detailed description of the dispute, and the relief you seek. We will endeavour to resolve the dispute informally by contacting you via email. If a dispute is not resolved within 60 days after submission, you or Legalbox may bring a formal proceeding. If you reside in the EU, the European Commission provides for an online dispute resolution platform, which you can access here:·https://ec.europa.eu/consumers/odr. </p>
        <p>Judicial Forum for Disputes.·You and Legalbox agree that any judicial proceeding to resolve claims relating to these Terms or the Services will be brought in the High Courts of Malaysia, subject to the mandatory arbitration provisions below.  </p>
        <p>YOU ALSO AGREE TO THE FOLLOWING MANDATORY ARBITRATION PROVISIONS: </p>
        <p>We Both Agree to Arbitrate.·You and Legalbox agree to resolve any claims relating to or arising out of these Terms or the Services through final and binding individual arbitration by a single arbitrator, except as set forth under the “Exceptions to Agreement to Arbitrate” below. This includes disputes arising out of or relating to the interpretation or application of this “Mandatory Arbitration Provisions” section, including its scope, enforceability, revocability, or validity. The arbitrator may award relief only individually and only to the extent necessary to redress your individual claim(s); the arbitrator may not award relief on behalf of others or the general public. </p>
        <p>Arbitration Procedures.·The Asian International·Arbitration Centre·(AIAC)·will administer the arbitration under its arbitration rules. The AIAC’s rules and filing instructions are available at·www.aiac.world . The arbitration will be held Kuala Lumpur.  </p>
        <p>NO CLASS OR REPRESENTATIVE ACTIONS. You may only resolve disputes with us on an individual basis, and you may not bring a claim as a plaintiff or a class member in a class, consolidated, or representative action. Class arbitrations, class actions, private attorney general actions, and consolidation with other arbitrations aren’t allowed. </p>
        <p>Severability. If any part of this “Mandatory Arbitration Provisions” section is found to be illegal or unenforceable, the remainder will remain in effect, except that if a finding of partial illegality or unenforceability would allow class or representative arbitration, this “Mandatory Arbitration Provisions” section will be unenforceable in its entirety. If you are found to have a non-waivable right to bring a particular claim or to request a particular form of relief that the arbitrator lacks authority to redress or award according to this “Mandatory Arbitration Provisions” section, including public injunctive relief, then only that respective claim or request for relief may be brought in court both you and us shall agree that litigation of any such claim or request for relief shall be stayed pending the resolution of any individual claim(s) or request(s) for relief in arbitration. </p>
      </div>

      <div id="ControllingLaw" class="verticalTabcontent" #ControllingLaw>
        <h3>Controlling Law</h3>
        <p>These Terms will be governed by laws of Malaysia except for its conflict of laws principles. However, some countries (including those in the European Union) have laws that require agreements to be governed by the local laws of the consumer's country. This paragraph does not override those laws.</p>
      </div>

      <div id="EntireAgreement" class="verticalTabcontent" #EntireAgreement>
        <h3>Entire Agreement</h3>
        <p>These Terms constitute the entire agreement between you and Legalbox with respect to the subject matter of these Terms and supersede and replace any other prior or contemporaneous agreements, or terms and conditions applicable to the subject matter of these Terms. Our past, present, and future affiliates and agents can invoke our rights under this agreement in the event they become involved in a dispute with you. Otherwise, these Terms do not give rights to any third parties. </p>
      </div>

      <div id="WaiverSeverabilityAndAssignment" class="verticalTabcontent" #WaiverSeverabilityAndAssignment>
        <h3>Waiver Severability & Assignment</h3>
        <p>Legalbox’s failure to enforce a provision is not a waiver of its right to do so later. If a provision is found unenforceable, the remaining provisions of the Terms will remain in full effect and an enforceable term will be substituted reflecting our intent as closely as possible. You may not assign any of your rights under these Terms, and any such attempt will be void. Legalbox may assign its rights to any of its affiliates or subsidiaries, or to any successor in interest of any business associated with the Services. </p>
      </div>

      <div id="Modifications" class="verticalTabcontent" #Modifications>
        <h3>Modifications</h3>
        <p>We may revise these Terms to better reflect: 
          changes to the law; 
          new regulatory requirements; or 
          improvements or enhancements made to our Services. 
          If an update affects your use of the Services or your legal rights as a user of our Services, we will publish it on the website and these updated Terms will be effective no less than 30 days from when we notify you. Your continuous use, access and subscription of the Services after the effective date of the revised updated Terms would mean you agree to be bound by the updated Terms.  
          If you do not agree to the updates we make, please cancel your account and stop using the Services before the updated Terms become effective. Where applicable, we will offer you a prorated refund based on the amounts you have prepaid for Services (if any) and your account cancellation date.  </p>
      </div>
     
    </div>
    <!-- // End target2 div -->
            </div>
           
          </div>
          <!-- // End Second Row Inner Third Row -->

        </div>
        <!-- // End Term of Service Tab Content -->
       
      </div>
    </div>
    <!-- // End Second Row -->

  </div>
</section>

<button class="btn btn-primary back-to-top position-fixed" (click)="scrollToTop()" *ngIf="showButton">Back to Top</button>

<app-get-started></app-get-started>
