import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassEmail: string;
  displayModal = false;
  displayEmailMissing = false;
  displayEmailMismatch = false;
  errorMessage: string;
  displayBackendErrorMessage = false;


  constructor(private router: Router, private userService: UserService) {

  }

  onFocus() {
    this.displayEmailMissing = false;
    this.displayEmailMismatch = false;
    this.displayBackendErrorMessage = false;
  }

  redirectToSignIn() {
    this.router.navigate(['SignIn']);
  }

  clickSubmit(email: string) {
    
    if (email.trim() == "") {
      // send error
      this.displayEmailMissing = true;
    } else {
      if (email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
        // Process forget password -> email link
        this.userService.forgotPassword(this.forgotPassEmail).subscribe({
          next: (event) => {
              if (event instanceof HttpResponse) {
                if (event.body.status == 500) {
                  this.errorMessage = event.body.message;
                  this.displayBackendErrorMessage = true;
                } else {
                  this.displayModal = true;
                }                
              }
            },
          error: (err) => {
              // Response error from backend / Fail to send forgot password request
              this.errorMessage = err.error.message;
              this.displayBackendErrorMessage = true;
              // window.location.reload();
            }
          });

      } else {
        // email format error
        this.displayEmailMismatch = true;
      }
    }

  }
   

  ngOnInit(): void {
    
 
  }


}
