import { Component, OnInit, HostBinding, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { HttpResponse } from '@angular/common/http';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-new-client',
  templateUrl: './add-new-client.component.html',
  styleUrls: ['./add-new-client.component.css']
})
export class AddNewClientComponent implements OnInit {
  displayAddNewDropdownContent = false;
  email: string;
  isEmailIncorrect: string;
  emailMessage: string;
  inputStorageSizeInGB: number;
  isStorageValidated: string;
  storageMessage: string;
  retrievedUserRemainingStorageSizeInGB: number;
  storageInput: string;
  showPopUp: boolean = false;
  hidePopUp: boolean = true;
  clickConfirm: boolean = false;
  disableButton: boolean = true;
  nextStage: boolean;
  userId: any;
  showMsgPopUp: boolean = false;
  hideMsgPopUp: boolean = true;
  isMsgError: boolean = false;
  messageHeader: string = "";
  message: string = "";
  successMessageIconImageURL = "/assets/revamp/successGreenIconNew.png";
  errorMessageIconImageURL = "/assets/revamp/redExclamationMark.png";
  displaySessionMessage = false;
  messageToBeDisplayed = "";
  errorType = "";
  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    console.log("isSmallScreen from Home Component: " + this.isSmallScreen);
  }



  constructor(private router: Router, private sanitizer: DomSanitizer, private userService: UserService,
    private tokenStorageService: TokenStorageService) {
    this.email = "";
    this.isEmailIncorrect = "";
    this.storageInput = "";
    this.inputStorageSizeInGB = null;
  }

  toggleAddNewDropdownContentDisplay() {
    this.displayAddNewDropdownContent = !this.displayAddNewDropdownContent;
  }

  redirectToAddNewClient() {
    this.router.navigate(['AddNewClient']);
  }

  ngOnInit(): void {
    this.displaySessionMessage = false;
    this.messageToBeDisplayed = "";
    this.errorType = "";
    let user = this.tokenStorageService.getUser();
    this.userId = user.userId;
    this.getUser(this.userId);
    this.nextStage = false;
    this.disableButton = false;

  }


  deValidate() {
    this.isEmailIncorrect = "";
    this.isStorageValidated = "";
  }

  checkEmail() {
    let emailValidation = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
    if (this.email.trim() != "") {
      if (emailValidation.test(this.email.trim())) {
        this.userService.checkClientUser(
          this.email.trim(),
          this.userId
        ).subscribe({
          next: async (event) => {
            const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
            if (event instanceof HttpResponse) {
              if (environment.debug) {
                console.log("Object.keys(event.body):" + Object.keys(event.body));
                console.log("event.body.message:", event.body.message);
              }


              if (event.body.status == 500) {
                this.displaySessionMessage = true;
                this.messageToBeDisplayed = event.body.message;
                this.errorType = "genericError";
              } else if (event.body.status == 501) {
                this.isEmailIncorrect = "is-invalid";
                this.emailMessage = event.body.message;

              } else {
                this.disableButton = true;
                this.isEmailIncorrect = "is-valid";
                this.emailMessage = event.body.message;
                await delay(2000);
                this.nextStage = true;
                this.disableButton = false;
              }
            }
          },
          error: (err) => {
            this.displaySessionMessage = true;
            this.messageToBeDisplayed = err.error.message;
            this.errorType = "genericError";
          }
        });
      } else {
        this.isEmailIncorrect = "is-invalid";
        this.emailMessage = "Invalid email address.";
      }
    } else {
      this.isEmailIncorrect = "is-invalid";
      this.emailMessage = "Please enter an email.";
    }
  }

  isCorrectStorageFormat(x) {
    return Math.round(x / 0.05) / (1 / 0.05) === x;
  }

  redirectToSignIn() {
    this.router.navigate(['SignIn']);
  }

  getUser(id): void {
    this.userService.get(id)
      .subscribe(
        data => {
          if (environment.debug) {
            console.log(data);
          }
        },
        error => {
          console.log(error);
        });
  }

  addNewClient(inputStorage: string) {

    let enteredClientStorageSize: number = +inputStorage;
    if (environment.debug) {
      console.log("allocated Storage Size in GB: " + enteredClientStorageSize);
      console.log("allocated Storage Size in GB To Specific Client: " + enteredClientStorageSize.toFixed(0));
      console.log("Introducing User Remaining Storage Size in GB: " + this.retrievedUserRemainingStorageSizeInGB);
    }

    if (inputStorage.trim() != "") {

      if (enteredClientStorageSize < 0.05) {
        this.isStorageValidated = "is-invalid";
        this.storageMessage = "Please allocate atleast 0.05GB of storage size for your client.";
      }
      else {

      if (enteredClientStorageSize > 5) {
        this.isStorageValidated = "is-invalid";
        this.storageMessage = "Please enter a storage size within the limits.";
      } else {
        if (this.isCorrectStorageFormat(enteredClientStorageSize)) {
          if (enteredClientStorageSize >= this.retrievedUserRemainingStorageSizeInGB) {
            this.isStorageValidated = "is-invalid";
            this.storageMessage = "Storage size exceeded your storage limit.";
          } else {
            // Valid amount of storage
            this.isStorageValidated = "is-valid";
            this.disableButton = true;
            this.hidePopUp = false;
            this.showPopUp = true;

          }
        } else {
          this.isStorageValidated = "is-invalid";
          this.storageMessage = "Storage size increment value is incorrect.";
        }
      }
    

    }

    } else {
      this.isStorageValidated = "is-invalid";
      this.storageMessage = "Enter the storage size for the client.";
    }



  }

  closePopUp() {
    this.hidePopUp = true;
    this.showPopUp = false;
    this.disableButton = false;
  }

  confirmSubmit() {
    this.clickConfirm = true;
    this.disableButton = true;
    let user = this.tokenStorageService.getUser();
    let userId = user.userId;


    this.userService.createNewClientUser(
      this.email,
      userId,
      this.storageInput
    ).subscribe({
      next: (event) => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.message:" + event.body.message);
          }
          if (event.body.status == 500 || event.body.status == 404) {
            // alert(event.body.message);
            this.hidePopUp = true;
            this.showPopUp = false;
            this.isMsgError = true;
            this.messageHeader = "Error !"
            this.message = event.body.message;
            this.hideMsgPopUp = false;
            this.showMsgPopUp = true;

          } else if (event.status == 200) {
            this.hidePopUp = true;
            this.showPopUp = false;
            this.isMsgError = false;
            this.messageHeader = "Success !"
            this.message = event.body.message;
            this.hideMsgPopUp = false;
            this.showMsgPopUp = true;
          } else {
            this.hidePopUp = true;
            this.showPopUp = false;
            this.isMsgError = true;
            this.messageHeader = "Error !"
            this.message = event.body.message;
            this.hideMsgPopUp = false;
            this.showMsgPopUp = true;
          }
        }
      },
      error: (err) => {
        // alert(err.message);
        this.hidePopUp = true;
        this.showPopUp = false;
        this.isMsgError = true;
        this.messageHeader = "Error !"
        if (err.error.message) {
          this.message = err.error.message;
        } else {
          this.message = "Unknown error! Please contact system administrator!";
        }
        // this.message = err.error.message;
        this.hideMsgPopUp = false;
        this.showMsgPopUp = true;
      }
    });
  }

  clickMsgOk() {
    this.clickConfirm = false;

    if (this.isMsgError) {
      this.hideMsgPopUp = true;
      this.showMsgPopUp = false;
      location.reload();
    } else {
      this.router.navigate(['ViewAllClients'],
        {
          state:
          {
            selectedNavBarLink: "Clients",
          }
        }
      );
    }

  }
}
