<section name="userDashboardSection1" class="">
  <div class="container-xl-fluid font-plus-jakarta-sans me-3 me-md-0">
    <!-- // Start First Row -->
    <div class="row">
      <div class="col-md-3">
        <app-side-navigation-bar [pageTitle]="'Add New Clients'"></app-side-navigation-bar>
      </div>

      <div class="col-md-9 bg-light">
        <!-- // Start First Row Inner First Row -->
        <div class="row mt-3 mt-md-5 ps-4 pt-0 pt-md-5 me-0 me-md-1">
          <div class="col-md-8 d-none d-md-block">
            <h5 class="p-2 fw-medium text-secondary float-start">Clients</h5>
            <img
              class="float-start mt-2"
              src="/assets/revamp/rightArrowIcon.png"
              width="24"
              height="24"
            />
            <h5 class="p-2 fw-bold float-start">Add New</h5>
          </div>
          <div class="col-md-3 ms-0 ms-md-5 d-grid gap-2 d-md-flex justify-content-md-end">
            <!-- Example single danger button -->
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-primary p-2 px-3 dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                (click)="toggleAddNewDropdownContentDisplay()"
              >
                <img
                  src="/assets/revamp/addNewButtonIcon.png"
                  width="25"
                  height="25"
                />
                &nbsp; Add New
              </button>
              <div
                class="dropdown-menu custom-margin-4rem me-5 border border-secondary-subtle p-2 px-3 shadow-sm"
                [ngClass]="displayAddNewDropdownContent ? (isSmallScreen? 'show w-100':'show') : ''"
              >
                <a class="dropdown-item text-center text-md-start" (click)="redirectToAddNewClient()">
                  <img
                    src="/assets/revamp/newClientDropdownIconNew.png"
                    width="13"
                    height="16"
                  />
                  &nbsp; New Client
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- // End First Row Inner First Row -->

        <!-- // Start First Row Inner Second Row -->
        <div class="row">
          <div class="col-md-11" style="width: 97%">
            <!-- // Start Add New Client Box -->
            <div
              name="recentFilesBox"
              class="bg-white rounded-2 ms-4 p-4 mt-4 mb-5 mb-md-0 custom-height-recentfilesbox"
            >
              <p class="ms-0 mb-2 fw-bold fs-6 float-start">Add New Client</p>
              <hr
                class="clearfix custom-hr-width mt-4 ms-0"
                style="clear: both"
              />

              <!-- // Start Add New Client Box First Inner Row -->
              <div class="row">
                <!-- // Start Add New Client Box Left Division -->
                <div class="col-md-6">
                  <!-- // Start Add New Client Box Left Division First Inner Row -->
                  <div class="row">
                    <div class="col-12">
                      <!-- // Start First Stage -->
                      <div [ngClass]="nextStage ? 'd-none' : 'd-block'">
                        <label for="email" class="form-label fw-bold mt-4"
                          >Email</label
                        >
                        <input
                          class="form-control border-0 bg-light p-3"
                          type="text"
                          placeholder="eg. john@example.com"
                          aria-label="default input example"
                          autocomplete="off"
                          id="email"
                          name="email"
                          (focus)="deValidate()"
                          [ngClass]="isEmailIncorrect"
                          required
                          [(ngModel)]="email"
                        />

                        <div class="valid-feedback">
                          {{ emailMessage }}
                        </div>

                        <div class="invalid-feedback">
                          {{ emailMessage }}
                        </div>
                      </div>
                      <!-- // End First Stage -->

                      <!-- // Start Second Stage -->
                      <div [ngClass]="nextStage ? 'd-block' : 'd-none'">
                        <label
                          for="storageInput"
                          class="form-label fw-bold mt-2 mt-md-4"
                          [ngClass]="isSmallScreen? 'custom-font-size-14':''"
                        >
                          Storage Size
                          <span class="fw-normal small"
                          [ngClass]="isSmallScreen? 'custom-font-size-14':'fs-6'"
                            >(up to 5GB per account)</span
                          >
                        </label>
                        <div class="input-group">
                          <input
                            class="form-control border-0 bg-light p-3"
                            type="text"
                            id="storageInput"
                            name="storageInput"
                            (focus)="deValidate()"
                            placeholder="eg. 0.05"
                            required
                            [(ngModel)]="storageInput"
                            [ngClass]="isStorageValidated"
                          />
                          <span class="input-group-text border-0">GB</span>
                          <div class="valid-feedback"></div>
                          <div class="invalid-feedback">
                            {{ storageMessage }}
                          </div>
                        </div>

                      

                        <p class="custom-font-size-13 mb-0 mt-2">
                          *Minimum storage size is 0.05 GB.
                        </p>
                        <p class="custom-font-size-13">
                          *Increment is 0.05 GB. Eg. 0.10 GB, 0.15 GB, 0.20 GB
                          etc.
                        </p>
                      </div>
                      <!-- // End Second Stage -->
                    </div>
                  </div>
                  <!-- // End Add New Client Box Left Division First Inner Row -->
                </div>
                <!-- // End Add New Client Box Left Division -->

                <!-- // Start Add New Client Box Right Division -->
                <div class="col-md-6">
                  <!-- // Start Add New Client Box Right Division First Inner Row -->
                  <div class="row custom-margin-4rem pt-5">
                    <div class="col-12 custom-margin-4rem pt-5">
                      <!-- // Start First Stage -->
                      <div [ngClass]="nextStage ? 'd-none' : 'd-block'">
                        <button
                          type="button"
                          class="btn btn-primary float-end custom-margin-4rem px-5 py-2"
                          (click)="checkEmail()"
                          [disabled]="disableButton"
                        >
                          Next
                        </button>
                      </div>
                      <!-- // End First Stage -->

                      <!-- // Start Second Stage -->
                      <div [ngClass]="nextStage ? 'd-block' : 'd-none'">
                        <button
                          type="button"
                          class="btn btn-primary float-end custom-margin-4rem px-5 py-2"
                          (click)="addNewClient(storageInput)"
                          data-bs-toggle="modal"
                          data-bs-target="#confirmPopUp"
                          [disabled]="disableButton"
                        >
                          Submit
                        </button>
                      </div>
                      <!-- // End Second Stage -->
                    </div>
                  </div>
                  <!-- // End Add New Client Box Right Division First Inner Row -->
                </div>
                <!-- // End Add New Client Box Right Division -->
              </div>
              <!-- // End Add New Client Box First Inner Row -->
            </div>
            <!-- // End Add New Client Box -->
          </div>
        </div>
        <!-- // End First Row Inner Second Row -->
      </div>
      <!-- // End First Row -->
    </div>

    <!-- Modal -->
    <div
      class="modal fade show"
      [style.display]="showPopUp ? 'block' : 'none'"
      [ngClass]="showPopUp ? 'show' : ''"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content p-2 border-0">
          <!-- // Start Delete Confirmation Modal Body -->
          <div class="modal-body text-center">
            <img
              src="/assets/revamp/yellowExclamationMark.png"
              width="45"
              height="45"
            />
            <h1
              class="modal-title fs-6 text-center fw-medium mt-3"
              id="exampleModalLabel"
            >
              <p>
                Are you sure you want to allocate
                <strong>{{ storageInput }}</strong> GB for
                <strong>{{ email }}</strong> ?
              </p>
            </h1>

            <!-- // Start Delete Confirmation Modal Body First Row -->
            <div class="row mt-4">
              <div class="col-6 float-start">
                <div class="d-grid gap-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    (click)="closePopUp()"
                    [disabled]="clickConfirm"
                  >
                    Close
                  </button>
                </div>
              </div>

              <div class="col-6 float-start">
                <div class="d-grid gap-2">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="confirmSubmit()"
                    [disabled]="clickConfirm"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
            <!-- // End Delete Confirmation Modal Body First Row -->
          </div>
          <!-- // End Delete Confirmation Modal Body -->
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade show"
      [style.display]="showMsgPopUp ? 'block' : 'none'"
      [ngClass]="showMsgPopUp ? 'show' : ''"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content p-2 border-0">
          <!-- // Start Delete Confirmation Modal Body -->
          <div class="modal-body text-center">
            <img
              [src]="
                isMsgError
                  ? errorMessageIconImageURL
                  : successMessageIconImageURL
              "
              width="45"
              height="45"
            />
            <h1
              class="modal-title fs-6 text-center fw-medium mt-3"
              id="exampleModalLabel"
            >
              <h1
                class="modal-title fs-5"
                [ngClass]="isMsgError ? 'text-danger' : 'text-success'"
                id="messagePopUp"
              >
                {{ messageHeader }}
              </h1>
              <p>{{ message }}</p>
            </h1>

            <!-- // Start Delete Confirmation Modal Body First Row -->
            <div class="row mt-4">
              <div class="col-12">
                <div class="d-grid gap-2">
                  <button
                    type="button"
                    class="btn"
                    [ngClass]="isMsgError ? 'btn-danger' : 'btn-success'"
                    (click)="clickMsgOk()"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
            <!-- // End Delete Confirmation Modal Body First Row -->
          </div>
          <!-- // End Delete Confirmation Modal Body -->
        </div>
      </div>
    </div>

    <app-error-pop-up
      [(displaySessionMessage)]="displaySessionMessage"
      [messageToBeDisplayed]="messageToBeDisplayed"
      [errorType]="errorType"
    >
    </app-error-pop-up>
  </div>
</section>
