import { Component, OnInit } from '@angular/core';
import { PricingPlanService } from 'src/app/services/pricing-plan.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-pricing-plan',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '100%',
        opacity: 1,
      })),
      state('closed', style({
        height: 0,
        opacity: 0,
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('1s')
      ]),
    ]),
  ],
  templateUrl: './pricing-plan.component.html',
  styleUrls: ['./pricing-plan.component.css']
})
export class PricingPlanComponent implements OnInit {
  showCorporatePricingPlan:boolean = true;
  showIndividualPricingPlan:boolean = false;
  plusIconPath = "/assets/scratchImg/homeSection4PlusIcon.svg";
  minusIconPath = "/assets/scratchImg/homeSection4MinusIcon.svg";

  faqItem1IconPath = this.plusIconPath;
  faqItem2IconPath = this.plusIconPath;
  faqItem3IconPath = this.plusIconPath;
  faqItem4IconPath = this.plusIconPath;
  faqItem5IconPath = this.plusIconPath;
  faqItem6IconPath = this.plusIconPath;

  showFaqItem1 = false;
  showFaqItem2 = false;
  showFaqItem3 = false;
  showFaqItem4 = false;
  showFaqItem5 = false;
  showFaqItem6 = false;

  pricingPlans: any;
  corporateBasicPlanPrice: any;
  corporateAdvancedPlanPrice: any;
  corporatePremiumPlanPrice: any;
  individualBasicPlanPrice: any;
  individualAdvancedPlanPrice: any;
  individualPremiumPlanPrice: any;
  freePlanPrice: any;
  corporateBasicPlanStorageSizeInGB: any;
  corporateAdvancedPlanStorageSizeInGB: any;
  corporatePremiumPlanStorageSizeInGB: any;
  individualBasicPlanStorageSizeInGB: any;
  individualAdvancedPlanStorageSizeInGB: any;
  individualPremiumPlanStorageSizeInGB: any;
  freePlanStorageSizeInGB: any;
  freePlanStorageSizeInMB: any;
  corporateBasicPlanId: any;
  freePlanId: any;
  corporateAdvancedPlanId: any;
  corporatePremiumPlanId: any;
  individualBasicPlanId: any;
  individualAdvancedPlanId: any;
  individualPremiumPlanId: any;
  
  constructor(private pricingPlanService: PricingPlanService) {

   }

  ngOnInit(): void {
    this.retrievePricingPlans();
  }

  retrievePricingPlans(): void {
    this.pricingPlanService.getAll()
      .subscribe(
        data => {
          this.pricingPlans = data;
          console.log(data);
  
          for(let i = 0; i < this.pricingPlans.length; i++) {
            if(this.pricingPlans[i].name == "Corporate Basic") {
              this.corporateBasicPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.corporateBasicPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.corporateBasicPlanId = this.pricingPlans[i].id;
            }
            if(this.pricingPlans[i].name == "Corporate Advanced") {
              this.corporateAdvancedPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.corporateAdvancedPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.corporateAdvancedPlanId = this.pricingPlans[i].id;
            }
            if(this.pricingPlans[i].name == "Corporate Premium") {
              this.corporatePremiumPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.corporatePremiumPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.corporatePremiumPlanId = this.pricingPlans[i].id;
            }
            if(this.pricingPlans[i].name == "Individual Basic") {
              this.individualBasicPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.individualBasicPlanPrice = parseFloat(this.individualBasicPlanPrice).toFixed(2);
              this.individualBasicPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.individualBasicPlanId = this.pricingPlans[i].id;
            }
            if(this.pricingPlans[i].name == "Individual Advanced") {
              this.individualAdvancedPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.individualAdvancedPlanPrice = parseFloat(this.individualAdvancedPlanPrice).toFixed(2);
              this.individualAdvancedPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.individualAdvancedPlanId = this.pricingPlans[i].id;
            }
            if(this.pricingPlans[i].name == "Individual Premium") {
              this.individualPremiumPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.individualPremiumPlanPrice = parseFloat(this.individualPremiumPlanPrice).toFixed(2);
              this.individualPremiumPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.individualPremiumPlanId = this.pricingPlans[i].id;
            }
            if(this.pricingPlans[i].name == "Free") {
              this.freePlanPrice = this.pricingPlans[i].monthlyAmount;
              this.freePlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.freePlanStorageSizeInMB = parseFloat(this.freePlanStorageSizeInGB) * 1000;
              this.freePlanId = this.pricingPlans[i].id;
            }
          }
        },
        error => {
          console.error(error);
        });
  }

  displayCorporatePricingPlan() {
    this.showCorporatePricingPlan = true;
    this.showIndividualPricingPlan = false;
  }

  displayIndividualPricingPlan() {
    this.showCorporatePricingPlan = false;
    this.showIndividualPricingPlan = true;
  }

  toggleFaqItem1() {
    this.showFaqItem1 = !this.showFaqItem1;
    if(this.faqItem1IconPath == this.plusIconPath) {
      this.faqItem1IconPath = this.minusIconPath;
    }
    else if(this.faqItem1IconPath == this.minusIconPath) {
      this.faqItem1IconPath = this.plusIconPath;
    }
    
  }

  toggleFaqItem2() {
    this.showFaqItem2 = !this.showFaqItem2;
    if(this.faqItem2IconPath == this.plusIconPath) {
      this.faqItem2IconPath = this.minusIconPath;
    }
    else if(this.faqItem2IconPath == this.minusIconPath) {
      this.faqItem2IconPath = this.plusIconPath;
    }
    
  }

  toggleFaqItem3() {
    this.showFaqItem3 = !this.showFaqItem3;
    if(this.faqItem3IconPath == this.plusIconPath) {
      this.faqItem3IconPath = this.minusIconPath;
    }
    else if(this.faqItem3IconPath == this.minusIconPath) {
      this.faqItem3IconPath = this.plusIconPath;
    }
    
  }

  toggleFaqItem4() {
    this.showFaqItem4 = !this.showFaqItem4;
    if(this.faqItem4IconPath == this.plusIconPath) {
      this.faqItem4IconPath = this.minusIconPath;
    }
    else if(this.faqItem4IconPath == this.minusIconPath) {
      this.faqItem4IconPath = this.plusIconPath;
    }
    
  }

  toggleFaqItem5() {
    this.showFaqItem5 = !this.showFaqItem5;
    if(this.faqItem5IconPath == this.plusIconPath) {
      this.faqItem5IconPath = this.minusIconPath;
    }
    else if(this.faqItem5IconPath == this.minusIconPath) {
      this.faqItem5IconPath = this.plusIconPath;
    }
  }

    
    toggleFaqItem6() {
      this.showFaqItem6 = !this.showFaqItem6;
      if(this.faqItem6IconPath == this.plusIconPath) {
        this.faqItem6IconPath = this.minusIconPath;
      }
      else if(this.faqItem6IconPath == this.minusIconPath) {
        this.faqItem6IconPath = this.plusIconPath;
      }
      
    }

}
