<section name="viewAllClientsSection1" class="">
  <div class="container-xl-fluid font-plus-jakarta-sans me-3 me-md-0">
    <!-- // Start First Row -->
    <div class="row">
      <div class="col-md-3">
        <app-side-navigation-bar [pageTitle]="'Clients'"></app-side-navigation-bar>
      </div>

      <div class="col-md-9 bg-light">
        <!-- // Start First Row Inner First Row -->
        <div class="row mt-3 mt-md-5 ps-4 pt-0 pt-md-5 me-1 me-md-0">
          <div class="col-md-8 d-none d-md-block">
            <h5 class="p-2 fw-bold">My Documents</h5>
          </div>
          <div class="col-md-3 ms-0 ms-md-5 d-grid gap-2 d-md-flex justify-content-md-end">
            <!-- Example single danger button -->
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-primary p-2 px-3 dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                (click)="toggleAddNewDropdownContentDisplay()"
              >
                <img
                  src="/assets/revamp/addNewButtonIcon.png"
                  width="25"
                  height="25"
                />
                &nbsp; Add New
              </button>
              <div
                class="dropdown-menu custom-margin-4rem me-5 border border-secondary-subtle p-2 px-3"
                [ngClass]="displayAddNewDropdownContent ? (isSmallScreen? 'show w-100':'show') : ''"
              >
                <a
                  class="dropdown-item text-center text-md-start"
                  (click)="redirectToAddNewClient()"
                  [style.filter]="
                    userAllocatedStorageSizeInGB >= 1 &&
                    displayAddNewDropdownContent
                      ? 'opacity(100%)'
                      : 'opacity(50%)'
                  "
                  [ngStyle]="{
                    'pointer-events':
                      userAllocatedStorageSizeInGB >= 1 &&
                      displayAddNewDropdownContent
                        ? 'inherit'
                        : 'none'
                  }"
                >
                  <img
                    src="/assets/revamp/newClientDropdownIconNew.png"
                    width="13"
                    height="16"
                  />
                  &nbsp; New Client
                </a>
              </div>
            </div>
            <!-- <button type="button" class="btn btn-primary p-2 px-3">
                        <img src="/assets/revamp/addNewButtonIcon.png" width="25" height="25">
                        &nbsp; Add New
                    </button> -->
          </div>
        </div>
        <!-- // End First Row Inner First Row -->

        <!-- // Start First Row Inner Second Row -->
        <div class="row">
          <div class="col-md-11" style="width: 97%">
            <!-- // Start My Storage Box -->
            <div
              name="myStorageBox"
              class="bg-white rounded-2 ms-4 p-4 mt-3 mt-md-5 custom-height-mystoragebox"
            >
              <!-- // Start My Storage Box First Row -->
              <div class="row">
                <div class="col-4 col-md-2">
                  <p class="ms-0 ms-md-4 mb-0 fw-bold" [ngClass]="isSmallScreen? 'small':'fs-6'">My Storage</p>
                </div>
                <div class="col-8 col-md-3">
                  <p class="mb-0 text-end text-md-start" [ngClass]="isSmallScreen? 'small':'fw-bold'">
                    {{
                      allocatedStorageSizeInGB >= 1
                        ? usedStorageSizeInGB
                        : usedStorageSizeInMB
                    }}
                    {{ allocatedStorageSizeInGB >= 1 ? "G" : "M" }}B used /
                    {{
                      allocatedStorageSizeInGB >= 1
                        ? allocatedStorageSizeInGB
                        : allocatedStorageSizeInMB
                    }}
                    {{ allocatedStorageSizeInGB >= 1 ? "G" : "M" }}B
                  </p>
                </div>

                <div class="col-md-7">
                  <div class="progress-stacked mt-3 mt-md-1 custom-bg-lightBlue">
                    <div
                      class="progress"
                      role="progressbar"
                      aria-label="Segment one"
                      aria-valuenow="15"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      [style.width]="
                        corporateInstitutionStorageValueInPercentage
                      "
                    >
                      <div class="progress-bar custom-bg-darkBlue"></div>
                    </div>
                    <div
                      class="progress"
                      role="progressbar"
                      aria-label="Segment two"
                      aria-valuenow="30"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      [style.width]="clientStorageValueInPercentage"
                    >
                      <div class="progress-bar custom-bg-mediumBlue"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- // End My Storage Box First Row -->
            </div>
            <!-- // End My Storage Box -->

            <!-- // Start Recent Files Box -->
            <div
              name="recentFilesBox"
              class="bg-white rounded-2 ms-4 p-4 mt-4 mb-5 mb-md-0 custom-height-recentfilesbox"
            >
              <p class="ms-0 ms-md-4 mb-2 mb-md-0 fw-bold fs-6">My Clients</p>

              <hr class="clearfix custom-hr-width mt-2 ms-3 d-none d-md-block" />
              <table class="table ms-0 ms-md-3 table-borderless" [ngClass]="isSmallScreen? '':'table-hover'">
                <thead class="">
                  <tr>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      Name &nbsp;<img
                        src="/assets/revamp/myDocumentsArrowUpIcon.png"
                        width="7"
                        height="7"
                      />
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      File Type
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      Storage
                    </th>
                  </tr>
                </thead>
                <tbody class="custom-font-size-13">
                  <ng-container *ngFor="let client of clientArray; index as i">
                    <tr>
                      <td colspan="isSmallScreen? 5:1"
                       class="align-middle"
                       [ngClass]="isSmallScreen? 'px-0':''"
                        (click)="
                          viewSpecificClient(
                            client.id,
                            client.firstName,
                            client.lastName
                          )
                        "
                      >
                        <img
                          src="/assets/revamp/myDocumentsFolderIcon.png"
                          class="float-start"
                          [style.width]="isSmallScreen? '1.8rem':'1rem'"
                          [style.height]="isSmallScreen? '1.8rem':'1rem'"
                        />

                        <p class="d-inline-block ms-3 custom-font-size-14" [ngClass]="isSmallScreen? 'fw-bold':''">
                          <span *ngIf="client.firstName!=null">
                            &nbsp;{{ client.firstName }}&nbsp;{{ client.lastName }}
                          </span>
                          <span *ngIf="client.firstName==null">
                            &nbsp;{{ client.emailAddress }}&nbsp;
                          </span>
                           <!-- // Start Document Additional Info Small Screen -->
                        <span class="d-table-cell d-md-none fw-normal custom-font-size-10" >
                          Folder
                          -
                          {{
                            formatBytes(
                              client.usedStorageSizeInGB * 1024 * 1024 * 1024
                            )
                          }}
                          used of {{ client.allocatedStorageSizeInGB }}GB
                        </span>
                        <!-- // End Document Additional Info Small Screen -->
                        </p>
                       
                      </td>
                                       
                      <td class="text-secondary align-middle d-none d-md-table-cell">Folder</td>
                      <td class="text-secondary align-middle d-none d-md-table-cell">
                        <div class="progress-stacked mt-1 custom-bg-lightBlue">
                          <div
                            class="progress"
                            role="progressbar"
                            aria-label="Segment one"
                            aria-valuenow="15"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            [style.width]="client.usedStoragePercentage"
                          >
                            <div
                              class="progress-bar custom-bg-mediumBlue"
                            ></div>
                          </div>
                        </div>

                        <p class="mb-0 mt-0 text-end">
                          {{
                            formatBytes(
                              client.usedStorageSizeInGB * 1024 * 1024 * 1024
                            )
                          }}
                          used of {{ client.allocatedStorageSizeInGB }}GB
                        </p>
                      </td>
                    </tr>
                    <hr class="mt-0 d-block d-md-none" style="width:100%;">
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!-- // End Recent Files Box -->
          </div>
        </div>
        <!-- // End First Row Inner Second Row -->
      </div>
    </div>
    <!-- // End First Row -->
  </div>
</section>

<app-error-pop-up
  [(displaySessionMessage)]="displaySessionMessage"
  [messageToBeDisplayed]="messageToBeDisplayed"
  [errorType]="errorType"
></app-error-pop-up>
