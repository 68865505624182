import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-up-steps.component',
  templateUrl: './sign-up-steps.component.html',
  styleUrls: ['./sign-up-steps.component.css']
})
export class SignUpStepsComponent implements OnInit {
  stage1Data: any;
  showStage1Personal: boolean;
  showStage1Industry: boolean;
  showStage2Credentials: boolean;
  displaySessionMessage = false;
  messageToBeDisplayed = "";
  errorType = "";


  urlEmailAddress: string = null;
  _authToken: string = null;
  clientId: any;

  requestToken: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService,
    private tokenStorageService: TokenStorageService, private authService: AuthService) {

  }

  ngOnInit(): void {
    this.displaySessionMessage = false;
    this.messageToBeDisplayed = "";
    this.errorType = "";
    this.authService.requestToken(
      "SignUpWeb"
    ).subscribe({
      next: async (event) => {
        if (event instanceof HttpResponse) {
          if (event.body.status == 500 || event.body.status == 403) {
            console.error("Error: " + event.body.message);
          } else {
            this.requestToken = event.body.accesstoken;
          }
        }
      },
      error: (err) => {
        console.error(err);
      }
    });

    this._authToken = this.activatedRoute.snapshot.queryParamMap.get('authToken');
    if (this.activatedRoute.snapshot.queryParamMap.get('authToken')) {
      this.userService.checkClientAuthToken(this._authToken).subscribe({
        next: (event) => {
          if (event instanceof HttpResponse) {
            if (environment.debug) {
              console.log("event:", event);
              console.log("Object.keys(event.body):" + Object.keys(event.body));
              console.log("Object Status:" + event.body.status);
              console.log("Object Token:" + event.body.authToken);
            }
            if (event.body.status == 500) {
              this.displaySessionMessage = true;
                this.messageToBeDisplayed = "Error: " + event.body.message;
                this.errorType = "genericError";
            } else {
              this.urlEmailAddress = event.body.emailAddress;
              this.clientId = event.body.clientID;
            }
          }
        },
        error: (err) => {
            // Response error from backend
            this.displaySessionMessage = true;
            this.messageToBeDisplayed = "System Error: "+ err.error.message;
            this.errorType = "genericError";
          }
        });
      if (this.urlEmailAddress != null || this.urlEmailAddress != "") {
        this.showStage1Personal = true;
        this.showStage1Industry = false;
        this.showStage2Credentials = false;
      } else {
        this.router.navigate(['SignUpSelection']);
      }
    } else {
      if (history.state.origin == 'IndividualSignUp') {
        this.showStage1Personal = true;
        this.showStage1Industry = false;
        this.showStage2Credentials = false;
      } else if (history.state.origin == 'BusinessSignUp') {
        this.showStage1Personal = false;
        this.showStage1Industry = true;
        this.showStage2Credentials = false;
      } else {
        this.router.navigate(['SignUpSelection']);
      }
    }


    // this.showStage1Industry = false;
    // this.showStage2Credentials = true;
  }

  redirectToSignIn() {
    this.router.navigate(['SignIn']);
  }

  // receives data from Stage 1 - personal to be parsed to Stage 2
  // onStage1PersonalSubmit(personalData: any) {
  //   this.personalData = personalData;
  //   console.log("Stage 1 personalData: ", personalData);
  //   this.showStage1Personal = false;
  //   this.showStage2Credentials = true;
  // }

  // receives data from Stage 1 - personal to be parsed to Stage 2
  onStage1Submit(data: any) {
    if (data) {
      if (data.origin == "personalData") {
        this.stage1Data = data;
        if (environment.debug) {
          console.log("Stage 1 personalData: ", data);
        }
        this.showStage1Personal = false;
        this.showStage2Credentials = true;
      } else if (data.origin == "industryData") {
        this.stage1Data = data;
        if (environment.debug) {
          console.log("Stage 1 industryData: ", data);
        }
        this.showStage1Industry = false;
        this.showStage2Credentials = true;
      }
    }

  }

  // receives data from Stage 1 - industry to be parsed to Stage 2
  // onStage1IndustrySubmit(industryData: any) {
  //   this.industryData = industryData;
  //   console.log("Stage 1 industryData: ", industryData);
  //   this.showStage1Industry = false;
  //   this.showStage2Credentials = true;
  // }

  onStage2GoBack(data: any) {
    if (data) {
      if (data.origin == "personalData") {
        this.stage1Data = data;
        if (environment.debug) {
          console.log("Go back data: ", data);
        }
        this.showStage2Credentials = false;
        this.showStage1Personal = true;
      } else if (data.origin == "industryData") {
        this.stage1Data = data;
        if (environment.debug) {
          console.log("Go back data: ", data);
        }
        this.showStage2Credentials = false;
        this.showStage1Industry = true;
      }
    }
  }
}
