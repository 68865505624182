import { Component, OnInit, HostBinding, HostListener } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('collapseAnimation', [
      state('open', style({ height: '*' })),
      state('closed', style({ height: 0, overflow: 'hidden' })),
      transition('open <=> closed', [animate('300ms ease-in-out')]),
    ]),
  ],
})
export class AppComponent {
  title = 'LegitLegalBoxFrontend';
  isServices: boolean = false;
  legalboxDefaultHeaderLogoPath = "/assets/scratchImg/legalboxHeaderLogo.png";
  legalboxWhiteHeaderLogoPath = "/assets/scratchImg/legalboxWhiteHeaderLogo.png";
  burgerIconPath = "/assets/scratchImg/burger.png";
  closeBurgerIconPath = "/assets/scratchImg/closeBurger.png";
  showHeader = true;
  showHeaderLinks = true;
  showFooter = true;
  scaleValue;
  isMenuOpen = false;
  isCollapsed = false;


  isShowDivIf = false;
  toggleDisplayDivIf() {
    this.isShowDivIf = !this.isShowDivIf;
  }


  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  isBigScreen: boolean = window.innerWidth >= 992; // Initial check
  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    this.isBigScreen = window.innerWidth >= 992;
    if (environment.debug) {
      console.log("isBigScreen from Parent Component: " + this.isBigScreen);
    }
  }

  @HostBinding("attr.style")
  public get valueAsStyle(): any {
    if (window.devicePixelRatio !== 1) {
      this.scaleValue = (1 / window.devicePixelRatio);
    }
    else {
      this.scaleValue = 1;
    }
    return this.sanitizer.bypassSecurityTrustStyle(`--percentage: ${this.scaleValue}`);
  }


  constructor(private router: Router, private sanitizer: DomSanitizer) {

  }

  onActivate(event) {
    // window.scroll(0,0);

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  checkRouterLink() {
    let currentRoute = this.router.url;

    // Start First If Block
    if (currentRoute.startsWith("/SignIn")) {
      this.showHeaderLinks = false;
      if (environment.debug) {
        console.log("showHeader from Parent Component: " + this.showHeaderLinks);
      }
    }
    else {
      this.showHeaderLinks = true;
    }
    // End First If Block

    // Start Second If Block
    if (currentRoute == "/Services" || currentRoute == "/SignUpSelectionDarkMode" || currentRoute == "/AboutUs") {
      this.isServices = true;
    }
    else {
      this.isServices = false;
    }
    // End Second If Block

    // Start Third If Block
    if (currentRoute == "/SignIn") {
      this.showFooter = false;
    }
    else {
      this.showFooter = true;
    }
    // End Third If Block

    // Start Fourth If Block
    if (currentRoute == "/UserDashboard" || currentRoute == "/UserDocuments" || currentRoute == "/ViewAllClients" || currentRoute == "/AddNewClient"
      || currentRoute == "/UploadNewFiles" || currentRoute.startsWith("/ViewSpecificClient") || currentRoute == "/UserArchive"
      || currentRoute == "/SharedFiles" || currentRoute == "/ViewShareddocumentsBusinesses" || currentRoute == "/ViewShareddocumentsSpecificBusiness"
      || currentRoute == "/Checkout" || currentRoute.startsWith("/CheckoutProcessing")) {
      this.showHeader = false;
    }
    else {
      this.showHeader = true;
    }
    // End Fourth If

  }

}
