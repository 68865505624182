<link
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
  rel="stylesheet"
  integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN"
  crossorigin="anonymous"
/>
<script
  src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"
  integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL"
  crossorigin="anonymous"
></script>

<div class="section">
  <div class="container">
    <!-- Main Content -->
    <div class="row justify-content-center">
      <div class="col-12">
        <!-- sub title -->
        <div class="row pb-3">
          <div class="col-10">
            <h2 class="fw-bold">Credentials to sign in</h2>
          </div>
          <div class="col-2">
            <p class="fs-4">2/2</p>
          </div>
        </div>

        <!-- sub content questions - Stage 1 -->
        <div class="row">
          <div class="col-12 text-left mb-3">
            <label class="form-label fw-semibold" for="email">Email</label>
            <input
              type="text"
              class="form-control"
              id="email"
              name="email"
              placeholder="eg. john@example.com"
              [ngClass]="isEmailValid"
              (focus)="resetValidation()"
              required
              [(ngModel)]="email"
              [value]="disableEmail ? '{{email}}' : ''"
              [disabled]="disableEmail"
              [readOnly]="disableEmail"
            />
            <div class="valid-feedback"></div>
            <div class="invalid-feedback">
              {{ invalidEmailText }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-left mb-3">
            <label class="form-label fw-semibold" for="userName"
              >Username</label
            >
            <input
              type="text"
              class="form-control"
              id="userName"
              name="userName"
              placeholder="eg. johndoe"
              aria-label="userName"
              required
              [(ngModel)]="userName"
              [ngClass]="isUsernameValid"
              (focus)="resetValidation()"
              aria-describedby="userNameHelpBlock"
            />
            <div class="valid-feedback"></div>
            <div class="invalid-feedback">
              Please enter your preferred username without spaces!
            </div>
            <div
              id="userNameHelpBlock"
              class="form-text"
              [ngClass]="isValidated ? 'd-none' : 'd-block'"
            >
            Please enter your preferred username without spaces. eg. john_doe
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-left mb-3">
            <label class="form-label fw-semibold" for="newPasword"
              >Password</label
            >
            <input
              type="password"
              class="form-control"
              id="newPasword"
              name="newPasword"
              [ngClass]="isPasswordValid"
              (focus)="resetValidation()"
              placeholder="* * * * * * * *"
              aria-label="newPasword"
              required
              [(ngModel)]="newPasword"
              aria-describedby="passwordHelpBlock"
            />
            <div class="valid-feedback"></div>
            <div class="invalid-feedback">
              {{ invalidPasswordText }}
            </div>
            <div
              id="passwordHelpBlock"
              class="form-text"
              [ngClass]="isValidated ? 'd-none' : 'd-block'"
            >
              Your password must be at least 8 characters long, contain at least
              1 uppercase letter, 1 lowercase letter, 1 number and 1 special
              character.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left mb-3">
            <label class="form-label fw-semibold" for="confPasword"
              >Confirm Password</label
            >
            <input
              type="password"
              class="form-control"
              id="confPasword"
              name="confPasword"
              [ngClass]="isConfPasswordValid"
              (focus)="resetValidation()"
              placeholder="* * * * * * * *"
              aria-label="confPasword"
              required
              [(ngModel)]="confPasword"
            />
            <div class="valid-feedback"></div>
            <div class="invalid-feedback">
              {{ invalidConfPasswordText }}
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkAgreeTerms"
                required
                (change)="isChecked($event)"
                [ngClass]="isTermsValid"
                (focus)="resetValidation()"
              />
              <label class="form-check-label" for="checkAgreeTerms">
                I hereby agree to accept and be bound by the
                <a (click)="openDocument('Terms_And_Conditions.pdf')"
                  >Terms and Conditions</a
                >
                &
                <a (click)="openDocument('Privacy_Policy.pdf')"
                  >Privacy Policy</a
                >
                of Legalbox.
              </label>
              <div class="invalid-feedback">
                Please tick the checkbox to agree to Legalbox's Privacy Policy & Terms and Conditions.
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12 d-grid gap-1">
            <button class="btn btn-primary" (click)="checkDetails()">
              Create Account
            </button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 d-grid gap-1">
            <button class="btn btn-secondary" (click)="goBack()">
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-error-pop-up
  [(displaySessionMessage)]="displaySessionMessage"
  [messageToBeDisplayed]="messageToBeDisplayed"
  [errorType]="errorType"
>
</app-error-pop-up>
