<section name="signInSection1" class="">
  <div class="container-xl-fluid font-plus-jakarta-sans">
    <!-- // Start First Row -->
    <div class="row">

      <div class="col-md-5">
        <img src="/assets/revamp/signInSection1BgImg.png" class="custom-negative-margin-top custom-img-responsive d-none d-md-block">
      </div>

      <div class="col-md-7">

        <!-- // Start First Row Inner First Row -->
        <div class="row p-1 p-md-5 ms-0 ms-md-5">

          <div class="col-md-9">
            <h3 class="fw-bold">Welcome back to your hub.</h3>

            <div>
              <input class="form-control border-0 bg-light mt-4 p-3" [ngClass]="isUsernameValid"
              type="text" placeholder="Username / Email" aria-label="default input example"
            autocomplete="off" required [(ngModel)]="username">
            <div class="valid-feedback"></div>
              <div class="p-3 py-0 invalid-feedback">
                Please enter your username!
              </div>
            </div>
            
            <div>
              <input class="form-control border-0 bg-light mt-4 p-3" [ngClass]="isPasswordValid"
              type="password" placeholder="Password" aria-label="default input example"
            autocomplete="off" required [(ngModel)]="password" (keydown.enter)="signIn()" />
            <div class="valid-feedback"></div>
              <div class="p-3 py-0 invalid-feedback">
                Please enter your password!
              </div>
            </div>
            

          </div>

       

          <div class="col-5 col-md-5 mt-4">
            <div class="form-check">
              <input class="form-check-input border-primary" type="checkbox" value="" id="flexCheckDefault">
              <label class="form-check-label text-responsive" for="flexCheckDefault">
                Remember me
              </label>
            </div>
          </div>

          <div class="col-7 col-md-4 me-0 me-md-5 mt-4">
            <p class="float-end mb-0"><a class="link-opacity-75-hover text-secondary text-responsive" (click)="redirectToForgotPassword()">Reset Password?</a></p>
          </div>

          <div class="col-md-9 mt-5 pt-2 mb-5 pb-5">
            <div class="d-grid gap-2">
              <button class="btn btn-primary p-2" type="button" (click)="signIn()" [disabled]="disableSignInButton">Sign In</button>
            </div>
            <p class="mt-4">
              <span class="text-body-tertiary">Don’t have an account?</span>
              <span class="link-opacity-75-hover link-underline-opacity-0 text-primary ms-1" (click)="redirectToSelectPlan()">Create now</span>
            </p>
          </div>
          
        </div>
         <!-- // End First Row Inner First Row -->

      </div>


    </div>
    <!-- // End First Row -->
  </div>
</section>

<app-error-pop-up [(displaySessionMessage)]="displaySessionMessage" [messageToBeDisplayed]="messageToBeDisplayed"
  [errorType]="errorType">
  </app-error-pop-up>
