import { Component, OnInit, HostListener } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-archive',
  templateUrl: './user-archive.component.html',
  styleUrls: ['./user-archive.component.css']
})
export class UserArchiveComponent implements OnInit {
  displayAddNewDropdownContent = false;
  message: any;
  corporateUser = false;
  individualUser = false;
  corporateInstitutionUser = false;
  retrievedArchivedLegalDocuments: any[] = [];
  legitUserId: any;
  retrievedUser: any;
  userType: any;
  finalSumOfClientStorageInGB: any;
  finalSumOfClientStorageInMB: any;
  finalSumOfCorporateInstitutionUsageInGB: any;
  finalSumOfCorporateInstitutionUsageInMB: any;
  storageBalanceInGB: any;
  storageBalanceInMB: any;
  corporateInstitutionStorageValue: any;
  corporateInstitutionStorageValueInPercentage: any;
  clientStorageValueInPercentage: any;
  showMoreOptions: boolean;
  displayRestoreMessage: boolean;
  documentTitle: string;
  documentID: any;
  dropdownDocumentID: string;
  buttonNo: any;
  restoring: boolean;
  displayLoadingMessage: boolean;

  displayResponseMessage = false;
  responseType: any;
  responseMessageText: any;
  responseTypeColor: any;
  responseMessageIconImageURL: any;
  successMessageIconImageURL = "/assets/revamp/successGreenIconNew.png";
  errorMessageIconImageURL = "/assets/revamp/redExclamationMark.png";
  displaySessionMessage = false;
  messageToBeDisplayed = "";
  errorType = "";

  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    console.log("isSmallScreen from Home Component: " + this.isSmallScreen);
  }

  constructor(private router: Router, private userService: UserService,
    private tokenStorageService: TokenStorageService) {

  }

  ngOnInit(): void {
    this.displaySessionMessage = false;
    this.messageToBeDisplayed = "";
    this.errorType = "";
    this.displayLoadingMessage = false;
    this.showMoreOptions = false;
    this.displayRestoreMessage = false;
    this.restoring = false;
    let retrievedUser = this.tokenStorageService.getUser();
    this.getUser(retrievedUser.userId);
    this.getUserArchivedLegalDocumentsList(retrievedUser.userId);
    if (environment.debug) {
      console.log("retrievedUser.userType:", retrievedUser);
    }
    if (retrievedUser.userType == "business") {
      this.corporateUser = true;
    } else if (retrievedUser.userType == "individual") {
      this.individualUser = true;
    }
  }

  localFormatDate(_date) {
    let monthNumericForm = formatDate(_date, 'MM', 'en-US');
    let monthStringForm = this.monthNumberToString(monthNumericForm);
    let date_Year = formatDate(_date, 'dd, yyyy', 'en-US');
    let final_date = monthStringForm + " " + date_Year;
    return final_date;
  }

  monthNumberToString(_month) {
    var returnMonth;

    switch (_month) {
      case "01": returnMonth = "January"; break;
      case "02": returnMonth = "February"; break;
      case "03": returnMonth = "March"; break;
      case "04": returnMonth = "April"; break;
      case "05": returnMonth = "May"; break;
      case "06": returnMonth = "June"; break;
      case "07": returnMonth = "July"; break;
      case "08": returnMonth = "August"; break;
      case "09": returnMonth = "September"; break;
      case "10": returnMonth = "October"; break;
      case "11": returnMonth = "November"; break;
      case "12": returnMonth = "December"; break;
    }

    return returnMonth;
  }

  /**
 * format bytes
 * @param bytes (File size in bytes)
 * @param decimals (Decimals point)
 */

  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  getUser(id): void {
    this.userService.get(id)
      .subscribe(
        data => {
          this.getUserArchivedLegalDocumentsList(id);
          if (environment.debug) {
            console.log(data);
          }
          if (data.status == 403) {
            alert("Your session has expired. Please login again!");
            this.tokenStorageService.signOut();
            this.router.navigate(['SignIn']);
          }
          this.retrievedUser = data;

          if (this.retrievedUser.userType == "business") {
            this.corporateInstitutionUser = true;
          }

        },
        error => {
          console.error(error);
        });
  }


  getUserArchivedLegalDocumentsList(userId) {

    this.userService.getUserArchivedDocumentsList(
      userId,
      "false"
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.retrievedArchivedLegalDocuments:" + event.body.retrievedArchivedLegalDocuments);
          }
          // this.message = event.body.message;
          this.retrievedArchivedLegalDocuments = event.body.retrievedArchivedLegalDocuments;

          // return event.body.message;
        }
      },
      err => {
        // this.progress = 0;
        // this.message = 'Could not create law firm user!';
        alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  redirectToClientArchiveSelection() {
    this.router.navigate(['ViewAllClients'],
      {
        state:
        {
          origin: "UserArchiveComponent",
        }
      }
    );

  }

  dropdownOptions(docID) {
    this.dropdownDocumentID = docID;
    if (!this.showMoreOptions) {
      this.showMoreOptions = true;
    } else {
      this.showMoreOptions = false;
    }
  }

  cancelRestore() {
    this.displayRestoreMessage = false;
  }

  restoreDocumentPopUp(docID: any, docTitle: string) {
    this.documentTitle = docTitle;
    this.documentID = docID;
    this.displayRestoreMessage = true;
  }

  restoreDocument() {
    // Archive the document
    this.displayLoadingMessage = true;
    this.restoring = true;
    if (environment.debug) {
      console.log("retrieved user: ", this.retrievedUser);
    }
    this.userService.restoreArchivedDocument(this.retrievedUser.id, "false", this.documentID).subscribe({
      next: (event) => {
        if (event instanceof HttpResponse) {
          if (event.body.status == 500) {
            alert("Error: " + event.body.message);
          } else if (event.body.status == 404) {
            alert("Error: " + event.body.message);
          } else {
            this.displayRestoreMessage = false;
            this.responseTypeColor = "#0BB831";
            this.responseMessageIconImageURL = this.successMessageIconImageURL;
            this.responseType = "Success";
            this.responseMessageText = "File successfully restored!";
            this.displayResponseMessage = true;
            this.displayLoadingMessage = false;
          }
        }
      },
      error: (err) => {
        // Response error from backend / Fail to send forgot password request
        this.responseTypeColor = "#EE4E55";
        this.responseMessageIconImageURL = this.errorMessageIconImageURL;
        this.responseType = "Error";
        this.responseMessageText = err.error.message;
        this.displayResponseMessage = true;
        this.displayLoadingMessage = false;
      }
    });

  }

  closeResponseMessage() {
    this.displayResponseMessage = false;
    const url = self ? '/UserDocuments' : '/UserDocuments';
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/${url}`]).then(() => {
        if (environment.debug) {
          console.log(`After navigation I am on:${this.router.url}`)
        }
      })
    });
  }

}
