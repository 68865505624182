<section name="homeSection1" class="pb-5">
  <div class="container-xl pt-4 font-plus-jakarta-sans">
    <!-- // Start First Row -->
    <div class="row gx-5 pb-2">

      <h3 class="text-center fw-bold">Choose the right plan for you</h3>

    <div
      class="col-md-4 d-flex align-items-stretch mt-5 ms-1 ms-md-0 px-5 px-md-1"
    >
      <div class="card px-1 pt-3 pb-5 bd-light-blue w-100 shadow">
        <div class="card-body letter-spacing-1">
          <h3 class="card-title fw-bold mt-1">Basic</h3>
          <h3 class="card-title fw-bold mt-1">
            MYR
            <span class="text-shadow-black">{{
              showCorporatePricingPlan
                ? corporateBasicPlanPrice
                : individualBasicPlanPrice
            }}</span>
            <small class="fw-normal ml-2">/mo</small>
          </h3>
          <p class="card-text text-secondary">
            {{
              showCorporatePricingPlan
                ? corporateBasicPlanStorageSizeInGB
                : individualBasicPlanStorageSizeInGB >= 1
                ? individualBasicPlanStorageSizeInGB
                : individualBasicPlanStorageSizeInGB * 1000
            }}
            {{
              showCorporatePricingPlan
                ? "G"
                : individualBasicPlanStorageSizeInGB >= 1
                ? "G"
                : "M"
            }}B
          </p>
          <div class="d-grid gap-2">
            <a class="btn text-black btn-primary border-0 fw-semibold mt-5 rounded-2 bg-medium-blue"
            (click)="showCorporatePricingPlan? selectPricingPlan(corporateBasicPlanId) : selectPricingPlan(individualBasicPlanId)"
              >Buy Now</a
            >
          </div>

          <!-- // Start Fourth Row Second Card Inner First Row -->
          <div class="row text-secondary mt-5">
            <div class="col-1">
              <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
            </div>
            <div class="col-10">
              <p class="">
                {{
                  showCorporatePricingPlan
                    ? corporateBasicPlanStorageSizeInGB
                    : individualBasicPlanStorageSizeInGB >= 1
                    ? individualBasicPlanStorageSizeInGB
                    : individualBasicPlanStorageSizeInGB * 1000
                }}
                {{
                  showCorporatePricingPlan
                    ? "G"
                    : individualBasicPlanStorageSizeInGB >= 1
                    ? "G"
                    : "M"
                }}B storage
              </p>
            </div>
          </div>
          <!-- // End Fourth Row Second Card Inner First Row -->

          <!-- // Start Fourth Row Second Card Inner Second Row -->
          <div class="row text-secondary mt-1">
            <div class="col-1">
              <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
            </div>
            <div class="col-10">
              <p class="">Email and phone support</p>
            </div>
          </div>
          <!-- // End Fourth Row Second Card Inner Second Row -->
        </div>
      </div>
    </div>

    <div
      class="col-md-4 d-flex align-items-stretch mt-5 ms-1 ms-md-0 px-5 px-md-1"
    >
      <div class="card px-1 pt-3 pb-5 bd-light-blue w-100 shadow">
        <div class="card-body letter-spacing-1">
          <h3 class="card-title fw-bold mt-1">Advanced</h3>
          <h3 class="card-title fw-bold mt-1">
            MYR
            <span class="text-shadow-black">{{
              showCorporatePricingPlan
                ? corporateAdvancedPlanPrice
                : individualAdvancedPlanPrice
            }}</span>
            <small class="fw-normal ml-2">/mo</small>
          </h3>
          <p class="card-text text-secondary">
            {{
              showCorporatePricingPlan
                ? corporateAdvancedPlanStorageSizeInGB
                : individualAdvancedPlanStorageSizeInGB >= 1
                ? individualAdvancedPlanStorageSizeInGB
                : individualAdvancedPlanStorageSizeInGB * 1000
            }}
            {{
              showCorporatePricingPlan
                ? "G"
                : individualAdvancedPlanStorageSizeInGB >= 1
                ? "G"
                : "M"
            }}B
          </p>
          <div class="d-grid gap-2">
            <a class="btn text-black btn-primary border-0 fw-semibold mt-5 rounded-2 bg-medium-blue"
            (click)="showCorporatePricingPlan? selectPricingPlan(corporateAdvancedPlanId) : selectPricingPlan(individualAdvancedPlanId)"
              >Buy Now</a
            >
          </div>

          <!-- // Start Fourth Row Third Card Inner First Row -->
          <div class="row text-secondary mt-5">
            <div class="col-1">
              <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
            </div>
            <div class="col-10">
              <p class="">
                {{
                  showCorporatePricingPlan
                    ? corporateAdvancedPlanStorageSizeInGB
                    : individualAdvancedPlanStorageSizeInGB >= 1
                    ? individualAdvancedPlanStorageSizeInGB
                    : individualAdvancedPlanStorageSizeInGB * 1000
                }}
                {{
                  showCorporatePricingPlan
                    ? "G"
                    : individualAdvancedPlanStorageSizeInGB >= 1
                    ? "G"
                    : "M"
                }}B storage
              </p>
            </div>
          </div>
          <!-- // End Fourth Row Third Card Inner First Row -->

          <!-- // Start Fourth Row Third Card Inner Second Row -->
          <div class="row text-secondary mt-1">
            <div class="col-1">
              <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
            </div>
            <div class="col-10">
              <p class="">Email and phone support</p>
            </div>
          </div>
          <!-- // End Fourth Row Third Card Inner Second Row -->
        </div>
      </div>
    </div>

    <div
      class="col-md-4 d-flex align-items-stretch mt-5 ms-1 ms-md-0 px-5 px-md-1"
    >
      <div class="card px-1 pt-3 pb-3 bd-light-blue w-100 shadow">
        <div class="card-body letter-spacing-1">
          <h3 class="card-title fw-bold mt-1">Premium</h3>
          <h3 class="card-title fw-bold mt-1">
            MYR
            <span class="text-shadow-black">{{
              showCorporatePricingPlan
                ? corporatePremiumPlanPrice
                : individualPremiumPlanPrice
            }}</span>
            <small class="fw-normal ml-2">/mo</small>
          </h3>
          <p class="card-text text-secondary">
            {{
              showCorporatePricingPlan
                ? corporatePremiumPlanStorageSizeInGB
                : individualPremiumPlanStorageSizeInGB
            }}
            GB
          </p>
          <div class="d-grid gap-2">
            <a class="btn text-black btn-primary border-0 fw-semibold mt-5 rounded-2 bg-medium-blue"
            (click)="showCorporatePricingPlan? selectPricingPlan(corporatePremiumPlanId) : selectPricingPlan(individualPremiumPlanId)"
              >Buy Now</a
            >
          </div>

          <!-- // Start Fourth Row Fourth Card Inner First Row -->
          <div class="row text-secondary mt-5">
            <div class="col-1">
              <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
            </div>
            <div class="col-10">
              <p class="">
                {{
                  showCorporatePricingPlan
                    ? corporatePremiumPlanStorageSizeInGB
                    : individualPremiumPlanStorageSizeInGB
                }}
                GB storage
              </p>
            </div>
          </div>
          <!-- // End Fourth Row Fourth Card Inner First Row -->

          <!-- // Start Fourth Row Fourth Card Inner Second Row -->
          <div class="row text-secondary mt-1">
            <div class="col-1">
              <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
            </div>
            <div class="col-10">
              <p class="">Dedicated account manager</p>
            </div>
          </div>
          <!-- // End Fourth Row Fourth Card Inner Second Row -->

          <!-- // Start Fourth Row Fourth Card Inner Third Row -->
          <div class="row text-secondary mt-1">
            <div class="col-1">
              <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
            </div>
            <div class="col-10">
              <p class="">First time set up and training provided</p>
            </div>
          </div>
          <!-- // End Fourth Row Fourth Card Inner Third Row -->
        </div>
      </div>
    </div>

    </div>
       <!-- // End First Row -->

        <!-- // Start Second Row -->
    <div class="row mt-5 bg-medium-blue border border-light mb-5 ms-4 ms-md-0 me-3 me-md-1 rounded-5 shadow">
      

        <div class="col-md-5 p-0 p-md-5 ms-3 ms-md-5 mt-4 mt-md-2">
          <h3 class="fw-bold">Get started with free plan</h3>
        
          <div class="mt-5">
            <img class="float-start" src="/assets/revamp/checkIcon.png" width="24" height="24">
            <p class="float-start ms-2">5 MB Storage </p>
          </div>
          <br>
          <div class="mt-3">
            <img class="float-start" src="/assets/revamp/checkIcon.png" width="24" height="24">
            <p class="float-start ms-2">Limited Transfer </p>
          </div>
          
        </div>
  
        <div class="col-md-4 offset-md-2 p-4 p-md-5 mt-4">
          <h3 class="fw-bold text-center">MYR 0</h3>
          <div class="d-flex justify-content-center mt-5">
            <button type="button" 
            class="btn text-black btn-primary border-2 border-dark rounded-5 p-2 px-4 fs-6 fw-semibold bg-medium-blue"
            (click)="selectPricingPlan(freePlanId)">
              Get Started for FREE
            </button>
          </div>
          
        </div>
     
    </div>
    <!-- // End Second Row -->
  </div>
</section>