import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './token-storage.service';

const baseUrl = environment.backendURL + '/api/clients';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  get(id): Observable<any> {
    const header ={
      'Access-Token':this.tokenStorageService.getToken()
    }

    const opt = {
      headers: new HttpHeaders(header),
    }
    
    return this.http.get(`${baseUrl}/${id}`, opt);
  }
}
