<link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>
<link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet'>

<!-- // Start pricingPlanSection1 -->
<div class="pricingPlanSection1">
    <h1>Choose the right plan for you</h1>

    <div class="tab">
      <button class="tablinks" (click)="displayCorporatePricingPlan()" 
      [style.background]="showCorporatePricingPlan? 'black' : 'white'"
      [style.color]="showCorporatePricingPlan? 'white' : 'black'"
      >Corporate</button>
      <button class="tablinks" (click)="displayIndividualPricingPlan()"
      [style.background]="showIndividualPricingPlan? 'black' : 'white'"
      [style.color]="showIndividualPricingPlan? 'white' : 'black'"
      >Individual</button>
    </div>
    
    <div id="Corporate" class="tabcontent" [style.display]="showCorporatePricingPlan? 'block':'none'">
      <!-- <h3>Corporate</h3> -->
      <br><br>

      <div class="pricingPlanCardItem pricingPlanFirstCardItem">
        <h2>Basic</h2>
        <div class="pricingPlanAmount pricingPlanCurrency">
          MYR
        </div>
        <div class="pricingPlanAmount">
          {{corporateBasicPlanPrice}}
        </div>
        <div class="pricingPlanDuration">
          /mo
        </div>
        <br><br>
         <p class="pricingPlanDescription">{{corporateBasicPlanStorageSizeInGB}} GB</p>
         <button class="button button1">Buy Now</button>
         <br>
        <div class="pricingPlanFeature pricingPlanFirstFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">{{corporateBasicPlanStorageSizeInGB}} GB of Storage</span>
        </div> 
        <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Email and Phone Support</span>
        </div> 
        <!-- <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Lorem ipsum dolor sit amet consectetur.</span>
        </div> 
        <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Lorem ipsum dolor sit amet consectetur.</span>
        </div>  -->
      </div>

      <div class="pricingPlanCardItem spotlightPricingPlanCardItem">
        <div class="spotlightText">Most Popular</div>
        <h2>Advanced</h2>
        <div class="pricingPlanAmount pricingPlanCurrency">
          MYR
        </div>
        <div class="pricingPlanAmount">
          {{corporateAdvancedPlanPrice}}
        </div>
        <div class="pricingPlanDuration">
          /mo
        </div>
        <br><br>
        <p class="pricingPlanDescription">{{corporateAdvancedPlanStorageSizeInGB}} GB</p>
        <button class="button button1 spotlightPricingPlanButton">Buy Now</button>
       <br>
        <div class="pricingPlanFeature pricingPlanFirstFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">{{corporateAdvancedPlanStorageSizeInGB}} GB of Storage</span>
        </div> 
        <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Email and Phone Support</span>
        </div> 
        <!-- <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Lorem ipsum dolor sit amet consectetur.</span>
        </div> 
        <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Lorem ipsum dolor sit amet consectetur.</span>
        </div>  -->
      </div>

      <div class="pricingPlanCardItem">
        <h2>Premium</h2>
        <div class="pricingPlanAmount pricingPlanCurrency">
          MYR
        </div>
        <div class="pricingPlanAmount">
          {{corporatePremiumPlanPrice}}
        </div>
        <div class="pricingPlanDuration">
          /mo
        </div>
        <br><br>
        <p class="pricingPlanDescription">{{corporatePremiumPlanStorageSizeInGB}} GB</p>
        <button class="button button1">Buy Now</button>
        <br>
      
        <div class="pricingPlanFeature pricingPlanFirstFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">{{corporatePremiumPlanStorageSizeInGB}} GB of Storage</span>
        </div> 
        <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Dedicated Account Manager</span>
        </div> 
        <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">First time set up and training provided</span>
        </div> 
        <!-- <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Lorem ipsum dolor sit amet consectetur.</span>
        </div>  -->
      </div>

    </div>
    
    <div id="Individual" class="tabcontent" [style.display]="showIndividualPricingPlan? 'block':'none'">
      <!-- <h3>Individual</h3> -->
      <br><br>

      <div class="pricingPlanCardItem pricingPlanFirstCardItem">
        <h2>Basic</h2>
        <div class="pricingPlanAmount pricingPlanCurrency">
          MYR
        </div>
        <div class="pricingPlanAmount">
          {{individualBasicPlanPrice}}
        </div>
        <div class="pricingPlanDuration">
          /mo
        </div>
        <br><br>
        <p class="pricingPlanDescription">{{individualBasicPlanStorageSizeInGB * 1000}} MB</p>
        <button class="button button1">Buy Now</button>
        <br>
       
        <div class="pricingPlanFeature pricingPlanFirstFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">{{individualBasicPlanStorageSizeInGB * 1000}} MB of Storage</span>
        </div> 
        <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Email and Phone Support</span>
        </div> 
        <!-- <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Lorem ipsum dolor sit amet consectetur.</span>
        </div> 
        <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Lorem ipsum dolor sit amet consectetur.</span>
        </div>  -->
      </div>

      <div class="pricingPlanCardItem spotlightPricingPlanCardItem">
        <div class="spotlightText">Most Popular</div>
        <h2>Advanced</h2>
        <div class="pricingPlanAmount pricingPlanCurrency">
          MYR
        </div>
        <div class="pricingPlanAmount">
          {{individualAdvancedPlanPrice}}
        </div>
        <div class="pricingPlanDuration">
          /mo
        </div>
        <br><br>
        <p class="pricingPlanDescription">{{individualAdvancedPlanStorageSizeInGB * 1000}} MB</p>
        <button class="button button1 spotlightPricingPlanButton">Buy Now</button>
        <br>
       
        <div class="pricingPlanFeature pricingPlanFirstFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">{{individualAdvancedPlanStorageSizeInGB * 1000}} MB of Storage</span>
        </div> 
        <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Email and Phone Support</span>
        </div> 
        <!-- <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Lorem ipsum dolor sit amet consectetur.</span>
        </div> 
        <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Lorem ipsum dolor sit amet consectetur.</span>
        </div>  -->
      </div>

      <div class="pricingPlanCardItem">
        <h2>Premium</h2>
        <div class="pricingPlanAmount pricingPlanCurrency">
          MYR
        </div>
        <div class="pricingPlanAmount">
          {{individualPremiumPlanPrice}}
        </div>
        <div class="pricingPlanDuration">
          /mo
        </div>
        <br><br>
        <p class="pricingPlanDescription">{{individualPremiumPlanStorageSizeInGB}} GB </p>
        <button class="button button1">Buy Now</button>
        <br>
     
        <div class="pricingPlanFeature pricingPlanFirstFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">{{individualPremiumPlanStorageSizeInGB}} GB of Storage</span>
        </div> 
        <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Dedicated Account Manager</span>
        </div> 
        <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">First time set up and training provided</span>
        </div> 
        <!-- <div class="pricingPlanFeature"> 
          <img src="/assets/scratchImg/pricingPlanFeatureIcon.svg" class="pricingPlanFeatureIcon">
          <span class="pricingPlanFeatureDescription">Lorem ipsum dolor sit amet consectetur.</span>
        </div>  -->
      </div>

    </div>

    <!-- // Start freePricingPlanBox -->
    <div class="freePricingPlanBox">

        <h2>Get started with free plan</h2>
        <img src="/assets/scratchImg/freePricingPlanCheckmarkIcon.svg" class="freePricingPlanCheckmarkIcon">
        <p class="freePricingPlanFeature">{{freePlanStorageSizeInMB}} MB Storage</p>

        <div class="freePricingPlanBottomFeature">
          <img src="/assets/scratchImg/freePricingPlanCheckmarkIcon.svg" class="freePricingPlanCheckmarkIcon">
          <p class="freePricingPlanFeature">Limited Transfer</p>
        </div>

        <div class="freePricingPlanCostContainer">
          <div class="freePricingPlanPrice">
            MYR {{freePlanPrice}}
            <!-- <span class="freePricingPlanDuration"> / Forever </span> -->
          </div>
          <button class="button button1 freePricingPlanButton">Get Started for FREE</button>
        </div>

    </div>
    <!-- // End freePricingPlanBox -->
    
</div>
<!-- // End pricingPlanSection1 -->

<!-- // Start pricingPlanSection2 -->
<div class="pricingPlanSection2">

  <h1>Frequently asked questions</h1>
  <p>Can’t find your answer? Contact us here at 60163380438 or email at support@legalbox.io</p>

  <div class="faqLeftSection">

    <div class="faqItem">
      <button class="faqItemPlusIconButton" (click)="toggleFaqItem1()">
        <img [src]="faqItem1IconPath" class="faqItemPlusIcon" />
      </button>
      <h2>How secure is LegalBox's Blockchain Storage?</h2>
      <hr class="faqItemHr">
      <br>
      <p [@openClose]="showFaqItem1 ? 'open' : 'closed'">
        At Legalbox, security is our top priority. Our Blockchain Storage 
        solution utilizes state-of-the-art security protocols such as encryption,
         multi-factor authentication, and automatic backups to ensure the safety
         of your data. Additionally, we are compliant with several industry regulations
         including HIPAA, SOC 2, and PCI-DSS. Our team of security experts is constantly
         monitoring and updating our systems to keep your data secure at all times.</p>
    </div>

    <div class="faqItem faqItem2">
      <button class="faqItemPlusIconButton" (click)="toggleFaqItem2()">
        <img [src]="faqItem2IconPath" class="faqItemPlusIcon" />
      </button>
      <h2>What happens if there is a security breach?</h2>
      <hr class="faqItemHr">
      <br>
      <p [@openClose]="showFaqItem2 ? 'open' : 'closed'">
        In the unlikely event of a security breach, our team of experts will work
        quickly to identify and resolve the issue. We will also notify you immediately
        and take steps to prevent further breaches.</p>
    </div>

    <div class="faqItem faqItem3">
      <button class="faqItemPlusIconButton" (click)="toggleFaqItem3()">
        <img [src]="faqItem3IconPath" class="faqItemPlusIcon" />
      </button>
      <h2>How do you comply with legal regulations?</h2>
      <hr class="faqItemHr">
      <br>
      <p [@openClose]="showFaqItem3 ? 'open' : 'closed'">
        LegalBox is compliant with several legal regulations and standards such as 
        HIPAA, SOC 2, and PCI-DSS. Our team of experts works closely with our clients
         to ensure that we meet their specific compliance needs and standards.</p>
    </div>

  </div>

  <div class="faqRightSection">

    <div class="faqItem">
      <button class="faqItemPlusIconButton" (click)="toggleFaqItem4()">
        <img [src]="faqItem4IconPath" class="faqItemPlusIcon" />
      </button>
      <h2>How LegalBox ensure the security of my data?</h2>
      <hr class="faqItemHr">
      <br>
      <p [@openClose]="showFaqItem4 ? 'open' : 'closed'">
        We utilize state-of-the-art security protocols such as encryption,
        multi-factor authentication, and automatic backups to ensure the 
        safety of your data. We also comply with several industry regulations
        such as HIPAA, SOC 2, and PCI-DSS.</p>
    </div>

    <div class="faqItem faqItem2">
      <button class="faqItemPlusIconButton" (click)="toggleFaqItem5()">
        <img [src]="faqItem5IconPath" class="faqItemPlusIcon" />
      </button>
      <h2>Are my files encrypted in LegalBox's servers?</h2>
      <hr class="faqItemHr">
      <br>
      <p [@openClose]="showFaqItem5 ? 'open' : 'closed'">
        Yes, all files stored on our servers are encrypted to ensure maximum security.</p>
    </div>

    <div class="faqItem faqItem3">
      <button class="faqItemPlusIconButton" (click)="toggleFaqItem6()">
        <img [src]="faqItem6IconPath" class="faqItemPlusIcon" />
      </button>
      <h2>Does  LegalBox have a disaster recovery plan?</h2>
      <hr class="faqItemHr">
      <br>
      <p [@openClose]="showFaqItem6 ? 'open' : 'closed'">
        Yes, we have a comprehensive disaster recovery plan in place to
        minimize data loss and downtime in case of any incidents.</p>
    </div>

  </div>


</div>
<!-- // End pricingPlanSection2 -->

<!-- // Start pricingPlanSection3 -->
<div class="pricingPlanSection3">

  <h1>Get started with LEGALBOX today</h1>
  <p>Get 7 days of full access to all toolkits. Cancel anytime.</p>
  <button class="button button3">Get Started</button>

</div>
<!-- // End pricingPlanSection3 -->