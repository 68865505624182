<link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>
<link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet'>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<!-- // Start faqPageSection1 -->
<div class="faqPageSection1">

  <h1>Frequently asked questions</h1>
  <p>Can’t find your answer? Contact us here at 03-2763844 or email at ask.legalbox@gmail.com</p>

  <div class="search-container">
    <button type="submit"><i class="fa fa-search"></i></button>
    <input type="text" placeholder="SEARCH" name="search">
  </div>

  <div class="faqLeftSection">

    <div class="faqItem">
      <button class="faqItemPlusIconButton" (click)="toggleFaqItem1()">
        <img [src]="faqItem1IconPath" class="faqItemPlusIcon" />
      </button>
      <h2>How secure is LegalBox's Blockchain Storage?</h2>
      <hr class="faqItemHr">
      <br>
      <p [@openClose]="showFaqItem1 ? 'open' : 'closed'">
        At Legalbox, security is our top priority. Our Blockchain Storage 
        solution utilizes state-of-the-art security protocols such as encryption,
         multi-factor authentication, and automatic backups to ensure the safety
         of your data. Additionally, we are compliant with several industry regulations
         including HIPAA, SOC 2, and PCI-DSS. Our team of security experts is constantly
         monitoring and updating our systems to keep your data secure at all times.</p>
    </div>

    <div class="faqItem faqItem2">
      <button class="faqItemPlusIconButton" (click)="toggleFaqItem2()">
        <img [src]="faqItem2IconPath" class="faqItemPlusIcon" />
      </button>
      <h2>What happens if there is a security breach?</h2>
      <hr class="faqItemHr">
      <br>
      <p [@openClose]="showFaqItem2 ? 'open' : 'closed'">
        In the unlikely event of a security breach, our team of experts will work
        quickly to identify and resolve the issue. We will also notify you immediately
        and take steps to prevent further breaches.</p>
    </div>

    <div class="faqItem faqItem3">
      <button class="faqItemPlusIconButton" (click)="toggleFaqItem3()">
        <img [src]="faqItem3IconPath" class="faqItemPlusIcon" />
      </button>
      <h2>How do you comply with legal regulations?</h2>
      <hr class="faqItemHr">
      <br>
      <p [@openClose]="showFaqItem3 ? 'open' : 'closed'">
        LegalBox is compliant with several legal regulations and standards such as 
        HIPAA, SOC 2, and PCI-DSS. Our team of experts works closely with our clients
         to ensure that we meet their specific compliance needs and standards.</p>
    </div>

  </div>

  <div class="faqRightSection">

    <div class="faqItem">
      <button class="faqItemPlusIconButton" (click)="toggleFaqItem4()">
        <img [src]="faqItem4IconPath" class="faqItemPlusIcon" />
      </button>
      <h2>How LegalBox ensure the security of my data?</h2>
      <hr class="faqItemHr">
      <br>
      <p [@openClose]="showFaqItem4 ? 'open' : 'closed'">
        We utilize state-of-the-art security protocols such as encryption,
        multi-factor authentication, and automatic backups to ensure the 
        safety of your data. We also comply with several industry regulations
        such as HIPAA, SOC 2, and PCI-DSS.</p>
    </div>

    <div class="faqItem faqItem2 faqItem5">
      <button class="faqItemPlusIconButton" (click)="toggleFaqItem5()">
        <img [src]="faqItem5IconPath" class="faqItemPlusIcon" />
      </button>
      <h2>Are my files encrypted in LegalBox's servers?</h2>
      <hr class="faqItemHr">
      <br>
      <p [@openClose]="showFaqItem5 ? 'open' : 'closed'">
        Yes, all files stored on our servers are encrypted to ensure maximum security.</p>
    </div>

    <div class="faqItem faqItem3 faqItem6">
      <button class="faqItemPlusIconButton" (click)="toggleFaqItem6()">
        <img [src]="faqItem6IconPath" class="faqItemPlusIcon" />
      </button>
      <h2>Does  LegalBox have a disaster recovery plan?</h2>
      <hr class="faqItemHr">
      <br>
      <p [@openClose]="showFaqItem6 ? 'open' : 'closed'">
        Yes, we have a comprehensive disaster recovery plan in place to
        minimize data loss and downtime in case of any incidents.</p>
    </div>

  </div>

</div>
<!-- // End faqPageSection1 -->

<!-- // Start faqPageSection2 -->
<div class="faqPageSection2">
  
</div>
<!-- // End faqPageSection2 -->