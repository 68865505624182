import { Component, OnInit, HostListener } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { HttpResponse } from '@angular/common/http';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-view-shareddocuments-businesses',
  templateUrl: './view-shareddocuments-businesses.component.html',
  styleUrls: ['./view-shareddocuments-businesses.component.css']
})

export class ViewShareddocumentsBusinessesComponent implements OnInit {

  displayAddNewDropdownContent = false;
  public allocatedStorageSizeInGB: any;
  corporateUser = false;
  corporateInstitutionUser = false;
  isPopupVisible: any;
  userId: any;
  lawFirmArray: any;
  businessArray: any;
  retrievedUser: any;
  userAllocatedStorageSizeInGB: any;
  clientData: any;
  userClientAllocatedStorageSizeInGB: any;

  displaySessionMessage = false;
  messageToBeDisplayed = "";
  errorType = "";
  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    console.log("isSmallScreen from Home Component: " + this.isSmallScreen);
  }

  constructor(private router: Router, private userService: UserService, private tokenStorageService: TokenStorageService) { }


  ngOnInit(): void {
    this.displaySessionMessage = false;
    this.messageToBeDisplayed = "";
    this.errorType = "";
    let retrievedUser = this.tokenStorageService.getUser();
    this.userId = retrievedUser.userId;
    if (environment.debug) {
      console.log("retrievedUser.userType:" + retrievedUser);
    }

    if (retrievedUser.userType == "business" || retrievedUser.userType == "individual") {
      this.corporateUser = true;
    }

    if (retrievedUser.userType == "business") {
      this.corporateInstitutionUser = true;
    }
    
    this.getUser(this.userId);
    this.viewAllBusiness();

  }

  showPopup() {
    this.isPopupVisible = true;
  }

  hidePopup() {
    this.isPopupVisible = false;
  }

  toggleAddNewDropdownContentDisplay() {
    this.displayAddNewDropdownContent = !this.displayAddNewDropdownContent;
  }

  redirectToAddNewClient() {
    this.router.navigate(['AddNewClient']);
  }

  redirectToUploadNewFiles() {
    // this.router.navigate(['UploadNewFiles']);
    this.router.navigate(['UploadNewFiles'], {
      state: {
        origin: "userDocumentsComponent",
      }
    }
    );
  }

  getUser(id): void {
    this.userService.get(id)
      .subscribe({
        next: data => {
          this.allocatedStorageSizeInGB = data.allocatedStorageSizeInGB;
          this.userService.get(data.id)
            .subscribe({
              next: clientData => {
                if (clientData.status == 403) {
                  alert("Your session has expired. Please login again!");
                  this.tokenStorageService.signOut();
                  this.router.navigate(['SignIn']);
                }
                this.clientData = clientData;
              },
              error: err => {
                console.error(err);
              }
            })
          if (environment.debug) {
            console.log(data);
          }
          this.retrievedUser = data;
          this.userAllocatedStorageSizeInGB = this.retrievedUser.allocatedStorageSizeInGB;
        },
        error: err => {
          console.error(err);
        }
      });
  }

  /**
  * format bytes
  * @param bytes (File size in bytes)
  * @param decimals (Decimals point)
  */
  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  viewAllBusiness() {
    this.userService.viewAllBusiness(
      this.userId,
    ).subscribe({
      next: event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.message:" + event.body.message);
          }

          if (event.body.status == 500) {
            alert("Error: " + event.body.message);
          } else {
            this.businessArray = event.body.businessArray;
            if (this.businessArray.length > 0) {
              if (environment.debug) {
                console.log("businessArray: ", this.businessArray);
              }
              for (let i = 0; i < this.businessArray.length; i++) {
                this.businessArray[i].indexValue = i;
              }
              if (environment.debug) {
                console.log("businessArray[i]: ", this.businessArray[0]);
              }
            }
          }
        }
      },
      error: err => {
        alert(err.error.message);
      }
    });
  }

  viewSpecificBusiness(businessID, businessName) {
    this.router.navigate(['ViewShareddocumentsSpecificBusiness/'], {
      state: {
        userId: this.userId,
        id: businessID,
        businessName: businessName
      }
    });
  }

}

