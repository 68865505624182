import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { MandateService } from 'src/app/services/mandate.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';


@Component({
  selector: 'app-checkout-processing',
  templateUrl: './checkout-processing.component.html',
  styleUrls: ['./checkout-processing.component.css']
})
export class CheckoutProcessingComponent implements OnInit {
  userId: any = history.state.userId;
  userType: any = history.state.userType;
  mainImageLoc: any;
  mainText: any;
  inPaymentProcess: boolean = true;
  isPaymentSuccess: boolean = false;
  curlecObject: any;

  constructor(private mandateService: MandateService, private _activatedRoute: ActivatedRoute,
    private router: Router, private tokenStorageService: TokenStorageService) { }

  ngOnInit() {

    this.mainImageLoc = "/assets/scratchImg/loadingGifIcon.gif";
    this.mainText = "Redirecting to payment page. Do not refresh.";
    if (history.state.newMandateAuthorizationURL) {
      window.open(history.state.newMandateAuthorizationURL, "_self");
    } else {
      // check mandate status response and proceed accordingly
      this._activatedRoute.queryParamMap
        .subscribe((params) => {
          if (this._activatedRoute.snapshot.queryParams['fpx_debitAuthCode']) {
            this.curlecObject = { ...params.keys, ...params };
            // let curlecMethod = this.curlecObject.params.curlec_method;
            let mandateReferenceNumber = this.curlecObject.params.fpx_sellerOrderNo;
            let fpxDebitAuthCode = this.curlecObject.params.fpx_debitAuthCode;
            this.updateMandateStatusAndEnableAutoCollection(fpxDebitAuthCode, mandateReferenceNumber);
          } else {
            if (environment.debug) {
              console.log("No params.")
            }
            this.router.navigate(['SignIn']);
          }
          // update mandateStatus in database,
          // if mandateStatus || fpx_debitAuthCode = 00, enable collectionCondition & autoCollection (curlec),
          // set collectionStatus = success && autoCollection = enabled && collectionCondition = enabled in database based on mandateReferenceNumber

          // set merchant callbackURL in create Mandate flow (2 places), 
          // update mandateStatus in database,
          // if mandateStatus || fpx_debitAuthCode = 00, enable collectionCondition & autoCollection (curlec),
          // set collectionStatus = success && autoCollection = enabled && collectionCondition = enabled in database based on mandateReferenceNumber

          // hardcode admin
          // To indicate whether user paid subscription amount based on selected billing cycle and payment method:
          // admin can set collectionStatus = failure || can set collectionStatus = success based on mandateReferenceNumber
          // To indicate whether user canceled or renewed subscription based on selected billing cycle and payment method:
          // admin can set collectionCondition = enabled || can set collectionCondition = disabled

        });
    }

  }

  updateMandateStatusAndEnableAutoCollection(fpxDebitAuthCode, mandateReferenceNumber) {
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
    this.mandateService.updateMandateStatusAndEnableAutoCollection(
      fpxDebitAuthCode,
      mandateReferenceNumber
    ).subscribe(
      async event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.message:" + event.body.message);
          }

          
          // this.message = event.body.message;

          if (event.body.success) {
            
            // alert("CollectionStatus is Successful!");
            this.mainImageLoc = "/assets/scratchImg/verifyEmailSuccess.jpg";
            this.mainText = "Payment Successful. Redirecting . . .";
            await delay(5000);
            this.router.navigate(['UserDashboard']);
            // return event.body.message;

          }
          // return event.body.message;
        }
      },
      err => {
        // this.progress = 0;
        // this.message = 'Could not create law firm user!';
        // alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

}
