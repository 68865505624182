import { Component, OnInit, ElementRef, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-term-and-policies',
  templateUrl: './term-and-policies.component.html',
  styleUrls: ['./term-and-policies.component.scss']
})
export class TermAndPoliciesComponent implements OnInit {

  headerLinksMap = new Map();
  privacyPolicyHeaderLinksMap = new Map();
  termOfServiceHeaderLinksMap = new Map();
  privacyPolicyActiveHeaderLink = "Legalbox Privacy Policy";
  termOfServiceActiveHeaderLink = "Welcome to Legalbox";
  showPrivacyPolicy = true;
  showTermOfService = false;
  element: HTMLElement;
  documentElement: any;

  tabDisplayArray = [];
  tabDisplayArrayLength = 7;

  verticalTabDisplayArray = [];
  verticalTabDisplayArrayLength = 24;
  showButton = false;

  isShowDivIf = false;
  toggleDisplayDivIf() {
    this.isShowDivIf = !this.isShowDivIf;
  }

  isShowTermOfServiceDivIf = false;
  toggleDisplayTermOfServiceDivIf() {
    this.isShowTermOfServiceDivIf = !this.isShowTermOfServiceDivIf;
  }

  constructor(@Inject(DOCUMENT) document: Document, private el: ElementRef) {
    this.documentElement = document;
  }

  ngOnInit(): void {
    this.headerLinksMap.set("showPrivacyPolicy", true);
    this.headerLinksMap.set("showTermOfService", false);

    this.privacyPolicyHeaderLinksMap.set("Legalbox Privacy Policy", true);
    this.privacyPolicyHeaderLinksMap.set("What And Why", false);
    this.privacyPolicyHeaderLinksMap.set("Account Information", false);
    this.privacyPolicyHeaderLinksMap.set("Your Stuff", false);
    this.privacyPolicyHeaderLinksMap.set("Usage Information", false);
    this.privacyPolicyHeaderLinksMap.set("Device Information", false);
    this.privacyPolicyHeaderLinksMap.set("Cookies And Other Technologies", false);
    this.privacyPolicyHeaderLinksMap.set("Legalbox Analytics", false);
    this.privacyPolicyHeaderLinksMap.set("Marketing", false);
    this.privacyPolicyHeaderLinksMap.set("Bases For Processing Your Data", false);
    this.privacyPolicyHeaderLinksMap.set("With Whom", false);
    this.privacyPolicyHeaderLinksMap.set("Others Working For And With Legalbox", false);
    this.privacyPolicyHeaderLinksMap.set("Other Users", false);
    this.privacyPolicyHeaderLinksMap.set("Other Applications", false);
    this.privacyPolicyHeaderLinksMap.set("Admins", false);
    this.privacyPolicyHeaderLinksMap.set("Law And Order And The Public Interest", false);
    this.privacyPolicyHeaderLinksMap.set("Security", false);
    this.privacyPolicyHeaderLinksMap.set("User Controls", false);
    this.privacyPolicyHeaderLinksMap.set("Retention", false);
    this.privacyPolicyHeaderLinksMap.set("Around The World", false);
    this.privacyPolicyHeaderLinksMap.set("Your Control And Access Of Your Data", false);
    this.privacyPolicyHeaderLinksMap.set("Legalbox As Controller Or Processor", false);
    this.privacyPolicyHeaderLinksMap.set("Changes", false);
    this.privacyPolicyHeaderLinksMap.set("Contact", false);

    this.termOfServiceHeaderLinksMap.set("Welcome To Legalbox", true);
    this.termOfServiceHeaderLinksMap.set("Your Documents", false);
    this.termOfServiceHeaderLinksMap.set("Your Responsibilities", false);
    this.termOfServiceHeaderLinksMap.set("Blockchain Platform", false);
    this.termOfServiceHeaderLinksMap.set("Beta Services", false);
    this.termOfServiceHeaderLinksMap.set("Electronic Records", false);
    this.termOfServiceHeaderLinksMap.set("Additional Features", false);
    this.termOfServiceHeaderLinksMap.set("Third Party Features", false);
    this.termOfServiceHeaderLinksMap.set("Our Stuff", false);
    this.termOfServiceHeaderLinksMap.set("Copyright", false);
    this.termOfServiceHeaderLinksMap.set("Paid Accounts", false);
    this.termOfServiceHeaderLinksMap.set("Termination", false);
    this.termOfServiceHeaderLinksMap.set("Discontinuation Of Services", false);
    this.termOfServiceHeaderLinksMap.set("Services AS IS", false);
    this.termOfServiceHeaderLinksMap.set("Limitation Of Liability", false);
    this.termOfServiceHeaderLinksMap.set("Resolving Disputes", false);
    this.termOfServiceHeaderLinksMap.set("Controlling Law", false);
    this.termOfServiceHeaderLinksMap.set("Entire Agreement", false);
    this.termOfServiceHeaderLinksMap.set("Waiver Severability And Assignment", false);
    this.termOfServiceHeaderLinksMap.set("Modifications", false);

    for (let i = 0; i < this.tabDisplayArrayLength; i++) {
      if (i == 2) {
        this.tabDisplayArray.push(true);
      }
      else {
        this.tabDisplayArray.push(false);
      }
    }

    for (let i = 0; i < this.verticalTabDisplayArrayLength; i++) {
      if (i == 0) {
        this.verticalTabDisplayArray.push(true);
      }
      else {
        this.verticalTabDisplayArray.push(false);
      }
    }

  }

  displayTab(tabNumber) {

    for (let j = 0; j < this.tabDisplayArrayLength; j++) {
      if (j == tabNumber) {
        this.tabDisplayArray[j] = true;
      }
      else {
        this.tabDisplayArray[j] = false;
      }
    }

  }

  getPrivacyPolicyArrayKeyViaIndex(index) {
    let extractedPrivacyPolicy = Array.from(this.privacyPolicyHeaderLinksMap.keys());
    let htmlElementID = extractedPrivacyPolicy[index].toString();
    this.makeSpecificPrivacyPolicyRouterLinkActive(htmlElementID);
    this.privacyPolicyActiveHeaderLink = htmlElementID;

    htmlElementID = htmlElementID.replace(/\s/g, '')
    if (environment.debug) {
      console.log("htmlElementID: " + htmlElementID);
    }

    this.scrollToDiv(htmlElementID);
  }

  getTermOfServiceArrayKeyViaIndex(index) {
    let extractedTermOfService = Array.from(this.termOfServiceHeaderLinksMap.keys());
    let htmlElementID = extractedTermOfService[index].toString();
    this.makeSpecificTermOfServiceRouterLinkActive(htmlElementID);
    this.termOfServiceActiveHeaderLink = htmlElementID;

    htmlElementID = htmlElementID.replace(/\s/g, '')
    if (environment.debug) {
      console.log("htmlElementID: " + htmlElementID);
    }
    this.scrollToDiv(htmlElementID);
  }

  scrollToDiv(targetDivId: string): void {
    const targetElement = this.el.nativeElement.querySelector(`#${targetDivId}`);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  displayVerticalTab(tabNumber) {

    for (let j = 0; j < this.verticalTabDisplayArrayLength; j++) {
      if (j == tabNumber) {
        this.verticalTabDisplayArray[j] = true;
      }
      else {
        this.verticalTabDisplayArray[j] = false;
      }
    }

  }

  getPrivacyPolicyHeaderLinksMapKeys() {
    return Array.from(this.privacyPolicyHeaderLinksMap.keys());
  }

  getTermOfServiceHeaderLinksMapKeys() {
    return Array.from(this.termOfServiceHeaderLinksMap.keys());
  }

  getPrivacyPolicyHeaderLinksMapValues() {
    return Array.from(this.privacyPolicyHeaderLinksMap.values());
  }

  getHeaderLinkValueFromKey(headerLinkKey) {
    return this.headerLinksMap.get(headerLinkKey);
  }

  getPrivacyPolicyHeaderLinkValueFromKey(headerLinkKey) {
    return this.privacyPolicyHeaderLinksMap.get(headerLinkKey);
  }

  getTermOfServiceHeaderLinkValueFromKey(headerLinkKey) {
    return this.termOfServiceHeaderLinksMap.get(headerLinkKey);
  }

  makeSpecificRouterLinkActive(headerLinkKey) {
    for (let key of this.headerLinksMap.keys()) {
      if (key == headerLinkKey) {
        this.headerLinksMap.set(key, !this.headerLinksMap.get(headerLinkKey));
      }
      else {
        this.headerLinksMap.set(key, false);
      }
    }
  }

  makeSpecificPrivacyPolicyRouterLinkActive(headerLinkKey) {
    for (let key of this.privacyPolicyHeaderLinksMap.keys()) {
      if (key == headerLinkKey) {
        this.privacyPolicyHeaderLinksMap.set(key, true);
      }
      else {
        this.privacyPolicyHeaderLinksMap.set(key, false);
      }
    }
  }

  makeSpecificTermOfServiceRouterLinkActive(headerLinkKey) {
    for (let key of this.termOfServiceHeaderLinksMap.keys()) {
      if (key == headerLinkKey) {
        this.termOfServiceHeaderLinksMap.set(key, true);
      }
      else {
        this.termOfServiceHeaderLinksMap.set(key, false);
      }
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showButton = window.pageYOffset > 20;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}
