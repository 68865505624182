import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';

@Component({
    selector: 'app-404-page-not-found.component',
    templateUrl: './404-page-not-found.component.html',
    styleUrls: ['./404-page-not-found.component.css']
  })
  export class PageNotFoundComponent implements OnInit {

    constructor(private router: Router) { }

    ngOnInit(): void {
        
    }

    goToHome() {
      this.router.navigate(['Home']);
    }
  }