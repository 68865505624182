<section name="userDashboardSection1" class="">
  <div class="container-xl-fluid font-plus-jakarta-sans me-3 me-md-0">
    <!-- // Start First Row -->
    <div class="row">
      <div class="col-md-3">
        <app-side-navigation-bar [pageTitle]="pageTitle"></app-side-navigation-bar>
      </div>

      <div class="col-md-9 bg-light">
        <!-- // Start First Row Inner First Row -->
        <div class="row mt-3 mt-md-5 ps-4 pt-0 pt-md-5 me-1 me-md-0">
          <div class="col-md-8 d-none d-md-block">
            <h5 class="p-2 fw-bold">{{pageTitle}}</h5>
          </div>
          <div class="col-md-3 ms-0 ms-md-5 mb-3 mb-md-0 d-grid gap-2 d-md-flex justify-content-md-end">
            <!-- Example single danger button -->
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-primary p-2 px-3 dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                (click)="toggleAddNewDropdownContentDisplay()"
                [style.display]="corporateUser ? 'block' : 'none'"
              >
                <img
                  src="/assets/revamp/addNewButtonIcon.png"
                  width="25"
                  height="25"
                />
                &nbsp; Add New
              </button>
              <div
                class="dropdown-menu custom-margin-4rem me-5 border border-secondary-subtle p-2 px-3"
                [ngClass]="displayAddNewDropdownContent ? (isSmallScreen? 'show w-100': 'show') : ''"
              >
                <a
                  class="dropdown-item text-center text-md-start"
                  (click)="redirectToAddNewClient()"
                  [style.display]="
                    allocatedStorageSizeInGB >= 1 && corporateInstitutionUser
                      ? 'block'
                      : 'none'
                  "
                >
                  <img
                    src="/assets/revamp/newClientDropdownIconNew.png"
                    width="13"
                    height="16"
                  />
                  &nbsp; New Client
                </a>
                <div
                  [style.display]="
                    allocatedStorageSizeInGB >= 1 && corporateInstitutionUser
                      ? 'block'
                      : 'none'
                  "
                  class="dropdown-divider"
                ></div>
                <a class="dropdown-item text-center text-md-start" (click)="redirectToUploadNewFiles()">
                  <img
                    src="/assets/revamp/fileUploadDropdownIconNew.png"
                    width="13"
                    height="16"
                  />
                  &nbsp; File Upload
                </a>
              </div>
            </div>
            <!-- <button type="button" class="btn btn-primary p-2 px-3">
                        <img src="/assets/revamp/addNewButtonIcon.png" width="25" height="25">
                        &nbsp; Add New
                    </button> -->
          </div>
        </div>
        <!-- // End First Row Inner First Row -->

        <!-- // Start First Row Inner Second Row -->
        <div class="row">
          <!-- // Start My Storage + Plan & Billing Section -->
          <div class="col-md-7 ps-4 pe-4 pe-md-0">
            <!-- // Start My Storage Box -->
            <div
              name="myStorageBox"
              class="bg-white rounded-2 ms-3 p-4 mt-0 mt-md-5 custom-height-mystoragebox"
              [ngClass]="isSmallScreen? (corporateInstitutionUser?'custom-height-mystoragebox-business-small-screen':'custom-height-mystoragebox-individual-small-screen'):'custom-height-mystoragebox'"
            >
              <p class="fw-bold">My Storage</p>
              <hr class="d-none d-md-block" />

              <!-- // Start My Storage Box First Row -->
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="half-arc mt-3" [ngClass]="isSmallScreen? 'half-arc-small-screen':'half-arc-big-screen'">
                    <div class="label mt-4">
                      {{
                        allocatedStorageSizeInGB >= 1
                          ? usedStorageSizeInGB
                          : usedStorageSizeInMB
                      }}
                      {{ allocatedStorageSizeInGB >= 1 ? "G" : "M" }}B
                    </div>
                    <br />
                    <div class="label2 mt-5 pt-4">
                      Used of
                      {{
                        allocatedStorageSizeInGB >= 1
                          ? allocatedStorageSizeInGB
                          : allocatedStorageSizeInMB
                      }}
                      {{ allocatedStorageSizeInGB >= 1 ? "G" : "M" }}B
                    </div>
                  </div>
                </div>

                <div
                  class="col-12 col-md-6 mt-5 mt-md-0 pt-5 pt-md-0"
                  [style.display]="corporateInstitutionUser ? 'block' : 'none'"
                >
                  <div name="lawFirmStorageBox" class="mt-5 mt-md-2 pt-5 pt-md-0">
                    <img
                      class="float-start"
                      src="/assets/revamp/userDashboardLawFirmIcon.png"
                      width="50"
                      height="50"
                    />
                    <div name="lawFirmStorageText" class="float-start ms-3">
                      <p class="mb-0 text-secondary">{{ userType }} Storage</p>
                      <p class="text-primary-emphasis fw-bold">
                        {{
                          allocatedStorageSizeInGB >= 1
                            ? finalSumOfCorporateInstitutionUsageInGB
                            : finalSumOfCorporateInstitutionUsageInMB
                        }}
                        {{ allocatedStorageSizeInGB >= 1 ? "G" : "M" }}B
                      </p>
                    </div>
                  </div>
                  <div name="clientStorageBox" class="mt-4 mt-md-5 pt-5">
                    <img
                      class="float-start"
                      src="/assets/revamp/userDashboardClientIcon.png"
                      width="50"
                      height="50"
                    />
                    <div name="lawFirmStorageText" class="float-start ms-3">
                      <p class="mb-0 text-secondary">Client Storage</p>
                      <p class="text-primary-emphasis fw-bold">
                        {{
                          allocatedStorageSizeInGB >= 1
                            ? finalSumOfClientStorageInGB
                            : finalSumOfClientStorageInMB
                        }}
                        {{ allocatedStorageSizeInGB >= 1 ? "G" : "M" }}B
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- // End My Storage Box First Row -->
            </div>
            <!-- // End My Storage Box -->

            <!-- // Start Plan and Billing Box -->
            <div
              name="planAndBillingBox"
              class="bg-white rounded-2 mt-4 ms-3 p-4"
            >
              <p class="fw-bold float-start">Plan & Billing</p>
              <p
                class="float-end text-primary fs-6 fw-medium"
                (mouseenter)="showPopup()"
                (mouseleave)="hidePopup()"
              >
                Change Plan
              </p>
              <div
                class="changePlanPopUp shadow-lg"
                [style.display]="isPopupVisible ? 'block' : 'none'"
              >
                Please email your change plan request to support@legalbox.io.
                <br />Our team will get in touch with you soon.
              </div>

              <div class="clearfix">
                <hr class="custom-hr-width d-none d-md-block" />
              </div>
              

              <!-- // Start Plan and Billing Box First Row -->
              <div class="row">
                <!-- // Start Plan and Billing Box First Row col-md-5 -->
                <div class="col-md-5">
                  <div name="planBox">
                    <p class="mb-1 text-secondary custom-font-size-14">Plan</p>
                    <p class="fw-bold">{{ pricingPlanName }}</p>
                  </div>
                  <div name="storageLimitBox">
                    <p class="mb-1 text-secondary custom-font-size-14">
                      Storage Limit
                    </p>
                    <p class="fw-bold">{{ pricingPlanStorageSizeInGB }} GB</p>
                  </div>
                  <div name="costBox">
                    <p class="mb-1 text-secondary custom-font-size-14">Cost</p>
                    <p class="fw-bold">
                      MYR {{ pricingPlanMonthlyAmount }}/month
                    </p>
                  </div>
                </div>
                <!-- // End Plan and Billing Box First Row col-md-5 -->

                <!-- // Start Plan and Billing Box First Row col-md-7 -->
                <div class="col-md-7">
                  <div name="planUsageBox">
                    <p class="mb-1 text-secondary custom-font-size-14">
                      Plan Usage
                    </p>
                    <p class="fw-bold">
                      {{
                        allocatedStorageSizeInGB >= 1
                          ? usedStorageSizeInGB
                          : usedStorageSizeInMB
                      }}
                      {{ allocatedStorageSizeInGB >= 1 ? "G" : "M" }}B used /
                      {{
                        allocatedStorageSizeInGB >= 1
                          ? allocatedStorageSizeInGB
                          : allocatedStorageSizeInMB
                      }}
                      {{ allocatedStorageSizeInGB >= 1 ? "G" : "M" }}B
                    </p>
                  </div>

                  <!-- // Start storageDetailsBox -->
                  <div name="storageDetailsBox">
                    <p class="mb-1 text-secondary custom-font-size-14">
                      Storage Details
                    </p>

                    <div class="progress-stacked mt-1 custom-bg-lightBlue">
                      <div
                        class="progress"
                        role="progressbar"
                        aria-label="Segment one"
                        aria-valuenow="15"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        [style.width]="
                          corporateInstitutionStorageValueInPercentage
                        "
                      >
                        <div class="progress-bar custom-bg-darkBlue"></div>
                      </div>
                      <div
                        class="progress"
                        role="progressbar"
                        aria-label="Segment two"
                        aria-valuenow="30"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        [style.width]="clientStorageValueInPercentage"
                      >
                        <div class="progress-bar custom-bg-mediumBlue"></div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-3">
                      <img
                        class="float-start mt-1"
                        src="/assets/revamp/darkBlueCircleIcon.png"
                        width="13"
                        height="13"
                      />
                      <p class="text-secondary float-start ms-2 mb-2">
                        {{ userType }} Usage
                      </p>
                      <p
                        class="custom-color-darkBlue fw-semibold text-end mb-2"
                      >
                        {{
                          allocatedStorageSizeInGB >= 1
                            ? finalSumOfCorporateInstitutionUsageInGB
                            : finalSumOfCorporateInstitutionUsageInMB
                        }}
                        {{ allocatedStorageSizeInGB >= 1 ? "G" : "M" }}B
                      </p>

                      <img
                        style="clear: both"
                        [style.display]="
                          corporateInstitutionUser ? 'block' : 'none'
                        "
                        class="float-start mt-1"
                        src="/assets/revamp/mediumBlueCircleIcon.png"
                        width="13"
                        height="13"
                      />
                      <p
                        class="text-secondary float-start ms-2 mb-2"
                        [style.display]="
                          corporateInstitutionUser ? 'block' : 'none'
                        "
                      >
                        Clients Usage
                      </p>
                      <p
                        class="custom-color-mediumBlue fw-semibold text-end mb-2"
                        [style.display]="
                          corporateInstitutionUser ? 'block' : 'none'
                        "
                      >
                        {{
                          allocatedStorageSizeInGB >= 1
                            ? finalSumOfClientStorageInGB
                            : finalSumOfClientStorageInMB
                        }}
                        {{ allocatedStorageSizeInGB >= 1 ? "G" : "M" }}B
                      </p>

                      <img
                        style="clear: both"
                        class="float-start mt-1"
                        src="/assets/revamp/lightBlueCircleIcon.png"
                        width="13"
                        height="13"
                      />
                      <p class="text-secondary float-start ms-2">
                        Storage Balance
                      </p>
                      <p class="text-secondary fw-semibold text-end mb-2">
                        {{
                          allocatedStorageSizeInGB >= 1
                            ? storageBalanceInGB
                            : storageBalanceInMB
                        }}
                        {{ allocatedStorageSizeInGB >= 1 ? "G" : "M" }}B
                      </p>
                    </div>
                  </div>
                  <!-- // End storageDetailsBox -->
                </div>
                <!-- // End Plan and Billing Box First Row col-md-7 -->
              </div>
              <!-- // End Plan and Billing Box First Row -->
            </div>
            <!-- // End Plan and Billing Box -->
          </div>
          <!-- // End My Storage + Plan & Billing Section -->

          <!-- // Start Personal + Legal Information Section -->
          <div class="col-md-5" style="">
            <!-- // Start Personal Information Box -->
            <div
              name="personalInformationBox"
              class="bg-white rounded-2 p-4 pe-0 ms-4 ms-md-2 me-3 me-md-4 mt-5 custom-height-personalinformationbox"
            >
              <p class="fw-bold mb-3 float-start">Personal Information</p>
              <img
                class="float-end me-4"
                src="/assets/revamp/pencilEditIcon.png"
                width="20"
                height="20"
              />

              <div class="clearfix">
                <hr class="custom-hr2-width d-none d-md-block" />
              </div>
              
              <p class="text-secondary mb-1 custom-font-size-14">
                {{ firstSectionABlockTitle }}
              </p>
              <p class="fw-bold">{{ firstSectionABlockValue }}</p>
              <p class="text-secondary mb-1 custom-font-size-14">Username</p>
              <p class="fw-bold">{{ retrievedUserUsername }}</p>
              <p class="text-secondary mb-1 custom-font-size-14">Email</p>
              <p class="fw-bold">{{ retrievedUserEmailAddress }}</p>
              <p class="text-secondary mb-1 custom-font-size-14">
                Phone Number
              </p>
              <p class="fw-bold">{{ retrievedUserContactNumber }}</p>
              <p class="text-secondary mb-1 custom-font-size-14">
                {{ firstSectionEBlockTitle }}
              </p>
              <p class="fw-bold">{{ firstSectionEBlockValue }}</p>
            </div>
            <!-- // End Personal Information Box -->

            <!-- // Start Legal Information Box -->
            <div
              name="legalInformationBox"
              class="bg-white rounded-2 p-4 pe-0 ms-4 ms-md-2 me-3 me-md-4 mt-4 custom-height-personalinformationbox mb-5"
            >
              <p class="fw-bold mb-3 float-start">Legal Information</p>
              <img
                class="float-end me-4"
                src="/assets/revamp/pencilEditIcon.png"
                width="20"
                height="20"
              />

              <div class="clearfix">
                <hr class="custom-hr2-width d-none d-md-block" />
              </div>
              
              <p class="text-secondary mb-1 custom-font-size-14">
                {{ secondSectionABlockTitle }}
              </p>
              <p class="fw-bold">{{ secondSectionABlockValue }}</p>
              <p class="text-secondary mb-1 custom-font-size-14">
                {{ secondSectionBBlockTitle }}
              </p>
              <p class="fw-bold">{{ secondSectionBBlockValue }}</p>
              <p class="text-secondary mb-1 custom-font-size-14">
                {{ secondSectionCBlockTitle }}
              </p>
              <p class="fw-bold">{{ secondSectionCBlockValue }}</p>
              <p class="text-secondary mb-1 custom-font-size-14">
                {{ secondSectionDBlockTitle }}
              </p>
              <p class="fw-bold mb-1">{{ secondSectionD1BlockValue }}</p>
              <p class="fw-bold">{{ secondSectionD2BlockValue }}</p>
            </div>
            <!-- // End Legal Information Box -->
          </div>
          <!-- // End Personal + Legal Information Section -->
        </div>
        <!-- // End First Row Inner Second Row -->
      </div>
    </div>
    <!-- // End First Row -->
  </div>
</section>

<app-error-pop-up
  [(displaySessionMessage)]="displaySessionMessage"
  [messageToBeDisplayed]="messageToBeDisplayed"
  [errorType]="errorType"
></app-error-pop-up>
