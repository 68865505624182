import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = environment.backendURL + '/api/auth';

  constructor(private http: HttpClient) { }

  requestToken(loc: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append("from", loc);

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    const req = new HttpRequest('POST', `${this.baseUrl}/requestToken`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
    
  }  
}
