<link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>
<link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet'>

<!-- // Start contactUsSection1 -->
<div class="contactUsSection1">

  <h1>Get in touch</h1>
  <br><br>
  <div class="contactCardItem contactCardFirstItem">
    <img src="/assets/scratchImg/contactCardItem1Icon.svg">
    <h2>Help Centre</h2>
    <p>Find help articles and tips<br>
       on how to use LEGALBOX.</p>
    <a routerLink="">Visit Help Centre</a>
  </div>

  <div class="contactCardItem">
    <img src="/assets/scratchImg/contactCardItem2Icon.svg">
    <h2>Support</h2>
    <p>Contact us for all<br>
       support requests.</p>
   <a routerLink="">Contact Support</a>
  </div>

  <div class="contactCardItem">
    <img src="/assets/scratchImg/contactCardItem3Icon.svg">
    <h2>Sales Enquiries</h2>
    <p>Contact our Sales and<br>
       marketing team.</p>
  <a routerLink="">Contact Sales</a>
  </div>

</div>
<!-- // End contactUsSection1 -->

<!-- // Start contactUsSection2 -->
<div class="contactUsSection2">
 
  <h1>Get started with LEGALBOX today</h1>
  <!-- <p>Get 7 days of full access to all toolkits. Cancel anytime.</p> -->
  <button class="button button3">Get Started</button>

</div>
<!-- // End contactUsSection2 -->