import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { HttpResponse } from '@angular/common/http';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { formatDate, Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-shareddocuments-specific-business',
  templateUrl: './view-shareddocuments-specific-business.component.html',
  styleUrls: ['./view-shareddocuments-specific-business.component.css']
})
export class ViewShareddocumentsSpecificBusinessComponent implements OnInit {

  userId: any;
  retrievedLegalDocuments: any[] = [];
  sharedByID: any = null;
  businessName: string = null;
  displaySessionMessage = false;
  corporateUser: any;
  corporateInstitutionUser: any;
  messageToBeDisplayed = "";
  errorType = "";
  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    console.log("isSmallScreen from Home Component: " + this.isSmallScreen);
  }



  constructor(private router: Router, private userService: UserService,
    private tokenStorageService: TokenStorageService, private transactionService: TransactionService, private location: Location) { }

  ngOnInit(): void {
    this.displaySessionMessage = false;
    this.messageToBeDisplayed = "";
    this.errorType = "";
    if (environment.debug) {
      console.log("event.body.userId:" + this.userId);
    }
    let retrievedUser = this.tokenStorageService.getUser();
    this.userId = retrievedUser.userId;

    if (history.state.id) {
      this.sharedByID = history.state.id;
      this.businessName = history.state.businessName;
      this.getSharedBusinessLegalDocumentsList(this.userId);
    } else {
      // No business id, revert back
      this.location.back();
    }

  }

  getSharedBusinessLegalDocumentsList(userId) {

    this.userService.getSharedBusinessLegalDocumentsList(
      userId,
      this.sharedByID
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.retrievedLegalDocuments:", event.body.retrievedLegalDocuments);
          }
          if (event.status == 403) {
            alert("Your session has expired. Please login again!");
            this.tokenStorageService.signOut();
            this.router.navigate(['SignIn']);
          }
          if (event.body.status == 500 || event.body.status == 404) {
            alert("Error: " + event.body.message);
          } else {
            this.retrievedLegalDocuments = event.body.retrievedLegalDocuments;
          }

        }
      },
      err => {
        // this.progress = 0;
        // this.message = 'Could not create law firm user!';
        alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  localFormatDate(_date) {
    let monthNumericForm = formatDate(_date, 'MM', 'en-US');
    let monthStringForm = this.monthNumberToString(monthNumericForm);
    // console.log("monthStringForm: " + monthStringForm);
    let date_Year = formatDate(_date, 'dd, yyyy', 'en-US');
    let final_date = monthStringForm + " " + date_Year;
    return final_date;
  }

  monthNumberToString(_month) {
    var returnMonth;

    switch (_month) {
      case "01": returnMonth = "January"; break;
      case "02": returnMonth = "February"; break;
      case "03": returnMonth = "March"; break;
      case "04": returnMonth = "April"; break;
      case "05": returnMonth = "May"; break;
      case "06": returnMonth = "June"; break;
      case "07": returnMonth = "July"; break;
      case "08": returnMonth = "August"; break;
      case "09": returnMonth = "September"; break;
      case "10": returnMonth = "October"; break;
      case "11": returnMonth = "November"; break;
      case "12": returnMonth = "December"; break;
    }

    return returnMonth;
  }

  /**
* format bytes
* @param bytes (File size in bytes)
* @param decimals (Decimals point)
*/

  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  displayUserSpecificLegalDocument(_documentTitle) {

    this.userService.displayUserSpecificLegalDocument(
      this.userId,
      _documentTitle,
      "true",
      this.sharedByID
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:" + event);
            console.log("Object.keys(event):" + Object.keys(event));
            console.log("event.body.size: " + event.body.size);
            console.log("event.body.type: " + event.body.type);
          }
          let fileURL = window.URL.createObjectURL(event.body);
          if (environment.debug) {
            console.log("fileURL: " + fileURL);
          }
          // window.open(fileURL, '_self');
          window.open(fileURL, '_blank');
          // console.log("event.body.message:" + event.body.message);
          // this.message = event.body.message;



          // return event.body.message;
        }
      },
      err => {
        // this.progress = 0;
        // this.message = 'Could not create law firm user!';
        // alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  goBack() {
    this.router.navigate(['ViewAllBusinesses']);
  }

  retrieveSmartContractFunctionTransactionHash(legalDocumentID, smartContractFunction) {

    this.transactionService.retrieveSmartContractFunctionTransactionHash(
      legalDocumentID,
      smartContractFunction,
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:" + event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.transaction:", event.body.transaction);
          }
          // this.message = event.body.message;
          let transaction = JSON.parse(JSON.stringify(event.body.transaction));
          // console.log("transaction: " + Object.keys(transaction));
          let legalDocumentTransactionHashURL = environment.blockExplorerURL + transaction.transactionHash;
          window.open(legalDocumentTransactionHashURL, "_blank");

          // return event.body.message;
        }
      },
      err => {
        // this.progress = 0;
        // alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  redirectToBlockExplorerURL(legalDocumentID) {
    this.retrieveSmartContractFunctionTransactionHash(legalDocumentID, "storeLegalDocumentIPFSHash");
  }

  redirectToIPFSHashURL(legalDocumentCIDHash) {
    let ipfsHashURL = environment.pinataGateway + legalDocumentCIDHash;
    window.open(ipfsHashURL, "_blank");
  }

}
