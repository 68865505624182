import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { fromEvent, merge, of, Subscription, timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[appLongPress]'
})
export class LongPressDirective implements OnDestroy {
  private eventSubscribe: Subscription;
  private threshold = 1000; // Set threshold to 5 seconds
  private isPressing = false;

  @Output()
  mouseLongPress = new EventEmitter();

  constructor(private elementRef: ElementRef) {
    const mousedown = fromEvent<MouseEvent>(
      elementRef.nativeElement,
      'mousedown'
    ).pipe(
      filter((event) => event.button === 0), // Only allow left button (Primary button)
      map(() => true) // turn on threshold counter
    );

    const touchstart = fromEvent(elementRef.nativeElement, 'touchstart').pipe(
      map(() => true)
    );

    const touchEnd = fromEvent(elementRef.nativeElement, 'touchend').pipe(
      map(() => false)
    );

    const mouseup = fromEvent<MouseEvent>(window, 'mouseup').pipe(
      filter((event) => event.button === 0), // Only allow left button (Primary button)
      map(() => false) // reset threshold counter
    );

    this.eventSubscribe = merge(mousedown, mouseup, touchstart, touchEnd)
      .pipe(
        switchMap((state) => {
          if (state) {
            this.isPressing = true;
            return timer(this.threshold);
          } else {
            this.isPressing = false;
            return of(null);
          }
        })
      )
      .subscribe(() => {
        if (this.isPressing) {
          this.mouseLongPress.emit();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.eventSubscribe) {
      this.eventSubscribe.unsubscribe();
    }
  }
}
