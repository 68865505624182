<section name="homeSection5" class="bg-white pt-5" [ngClass]="isSmallScreen ? 'custom-Small-Screen-legalBox-light-icon' : 'pb-5 custom-Big-Screen-legalBox-light-icon'">
    <div class="container-xl font-plus-jakarta-sans" [ngClass]="isSmallScreen? '':'pb-5 mb-5'">
      <!-- // Start First Row -->
      <div class="row">
        <div class="col-lg-12 d-flex justify-content-center">
          <h1 class="fw-bold text-shadow-black letter-spacing-1 pt-5 text-center">
            <small *ngIf="isSmallScreen" class="font-weight-bold">
              Get started with LEGALBOX today
            </small>
            <span *ngIf="!isSmallScreen"> Get started with LEGALBOX today </span>
          </h1>
        </div>
      </div>
      <!-- // End First Row -->
  
      <!-- // Start Second Row -->
      <div class="row">
        <div
          class="col-lg-11 col-12 d-flex justify-content-center mx-auto"
          [ngClass]="isSmallScreen ? 'px-5' : ''"
        >
          <p
            class="line-height-2 mt-3 text-secondary text-center"
            [ngClass]="isSmallScreen ? 'fs-6' : 'fw-semibold fs-5'"
          >
            <small *ngIf="isSmallScreen" class="font-weight-bold">
              Get 7 days of full access to all toolkits. Cancel anytime.
            </small>
            <span *ngIf="!isSmallScreen">
              Get 7 days of full access to all toolkits. Cancel anytime.
            </span>
          </p>
        </div>
      </div>
      <!-- // End Second Row -->
      
      <!-- // Start Third Row -->
      <div class="row mt-3">
        <div class="col-lg-3 col-md-5 col-sm-6 col-7 mx-auto">
          <button
          type="button"
          class="btn btn-sm btn-primary px-5 py-2 mt-4 rounded-2 fs-6"
          (click)="redirectToSignUpSelection()"
        >
          Get Started
        </button>
        </div>
      </div>
      <!-- // End Third Row -->
  
       <!-- // Start Fourth Row -->
       <div class="row mt-4 d-block d-sm-none">
        <div class="col-4 mx-auto">
          <img src="/assets/revamp/legalBoxLightIconThreeQuarters.png" width="100" height="100">
        </div>
      </div>
      <!-- // End Fourth Row -->
  
    </div>
  </section>
