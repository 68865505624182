import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { MandateService } from 'src/app/services/mandate.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  redirectPage: string = null;
  username: any;
  password: any;
  disableSignInButton = false;
  curlecObject: any;
  rawCurlecMandateStatusURL: any;
  isUsernameValid: any;
  isPasswordValid: any;
  displaySessionMessage = false;
  messageToBeDisplayed = "";
  errorType = "";

  requestToken: any = "";

  constructor(private router: Router, private _activatedRoute: ActivatedRoute, private userService: UserService,
    private tokenStorageService: TokenStorageService, private authService: AuthService) {
    this.username = "";
    this.password = "";
  }

  redirectToForgotPassword() {
    this.router.navigate(['ForgotPassword']);
  }

  redirectToSelectPlan() {
    this.router.navigate(['SignUpSelection']);
  }

  ngOnInit(): void {
    this.displaySessionMessage = false;
    this.messageToBeDisplayed = "";
    this.errorType = "";
    this.redirectPage = this._activatedRoute.snapshot.queryParamMap.get('redirectTo');
    this.authService.requestToken(
      "SignInWeb"
    ).subscribe({
      next: async (event) => {
        if (event instanceof HttpResponse) {
          if (event.body.status == 500 || event.body.status == 403) {
            console.error("Error: " + event.body.message);
          } else {
            this.requestToken = event.body.accesstoken;
          }
        }
      },
      error: (err) => {
        console.error(err);
      }
    });

    // // https://www.digitalocean.com/community/tutorials/angular-query-parameters
    // this._activatedRoute.queryParamMap
    //   .subscribe((params) => {
    //     this.curlecObject = { ...params.keys, ...params };
    //     let curlecMethod = this.curlecObject.params.curlec_method;
    //     let mandateReferenceNumber = this.curlecObject.params.fpx_sellerOrderNo;
    //     let fpxDebitAuthCode = this.curlecObject.params.fpx_debitAuthCode;
    //     this.updateMandateStatusAndEnableAutoCollection(fpxDebitAuthCode, mandateReferenceNumber);

    //     // update mandateStatus in database,
    //     // if mandateStatus || fpx_debitAuthCode = 00, enable collectionCondition & autoCollection (curlec),
    //     // set collectionStatus = success && autoCollection = enabled && collectionCondition = enabled in database based on mandateReferenceNumber

    //     // set merchant callbackURL in create Mandate flow (2 places), 
    //     // update mandateStatus in database,
    //     // if mandateStatus || fpx_debitAuthCode = 00, enable collectionCondition & autoCollection (curlec),
    //     // set collectionStatus = success && autoCollection = enabled && collectionCondition = enabled in database based on mandateReferenceNumber

    //     // hardcode admin
    //     // To indicate whether user paid subscription amount based on selected billing cycle and payment method:
    //     // admin can set collectionStatus = failure || can set collectionStatus = success based on mandateReferenceNumber
    //     // To indicate whether user canceled or renewed subscription based on selected billing cycle and payment method:
    //     // admin can set collectionCondition = enabled || can set collectionCondition = disabled

    //   });

  }

  // updateMandateStatusAndEnableAutoCollection(fpxDebitAuthCode, mandateReferenceNumber) {

  //   this.mandateService.updateMandateStatusAndEnableAutoCollection(
  //     fpxDebitAuthCode,
  //     mandateReferenceNumber,
  //   ).subscribe(
  //     event => {
  //       if (event.type === HttpEventType.UploadProgress) {
  //         // this.progress = Math.round(100 * event.loaded / event.total);
  //       } else if (event instanceof HttpResponse) {
  //         if (environment.debug) {
  //           console.log("event:", event);
  //           console.log("Object.keys(event.body):" + Object.keys(event.body));
  //         }

  //         // console.log("event.body.message:" + event.body.message);
  //         // this.message = event.body.message;
  //         if (event.body.collectionCondition == "1") {
  //           // alert("Subscription created / renewed!");

  //           if (event.body.autoCollection == "1") {
  //             // alert("AutoCollection Enabled!");

  //             if (event.body.collectionStatus == "success") {
  //               // alert("CollectionStatus is Successful!");

  //             }

  //           }

  //         }

  //         // return event.body.message;
  //       }
  //     },
  //     err => {
  //       // this.progress = 0;
  //       // this.message = 'Could not create law firm user!';
  //       // alert(err.error.message);
  //       // return null;
  //     });
  //   // this.selectedFiles = undefined;
  // }

  signInVerifyUser() {

    this.userService.signInVerifyUser(
      this.username,
      this.password,
      this.requestToken
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):", Object.keys(event.body));
            console.log("event.body.status:" + event.body.status);
            console.log("event.body.message:" + event.body.message);
          }

          if (event.body.status == 500) {
            if ('verified' in event.body) {
              if (!event.body.verified) {
                this.router.navigate(['ActivateAccount'], {
                  state: {
                    userId: event.body.userId,
                    userUuid: event.body.userUuid,
                    emailAddress: event.body.emailAddress,
                    username: this.username
                  }
                });
              } else {
                this.displaySessionMessage = true;
                this.messageToBeDisplayed = event.body.message;
                this.errorType = "genericError";
                this.disableSignInButton = false;
              }
            } else {
              this.displaySessionMessage = true;
              this.messageToBeDisplayed = event.body.message;
              this.errorType = "genericError";
              this.disableSignInButton = false;
            }

          } else if (event.body.status == 404) {
            this.displaySessionMessage = true;
              this.messageToBeDisplayed = event.body.message;
              this.errorType = "404Error";
          } else {
            if (event.body.jwtToken) {
              if (environment.debug) {
                console.log("event.body.jwtToken:" + event.body.jwtToken);
                console.log("event.body.userItem:", event.body.userItem);
              }
              // this.message = event.body.message;

              this.tokenStorageService.saveToken(event.body.jwtToken);
              this.tokenStorageService.saveUser(event.body.userItem);

              this.disableSignInButton = false;
              if (this.redirectPage) {
                if (this.redirectPage != null || this.redirectPage != "") {
                  this.router.navigate([this.redirectPage], {
                    state: {
                      selectedNavBarLink: this.redirectPage
                    }
                  });
                } else {
                  this.router.navigate(['UserDashboard'], {
                    state: {
                      userId: event.body.userItem.userId
                    }
                  });
                }
              } else {
                this.router.navigate(['UserDashboard'], {
                  state: {
                    userId: event.body.userItem.userId
                  }
                });
              }
              // return event.body.message;
            } else if (event.body.newMandateAuthorizationURL) {
              this.displaySessionMessage = true;
                this.messageToBeDisplayed = "You will be redirected to complete payment in order to login!";
                this.errorType = "genericError";
                setTimeout(() => {
                  window.open(event.body.newMandateAuthorizationURL, "_self");
                }, 5000);
             

            } else if (!event.body.pricePlan && event.body.userType != "client") {
              // alert("Please select a pricing plan in order to login!");
              this.tokenStorageService.saveToken(event.body.access.token);
              this.tokenStorageService.saveUser(event.body.access.userItem);
              this.router.navigate(['SelectPlan'],
                {
                  state:
                  {
                    userId: event.body.userId,
                    userUuid: event.body.userUuid,
                  }
                }
              );
            }
          }
       }
      },
      err => {

        // this.message = 'Could not create law firm user!';
        this.displaySessionMessage = true;
        this.messageToBeDisplayed = err.error.message;
        this.errorType = "genericError";
        this.disableSignInButton = false;
        // return null;
      });
    // this.selectedFiles = undefined;
  }


  signIn() {
    this.disableSignInButton = true;
    if (this.username.trim() == "") {
      this.isUsernameValid = "is-invalid";
      this.disableSignInButton = false;
    }
    else if (this.password.trim() == "") {
      this.isPasswordValid = "is-invalid";
      this.disableSignInButton = false;
    }

    if (this.username.trim() != "") {
      this.isUsernameValid = "is-valid";

      if(this.password.trim() != "") {
        this.isPasswordValid = "is-valid";
        this.signInVerifyUser();
      }
      
      
    }

  }

}
