<section name="userDocumentsSection1" class="">
  <div class="container-xl-fluid font-plus-jakarta-sans me-3 me-md-0">
    <!-- // Start First Row -->
    <div class="row">
      <div class="col-md-3">
        <app-side-navigation-bar [pageTitle]="pageTitle"></app-side-navigation-bar>
      </div>

      <div class="col-md-9 bg-light">
        <!-- // Start First Row Inner First Row -->
        <div class="row mt-3 mt-md-5 ps-4 pt-0 pt-md-5 me-1 me-md-0">
          <div class="col-md-8 d-none d-md-block">
            <h5 class="p-2 fw-bold">{{pageTitle}}</h5>
          </div>
          <div class="col-md-3 ms-0 ms-md-5 d-grid gap-2 d-md-flex justify-content-md-end">
            <!-- Example single danger button -->
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-primary p-2 px-3 dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                (click)="toggleAddNewDropdownContentDisplay()"
                [style.display]="corporateUser ? 'block' : 'none'"
              >
                <img
                  src="/assets/revamp/addNewButtonIcon.png"
                  width="25"
                  height="25"
                />
                &nbsp; Add New
              </button>
              <div
                class="dropdown-menu custom-margin-4rem me-5 border border-secondary-subtle p-2 px-3"
                [ngClass]="displayAddNewDropdownContent ? (isSmallScreen? 'show w-100':'show') : ''"
              >
                <a
                  class="dropdown-item text-center text-md-start"
                  (click)="redirectToAddNewClient()"
                  [style.display]="
                    allocatedStorageSizeInGB >= 1 && corporateInstitutionUser
                      ? 'block'
                      : 'none'
                  "
                >
                  <img
                    src="/assets/revamp/newClientDropdownIconNew.png"
                    width="13"
                    height="16"
                  />
                  &nbsp; New Client
                </a>
                <div
                  [style.display]="
                    allocatedStorageSizeInGB >= 1 && corporateInstitutionUser
                      ? 'block'
                      : 'none'
                  "
                  class="dropdown-divider"
                ></div>
                <a class="dropdown-item text-center text-md-start" (click)="redirectToUploadNewFiles()">
                  <img
                    src="/assets/revamp/fileUploadDropdownIconNew.png"
                    width="13"
                    height="16"
                  />
                  &nbsp; File Upload
                </a>
              </div>
            </div>
            <!-- <button type="button" class="btn btn-primary p-2 px-3">
                        <img src="/assets/revamp/addNewButtonIcon.png" width="25" height="25">
                        &nbsp; Add New
                    </button> -->
          </div>
        </div>
        <!-- // End First Row Inner First Row -->

        <!-- // Start First Row Inner Second Row -->
        <div class="row">
          <div class="col-md-11" style="width: 97%">
            <!-- // Start My Storage Box -->
            <div
              name="myStorageBox"
              class="bg-white rounded-2 ms-4 p-4 mt-3 mt-md-5 custom-height-mystoragebox"
            >
              <!-- // Start My Storage Box First Row -->
              <div class="row">
                <div class="col-4 col-md-2">
                  <p class="ms-0 ms-md-4 mb-0 fw-bold" [ngClass]="isSmallScreen? 'small':'fs-6'">My Storage</p>
                </div>
                <div class="col-8 col-md-3">
                  <p class="mb-0 text-end text-md-start" [ngClass]="isSmallScreen? 'small':'fw-bold'">
                    {{
                      allocatedStorageSizeInGB >= 1
                        ? usedStorageSizeInGB
                        : usedStorageSizeInMB
                    }}
                    {{ allocatedStorageSizeInGB >= 1 ? "G" : "M" }}B 
                    used /
                    {{
                      allocatedStorageSizeInGB >= 1
                        ? allocatedStorageSizeInGB
                        : allocatedStorageSizeInMB
                    }}
                    {{ allocatedStorageSizeInGB >= 1 ? "G" : "M" }}B
                  </p>
                </div>

                <div class="col-md-7">
                  <div class="progress-stacked mt-3 mt-md-1 custom-bg-lightBlue">
                    <div
                      class="progress"
                      role="progressbar"
                      aria-label="Segment one"
                      aria-valuenow="15"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      [style.width]="
                        corporateInstitutionStorageValueInPercentage
                      "
                    >
                      <div class="progress-bar custom-bg-darkBlue"></div>
                    </div>
                    <div
                      class="progress"
                      role="progressbar"
                      aria-label="Segment two"
                      aria-valuenow="30"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      [style.width]="clientStorageValueInPercentage"
                    >
                      <div class="progress-bar custom-bg-mediumBlue"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- // End My Storage Box First Row -->
            </div>
            <!-- // End My Storage Box -->

            <!-- // Start Recent Files Box -->
            <div
              name="recentFilesBox"
              class="bg-white rounded-2 ms-4 p-4 mt-4 mb-5 mb-md-0 custom-height-recentfilesbox"
            >
              <p class="ms-0 ms-md-4 mb-4 mb-md-0 fw-bold fs-6 float-start" >Recent Files</p>
              <p
                class="float-end text-primary fs-6 fw-medium"
                (mouseenter)="showPopup()"
                (mouseleave)="hidePopup()"
              >
                Change Plan
              </p>
              <div
                class="changePlanPopUp shadow-lg"
                [style.display]="isPopupVisible ? 'block' : 'none'"
              >
                Please email your change plan request to support@legalbox.io.
                <br />Our team will get in touch with you soon.
              </div>
              <hr
                class="clearfix custom-hr-width mt-0 ms-3 d-none d-md-block"
                style="clear: both"
              />
              <table class="table ms-0 ms-md-3 table-borderless" [ngClass]="isSmallScreen? 'border-collapse':'table-hover'">
                <thead class="" >
                  <tr>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      Name &nbsp;<img
                        src="/assets/revamp/myDocumentsArrowUpIcon.png"
                        width="7"
                        height="7"
                      />
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      File Type
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      Size
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      Last Modified
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      Transaction Hash
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      IPFS Hash
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                      [style.display]="corporateUser ? 'block' : 'none'"
                    >
                      Options
                    </th>
                  </tr>
                </thead>
                <tbody class="custom-font-size-13">
                  <ng-container
                    *ngFor="
                      let legalDocument of retrievedLegalDocuments;
                      index as i
                    "
                  >
                    <tr appLongPress (mouseLongPress)="onLongPress($event, legalDocument.id)" 
                    class="d-flex justify-content-end d-md-table-row">

                      <td colspan="isSmallScreen? 5:1"
                        class="align-middle custom-cursor"
                        [ngClass]="isSmallScreen? 
                        (longPressDocumentID == legalDocument.id? (showMoreOptions? 'px-0 py-0 me-auto':'px-0 py-0 me-auto'):'px-0 py-0 me-auto')
                        :''"
                        (click)="
                          displayUserSpecificLegalDocument(legalDocument.title)
                        "
                      >
                        <img
                          src="/assets/revamp/myDocumentsFolderIcon.png"
                          class="float-start"
                          [style.width]="isSmallScreen? '1.8rem':'1rem'"
                          [style.height]="isSmallScreen? '1.8rem':'1rem'"
                        />
                        &nbsp; 
                        <p class="mb-md-0 float-start ms-2" [ngClass]="isSmallScreen? 'fw-bold':''"> 
                          {{ isSmallScreen? legalDocument.title.substring(0, 22):legalDocument.title }}
                          <!-- // Start Document Additional Info Small Screen -->
                        <span class="d-table-cell d-md-none fw-normal custom-font-size-10">
                          {{ legalDocument.updatedAt | customDate }}
                          -
                          {{
                            formatBytes(
                              legalDocument.fileSizeInGB * 1000 * 1000 * 1000
                            )
                          }}
                        </span>
                        <!-- // End Document Additional Info Small Screen -->
                         </p>

                      </td>
                      <td class="text-secondary align-middle d-none d-md-table-cell">
                        .{{ legalDocument.fileExtension }}
                      </td>
                      <td class="text-secondary align-middle d-none d-md-table-cell">
                        {{
                          formatBytes(
                            legalDocument.fileSizeInGB * 1000 * 1000 * 1000
                          )
                        }}
                      </td>
                      <td class="text-secondary align-middle d-none d-md-table-cell">
                        {{ localFormatDate(legalDocument.updatedAt) }}
                      </td>

                      <!-- // Start td for Legal Document with Transaction Hash -->
                      <td [ngClass]="isSmallScreen? 'px-0':''"
                        *ngIf="isSmallScreen? (longPressDocumentID != legalDocument.id && legalDocument.legalDocumentIDInBlockchain != null):
                          legalDocument.legalDocumentIDInBlockchain != null
                        "
                      >
                        <button
                          class="btn bg-transparent ms-0 ms-md-4 p-0 p-md-1"
                          (click)="
                            $event.stopPropagation();
                            redirectToBlockExplorerURL(legalDocument.id)
                          "
                        >
                          <img
                            src="/assets/scratchImg/polygonIcon.png"
                            width="22"
                            height="22"
                          />
                        </button>
                      </td>
                       <!-- // End td for Legal Document with Transaction Hash -->

                        <!-- // Start td for Legal Document without Transaction Hash -->
                      <td [ngClass]="isSmallScreen? 'px-0':''"
                        *ngIf="isSmallScreen? (longPressDocumentID != legalDocument.id &&  legalDocument.legalDocumentIDInBlockchain == null):
                          legalDocument.legalDocumentIDInBlockchain == null
                        "
                      >
                        <button
                          class="btn bg-transparent ms-0 ms-md-4 p-0 p-md-1"
                          [disabled]="tryingBCUpload || !corporateUser"
                          (click)="
                            $event.stopPropagation();
                            retryUploadToBlockchain(
                              legalDocument.id,
                              legalDocument.title,
                              legalDocument.description,
                              legalDocument.CIDHash
                            )
                          "
                        >
                          <img
                            src="/assets/scratchImg/polygonRefreshIcon.svg"
                            width="22"
                            height="22"
                            [style.display]="tryingBCUpload ? 'none' : 'block'"
                          />
                          <img
                            src="/assets/scratchImg/polygonUpload.gif"
                            width="22"
                            height="22"
                            [style.display]="tryingBCUpload ? 'block' : 'none'"
                          />
                        </button>
                      </td>
                       <!-- // End td for Legal Document without Transaction Hash -->

                      <td [ngClass]="isSmallScreen? 'px-0':''"
                      *ngIf="isSmallScreen?(longPressDocumentID != legalDocument.id):true">
                      
                        <button
                        class="btn bg-transparent ms-2 p-0 p-md-1"
                        (click)="
                          $event.stopPropagation();
                          redirectToIPFSHashURL(legalDocument.CIDHash)
                        "
                      >
                        <img
                          src="/assets/revamp/myDocumentsIpfsIcon.png"
                          width="22"
                          height="22"
                        />
                      </button>
                    
                      </td>

                      <td 
                        [style.display]="corporateUser ? 'block' : 'none'"
                        *ngIf="isSmallScreen? (longPressDocumentID == legalDocument.id):true"
                        class="align-middle"
                      >
                        <button
                          class="btn bg-transparent ms-0 ms-md-2 p-0 p-md-1"
                          (click)="
                            $event.stopPropagation();
                            dropdownOptions(legalDocument.id)
                          "
                        >
                          <img 
                            src="/assets/revamp/myDocumentsOptionsIcon.png"
                            width="11"
                            height="29.15"
                            alt="More Options"
                          />
                        </button>

                        <div
                          *ngIf="
                            dropdownDocumentID == legalDocument.id &&
                            showMoreOptions
                          "
                          class="bg-white position-relative border border-secondary-subtle rounded-2 custom-margin-top-negative-2rem custom-margin-left-negative-2rem custom-zindex-4 p-2 text-center"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="mb-0">
                              <p
                                class="mb-0"
                                (click)="
                                  $event.stopPropagation();
                                  deleteDocumentPopUp(
                                    legalDocument.id,
                                    legalDocument.title
                                  )
                                "
                              >
                                Delete
                              </p>
                            </li>
                          </ul>
                        </div>
                      </td>
                    
                    </tr>
                    <hr class="mt-0 d-block d-md-none" style="width:100%;">
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!-- // End Recent Files Box -->
          </div>
        </div>
        <!-- // End First Row Inner Second Row -->
      </div>
    </div>
    <!-- // End First Row -->
  </div>
</section>

<!-- Modal -->
<div
  class="modal fade show"
  [style.display]="displayDeleteMessage ? 'block' : 'none'"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  style="display: block"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content p-2 border-0">
      <!-- // Start Delete Confirmation Modal Body -->
      <div class="modal-body text-center">
        <img
          src="/assets/revamp/yellowExclamationMark.png"
          width="45"
          height="45"
        />
        <h1
          class="modal-title fs-6 text-center fw-medium mt-3"
          id="exampleModalLabel"
        >
          Are you sure you want to delete {{ documentTitle }} file?
        </h1>

        <!-- // Start Delete Confirmation Modal Body First Row -->
        <div class="row mt-4">
          <div class="col-6 float-start">
            <div class="d-grid gap-2">
              <button
                type="button"
                class="btn btn-primary"
                (click)="$event.stopPropagation(); cancelDel()"
              >
                No
              </button>
            </div>
          </div>

          <div class="col-6 float-start">
            <div class="d-grid gap-2">
              <button
                type="button"
                class="btn btn-outline-primary"
                (click)="$event.stopPropagation(); archiveDocument()"
                [disabled]="displayLoadingMessage"
                [style.cursor]="displayLoadingMessage ? 'default' : 'pointer'"
              >
                Yes, delete
              </button>
            </div>
          </div>
        </div>
        <!-- // End Delete Confirmation Modal Body First Row -->
      </div>
      <!-- // End Delete Confirmation Modal Body -->
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade show text-center"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  [style.display]="
    displayLoadingMessage || displayResponseMessage ? 'block' : 'none'
  "
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content p-2 border-0">
      <!-- // Start Modal Body -->
      <div class="modal-body">
        <!-- //*ngif loading -->
        <div *ngIf="displayLoadingMessage">
          <img
            src="/assets/scratchImg/loadingGifIcon.gif"
            class="loadingGifIcon"
          />
          <br /><br />
          <p class="fw-bold">Deleting File ...</p>
        </div>

        <!-- //*ngif response -->
        <div *ngIf="displayResponseMessage">
          <img
            [src]="responseMessageIconImageURL"
            class="successMessageIcon"
            width="45"
            height="45"
          />

          <div class="mt-2">
            <p class="fs-5 fw-bold">{{ responseType }}</p>
            <p class="fs-6 fw-normal">{{ responseMessageText }}</p>
          </div>

          <div class="d-grid gap-2">
            <button
              class="btn btn-primary"
              (click)="closeResponseMessage()"
              type="button"
            >
              CLOSE
            </button>
          </div>
        </div>
      </div>
      <!-- // End Modal Body -->
    </div>
  </div>
</div>

<app-error-pop-up
  [(displaySessionMessage)]="displaySessionMessage"
  [messageToBeDisplayed]="messageToBeDisplayed"
  [errorType]="errorType"
></app-error-pop-up>
