import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-up-step2-credentials',
  templateUrl: './sign-up-step2-credentials.component.html',
  styleUrls: ['./sign-up-step2-credentials.component.css']
})


export class SignUpStep2CredentialsComponent implements OnInit {
  @Input() stage1Data: any;
  @Input() urlEmail: string;
  @Input() requestToken: any;
  @Input() clientId: any;
  @Output() stage2Data = new EventEmitter<any>();

  isValidated: boolean;
  isEmailValid: string;
  email: string;
  isUsernameValid: string;
  userName: String;
  isPasswordValid: string;
  newPasword: string;
  isConfPasswordValid: string;
  confPasword: string;
  isTermsValid: string;
  checkTerms: boolean;
  invalidEmailText: String;
  invalidPasswordText: String;
  invalidConfPasswordText: String;
  disableEmail: boolean = false;

  isClient: boolean = false;
  authToken: string = null;
  displaySessionMessage = false;
  messageToBeDisplayed = "";
  errorType = "";

  constructor(private router: Router, private userService: UserService, private _activatedRoute: ActivatedRoute) {
    this.isValidated = false;
    this.email = "";
    this.userName = "";
    this.newPasword = "";
    this.confPasword = "";
    this.checkTerms = false;

    this.invalidPasswordText = "";
    this.invalidConfPasswordText = "";
  }

  ngOnInit(): void {
    this.displaySessionMessage = false;
    this.messageToBeDisplayed = "";
    this.errorType = "";
    this.authToken = this._activatedRoute.snapshot.queryParamMap.get('authToken');
    if (this.urlEmail != null) {
      this.email = this.urlEmail;
      this.disableEmail = true;
      this.isClient = true;
    }
  }

  openDocument(documentName: string) {
    window.open("assets/documents/" + documentName);
  }

  isChecked(event: any) {
    if (event.target.checked == true) {
      this.checkTerms = true;
    } else {
      this.checkTerms = false;
    }
  }

  resetValidation() {
    this.isTermsValid = "";
    this.isConfPasswordValid = "";
    this.isPasswordValid = "";
    this.isUsernameValid = "";
    this.isEmailValid = "";
  }

  checkDetails() {
    this.isValidated = true;
    if (this.email.trim() == "") {
      this.isEmailValid = "is-invalid";
      this.invalidEmailText = "Please enter your email address!";
      console.error("Email missing!");
    }

    if (this.userName.trim() == "") {
      if (/\s/.test(this.userName.toString())) {
        this.isUsernameValid = "is-invalid";
        console.error("Username cannot contain whitespaces!");
      }
      this.isUsernameValid = "is-invalid";
      console.error("Username missing!");
    }

    if (this.newPasword.trim() == "") {
      this.isPasswordValid = "is-invalid";
      console.error("Password missing!");
    }

    if (this.confPasword.trim() == "") {
      this.isConfPasswordValid = "is-invalid";
      console.error("Confirm Password missing!");
    }

    if (this.checkTerms == false) {
      this.isTermsValid = "is-invalid";
      console.error("Terms not agreed!");
    }

    if (this.email.trim() != "") {

      let emailValidation = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
      // if (this.email.trim().match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      if (emailValidation.test(this.email.trim())) {
        this.isEmailValid = "is-valid";

        if (this.userName.trim() != "") {

          if (/\s/.test(this.userName.toString())) {
            this.isUsernameValid = "is-invalid";

          } else {

            this.isUsernameValid = "is-valid";

            if (this.newPasword.trim() != "") {
              let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
              var specialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
              var uppercaseCharacter = /[A-Z]/;
              var lowercaseCharacter = /[a-z]/;
              var numberOrDigit = /\d/;

              // Start Strong Password Test
              // Start Special Character Test
              if (specialCharacter.test(this.newPasword.trim())) {
                this.isPasswordValid = "is-valid";

                // Start Uppercase Character Test
                if (lowercaseCharacter.test(this.newPasword.trim())) {
                  this.isPasswordValid = "is-valid";

                  // Start Lowercase Character Test
                  if (uppercaseCharacter.test(this.newPasword.trim())) {
                    this.isPasswordValid = "is-valid";

                    // Start Number or Digit Test
                    if (numberOrDigit.test(this.newPasword.trim())) {
                      this.isPasswordValid = "is-valid";

                      // Start 8 Character Test
                      if (this.newPasword.trim().length >= 8) {
                        this.isPasswordValid = "is-valid";

                        if (this.confPasword.trim() != "") {
                          if (this.confPasword.trim() == this.newPasword.trim()) {
                            this.isConfPasswordValid = "is-valid";
                            if (this.checkTerms == true) {
                              // create account function
                              this.isTermsValid = "is-valid";
                              this.createAccount();
                            }
                          } else {
                            this.isConfPasswordValid = "is-invalid";
                            this.invalidConfPasswordText = "Password and confirm password do not match.";
                            console.error("Password and confirm password do not match.");
                          }
                        }
                        else {
                          this.isConfPasswordValid = "is-invalid";
                          this.invalidConfPasswordText = "Please re-enter your password.";
                        }

                      } else {
                        this.isPasswordValid = "is-invalid";
                        this.invalidPasswordText = "Password must be at least 8 characters long.";
                      }
                      // End 8 Character Test

                    } else {
                      this.isPasswordValid = "is-invalid";
                      this.invalidPasswordText = "Password needs to contain at least one digit.";
                    }
                    // End Number or Digit Test

                  } else {
                    this.isPasswordValid = "is-invalid";
                    this.invalidPasswordText = "Password needs to contain at least one lowercase character.";
                  }
                  // End Lowercase Character Test

                } else {
                  this.isPasswordValid = "is-invalid";
                  this.invalidPasswordText = "Password needs to contain at least one uppercase character.";
                }
                // End Uppercase Character Test

              } else {
                this.isPasswordValid = "is-invalid";
                this.invalidPasswordText = "Password needs to contain at least one special character.";
              }
              // End Strong Password Test

            }
          }

        }

      } else {
        this.isEmailValid = "is-invalid";
        this.invalidEmailText = "Please enter a valid email address!";
        console.error("Please enter a valid email address!");
      }

    }


  }

  createAccount() {
    if (this.stage1Data) {
      if (this.stage1Data.origin == "personalData") {
        // Create Individual Account
        this.userService.createNewIndividualUser(
          this.stage1Data,
          this.userName,
          this.email,
          this.newPasword,
          this.clientId,
          this.requestToken
        ).subscribe({
          next: async (event) => {
            if (event instanceof HttpResponse) {
              if (environment.debug) {
                console.log("event:", event);
                console.log("Object.keys(event.body):" + Object.keys(event.body));
                console.log("event.body.message:" + event.body.message);
              }

              // this.message = event.body.message;
              // this.userId = event.body.userId;
              // this.disableIndividualSignUpButton = false;
              // return event.body.message;
              if (event.body.status == 500) {
                this.displaySessionMessage = true;
                this.messageToBeDisplayed = event.body.message;
                this.errorType = "genericError";
              } else if (event.body.status == 400) {
                this.displaySessionMessage = true;
                this.messageToBeDisplayed = event.body.message;
                this.errorType = "genericError";
              } else {
                this.router.navigate(['ActivateAccount'], {
                  state: {
                    username: this.userName,
                    emailAddress: this.email,
                    userId: event.body.userId,
                    userUuid: event.body.userUuid,
                    isClient: this.isClient,
                    authToken: this.authToken
                  }
                });
                // this.emailVerificationDisplay = true;
                // this.myModalDisplay = true;
              }
            }
          }, error: (err) => {
            // this.errorMessage = err.error.message;
            // this.displayBackendErrorMessage = true;
            this.displaySessionMessage = true;
            this.messageToBeDisplayed = err.error.message;
            this.errorType = "genericError";
          }
        });
      } else if (this.stage1Data.origin == "industryData") {
        // Create Business Account
        this.userService.createNewBusinessUser(
          this.stage1Data,
          this.userName,
          this.email,
          this.newPasword,
          this.requestToken
        ).subscribe({
          next: async (event) => {
            if (event instanceof HttpResponse) {
              if (environment.debug) {
                console.log("event:", event);
                console.log("Object.keys(event.body):" + Object.keys(event.body));
                console.log("event.body.message:" + event.body.message);
              }// this.message = event.body.message;
              // this.userId = event.body.userId;
              // this.disableIndividualSignUpButton = false;
              // return event.body.message;
              if (event.body.status == 500) {
                this.displaySessionMessage = true;
                this.messageToBeDisplayed = event.body.message;
                this.errorType = "genericError";
              } else if (event.body.status == 400) {
                this.displaySessionMessage = true;
                this.messageToBeDisplayed = event.body.message;
                this.errorType = "genericError";
              } else {
                this.router.navigate(['ActivateAccount'], {
                  state: {
                    username: this.userName,
                    emailAddress: this.email,
                    userId: event.body.userId,
                    userUuid: event.body.userUuid
                  }
                });
                // this.emailVerificationDisplay = true;
                // this.myModalDisplay = true;
              }
            }
          }, error: (err) => {
            // this.errorMessage = err.error.message;
            // this.displayBackendErrorMessage = true;
            this.displaySessionMessage = true;
            this.messageToBeDisplayed = err.error.message;
            this.errorType = "genericError";
          }
        });
      }


    } else {
      this.router.navigate(['SignUpSelection']);
    }
  }

  goBack() {
    this.stage2Data.emit(this.stage1Data);
  }


}
