

<!-- Modal -->

<div class="modal fade show border-0" [style.display]="displaySessionMessage? 'block' : 'none'" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel">

    <div class="modal-dialog modal-dialog-centered modal-sm border-0">

      <div class="modal-content p-2 border-0" style="border: 0;">

 

        <!-- // Start Delete Confirmation Modal Body -->

        <div class="modal-body text-center border-0">

            <img src="/assets/revamp/redExclamationMark.png" width="45" height="45">

            <h1 class="modal-title fs-6 text-center fw-medium mt-3" id="exampleModalLabel">

               {{messageToBeDisplayed}}

            </h1>

 

            <!-- // Start Delete Confirmation Modal Body First Row -->

            <div class="row mt-4">

                <div class="col-12">

                    <div class="d-grid gap-2">

                        <button type="button" class="btn btn-primary" (click)="performAction()">Ok</button>

                    </div>

                  </div>

               

            </div>

             <!-- // End Delete Confirmation Modal Body First Row -->

         

        </div>

        <!-- // End Delete Confirmation Modal Body -->

 

      </div>

    </div>

  </div>

 

 