<section name="userArchiveSection1" class="">
  <div class="container-xl-fluid font-plus-jakarta-sans me-3 me-md-0">
    <!-- // Start First Row -->
    <div class="row">
      <!-- // Start Side Navigation Bar -->
      <div class="col-md-3">
        <app-side-navigation-bar [pageTitle]="'Archive'"></app-side-navigation-bar>
      </div>
      <!-- // End Side Navigation Bar -->

      <!-- // Start User Archive Content -->
      <div class="col-md-9 bg-light">
        <!-- // Start First Row Inner First Row -->
        <div class="row mt-0 mt-md-5 ps-4 pt-0 pt-md-5">
          <div class="col-md-8 d-none d-md-block">
            <h5 class="p-2 fw-bold">Archive</h5>
          </div>
        </div>
        <!-- // End First Row Inner First Row -->

        <!-- // Start First Row Inner Second Row -->
        <div class="row">
          <div class="col-md-11" style="width: 97%">
            <!-- // Start Recent Files Box -->
            <div
              name="recentFilesBox"
              class="bg-white rounded-2 ms-4 p-4 mt-4 mb-5 mb-md-0 custom-height-recentfilesbox"
            >
              <p class="ms-0 ms-md-3 mb-0 fw-bold fs-6"  >
                <span [ngClass]="isSmallScreen? 'float-start':''" class="mb-2 mb-md-0">
                  Archive Files
                </span>

                <span  [style.display]="corporateUser ? 'block' : 'none'"
                  class="text-primary fs-6 fw-medium d-inline-block d-md-none custom-font-size-12 float-end mb-2 mb-md-0"
                  (click)="redirectToClientArchiveSelection()"
                  >View Clients Archive
                  </span>
                
                <span class="d-block d-md-inline-block text-secondary fw-normal ms-0 ms-md-3 mt-1 mt-md-0"
                [ngClass]="isSmallScreen? 'custom-font-size-13':'small'">
                  *Deleted files are stored in Archive for 7 days.
                </span>

                <span
                [style.display]="corporateUser ? 'block' : 'none'"
                  class="text-primary fs-6 fw-medium d-none d-md-inline-block float-end"
                  (click)="redirectToClientArchiveSelection()"
                  
                  >View Clients Archive
                  </span>
              </p>
              <hr class="clearfix custom-hr-width mt-2 ms-3 d-none d-md-block" />
              <table class="table ms-0 ms-md-3 table-borderless" [ngClass]="isSmallScreen? '':'table-hover'">
                <thead class="">
                  <tr>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      Name &nbsp;<img
                        src="/assets/revamp/myDocumentsArrowUpIcon.png"
                        width="7"
                        height="7"
                      />
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      File Type
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      Size
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      Deletion Date
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                      [style.display]="
                        corporateUser || individualUser ? 'block' : 'none'
                      "
                    >
                      Options
                    </th>
                  </tr>
                </thead>
                <tbody class="custom-font-size-13">
                  <ng-container
                    *ngFor="
                      let legalDocument of retrievedArchivedLegalDocuments;
                      index as i
                    "
                  >
                    <tr>
                      <td class="align-middle">
                        <img
                          src="/assets/revamp/myDocumentsFolderIcon.png"
                          [style.width]="isSmallScreen? '1.8rem':'1rem'"
                          [style.height]="isSmallScreen? '1.8rem':'1rem'"
                        />
                        &nbsp; 
                        <span class="d-inline-block ms-2" style="vertical-align: middle;">
                          {{ isSmallScreen? legalDocument.title.substring(0, 26):legalDocument.title }}
                        </span>
                       
                      </td>
                      <td class="d-none d-md-table-cell text-secondary align-middle">
                        .{{ legalDocument.fileExtension }}
                      </td>
                      <td class="d-none d-md-table-cell text-secondary align-middle">
                        {{
                          formatBytes(
                            legalDocument.fileSizeInGB * 1000 * 1000 * 1000
                          )
                        }}
                      </td>
                      <td class="d-none d-md-table-cell text-secondary align-middle">
                        {{ localFormatDate(legalDocument.updatedAt) }}
                      </td>

                      <td
                        [style.display]="
                          corporateUser || individualUser ? 'block' : 'none'
                        "
                        class="align-middle float-end float-md-none me-2 me-md-0"
                      
                      >
                        <button
                          class="btn bg-transparent ms-2"
                          (click)="
                            $event.stopPropagation();
                            dropdownOptions(legalDocument.id)
                          "
                        >
                          <img
                            src="/assets/revamp/myDocumentsOptionsIcon.png"
                            width="11"
                            height="29.15"
                            alt="More Options"
                          />
                        </button>

                        <div
                          *ngIf="
                            dropdownDocumentID == legalDocument.id &&
                            showMoreOptions
                          "
                          class="bg-white position-relative border border-secondary-subtle rounded-2 custom-margin-top-negative-2rem custom-margin-left-negative-2rem custom-zindex-4 p-2 text-center"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="mb-0">
                              <p
                                class="mb-0"
                                (click)="
                                  restoreDocumentPopUp(
                                    legalDocument.id,
                                    legalDocument.title
                                  )
                                "
                              >
                                Restore
                              </p>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <hr class="mt-0 d-block d-md-none" style="width:170%;">
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!-- // End Recent Files Box -->
          </div>
        </div>
        <!-- // End First Row Inner Second Row -->
      </div>
      <!-- // End User Archive Content -->
    </div>
    <!-- // End First Row -->
  </div>
</section>

<!-- Modal -->
<div
  class="modal fade show"
  [style.display]="displayRestoreMessage ? 'block' : 'none'"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  style="display: block"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content p-2 border-0">
      <!-- // Start Delete Confirmation Modal Body -->
      <div class="modal-body text-center">
        <img
          src="/assets/revamp/yellowExclamationMark.png"
          width="45"
          height="45"
        />
        <h1
          class="modal-title fs-6 text-center fw-medium mt-3"
          id="exampleModalLabel"
        >
          Are you sure you want to restore {{ documentTitle }} file?
        </h1>

        <!-- // Start Delete Confirmation Modal Body First Row -->
        <div class="row mt-4">
          <div class="col-6 float-start">
            <div class="d-grid gap-2">
              <button
                type="button"
                class="btn btn-primary"
                (click)="cancelRestore()"
              >
                No
              </button>
            </div>
          </div>

          <div class="col-6 float-start">
            <div class="d-grid gap-2">
              <button
                type="button"
                class="btn btn-outline-primary"
                (click)="restoreDocument()"
                [disabled]="restoring"
              >
                Yes, restore
              </button>
            </div>
          </div>
        </div>
        <!-- // End Delete Confirmation Modal Body First Row -->
      </div>
      <!-- // End Delete Confirmation Modal Body -->
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade show text-center"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  [style.display]="
    displayLoadingMessage || displayResponseMessage ? 'block' : 'none'
  "
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content p-2 border-0">
      <!-- // Start Modal Body -->
      <div class="modal-body">
        <!-- //*ngif loading -->
        <div *ngIf="displayLoadingMessage">
          <img
            src="/assets/scratchImg/loadingGifIcon.gif"
            class="loadingGifIcon"
          />
          <br /><br />
          <p class="fw-bold">Restoring File ...</p>
        </div>

        <!-- //*ngif response -->
        <div *ngIf="displayResponseMessage">
          <img
            [src]="responseMessageIconImageURL"
            class="successMessageIcon"
            width="45"
            height="45"
          />

          <div class="mt-2">
            <p class="fs-5 fw-bold">{{ responseType }}</p>
            <p class="fs-6 fw-normal">{{ responseMessageText }}</p>
          </div>

          <div class="d-grid gap-2">
            <button
              class="btn btn-primary"
              (click)="closeResponseMessage()"
              type="button"
            >
              CLOSE
            </button>
          </div>
        </div>
      </div>
      <!-- // End Modal Body -->
    </div>
  </div>
</div>

<app-error-pop-up
  [(displaySessionMessage)]="displaySessionMessage"
  [messageToBeDisplayed]="messageToBeDisplayed"
  [errorType]="errorType"
></app-error-pop-up>
