<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL" crossorigin="anonymous"></script>

<div class="section">
  <div class="container">

    <!-- Main Content -->
    <div class="row justify-content-center">
      <div class="col-12">


        <!-- sub title -->
        <div class="row pb-3">
          <div class="col-10">
            <h2 class="fw-bold">
              What is your business info?
            </h2>
          </div>
          <div class="col-2">
            <p class="fs-4">
              1/2
            </p>
          </div>
        </div>

        <!-- sub content questions - Stage 1 - Industry -->
          <div class="row">
            <div class="col-12 text-left">
              <label class="form-label fw-semibold" for="businessName">Name of Business</label>
              <input type="text" class="form-control" id="businessName" name="businessName" [ngClass]="isBusinessNameValid"
                placeholder="eg. John Associates" aria-label="businessName" required [(ngModel)]="businessName">
              <div class="valid-feedback"></div>
              <div class="invalid-feedback">
                Please enter your business name!
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <label for="selectIndustry" class="form-label fw-semibold">Business Industry</label>
              <select #selectIndustry class="form-select" id="selectIndustry" required [(ngModel)]="selectingIndustry"
                [ngClass]="isBusinessIndustryValid" name="selectingIndustry" (change)="selectedIndustry(selectIndustry.value)">
                <!-- <option [selected]="isBaseOptionSelected" disabled value="" hidden="hidden">Select your industry</option> -->
                <option *ngFor="let industry of listOfIndustries, index as i" [value]="i"
                  [selected]="i.toString()==selectingIndustry" [disabled]="i.toString()=='0'"
                  [hidden]="i.toString()=='0'">{{industry}}</option>
              </select>
              <div class="valid-feedback"></div>
              <div class="invalid-feedback">
                Please choose your industry!
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 text-left">
              <label class="form-label fw-semibold" for="businessRegNo">Business Registration Number</label>
              <input type="text" class="form-control" id="businessRegNo" name="businessRegNo" [ngClass]="isBusinessRegNoValid"
                placeholder="eg. 2012341" aria-label="businessRegNo" required [(ngModel)]="businessRegNo">
              <div class="valid-feedback"></div>
              <div class="invalid-feedback">
                Please enter your new business registration number!
              </div>
            </div>
          </div>

          <div class="section" [ngClass]="otherIndustry? 'd-block':'d-none'">
            <div class="row mt-3">
              <div class="col-12 text-left">
                <label for="otherIndustryText" class="form-label fw-semibold">Specify others</label>
                <input id="otherIndustryText" type="text" name="otherIndustryText" class="form-control"
                  placeholder="eg. Real Estate" aria-label="otherIndustryText" [(ngModel)]="otherIndustryText"
                  [required]="otherIndustry" [ngClass]="isOtherIndustryTextValid">
                <div class="valid-feedback"></div>
                <div class="invalid-feedback">
                  Please specify your industry!
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 d-grid gap-1 mt-5">
              <button class="btn btn-primary" (click)="goToNext()">
                Next
              </button>
            </div>
          </div>

      </div>
    </div>
  </div>
</div>