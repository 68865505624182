import { Component, OnInit, HostBinding, HostListener, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { HttpResponse } from '@angular/common/http';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { BusinessService } from 'src/app/services/business.service';
import { IndividualService } from 'src/app/services/individual.service';
import { ClientService } from 'src/app/services/client.service';
import { PricingPlanService } from 'src/app/services/pricing-plan.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {
  pageTitle = "Profile";
  displayAddNewDropdownContent = false;
  message: any;
  corporateUser = false;
  corporateInstitutionUser = false;
  retrievedUser: any;
  retrievedUserUsername: any;
  retrievedUserEmailAddress: any;
  retrievedUserContactNumber: any;
  firstSectionABlockTitle = "";
  firstSectionEBlockTitle = "";
  secondSectionABlockTitle = "";
  secondSectionBBlockTitle = "";
  secondSectionCBlockTitle = "";
  secondSectionDBlockTitle = "";

  firstSectionABlockValue = "";
  firstSectionEBlockValue = "";
  secondSectionABlockValue = "";
  secondSectionBBlockValue = "";
  secondSectionCBlockValue = "";
  secondSectionD1BlockValue = "";
  secondSectionD2BlockValue = "";
  extractedUserId: any;
  displayPlanAndPaymentInfo = false;
  userType: any;
  finalSumOfClientStorageInGB: any;
  finalSumOfClientStorageInMB: any;
  finalSumOfCorporateInstitutionUsageInGB: any;
  finalSumOfCorporateInstitutionUsageInMB: any;
  pricingPlanName: any;
  retrievedPricingPlan: any;
  pricingPlanStorageSizeInGB: any;
  pricingPlanMonthlyAmount: any;
  storageBalanceInGB;
  storageBalanceInMB;
  isPopupVisible: any;
  messageToBeDisplayed = "";
  errorType = "";

  // @HostListener('document:mousedown', ['$event'])
  // onGlobalClick(event): void {
  //    if (this.elementRef.nativeElement.contains(event.target)) {
  //       // clicked outside => close dropdown list
  //    this.displayAddNewDropdownContent = false;
  //    }
  // }

  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    console.log("isSmallScreen from Home Component: " + this.isSmallScreen);
  }

  @HostBinding("style.--percentage")
  // set percentage of storage used here
  public allocatedStorageSizeInGB: any;
  public allocatedStorageSizeInMB: any;
  public usedStorageSizeInGB: any;
  public usedStorageSizeInMB: any;
  public value = '65%';

  public corporateInstitutionStorageValue: any;
  public corporateInstitutionStorageValueInPercentage = '0%';
  public clientStorageValue: any;
  public clientStorageValueInPercentage = '0%';
  public usedStorage = this.value.split('%')[0] + ' GB';
  displaySessionMessage = false;

  @HostBinding("attr.style")
  public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(`--percentage: ${this.value}`);
  }

  redirectToAddNewClient() {
    this.router.navigate(['AddNewClient']);
  }

  redirectToUploadNewFiles() {
    // this.router.navigate(['UploadNewFiles']);
    this.router.navigate(['UploadNewFiles'],
      {
        state: {
          origin: "userDocumentsComponent",
        }
      }
    );
  }

  constructor(private router: Router, private sanitizer: DomSanitizer, private userService: UserService, private tokenStorageService: TokenStorageService,
    private businessService: BusinessService, private individualService: IndividualService,
    private clientService: ClientService, private pricingPlanService: PricingPlanService) { }

  ngOnInit(): void {
    this.pageTitle = "Profile";
    this.displaySessionMessage = false;
    this.messageToBeDisplayed = "";
    this.errorType = "";
    // this.accessRestrictedRoutes();
    // let retrievedUser = this.tokenStorageService.getUser();
    // console.log("retrievedUser.userType:" + retrievedUser);
    let tempUser = this.tokenStorageService.getUser();
    if (history.state.userId) {
      if (tempUser.userId == history.state.userId) {
        this.getUser(tempUser.userId);
      } else {
        alert("Your session has expired. Please login again!");
        this.tokenStorageService.signOut();
        this.router.navigate(['SignIn']);
      }
    } else {
      this.getUser(tempUser.userId);
    }

  }

  toggleDisplayPlanAndPaymentInfo() {
    this.displayPlanAndPaymentInfo = !this.displayPlanAndPaymentInfo;
  }

  toggleAddNewDropdownContentDisplay() {
    this.displayAddNewDropdownContent = !this.displayAddNewDropdownContent;
  }

  showPopup() {
    this.isPopupVisible = true;
  }

  hidePopup() {
    this.isPopupVisible = false;
  }

  getUser(id): void {
    this.userService.get(id)
      .subscribe(
        data => {
          if (environment.debug) {
            console.log(data);
          }

          if (data.status == 403) {
            alert("Your session has expired. Please login again!");
            this.tokenStorageService.signOut();
            this.router.navigate(['SignIn']);
          }

          this.retrievedUser = data;
          this.retrievedUserUsername = data.username;
          this.retrievedUserEmailAddress = data.emailAddress;
          this.retrievedUserContactNumber = data.contactNumber;
          this.retrieveSpecificPricingPlan(this.retrievedUser.pricingPlanID);

          this.allocatedStorageSizeInGB = data.allocatedStorageSizeInGB;
          this.usedStorageSizeInGB = data.usedStorageSizeInGB;
          if (this.allocatedStorageSizeInGB < 1) {
            this.allocatedStorageSizeInMB = this.allocatedStorageSizeInGB * 1000;
            this.allocatedStorageSizeInMB = parseFloat(this.allocatedStorageSizeInMB).toFixed(2);
            this.usedStorageSizeInMB = this.usedStorageSizeInGB * 1000;
            this.usedStorageSizeInMB = parseFloat(this.usedStorageSizeInMB).toFixed(2);
            this.value = ((this.usedStorageSizeInMB / this.allocatedStorageSizeInMB) * 100).toString();
            this.value = this.value + "%";
          }
          else if (this.allocatedStorageSizeInGB >= 1) {
            this.allocatedStorageSizeInGB = parseFloat(this.allocatedStorageSizeInGB).toFixed(2);
            this.usedStorageSizeInGB = parseFloat(this.usedStorageSizeInGB).toFixed(2);
            this.value = ((this.usedStorageSizeInGB / this.allocatedStorageSizeInGB) * 100).toString();
            this.value = this.value + "%";
          }

          if (this.retrievedUser.userType == "business" || this.retrievedUser.userType == "individual") {
            this.corporateUser = true;
          }
          if (environment.debug) {
            console.log("user-dashboard.component.ts this.retrievedUser.userType: " + this.retrievedUser.userType);
          }
          // if(this.retrievedUser.userType == "lawFirm") {
          //   this.userType = "Law Firm";
          //   this.corporateInstitutionUser = true;
          //   this.firstSectionABlockTitle = "Managing Partner’s Name";
          //   this.secondSectionABlockTitle = "Name of Firm";
          //   this.secondSectionBBlockTitle = "Law Firm Registration Number";
          //   this.secondSectionCBlockTitle = "Bar Council Registration No.";
          //   this.secondSectionDBlockTitle = "Office Address";
          //   this.getLawFirm(this.retrievedUser.lawFirmID, this.retrievedUser.id, this.retrievedUser.usedStorageSizeInGB, this.retrievedUser.allocatedStorageSizeInGB);

          //   // retrieve user's lawFirmID
          //   // retrieve clientIDs based on lawFirmID
          //   // retrieve userIDs based on clientIDs
          //   // retrieve user's allocatedStorageSizeInGB for each userID and return sum of all allocatedStorageSizeInGB
          //   // parse value to float with 0 decimal places

          // }
          // else 
          if (this.retrievedUser.userType == "business") {
            this.userType = "Business";
            this.corporateInstitutionUser = true;
            this.firstSectionABlockTitle = "Company Registration Number";
            this.secondSectionABlockTitle = "Name of Company";
            this.secondSectionBBlockTitle = "Business Address";
            this.secondSectionCBlockTitle = "";
            this.secondSectionDBlockTitle = "Registered Address";
            this.getBusiness(this.retrievedUser.businessID);

            // retrieve user's businessID
            // retrieve clientIDs based on businessID
            // retrieve userIDs based on clientIDs
            // retrieve user's allocatedStorageSizeInGB for each userID and return sum of all allocatedStorageSizeInGB
            // parse value to float with 0 decimal places  
            this.viewAllClients(this.retrievedUser.id, this.retrievedUser.usedStorageSizeInGB, this.retrievedUser.allocatedStorageSizeInGB);
          }
          else if (this.retrievedUser.userType == "individual") {
            this.userType = "Individual";
            this.firstSectionABlockTitle = "Full Name";
            this.secondSectionABlockTitle = "ID Type";
            this.secondSectionBBlockTitle = "ID Number";
            this.secondSectionCBlockTitle = "";
            this.secondSectionDBlockTitle = "Address";
            this.getIndividual(this.retrievedUser.individualID);
            this.storageBalanceInGB = parseFloat(this.retrievedUser.allocatedStorageSizeInGB) - parseFloat(this.retrievedUser.usedStorageSizeInGB);
            let individualAllocatedStorageSizeInGB = this.retrievedUser.allocatedStorageSizeInGB;
            let individualAllocatedStorageSizeInMB = this.retrievedUser.allocatedStorageSizeInGB * 1000;


            if (this.allocatedStorageSizeInGB < 1) {
              this.finalSumOfCorporateInstitutionUsageInMB = this.retrievedUser.usedStorageSizeInGB * 1000;
              this.finalSumOfCorporateInstitutionUsageInMB = parseFloat(this.finalSumOfCorporateInstitutionUsageInMB).toFixed(2);
              this.storageBalanceInMB = this.storageBalanceInGB * 1000;
              this.storageBalanceInMB = parseFloat(this.storageBalanceInMB).toFixed(2);
              this.corporateInstitutionStorageValue = ((this.finalSumOfCorporateInstitutionUsageInMB / individualAllocatedStorageSizeInMB) * 100).toString();
              this.corporateInstitutionStorageValueInPercentage = this.corporateInstitutionStorageValue + "%";

            }
            else if (this.allocatedStorageSizeInGB >= 1) {
              this.finalSumOfCorporateInstitutionUsageInGB = parseFloat(this.retrievedUser.usedStorageSizeInGB).toFixed(2);
              this.storageBalanceInGB = parseFloat(this.storageBalanceInGB).toFixed(2);
              this.corporateInstitutionStorageValue = ((this.finalSumOfCorporateInstitutionUsageInGB / individualAllocatedStorageSizeInGB) * 100).toString();
              this.corporateInstitutionStorageValueInPercentage = this.corporateInstitutionStorageValue + "%";
            }

          }
          else if (this.retrievedUser.userType == "client") {
            this.firstSectionABlockTitle = "Full Name";
            this.firstSectionEBlockTitle = "Date of Birth"
            this.secondSectionABlockTitle = "Legal Representation";
            this.secondSectionBBlockTitle = "Case Information";
            this.secondSectionCBlockTitle = "";
            this.secondSectionDBlockTitle = "Address";
            this.getClient(this.retrievedUser.clientID);
          }

        },
        error => {
          console.error(error);
        });
  }

  retrieveSpecificPricingPlan(pricingPlanId): void {
    this.pricingPlanService.get(pricingPlanId)
      .subscribe(
        data => {
          this.retrievedPricingPlan = data;

          var words = this.retrievedPricingPlan.name.split(' ');
          let lastWordInString = words[words.length - 1];
          this.pricingPlanName = lastWordInString;

          this.pricingPlanStorageSizeInGB = this.retrievedPricingPlan.storageSizeInGB;
          this.pricingPlanMonthlyAmount = parseFloat(this.retrievedPricingPlan.monthlyAmount).toFixed(2);
        },
        error => {
          console.error(error);
        });
  }

  viewAllClients(userId, userUsedStorageSizeInGB, userAllocatedStorageSizeInGB) {

    this.userService.viewAllClients(
      userId,
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:" + event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.message:" + event.body.message);
          }
          this.message = event.body.message;
          let clientArray = event.body.clientArray;
          if (environment.debug) {
            console.log("clientArray: " + clientArray);
          }
          let sumOfClientStorage = 0.00;
          if (environment.debug) {
            console.log("sumOfClientStorageBeforeLoop: " + sumOfClientStorage);
          }
          for (let i = 0; i < clientArray.length; i++) {
            if (environment.debug) {
              console.log("entered loop");
              console.log(clientArray[i]);
            }
            sumOfClientStorage = sumOfClientStorage + parseFloat(clientArray[i].allocatedStorageSizeInGB);
            if (environment.debug) {
              console.log("sumOfClientStorageAfterLoop " + i + ":" + sumOfClientStorage);
            }
          }
          if (environment.debug) {
            console.log("sumOfClientStorageAfterLoop: " + sumOfClientStorage);
          }
          let userAllocatedStorageSizeInMB = userAllocatedStorageSizeInGB * 1000;

          this.finalSumOfClientStorageInGB = sumOfClientStorage;
          this.finalSumOfCorporateInstitutionUsageInGB = parseFloat(userUsedStorageSizeInGB) - parseFloat(this.finalSumOfClientStorageInGB);
          this.storageBalanceInGB = userAllocatedStorageSizeInGB - this.finalSumOfCorporateInstitutionUsageInGB - this.finalSumOfClientStorageInGB;

          if (userAllocatedStorageSizeInGB < 1) { // start if statement
            this.finalSumOfCorporateInstitutionUsageInMB = this.finalSumOfCorporateInstitutionUsageInGB * 1000;
            this.finalSumOfCorporateInstitutionUsageInMB = parseFloat(this.finalSumOfCorporateInstitutionUsageInMB).toFixed(2);
            this.corporateInstitutionStorageValue = ((this.finalSumOfCorporateInstitutionUsageInMB / userAllocatedStorageSizeInMB) * 100).toString();
            this.corporateInstitutionStorageValueInPercentage = this.corporateInstitutionStorageValue + "%";

            this.finalSumOfClientStorageInMB = this.finalSumOfClientStorageInGB * 1000;
            this.finalSumOfClientStorageInMB = parseFloat(this.finalSumOfClientStorageInMB).toFixed(2);
            this.clientStorageValue = ((this.finalSumOfClientStorageInMB / userAllocatedStorageSizeInMB) * 100).toString();
            this.clientStorageValueInPercentage = this.clientStorageValue + "%";

            this.storageBalanceInMB = this.storageBalanceInGB * 1000;
            this.storageBalanceInMB = parseFloat(this.storageBalanceInMB).toFixed(2);
          } // end if statement
          else if (userAllocatedStorageSizeInGB >= 1) { // start else if statement
            this.finalSumOfCorporateInstitutionUsageInGB = parseFloat(this.finalSumOfCorporateInstitutionUsageInGB).toFixed(2);
            this.corporateInstitutionStorageValue = ((this.finalSumOfCorporateInstitutionUsageInGB / userAllocatedStorageSizeInGB) * 100).toString();
            this.corporateInstitutionStorageValueInPercentage = this.corporateInstitutionStorageValue + "%";

            this.finalSumOfClientStorageInGB = parseFloat(this.finalSumOfClientStorageInGB).toFixed(2);
            this.clientStorageValue = ((this.finalSumOfClientStorageInGB / userAllocatedStorageSizeInGB) * 100).toString();
            this.clientStorageValueInPercentage = this.clientStorageValue + "%";

            this.storageBalanceInGB = parseFloat(this.storageBalanceInGB).toFixed(2);
          } // end else if statement

          //  console.log("clientArray[i]: " + Object.keys(this.clientArray[0]));

          // return event.body.message;
        }
        else {
          if (environment.debug) {
            console.log("none from User Dashboard Component");
          }
          let userAllocatedStorageSizeInMB = userAllocatedStorageSizeInGB * 1000;

          this.finalSumOfClientStorageInGB = 0;
          this.finalSumOfCorporateInstitutionUsageInGB = parseFloat(userUsedStorageSizeInGB) - parseFloat(this.finalSumOfClientStorageInGB);
          this.storageBalanceInGB = userAllocatedStorageSizeInGB - this.finalSumOfCorporateInstitutionUsageInGB - this.finalSumOfClientStorageInGB;

          if (userAllocatedStorageSizeInGB < 1) { // start if statement
            this.finalSumOfCorporateInstitutionUsageInMB = this.finalSumOfCorporateInstitutionUsageInGB * 1000;
            this.finalSumOfCorporateInstitutionUsageInMB = parseFloat(this.finalSumOfCorporateInstitutionUsageInMB).toFixed(2);
            this.corporateInstitutionStorageValue = ((this.finalSumOfCorporateInstitutionUsageInMB / userAllocatedStorageSizeInMB) * 100).toString();
            this.corporateInstitutionStorageValueInPercentage = this.corporateInstitutionStorageValue + "%";

            this.finalSumOfClientStorageInMB = this.finalSumOfClientStorageInGB * 1000;
            this.finalSumOfClientStorageInMB = parseFloat(this.finalSumOfClientStorageInMB).toFixed(2);
            this.clientStorageValue = ((this.finalSumOfClientStorageInMB / userAllocatedStorageSizeInMB) * 100).toString();
            this.clientStorageValueInPercentage = this.clientStorageValue + "%";

            this.storageBalanceInMB = this.storageBalanceInGB * 1000;
            this.storageBalanceInMB = parseFloat(this.storageBalanceInMB).toFixed(2);
          } // end if statement
          else if (userAllocatedStorageSizeInGB >= 1) { // start else if statement
            this.finalSumOfCorporateInstitutionUsageInGB = parseFloat(this.finalSumOfCorporateInstitutionUsageInGB).toFixed(2);
            this.corporateInstitutionStorageValue = ((this.finalSumOfCorporateInstitutionUsageInGB / userAllocatedStorageSizeInGB) * 100).toString();
            this.corporateInstitutionStorageValueInPercentage = this.corporateInstitutionStorageValue + "%";

            this.finalSumOfClientStorageInGB = parseFloat(this.finalSumOfClientStorageInGB).toFixed(2);
            this.clientStorageValue = ((this.finalSumOfClientStorageInGB / userAllocatedStorageSizeInGB) * 100).toString();
            this.clientStorageValueInPercentage = this.clientStorageValue + "%";

            this.storageBalanceInGB = parseFloat(this.storageBalanceInGB).toFixed(2);
          } // end else if statement


        }
      },
      err => {
        // this.progress = 0;
        this.message = 'Could not create law firm user!';
        // alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  // getLawFirm(lawFirmId, retrievedUserId, retrievedUserUsedStorageSizeInGB, retrievedUserAllocatedStorageSizeInGB): void {
  //   this.lawFirmService.get(lawFirmId)
  //     .subscribe(
  //       data => {
  //         console.log(data);
  //         let retrievedLawFirm = data;
  //         this.firstSectionABlockValue = retrievedLawFirm.managingPartnerName;
  //         this.secondSectionABlockValue = retrievedLawFirm.firmName;
  //         this.secondSectionBBlockValue = retrievedLawFirm.lawFirmRegistrationNumber;
  //         this.secondSectionCBlockValue = retrievedLawFirm.barCouncilRegistrationNumber;
  //         // officeAddress.length > 50? next step : secondSectionD1BlockValue = officeAddress
  //         // keep an originalCopy of the officeAddress, make a duplicateCopy of the officeAddress
  //         // split the duplicateCopy into half
  //         // identify the position of lastIndexOf " ", i.e. space in the first half of duplicateCopy 
  //         // split the originalCopy into the substring(0, identifiedSpacePosition); = secondSectionD1BlockValue
  //         // split the originalCopy into the substring(identifiedSpacePosition + 1, originalCopy.length) = secondSectionD2BlockValue
  //         let rawLawFirmAddress = retrievedLawFirm.officeAddress;
  //         if(rawLawFirmAddress.length <= 50) {
  //           this.secondSectionD1BlockValue = rawLawFirmAddress;
  //         }
  //         else if(rawLawFirmAddress.length > 50) {
  //           let originalCopyLawFirmAddress = rawLawFirmAddress;
  //           let duplicateCopyLawFirmAddress = rawLawFirmAddress;
  //           let duplicateCopyFirstHalf = duplicateCopyLawFirmAddress.substring(0, (duplicateCopyLawFirmAddress.length/2));
  //           let identifiedSpacePosition = duplicateCopyFirstHalf.lastIndexOf(" ");
  //           this.secondSectionD1BlockValue = originalCopyLawFirmAddress.substring(0, identifiedSpacePosition);
  //           this.secondSectionD2BlockValue = originalCopyLawFirmAddress.substring(identifiedSpacePosition + 1, originalCopyLawFirmAddress.length);
  //         }

  //         this.viewAllClients(retrievedUserId, retrievedUserUsedStorageSizeInGB, retrievedUserAllocatedStorageSizeInGB);

  //       },
  //       error => {
  //         console.log(error);
  //       });
  // }

  getIndividual(individualId): void {
    this.individualService.get(individualId)
      .subscribe(
        data => {
          if (environment.debug) {
            console.log(data);
          }
          let retrievedIndividual = data;
          this.firstSectionABlockValue = retrievedIndividual.fullName;
          this.secondSectionABlockValue = retrievedIndividual.idType;
          this.secondSectionBBlockValue = retrievedIndividual.idNumber;
          this.secondSectionCBlockValue = "";
          // officeAddress.length > 50? next step : secondSectionD1BlockValue = officeAddress
          // keep an originalCopy of the officeAddress, make a duplicateCopy of the officeAddress
          // split the duplicateCopy into half
          // identify the position of lastIndexOf " ", i.e. space in the first half of duplicateCopy 
          // split the originalCopy into the substring(0, identifiedSpacePosition); = secondSectionD1BlockValue
          // split the originalCopy into the substring(identifiedSpacePosition + 1, originalCopy.length) = secondSectionD2BlockValue
          let rawIndividualAddress = retrievedIndividual.address;
          if (rawIndividualAddress != null) {
            if (rawIndividualAddress.length <= 50) {
              this.secondSectionD1BlockValue = rawIndividualAddress;
            } else if (rawIndividualAddress.length > 50) {
              let originalCopyIndividualAddress = rawIndividualAddress;
              let duplicateCopyIndividualAddress = rawIndividualAddress;
              let duplicateCopyFirstHalf = duplicateCopyIndividualAddress.substring(0, (duplicateCopyIndividualAddress.length / 2));
              let identifiedSpacePosition = duplicateCopyFirstHalf.lastIndexOf(" ");
              this.secondSectionD1BlockValue = originalCopyIndividualAddress.substring(0, identifiedSpacePosition);
              this.secondSectionD2BlockValue = originalCopyIndividualAddress.substring(identifiedSpacePosition + 1, originalCopyIndividualAddress.length);
            }
          }

        },
        error => {
          console.error(error);
        });
  }

  getClient(clientId): void {
    this.clientService.get(clientId)
      .subscribe(
        data => {
          if (environment.debug) {
            console.log(data);
          }
          let retrievedClient = data;
          this.firstSectionABlockValue = retrievedClient.firstName + retrievedClient.lastName;
          this.firstSectionEBlockValue = retrievedClient.dateOfBirth;
          this.secondSectionABlockValue = retrievedClient.legalRepresentation != "null" ? retrievedClient.legalRepresentation : "";
          this.secondSectionBBlockValue = retrievedClient.caseInformation != "null" ? retrievedClient.caseInformation : "";
          this.secondSectionCBlockValue = "";
          // officeAddress.length > 50? next step : secondSectionD1BlockValue = officeAddress
          // keep an originalCopy of the officeAddress, make a duplicateCopy of the officeAddress
          // split the duplicateCopy into half
          // identify the position of lastIndexOf " ", i.e. space in the first half of duplicateCopy 
          // split the originalCopy into the substring(0, identifiedSpacePosition); = secondSectionD1BlockValue
          // split the originalCopy into the substring(identifiedSpacePosition + 1, originalCopy.length) = secondSectionD2BlockValue
          let rawClientAddress = retrievedClient.address;
          if (rawClientAddress != null) {
            if (rawClientAddress.length <= 50) {
              this.secondSectionD1BlockValue = rawClientAddress;
            }
            else if (rawClientAddress.length > 50) {
              let originalCopyClientAddress = rawClientAddress;
              let duplicateCopyClientAddress = rawClientAddress;
              let duplicateCopyFirstHalf = duplicateCopyClientAddress.substring(0, (duplicateCopyClientAddress.length / 2));
              let identifiedSpacePosition = duplicateCopyFirstHalf.lastIndexOf(" ");
              this.secondSectionD1BlockValue = originalCopyClientAddress.substring(0, identifiedSpacePosition);
              this.secondSectionD2BlockValue = originalCopyClientAddress.substring(identifiedSpacePosition + 1, originalCopyClientAddress.length);
            }
          }

        },
        error => {
          console.error(error);
        });
  }

  getBusiness(businessId): void {
    this.businessService.get(businessId)
      .subscribe(
        data => {
          if (environment.debug) {
            console.log(data);
          }
          let retrievedBusiness = data;
          this.firstSectionABlockValue = retrievedBusiness.companyRegistrationNumber;
          this.secondSectionABlockValue = retrievedBusiness.companyName;
          // split the originalCopy into the substring(identifiedSpacePosition + 1, originalCopy.length) = secondSectionD2BlockValue
          let rawBusinessAddress = retrievedBusiness.businessAddress;

          if (rawBusinessAddress.length <= 50) {
            this.secondSectionBBlockValue = rawBusinessAddress;
          }
          else if (rawBusinessAddress.length > 50) {
            let originalCopyBusinessAddress = rawBusinessAddress;
            let duplicateCopyBusinessAddress = rawBusinessAddress;
            let duplicateCopyFirstHalf = duplicateCopyBusinessAddress.substring(0, (duplicateCopyBusinessAddress.length / 2));
            let identifiedSpacePosition = duplicateCopyFirstHalf.lastIndexOf(" ");
            this.secondSectionBBlockValue = originalCopyBusinessAddress.substring(0, identifiedSpacePosition);
            this.secondSectionCBlockValue = originalCopyBusinessAddress.substring(identifiedSpacePosition + 1, originalCopyBusinessAddress.length);
          }
          // this.secondSectionBBlockValue = retrievedBusiness.lawFirmRegistrationNumber;
          // this.secondSectionCBlockValue = retrievedLawFirm.barCouncilRegistrationNumber;
          // officeAddress.length > 50? next step : secondSectionD1BlockValue = officeAddress
          // keep an originalCopy of the officeAddress, make a duplicateCopy of the officeAddress
          // split the duplicateCopy into half
          // identify the position of lastIndexOf " ", i.e. space in the first half of duplicateCopy 
          // split the originalCopy into the substring(0, identifiedSpacePosition); = secondSectionD1BlockValue
          // split the originalCopy into the substring(identifiedSpacePosition + 1, originalCopy.length) = secondSectionD2BlockValue
          let rawBusinessRegisteredAddress = retrievedBusiness.registeredAddress;
          if (rawBusinessRegisteredAddress != null) {
            if (rawBusinessRegisteredAddress.length <= 50) {
              this.secondSectionD1BlockValue = rawBusinessRegisteredAddress;
            }
            else if (rawBusinessRegisteredAddress.length > 50) {
              let originalCopyBusinessRegisteredAddress = rawBusinessRegisteredAddress;
              let duplicateCopyBusinessRegisteredAddress = rawBusinessRegisteredAddress;
              let duplicateCopyFirstHalf = duplicateCopyBusinessRegisteredAddress.substring(0, (duplicateCopyBusinessRegisteredAddress.length / 2));
              let identifiedSpacePosition = duplicateCopyFirstHalf.lastIndexOf(" ");
              this.secondSectionD1BlockValue = originalCopyBusinessRegisteredAddress.substring(0, identifiedSpacePosition);
              this.secondSectionD2BlockValue = originalCopyBusinessRegisteredAddress.substring(identifiedSpacePosition + 1, originalCopyBusinessRegisteredAddress.length);
            }
          }

        },
        error => {
          console.error(error);
        });
  }

}
