import { Component, OnInit, HostListener, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Input() isBigScreen: boolean = false;
  @Input() showFooter;

  ngOnChanges(changes) {
    if (changes['isBigScreen']) {
      if (environment.debug) {
      console.log("Detecting Change from Child Component (Big Screen): " + this.isBigScreen);
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

}
