import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { HttpResponse } from '@angular/common/http';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {
  userId: any;
  userUuid: any;

  username: String;
  errorMessage: String;
  displayEmailSent: boolean;
  displayBackendErrorMessage: boolean;
  verifiedEmail = false;
  checkingVerify = true;
  retrievedEmail: String;
  hisUser = history.state.username;
  hisEmail = history.state.emailAddress;
  hisUserID = history.state.userId;
  hisUserUUID = history.state.userUuid;

  emailTitleText: String;
  emailContextImage: String;

  displayProceedButton: boolean;
  displaySessionMessage = false;
  messageToBeDisplayed = "";
  errorType = "";
  



  constructor(private router: Router, private _activatedRoute: ActivatedRoute,
    private userService: UserService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    this.displaySessionMessage = false;
    this.messageToBeDisplayed = "";
    this.errorType = "";
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
    this.displayBackendErrorMessage = false;

    if (!history.state.isClient) {
      const user = this._activatedRoute.snapshot.queryParamMap.get('username');
      const authToken = this._activatedRoute.snapshot.queryParamMap.get('authToken');
      if (environment.debug) {
        console.log("History items: 1. " + this.hisUser + " 2. " + this.hisEmail + " 3. " + this.hisUserID + " 4. " + this.hisUserUUID);
      }
      if (user == null && authToken == null) {
        if (this.hisUser != null && this.hisEmail !== null) {
          if (this.hisUserID != null && this.hisUserUUID !== null) {
            this.retrievedEmail = this.hisEmail;
            this.emailTitleText = "Verify Your Email";
            this.emailContextImage = "/assets/revamp/verifyEmailIconNew.png";
            this.checkingVerify = false;
            this.sendActivationEmail(this.hisUser, this.hisEmail, this.hisUserID, this.hisUserUUID);
          } else {
            this.displaySessionMessage = true;
            this.messageToBeDisplayed = "Invalid state! Redirecting to Sign In  ...";
            this.errorType = "genericError";
            setTimeout(() => {
              this.router.navigate(['SignIn']);
            }, 5000);
            
          }
        } else {
          this.displaySessionMessage = true;
          this.messageToBeDisplayed = "Invalid state! Redirecting to Sign In  ...";
          this.errorType = "genericError";
          setTimeout(() => {
            this.router.navigate(['SignIn']);
          }, 5000);
          
        }

      } else {

        this.userService.activateAccount(user, authToken)
          .subscribe({
            next: async (event) => {
              if (event instanceof HttpResponse) {
                if (environment.debug) {
                  console.log("event:", event);
                  console.log("Object.keys(event.body):" + Object.keys(event.body));
                  console.log("event.body.message:" + event.body.message);
                }
                if (event.body.status == 500 || event.body.status == 404 || event.body.status == 555) {
                  this.errorMessage = event.body.error.message;
                  this.displayBackendErrorMessage = true;
                } else {
                  this.verifiedEmail = true;
                  this.emailTitleText = "Your Email is Verified";
                  this.emailContextImage = "/assets/scratchImg/verifyEmailSuccess.jpg";
                  this.checkingVerify = false;
                  this.tokenStorageService.saveToken(event.body.jwtToken);
                  if (environment.debug) {
                    console.log("JWT: " + event.body.jwtToken);
                  }
                  this.tokenStorageService.saveUser(event.body.userItem);
                  await delay(5000);
                  this.userId = event.body.userItem.userId;
                  this.userUuid = event.body.userUuid;
                  this.router.navigate(['SelectPlan'], {
                    state:
                    {
                      userId: event.body.userItem.userId,
                      userUuid: event.body.userUuid,
                    }
                  });
                }
              }
            },
            error: (err) => {
              if (err.status == 404) {

              }
              console.error(err);
              this.errorMessage = err.message;
              this.displayBackendErrorMessage = true;
            }
          });

      }
    } else {
      // const email = this._activatedRoute.snapshot.queryParamMap.get('email');
      // console.log("History items: 1. " + this.hisUser + " 2. " + this.hisEmail + " 3. " + this.hisUserID + " 4. " + this.hisUserUUID);

      this.userService.activateAccount(this.hisUser, history.state.authToken)
        .subscribe({
          next: async (event) => {
            if (event instanceof HttpResponse) {
              if (event.body.status == 500 || event.body.status == 404 || event.body.status == 555) {
                this.errorMessage = event.body.message;
                this.displayBackendErrorMessage = true;
              } else {
                this.verifiedEmail = true;
                this.emailTitleText = "Your Email is Verified";
                this.emailContextImage = "/assets/scratchImg/verifyEmailSuccess.jpg";
                this.checkingVerify = false;
                this.tokenStorageService.saveToken(event.body.jwtToken);
                if (environment.debug) {
                  console.log("JWT: " + event.body.jwtToken);
                }
                this.tokenStorageService.saveUser(event.body.userItem);
                await delay(5000);
                this.userId = event.body.userId;
                this.userUuid = event.body.userUuid;
                this.router.navigate(['SelectPlan'], {
                  state:
                  {
                    userId: event.body.userId,
                    userUuid: event.body.userUuid,
                  }
                });
              }
            }
          },
          error: (err) => {
            this.errorMessage = err.error.message;
            this.displayBackendErrorMessage = true;
          }
        });
    }


  }

  sendActivationEmail(user, email, uid, uuid) {
    this.userService.sendActivationEmail(user, email, uid, uuid).subscribe({
      next: (event) => {
        if (event instanceof HttpResponse) {
          if (event.body.status == 500) {
            this.errorMessage = event.body.message;
            this.displayBackendErrorMessage = true;
          }
        }
      },
      error: (err) => {
        this.errorMessage = err.error.message;
        this.displayBackendErrorMessage = true;
      }
    })
  }


  redirectToSelectPlan() {
    this.router.navigate(['SelectPlan'],
      {
        state:
        {
          userId: this.userId,
          userUuid: this.userUuid,
        }
      }
    );
  }

}
