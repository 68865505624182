<link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>
<link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet'>

<!-- // Start servicesSection1 -->
<div class="servicesSection1">
    <h1>Protecting Your Critical Information</h1>
    <h4>Our services include secure Blockchain Storage, advanced security measures, data management,
       technical support, backup and disaster recovery, encryption at rest and in transit.</h4>
    <button class="button button1" routerLink="">Get Started</button>
</div>
<!-- // End servicesSection1 -->

<!-- // Start servicesSection2 -->
<div class="servicesSection2">
    <h1>Why LegalBox?</h1>
    <h4>With our blockchain enabled document storage service, you can be rest assured that your <br>
       confidential information is stored safely and always within reach. Say goodbye to the<br>
        hassle of manually organizing and storing documents.</h4>
        <div class="servicesSection2Container">

          <div class="cardItem cardFirstItem">
            <img src="/assets/scratchImg/homeSection2Card1Img.svg" class="cardItemImage">
            <h2>Backup & Recovery</h2>
            <p>Ensuring the implementation of a<br>
              comprehensive backup and<br>
              disaster recovery solution to<br>
              safeguard against data loss during<br>
              unexpected outages or incidents.</p>
          </div>

          <div class="cardItem">
            <img src="/assets/scratchImg/homeSection2Card2Img.svg" class="cardItemImage">
            <h2>Storage</h2>
            <p>Secure and transparent<br>
              solution for storing important<br>
              documents, with the added<br>
              benefits of decentralization<br>
              ensuring it is immutable.</p>
          </div>

          <div class="cardItem">
            <img src="/assets/scratchImg/homeSection2Card3Img.svg" class="cardItemImage">
            <h2>Data Management</h2>
            <p>Empowering you to<br>
              effortlessly manage and<br>
              access your data through a<br>
              user-friendly interface and<br>
              collaborative tools,<br>
              anytime, anywhere.</p>
          </div>

        </div>
</div>
<!-- // End servicesSection2 -->

<!-- // Start servicesSection3 -->
<div class="servicesSection3">
  <h1>Get started with LEGALBOX today</h1>
  <!-- <p>Get 7 days of full access to all toolkits. Cancel anytime.</p> -->
  <button class="button button3">Get Started</button>
</div>
<!-- // End servicesSection3 -->