import { Component, OnInit, HostBinding, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { PricingPlanService } from 'src/app/services/pricing-plan.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '100%',
        opacity: 1,
      })),
      state('closed', style({
        height: 0,
        opacity: 0,
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('1s')
      ]),
    ]),
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  plusIconPath = "/assets/scratchImg/homeSection4PlusIcon.svg";
  minusIconPath = "/assets/scratchImg/homeSection4MinusIcon.svg";
  showCorporatePricingPlan: boolean = true;
  showIndividualPricingPlan: boolean = false;

  faqItem1IconPath = this.plusIconPath;
  faqItem2IconPath = this.plusIconPath;
  faqItem3IconPath = this.plusIconPath;
  faqItem4IconPath = this.plusIconPath;
  faqItem5IconPath = this.plusIconPath;
  faqItem6IconPath = this.plusIconPath;

  headerLinksMap = new Map();
  showFaqItem1 = false;
  showFaqItem2 = false;
  showFaqItem3 = false;
  showFaqItem4 = false;
  showFaqItem5 = false;
  showFaqItem6 = false;
  scaleValue;

  pricingPlans: any;
  corporateBasicPlanPrice: any;
  corporateAdvancedPlanPrice: any;
  corporatePremiumPlanPrice: any;
  individualBasicPlanPrice: any;
  individualAdvancedPlanPrice: any;
  individualPremiumPlanPrice: any;
  freePlanPrice: any;
  corporateBasicPlanStorageSizeInGB: any;
  corporateAdvancedPlanStorageSizeInGB: any;
  corporatePremiumPlanStorageSizeInGB: any;
  individualBasicPlanStorageSizeInGB: any;
  individualAdvancedPlanStorageSizeInGB: any;
  individualPremiumPlanStorageSizeInGB: any;
  freePlanStorageSizeInGB: any;
  freePlanStorageSizeInMB: any;
  corporateBasicPlanId: any;
  freePlanId: any;
  corporateAdvancedPlanId: any;
  corporatePremiumPlanId: any;
  individualBasicPlanId: any;
  individualAdvancedPlanId: any;
  individualPremiumPlanId: any;
  addShadow: boolean = false;

  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    console.log("isSmallScreen from Home Component: " + this.isSmallScreen);
  }

  @HostBinding("attr.style")
  public get valueAsStyle(): any {
    if (window.devicePixelRatio !== 1) {
      this.scaleValue = (1 / window.devicePixelRatio);
    }
    else {
      this.scaleValue = 1;
    }
    return this.sanitizer.bypassSecurityTrustStyle(`--percentage: ${this.scaleValue}`);
  }


  constructor(private sanitizer: DomSanitizer, private pricingPlanService: PricingPlanService, private router: Router) {

  }

  ngOnInit(): void {
    // console.log("Date.now(): " + Date.now());
    this.retrievePricingPlans();
    this.headerLinksMap.set("showFaqItem1", false);
    this.headerLinksMap.set("showFaqItem2", false);
    this.headerLinksMap.set("showFaqItem3", false);
    this.headerLinksMap.set("showFaqItem4", false);
    this.headerLinksMap.set("showFaqItem5", false);
    this.headerLinksMap.set("showFaqItem6", false);
  }

  retrievePricingPlans(): void {
    this.pricingPlanService.getAll()
      .subscribe(
        data => {
          this.pricingPlans = data;
          if (environment.debug) {
            console.log(data);
          }


          for (let i = 0; i < this.pricingPlans.length; i++) {
            if (this.pricingPlans[i].name == "Corporate Basic") {
              this.corporateBasicPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.corporateBasicPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.corporateBasicPlanId = this.pricingPlans[i].id;
            }
            if (this.pricingPlans[i].name == "Corporate Advanced") {
              this.corporateAdvancedPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.corporateAdvancedPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.corporateAdvancedPlanId = this.pricingPlans[i].id;
            }
            if (this.pricingPlans[i].name == "Corporate Premium") {
              this.corporatePremiumPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.corporatePremiumPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.corporatePremiumPlanId = this.pricingPlans[i].id;
            }
            if (this.pricingPlans[i].name == "Individual Basic") {
              this.individualBasicPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.individualBasicPlanPrice = parseFloat(this.individualBasicPlanPrice).toFixed(2);
              this.individualBasicPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.individualBasicPlanId = this.pricingPlans[i].id;
            }
            if (this.pricingPlans[i].name == "Individual Advanced") {
              this.individualAdvancedPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.individualAdvancedPlanPrice = parseFloat(this.individualAdvancedPlanPrice).toFixed(2);
              this.individualAdvancedPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.individualAdvancedPlanId = this.pricingPlans[i].id;
            }
            if (this.pricingPlans[i].name == "Individual Premium") {
              this.individualPremiumPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.individualPremiumPlanPrice = parseFloat(this.individualPremiumPlanPrice).toFixed(2);
              this.individualPremiumPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.individualPremiumPlanId = this.pricingPlans[i].id;
            }
            if (this.pricingPlans[i].name == "Free") {
              this.freePlanPrice = this.pricingPlans[i].monthlyAmount;
              this.freePlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.freePlanStorageSizeInMB = parseFloat(this.freePlanStorageSizeInGB) * 1000;
              this.freePlanId = this.pricingPlans[i].id;
            }
          }
        },
        error => {
          console.log(error);
        });
  }

  redirectToSignUpSelection() {
    this.router.navigate(['SignUpSelection']);
  }

  displayCorporatePricingPlan() {
    this.showCorporatePricingPlan = true;
    this.showIndividualPricingPlan = false;
  }

  displayIndividualPricingPlan() {
    this.showCorporatePricingPlan = false;
    this.showIndividualPricingPlan = true;
  }

  getHeaderLinkValueFromKey(headerLinkKey) {
    return this.headerLinksMap.get(headerLinkKey);
  }

  makeSpecificRouterLinkActive(headerLinkKey) {
    for (let key of this.headerLinksMap.keys()) {
      if (key == headerLinkKey) {
        this.headerLinksMap.set(key, !this.headerLinksMap.get(headerLinkKey));
      }
      else {
        this.headerLinksMap.set(key, false);
      }
    }
  }

}
