import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-up-selection',
  templateUrl: './sign-up-selection.component.html',
  styleUrls: ['./sign-up-selection.component.css']
})
export class SignUpSelectionComponent implements OnInit {

  constructor(private router: Router) { }

  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    if (environment.debug) {
      console.log("isSmallScreen from Home Component: " + this.isSmallScreen);
    }  
  }

  ngOnInit(): void {
  }

  lawFirmSignUp() {
    this.router.navigate(['LawFirmSignUp']);
  }

  businessSignUp() {
    this.router.navigate(['SignUpSteps'], {
      state: {
        origin: 'BusinessSignUp'
      }
    });
  }

  individualSignUp() {
    this.router.navigate(['SignUpSteps'], {
      state: {
        origin: 'IndividualSignUp'
      }
    });
  }

}
