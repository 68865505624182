<section class="pb-5 mt-5 mx-5 my-5 pt-5">
  <div class="container pb-5 mt-5 my-5">
    <!-- <div class="alert alert-danger" role="alert" [style.display]="displayBackendErrorMessage? 'block':'none'">
      {{errorMessage}}</div> -->

    <div class="row d-flex justify-content-center mt-5 pb-5">
      <div class="col-lg-6 col-sm-12" [style.display]="inPaymentProcess? 'block':'none'">
        <div class="row d-flex justify-content-center">
          <img class="img-fluid w-25" src="/assets/scratchImg/loadingGifIcon.gif" alt="Checking">
        </div>
        <div class="p-3">
          <h2 class="d-flex fw-bold justify-content-center text-center">
            Redirecting to payment page. <br> Do not refresh.
          </h2>
        </div>
      </div>
    </div>  
      <!-- Verify Your Email Content -->
    <div class="row d-flex justify-content-center">
      <div class="col-lg-4 col-sm-12" [style.display]="inPaymentProcess? 'none':'block'">
        <!-- <div class="row d-flex justify-content-center">
          <img class="img-fluid w-50" src={{emailContextImage}} alt="Verify Email">
        </div>
        <div class="p-3">
          <h2 class="d-flex fw-bold justify-content-center text-center">
            {{emailTitleText}}
          </h2>
          <p class="lead text-center" [style.display]="verifiedEmail? 'none':'block'">
            A verification email is sent to {{retrievedEmail}}. <br> Click the link in the email to verify.
          </p>
          <p class="lead text-center" [style.display]="verifiedEmail? 'block':'none'">
            You'll be redirected to select a pricing plan. <br> If you're not redirected, click <a (click)="redirectToSelectPlan()">here</a>.
          </p>
        </div> -->
      </div>

    </div>
  </div>

</section>