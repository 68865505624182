<link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>
<link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet'>
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9" crossorigin="anonymous">
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js" integrity="sha384-HwwvtgBNo3bZJJLYd8oVXjrBZt8cqVSpeBNS5n7C8IVInixGAoxmnlMuBnhbgrkm" crossorigin="anonymous"></script>


<section>
  <!-- Page items -->
  <div class="container-fluid p-5 pb-5">
    <!-- Backend error alert -->
    <div class="alert alert-danger" role="alert" [style.display]="displayBackendErrorMessage? 'block':'none'">{{errorMessage}}</div>

    <div class="row align-items-center">
      <div class="col-lg-4 col-sm justify-contents-center mx-auto">
        <div class="row d-flex justify-content-center">
          <!-- Image row -->
          <img class="img-fluid w-50" src="/assets/revamp/forgotPasswordIconNew.png" alt="Forgot Password">
        </div>
        <div class="p-0 p-md-3">
          <h2 class="fw-bold d-flex justify-content-center text-center">
            Forgot your password?
          </h2>
          <p class="lead text-center">
            To reset your password, please enter the email address
            you used to sign up at LegalBox.
          </p>
        </div>
        <div class="pb-2">
          <!-- textbox -->
          <input (focus)="onFocus()" type="email" class="form-control w-100" id="forgotPassEmail" placeholder="Email address (john@smith.com)" required [(ngModel)]="forgotPassEmail">
          <p class="lead pt-2 ms-2 text-danger" [ngClass]="displayEmailMissing? 'd-block':'d-none'"> Please enter your email !</p>
          <p class="lead pt-2 ms-2 text-danger" [ngClass]="displayEmailMismatch? 'd-block':'d-none'"> Please enter a valid email address !</p>
        </div>
        <div class="py-3 d-grid gap-2">
          <!-- Email sent modal -->
          <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" [attr.aria-hidden]="!displayModal" [attr.aria-modal]="displayModal" [attr.role]="displayModal? 'dialog':''" [ngClass]="displayModal? 'show showModal':'hideModal'" >
            <div class="modal-dialog  modal-dialog-centered">
              <div class="modal-content border-0">
                <div class="modal-body text-center fw-bold">
                  <div class="pb-3">
                    <img class="img" src="/assets/revamp/successGreenIconNew.png" style="width: 10%;" alt="Info Message">
                  </div>
                  An email has been sent to the email address, {{this.forgotPassEmail}}. <br>
                  Follow the directions in the email to reset your password.
                  <div class="pt-3">
                    <button type="button" data-bs-dismiss="modal" class="btn btn-primary"  (click)="redirectToSignIn()">Back to Login</button>
                  </div>                  
                </div>
              </div>
            </div>
          </div>
          <button type="button" class="btn btn-primary fw-bold btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop" (click)="clickSubmit(this.forgotPassEmail)" [disabled]="displayModal"> Submit </button>
   </div>
        <div class="mb-5 text-center">
          <!-- <p class="lead">
            Please wait 60 seconds before submitting another request.
          </p> -->
          <a class="pt-3" style="color: black;" (click)="redirectToSignIn()">
            Back to login
          </a>
        </div>
      </div>
    </div>
  </div> 
</section>

