import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-navigation-bar',
  templateUrl: './side-navigation-bar.component.html',
  styleUrls: ['./side-navigation-bar.component.scss']
})
export class SideNavigationBarComponent implements OnInit {
  selectedNavBarLink = 'Profile';
  username: any;
  retrievedUser: any;
  retrievedUserUsername: any;
  retrievedUserUserType: any;
  displayLogOutConfirmation = false;
  displayClientTabLink = false;
  displayArchiveTabLink = false;
  displaySharedFilesTabLink = false;
  addShadow = false;
  addShadow2 = false;
  isShowDivIf = false;

  @Input() pageTitle: string;

  toggleDisplayDivIf() {
    this.isShowDivIf = !this.isShowDivIf;
  }

  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    console.log("isSmallScreen from Home Component: " + this.isSmallScreen);
  }

  constructor(private router: Router, private tokenStorageService: TokenStorageService, private userService: UserService) { }

  ngOnInit(): void {
    this.displayArchiveTabLink = false;
    this.displayClientTabLink = false;
    this.displaySharedFilesTabLink = false;
    this.selectedNavBarLink = history.state.selectedNavBarLink;
    let user = this.tokenStorageService.getUser();
    let userId = user.userId;
    this.getUser(userId);
  }

  changeRoute(routeValue) {
    this.isShowDivIf = false;
    this.router.navigate([routeValue]);
    // you have to check this out by passing required route value.
    // this line will redirect you to your destination. By reaching to destination you can close your loader service.
    // please note this implementation may vary according to your routing code.

  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getUser(id): void {
    this.userService.get(id)
      .subscribe(
        data => {
          if (environment.debug) {
            console.log(data);
          }
          if (data.status == 403) {
            alert("Your session has expired. Please login again!");
            this.tokenStorageService.signOut();
            this.router.navigate(['SignIn']);
          }
          this.retrievedUser = data;
          this.retrievedUserUsername = data.username;
          if (data.userType == "business") {
            this.displayClientTabLink = true;
            this.displayArchiveTabLink = true;
          } else if (data.userType == "individual") {
            this.displaySharedFilesTabLink = true;
            this.displayArchiveTabLink = true;
          }
          else if (data.userType == "client") {
            this.displaySharedFilesTabLink = true;
          }
          this.retrievedUserUserType = this.capitalizeFirstLetter(data.userType);
        },
        error => {
          console.error(error);

        });
  }

  // changeSelectedNavBarLink() {

  // }

  showLogOutConfirmation() {
    this.displayLogOutConfirmation = true;
  }

  hideLogOutConfirmation() {
    this.displayLogOutConfirmation = false;
  }

  redirectToUserSideLink(selectedNavBarLink: any) {
    this.isShowDivIf = false;
    this.selectedNavBarLink = selectedNavBarLink;

    if (selectedNavBarLink == "myDocuments") {
      this.router.navigate(['UserDocuments'],
        {
          state:
          {
            selectedNavBarLink: selectedNavBarLink,
          }
        }
      );
    }

    else if (selectedNavBarLink == "UserArchive") {
      this.router.navigate(['UserArchive'],
        {
          state:
          {
            selectedNavBarLink: selectedNavBarLink,
          }
        }
      );
    }

    else if (selectedNavBarLink == "UserDashboard") {
      this.router.navigate(['UserDashboard'],
        {
          state:
          {
            selectedNavBarLink: selectedNavBarLink,
          }
        }
      );
    }

    else if (selectedNavBarLink == "Clients") {
      this.router.navigate(['ViewAllClients'],
        {
          state:
          {
            selectedNavBarLink: selectedNavBarLink,
          }
        }
      );
    }

    else if (selectedNavBarLink == "SharedFiles") {
      this.router.navigate(['ViewShareddocumentsBusinesses'],
        {
          state:
          {
            selectedNavBarLink: selectedNavBarLink,
          }
        }
      );
    }

    else if (selectedNavBarLink == "Profile") {
      this.router.navigate(['UserDashboard'],
        {
          state:
          {
            selectedNavBarLink: selectedNavBarLink,
          }
        }
      );
    }

  }

  addShadowMethod(value: boolean) {
    if (value == true) {
      this.addShadow = true;
    } else {
      this.addShadow = false;
    }
  }



  addShadowMethod2(value: boolean) {
    if (value == true) {
      this.addShadow2 = true;
    } else {
      this.addShadow2 = false;
    }
  }

  logOutOfAccount() {
    this.tokenStorageService.signOut();
    this.router.navigate(['SignIn']);
  }
}
