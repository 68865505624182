<section name="uploadNewFilesSection1" class="">
  <div class="container-xl-fluid font-plus-jakarta-sans me-3 me-md-0">
    <!-- // Start First Row -->
    <div class="row">
      <div class="col-md-3">
        <app-side-navigation-bar [pageTitle]="displayClientFirstNameLastName?'Add New Client Files' : 'Add New Files'"></app-side-navigation-bar>
      </div>

      <div class="col-md-9 bg-light">
        <!-- // Start First Row Inner First Row -->
        <div class="row mt-3 mt-md-5 ps-4 pt-0 pt-md-5 me-1 me-md-0">
          <div class="col-md-8 d-none d-md-block">
            <h5 class="p-2 fw-medium text-secondary float-start">
              {{ profileFirstHeaderText }}
            </h5>
            <img
              class="float-start mt-2"
              src="/assets/revamp/rightArrowIcon.png"
              width="24"
              height="24"
            />
            <h5 class="p-2 fw-bold float-start">
              {{
                displayClientFirstNameLastName
                  ? profileSecondHeaderText
                  : "Add New"
              }}
            </h5>
          </div>
          <div class="col-md-3 ms-0 ms-md-5 d-grid gap-2 d-md-flex justify-content-md-end">
            <!-- Example single danger button -->
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-primary p-2 px-3 dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                (click)="toggleAddNewDropdownContentDisplay()"
                [style.display]="corporateUser ? 'block' : 'none'"
              >
                <img
                  src="/assets/revamp/addNewButtonIcon.png"
                  width="25"
                  height="25"
                />
                &nbsp; Add New
              </button>
              <div
                class="dropdown-menu custom-margin-4rem me-5 border border-secondary-subtle p-2 px-3 shadow-sm"
                [ngClass]="displayAddNewDropdownContent ? (isSmallScreen? 'show w-100': 'show') : ''"
              >
                <a
                  class="dropdown-item text-center text-md-start"
                  (click)="redirectToAddNewClient()"
                  [style.display]="
                    allocatedStorageSizeInGB >= 1 && corporateInstitutionUser
                      ? 'block'
                      : 'none'
                  "
                >
                  <img
                    src="/assets/revamp/newClientDropdownIconNew.png"
                    width="13"
                    height="16"
                  />
                  &nbsp; New Client
                </a>
                <div
                  [style.display]="
                    allocatedStorageSizeInGB >= 1 && corporateInstitutionUser
                      ? 'block'
                      : 'none'
                  "
                  class="dropdown-divider"
                ></div>
                <a class="dropdown-item text-center text-md-start" (click)="redirectToUploadNewFiles()">
                  <img
                    src="/assets/revamp/fileUploadDropdownIconNew.png"
                    width="13"
                    height="16"
                  />
                  &nbsp; File Upload
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- // End First Row Inner First Row -->

        <!-- // Start First Row Inner Second Row -->
        <div class="row">
          <div class="col-md-11" style="width: 97%">
            <!-- // Start Upload New Files Box -->
            <div
              name="uploadNewFilesBox"
              class="bg-white rounded-2 ms-4 p-4 mt-3 mt-md-5 mb-5 mb-md-0 custom-height-uploadnewfilesbox"
            >
              <p class="fw-bold fs-6">Upload New Files</p>
              <hr class="clearfix custom-hr-width" />

              <!-- // Start Upload New Files Box First Inner Row -->
              <div class="row">
                <!-- // Start Upload New Files Box Left Division -->
                <div class="col-md-6">
                  <div>
                    <label
                      for="selectDocumentType"
                      class="form-label fw-bold mt-2 mt-md-4"
                      >Document Type</label
                    >
                    <select
                      class="form-select bg-light text-secondary border-0 p-3"
                      aria-label="Default select example"
                      #documentTypes
                      (change)="onSelected(documentTypes.value)"
                      [ngClass]="isDocumentTypeValid"
                    >
                      <option value="" disabled selected hidden>eg. Agreement</option>
                      <option
                        *ngFor="let documentTypeObject of documentTypeArray"
                        value={{documentTypeObject.id}}
                      >
                        {{ documentTypeObject.documentType }}
                      </option>
                    </select>
                    <div class="valid-feedback"></div>
                    <div class="invalid-feedback">
                      Please select Document Type!
                    </div>
                  </div>

                  <div>
                    <label for="title" class="form-label fw-bold mt-4"
                      >Title</label
                    >
                    <input
                      class="form-control border-0 bg-light p-3"
                      type="text"
                      placeholder="eg. Agreement client"
                      aria-label="default input example"
                      autocomplete="off"
                      required
                      [(ngModel)]="title"
                      [ngClass]="isTitleValid"
                    />
                    <div class="valid-feedback"></div>
                    <div class="invalid-feedback">Please enter Title!</div>
                  </div>

                  <div>
                    <label for="description" class="form-label fw-bold mt-4"
                      >Description</label
                    >
                    <input
                      class="form-control border-0 bg-light p-3"
                      type="text"
                      placeholder="eg. Tenancy agreement"
                      aria-label="default input example"
                      autocomplete="off"
                      required
                      [(ngModel)]="description"
                      [ngClass]="isDescriptionValid"
                    />
                    <div class="valid-feedback"></div>
                    <div class="invalid-feedback">
                      Please enter Description!
                    </div>
                  </div>
                </div>
                <!-- // End Upload New Files Box Left Division -->

                <!-- // Start Upload New Files Box Right Division -->
                <div class="col-md-6">
                  <div class="custom-file-button image-upload mt-4 p-2">
                    <label
                      for="uploadDocumentFile"
                      class="form-label fw-bold mt-4 w-100 text-center rounded-3"
                      appDnd
                      (fileDropped)="onFileDropped($event)"
                    >
                      <div
                        class="bg-light d-block p-5 py-4 rounded-3 custom-border-dashed"
                      >
                        <img
                          src="/assets/revamp/uploadFileArrowIcon.png"
                          width="37"
                          height="37"
                        />
                        <p class="fw-medium mt-3 mb-0">Upload a File</p>
                        <p class="fw-normal custom-font-size-14 mt-1">
                          <span class="text-body-secondary"
                            >Drag and Drop file here or</span
                          >
                          <span class="text-primary">&nbsp; Browse</span>
                        </p>
                      </div>
                    </label>
                    <div
                      class="text-danger"
                      [style.display]="
                        isUploadFileValid == ''
                          ? 'none'
                          : isUploadFileValid == 'is-valid'
                          ? 'none'
                          : 'block'
                      "
                    >
                      Please upload a file!
                    </div>

                    <input
                      class="form-control bg-light p-3"
                      type="file"
                      (change)="fileBrowseHandler($event)"
                      placeholder="eg. Danial Hakim"
                      aria-label="default input example"
                      id="uploadDocumentFile"
                      #fileDropRef
                      autocomplete="off"
                      required
                    />
                  </div>

                  <div class="files-list mt-2">
                    <div
                      class="single-file"
                      *ngFor="let file of files; let i = index"
                    >
                      <img
                        src="assets/img/legit-ic-file.png"
                        class="filesListIcon"
                        alt="file"
                      />
                      <div class="info">
                        <h4 class="name">
                          {{ file?.name }}
                        </h4>
                        <p class="size">
                          {{ formatBytes(file?.size) }}
                        </p>
                        <app-progress
                          [progress]="file?.progress"
                        ></app-progress>
                      </div>
                      <img
                        src="assets/img/legit-ic-delete-file.png"
                        class="delete filesListIcon"
                        width="20px"
                        alt="file"
                        (click)="deleteFile(i)"
                      />
                    </div>
                  </div>

                  <div class="row px-2 mt-0 mt-md-4 pt-0 pt-md-3">
                    <div class="col-md-8">
                      <p class="mb-0 text-secondary custom-font-size-12">
                        Maximum upload file sizes : 20MB
                      </p>
                      <p class="mb-0 text-secondary custom-font-size-12">
                        File format restriction : docx, pdf, jpg, png
                        <span class="text-primary">etc</span>
                      </p>
                      <p class="mb-0 text-secondary custom-font-size-12">
                        Maximum number of files per upload : 1 file
                      </p>
                    </div>
                    <div class="col-md-4">
                      <div class="d-grid gap-2">
                        <button
                          class="btn btn-primary py-3 mt-3 mt-md-0"
                          type="button"
                          (click)="uploadLegalDocument()"
                          [disabled]="disableUploadLegalDocumentButton"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- // End Upload New Files Box Right Division -->
              </div>
              <!-- // End Upload New Files Box First Inner Row -->
            </div>
            <!-- // End Upload New Files Box -->
          </div>
        </div>
        <!-- // End First Row Inner Second Row -->
      </div>
    </div>
    <!-- // End First Row -->
  </div>
</section>

<!-- Modal -->
<div
  class="modal fade show text-center"
  id="exampleModal"
  *ngIf="displayLoadingMessage || displayResponseMessage"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  style="display: block"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content p-2 border-0">
      <!-- // Start Modal Body -->
      <div class="modal-body">
        <!-- //*ngif loading -->
        <div *ngIf="displayLoadingMessage">
          <img
            src="/assets/scratchImg/loadingGifIcon.gif"
            class="loadingGifIcon"
          />
          <br /><br />
          <p class="fw-bold">Uploading New File ...</p>
        </div>

        <!-- //*ngif response -->
        <div *ngIf="displayResponseMessage">
          <img
            [src]="responseMessageIconImageURL"
            class="successMessageIcon"
            width="45"
            height="45"
          />

          <div class="mt-2">
            <p class="fs-5 fw-bold">{{ responseType }}</p>
            <p class="fs-6 fw-normal">{{ responseMessageText }}</p>
          </div>

          <div class="d-grid gap-2">
            <button
              class="btn btn-primary"
              (click)="closeResponseMessage()"
              type="button"
            >
              CLOSE
            </button>
          </div>
        </div>
      </div>
      <!-- // End Modal Body -->
    </div>
  </div>
</div>

<app-error-pop-up
  [(displaySessionMessage)]="displaySessionMessage"
  [messageToBeDisplayed]="messageToBeDisplayed"
  [errorType]="errorType"
></app-error-pop-up>
