import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  private baseUrl = environment.backendURL + '/api/transactions';

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  retrieveSmartContractFunctionTransactionHash(legalDocumentId, smartContractFunction): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append("legalDocumentID", legalDocumentId);
    formData.append("smartContractFunction", smartContractFunction);
    if (environment.debug) {
      console.log("entered retrieveSmartContractFunctionTransactionHash service");
    }

    let headers = new HttpHeaders().set('Access-Token', this.tokenStorageService.getToken());

    const req = new HttpRequest('POST', `${this.baseUrl}/retrieveStoreLegalDocumentTransactionHash`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }    
}
