import { Component, OnInit,HostListener } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-specific-client-archive',
  templateUrl: './view-specific-client-archive.component.html',
  styleUrls: ['./view-specific-client-archive.component.css']
})
export class ViewSpecificClientArchiveComponent implements OnInit {
  routeParameterClientId: any;
  routeParameterClientFirstName: any;
  routeParameterClientLastName: any;
  message: any;
  userId: any;
  retrievedLegalDocuments: any[] = [];
  extractedUserId: any;
  retrievedClientUser: any;
  disableFileUploadDropdownContent = false;
  showMoreOptions: boolean;
  displayRestoreMessage: boolean;
  documentTitle: string;
  dropdownDocumentID: string;
  documentID: any;
  restoring: boolean;
  corporateUser = false;
  displayLoadingMessage: boolean;
  displaySessionMessage = false;

  displayResponseMessage = false;
  responseType: any;
  responseMessageText: any;
  responseTypeColor: any;
  responseMessageIconImageURL: any;
  successMessageIconImageURL = "/assets/revamp/successGreenIconNew.png";
  errorMessageIconImageURL = "/assets/revamp/redExclamationMark.png";
  messageToBeDisplayed = "";
  errorType = "";
  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    console.log("isSmallScreen from Home Component: " + this.isSmallScreen);
  }


  constructor(private router: Router, private _activatedRoute: ActivatedRoute, private userService: UserService,
    private tokenStorageService: TokenStorageService, private transactionService: TransactionService) { }

  ngOnInit(): void {
    this.displaySessionMessage = false;
    this.messageToBeDisplayed = "";
    this.errorType = "";
    this.restoring = false;
    this.displayLoadingMessage = false;
    this._activatedRoute.params.subscribe(parameter => {
      this.routeParameterClientId = parameter.clientId;
      this.routeParameterClientFirstName = parameter.clientFirstName;
      this.routeParameterClientLastName = parameter.clientLastName;
      // alert(this.routeParameterClientId);
      this.retrieveUserIDByClientID(this.routeParameterClientId);
    })

    let retrievedUser = this.tokenStorageService.getUser();
    this.userId = retrievedUser.userId;
    if (environment.debug) {
      console.log("retrievedUser.userType:", retrievedUser);
    }
    if (retrievedUser.userType == "business" || retrievedUser.userType == "individual") {
      this.corporateUser = true;
    }
  }

  localFormatDate(_date) {
    let monthNumericForm = formatDate(_date, 'MM', 'en-US');
    let monthStringForm = this.monthNumberToString(monthNumericForm);
    let date_Year = formatDate(_date, 'dd, yyyy', 'en-US');
    let final_date = monthStringForm + " " + date_Year;
    return final_date;
  }

  monthNumberToString(_month) {
    var returnMonth;

    switch (_month) {
      case "01": returnMonth = "January"; break;
      case "02": returnMonth = "February"; break;
      case "03": returnMonth = "March"; break;
      case "04": returnMonth = "April"; break;
      case "05": returnMonth = "May"; break;
      case "06": returnMonth = "June"; break;
      case "07": returnMonth = "July"; break;
      case "08": returnMonth = "August"; break;
      case "09": returnMonth = "September"; break;
      case "10": returnMonth = "October"; break;
      case "11": returnMonth = "November"; break;
      case "12": returnMonth = "December"; break;
    }
    return returnMonth;
  }

  getUser(id): void {
    this.userService.get(id)
      .subscribe(
        data => {
          if (environment.debug) {
            console.log(data);
          }
          if (data.status == 403) {
            alert("Your session has expired. Please login again!");
            this.tokenStorageService.signOut();
            this.router.navigate(['SignIn']);
          }
          this.retrievedClientUser = data;

        },
        error => {
          console.error(error);
        });
  }

  getUserLegalDocumentsList(userId) {
    this.userService.getUserArchivedDocumentsList(
      userId,
      "true"
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.retrievedArchivedLegalDocuments:" + event.body.retrievedArchivedLegalDocuments);
          }
          // this.message = event.body.message;

          if (event.body.status == 500) {
            alert(event.body.message);
          } else {
            this.retrievedLegalDocuments = event.body.retrievedArchivedLegalDocuments;
          }
        }
      },
      err => {

        alert(err.error.message);

      });

  }

  /**
* format bytes
* @param bytes (File size in bytes)
* @param decimals (Decimals point)
*/

  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }


  retrieveUserIDByClientID(clientId) {
    this.userService.retrieveUserIDByClientID(
      clientId
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.message:" + event.body.message);
          }
          this.message = event.body.message;
          this.extractedUserId = event.body.userId;
          this.getUser(this.extractedUserId);
          this.getUserLegalDocumentsList(this.extractedUserId);
        }
      },
      err => {
        alert(err.error.message);
      });
  }

  goBack() {
    this.router.navigate(['ViewAllClients'],
      {
        state:
        {
          origin: "UserArchiveComponent",
        }
      });
  }

  dropdownOptions(docID) {
    this.dropdownDocumentID = docID;
    if (!this.showMoreOptions) {
      this.showMoreOptions = true;
    } else {
      this.showMoreOptions = false;
    }
  }

  cancelRestore() {
    this.displayRestoreMessage = false;
  }

  restoreDocumentPopUp(docID: any, docTitle: string) {
    this.documentTitle = docTitle;
    this.documentID = docID;
    this.displayRestoreMessage = true;
  }

  restoreDocument() {
    // Archive the document
    this.restoring = true;
    this.displayLoadingMessage = true;
    if (environment.debug) {
      console.log("retrieved user: ", this.retrievedClientUser);
    }
    this.userService.restoreArchivedDocument(this.retrievedClientUser.id, "true", this.documentID).subscribe({
      next: (event) => {
        if (event instanceof HttpResponse) {
          if (event.body.status == 500) {
            alert("Error: " + event.body.message);
          } else if (event.body.status == 404) {
            alert("Error: " + event.body.message);
          } else {
            this.displayRestoreMessage = false;
            this.responseTypeColor = "#0BB831";
            this.responseMessageIconImageURL = this.successMessageIconImageURL;
            this.responseType = "Success";
            this.responseMessageText = "File successfully restored!";
            this.displayResponseMessage = true;
            this.displayLoadingMessage = false;
          }
        }
      },
      error: (err) => {
        // Response error from backend / Fail to send forgot password request
        this.responseTypeColor = "#EE4E55";
        this.responseMessageIconImageURL = this.errorMessageIconImageURL;
        this.responseType = "Error";
        this.responseMessageText = err.error.message;
        this.displayResponseMessage = true;
        this.displayLoadingMessage = false;
      }
    });

  }

  closeResponseMessage() {
    this.displayResponseMessage = false;
    const url = 'ViewSpecificClient/' + this.routeParameterClientId + "/" + this.routeParameterClientFirstName + "/" + this.routeParameterClientLastName;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/${url}`]).then(() => {
        if (environment.debug) {
          console.log(`After navigation I am on:${this.router.url}`)
        }
      })
    });
  }

}
