import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
// import { LawFirmSignUpComponent } from './components/law-firm-sign-up/law-firm-sign-up.component';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ServicesComponent } from './components/services/services.component';
import { PricingPlanComponent } from './components/pricing-plan/pricing-plan.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FaqComponent } from './components/faq/faq.component';
import { TermAndPoliciesComponent } from './components/term-and-policies/term-and-policies.component';
import { SignUpSelectionComponent } from './components/sign-up-selection/sign-up-selection.component';
import { SignUpStepsComponent } from './components/sign-up-steps/sign-up-steps.component';
import { SignUpStep1IndustryComponent } from './components/sign-up-step1-industry/sign-up-step1-industry.component';
import { SignUpStep1PersonalComponent } from './components/sign-up-step1-personal/sign-up-step1-personal.component';
import { SignUpStep2CredentialsComponent } from './components/sign-up-step2-credentials/sign-up-step2-credentials.component';
import { DndDirective } from './directives/dnd.directive';
import { ProgressComponent } from './components/progress/progress.component';
// import { BusinessSignUpComponent } from './components/business-sign-up/business-sign-up.component';
// import { IndividualSignUpComponent } from './components/individual-sign-up/individual-sign-up.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CheckoutProcessingComponent } from './components/checkout-processing/checkout-processing.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { SideNavigationBarComponent } from './components/side-navigation-bar/side-navigation-bar.component';
import { UserDashboardComponent } from './components/user-dashboard/user-dashboard.component';
// import { ClientSignUpComponent } from './components/client-sign-up/client-sign-up.component';
import { UserDocumentsComponent } from './components/user-documents/user-documents.component';
import { UserArchiveComponent } from './components/user-archive/user-archive.component';
import { ViewAllClientsComponent } from './components/view-all-clients/view-all-clients.component';
import { AddNewClientComponent } from './components/add-new-client/add-new-client.component';
import { UploadNewFilesComponent } from './components/upload-new-files/upload-new-files.component';
import { ViewSpecificClientComponent } from './components/view-specific-client/view-specific-client.component';
import { ViewSpecificClientArchiveComponent } from './components/view-specific-client-archive/view-specific-client-archive.component';
import { SelectPlanComponent } from './components/select-plan/select-plan.component';
import { ActivateAccountComponent } from './components/activate-account/activate-account.component';
import { GetStartedComponent } from './components/get-started/get-started.component';
import { ErrorComponent } from './components/error/error.component';
import { ViewShareddocumentsBusinessesComponent } from './components/view-shareddocuments-businesses/view-shareddocuments-businesses.component';
import { ViewShareddocumentsSpecificBusinessComponent } from './components/view-shareddocuments-specific-business/view-shareddocuments-specific-business.component';
import { ErrorPopUpComponent } from './components/error-pop-up/error-pop-up.component';
import { LinkExpiredComponent } from './components/link-expired/link-expired.component';
import { CustomDatePipe } from './customDate.pipe';
import { MyDirectiveDirective } from './my-directive.directive';
import { LongPressDirective } from './long-press.directive';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    // LawFirmSignUpComponent,
    HomeComponent,
    AboutUsComponent,
    ServicesComponent,
    PricingPlanComponent,
    ContactUsComponent,
    FaqComponent,
    TermAndPoliciesComponent,
    SignUpSelectionComponent,
    SignUpStepsComponent,
    SignUpStep1IndustryComponent,
    SignUpStep1PersonalComponent,
    SignUpStep2CredentialsComponent,
    DndDirective,
    ProgressComponent,
    // BusinessSignUpComponent,
    // IndividualSignUpComponent,
    CheckoutComponent,
    CheckoutProcessingComponent,
    SignInComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    SideNavigationBarComponent,
    UserDashboardComponent,
    // ClientSignUpComponent,
    UserDocumentsComponent,
    UserArchiveComponent,
    ViewAllClientsComponent,
    AddNewClientComponent,
    UploadNewFilesComponent,
    ViewSpecificClientComponent,
    ViewSpecificClientArchiveComponent,
    SelectPlanComponent,
    ActivateAccountComponent,
    HeaderComponent,
    FooterComponent,
    GetStartedComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    UserArchiveComponent,
    ViewSpecificClientArchiveComponent,
    ErrorComponent,
    ViewShareddocumentsBusinessesComponent,
    ViewShareddocumentsSpecificBusinessComponent,
    ErrorPopUpComponent,
    LinkExpiredComponent,
    CustomDatePipe,
    MyDirectiveDirective,
    LongPressDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
