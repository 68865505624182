<link
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
  rel="stylesheet"
  integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN"
  crossorigin="anonymous"
/>
<script
  src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"
  integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL"
  crossorigin="anonymous"
></script>
<div class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <!-- Main Content -->
        <div class="row mt-5 mb-5 text-center">
          <div class="col-12">
            <h2 class="fw-bold">Sign Up</h2>
            <p>
              Already have an account?
              <a (click)="redirectToSignIn()">Log In</a>
            </p>
          </div>
        </div>

        <!-- Sub content -->
        <div class="row pb-5 mb-5">
          <div class="col-12">
            <app-sign-up-step1-personal
              *ngIf="showStage1Personal"
              [personalData]="stage1Data"
              (stage1PersonalData)="onStage1Submit($event)"
            ></app-sign-up-step1-personal>
            <!-- sub stage 1 -->
            <app-sign-up-step1-industry
              *ngIf="showStage1Industry"
              [industryData]="stage1Data"
              (stage1IndustryData)="onStage1Submit($event)"
            ></app-sign-up-step1-industry>
            <!-- sub stage 2 -->
            <app-sign-up-step2-credentials
              *ngIf="showStage2Credentials"
              [stage1Data]="stage1Data"
              [urlEmail]="urlEmailAddress"
              [clientId]="clientId" [requestToken]="requestToken" (stage2Data)="onStage2GoBack($event)"
            ></app-sign-up-step2-credentials>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-error-pop-up
  [(displaySessionMessage)]="displaySessionMessage"
  [messageToBeDisplayed]="messageToBeDisplayed"
  [errorType]="errorType"
>
</app-error-pop-up>
