<link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>
<link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet'>
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9" crossorigin="anonymous">
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js" integrity="sha384-HwwvtgBNo3bZJJLYd8oVXjrBZt8cqVSpeBNS5n7C8IVInixGAoxmnlMuBnhbgrkm" crossorigin="anonymous"></script>

<section>
    

  <div class="container p-5 pb-5">
    <div class="alert alert-danger" role="alert" [style.display]="displayBackendErrorMessage? 'block':'none'">{{errorMessage}}</div>
    <div class="alert alert-danger" role="alert" [style.display]="displayErrorModal? 'block':'none'">
      The URL link is invalid or expired. Please <a class="alert-link" style="cursor: pointer;" (click)="redirectToForgotPassword()">reset password</a> again ! <br> Error: {{errorMessage}}
    </div>
    <div class="alert alert-success" role="alert" [style.display]="changedPassword? 'block':'none'">Password changed successfully. Redirecting to Sign In in 5 seconds.</div>
    <div class="row d-flex justify-content-center ">
      <div class="col-lg-4 col-sm-12 justify-content-center my-auto">
        <div class="pb-3">
          <h2 class="fw-bold">
            Change Password
          </h2>
        </div>
        <div class="pb-3">
          <!-- textbox -->
          <label for="newPassword">New Password</label>
          <input (focus)="onFocus()" type="password" class="form-control" id="newPassword" placeholder="*******" [disabled]="displayErrorModal" [disabled]="changedPassword" [(ngModel)]="newPassword">
        </div>
        <div class="pb-3">
          <!-- textbox -->
          <label for="confNewPassword">Confirm New Password</label>
          <input (focus)="onFocus()" type="password" class="form-control" id="confNewPassword" placeholder="*******" [disabled]="displayErrorModal" [disabled]="changedPassword" required [(ngModel)]="confNewPassword">
          <p class="lead pt-2 ms-2 text-danger" [ngClass]="checkPasswordField? 'd-block':'d-none'"> Please enter your new password in both fields !</p>
          <p class="lead pt-2 ms-2 text-danger" [ngClass]="checkPasswordMatch? 'd-block':'d-none'"> The entered passwords does not match !</p>
          <p class="lead pt-2 ms-2 text-danger" [ngClass]="checkPasswordStrength? 'd-block':'d-none'"> Your password must contain at least 1 uppercase letter, one lowercase letter, one digit, one special character and 8 characters long.</p>
        </div>
        <div class="py-3 d-grid gap-2">
          <button class="btn fw-bold btn-lg" style="background-color: #E1BBA6; color: white;" [disabled]="displayErrorModal" [disabled]="changedPassword"  (click)="clickChangePassword()"> Change Password </button>        
        </div>
      </div>
      <div class="col-4 d-none d-md-block d-lg-block">
        <img class="img-fluid mx-auto" src="/assets/scratchImg/changePassword.png" alt="Change Password">
      </div>
    </div>
  </div> 

</section>
