import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up-step1-personal',
  templateUrl: './sign-up-step1-personal.component.html',
  styleUrls: ['./sign-up-step1-personal.component.css']
})

export class SignUpStep1PersonalComponent implements OnInit {

  isFirstNameValid: string;
  firstName: any;
  isLastNameValid: string;
  lastName: any;
  isIDTypeValid: string;
  selectingIDType: any;
  isIDNumberValid: string;
  isIDNumberActive: boolean;
  IDNumber: any;
  idNumberText: any = "";
  placeholderText: any = "eg. 970212105678 / A123459";
  
  listOfIDTypes = [
    'NRIC',
    'Passport'
  ]

  @Input() personalData: any;
  @Output() stage1PersonalData = new EventEmitter<any>();


  constructor(private router: Router) {
    this.firstName = "";
    this.lastName = "";
    this.isFirstNameValid = "";
    this.isLastNameValid = "";
    this.IDNumber = "";
    this.isIDNumberValid = "";
    this.isIDNumberActive = true;
    this.isIDTypeValid = "";
    this.selectingIDType = "";
    
  }

  ngOnInit(): void {
    if (this.personalData) {
      this.firstName = this.personalData.firstName;
      this.lastName = this.personalData.lastName;
      this.selectingIDType = this.personalData.IDType;
      this.IDNumber = this.personalData.IDNumber;
    }
  }

  redirectToSignIn() {
    this.router.navigate(['SignIn']);
  }

  resetValidation() {
    this.isFirstNameValid = "";
    this.isLastNameValid = ""
    this.isIDNumberValid = "";
    this.isIDTypeValid = "";
  }

  selectedIDType(option) {
    if (option != "") {
      if (option == "0") {
        this.isIDNumberActive = false;
        this.idNumberText = "(NRIC)";
        this.placeholderText = "eg. 970212105678";
      } else if (option == "1") {
        this.isIDNumberActive = false;
        this.idNumberText = "(Passport)";
        this.placeholderText = "eg. A123459";
      }
    }   
  }

  goToNext() {
    console.log("fist name: " + this.firstName);
    if (this.firstName.trim() == "") {
      this.isFirstNameValid = "is-invalid";
    }

    if (this.lastName.trim() == "") {
      this.isLastNameValid = "is-invalid";
    }

    if (this.IDNumber.trim() == "") {
      this.isIDNumberValid = "is-invalid";
    }

    if (this.firstName.trim() != "") {
      this.isFirstNameValid = "is-valid";
      if (this.lastName.trim() != "") {
        this.isLastNameValid = "is-valid";
        if (this.IDNumber.trim() != "") {
          this.isIDNumberValid = "is-valid";
          this.personalData = {
            firstName: this.firstName,
            lastName: this.lastName,
            IDType: this.selectingIDType,
            IDNumber: this.IDNumber,
            origin: "personalData"
          }
          this.stage1PersonalData.emit(this.personalData);
        }
      }
    }
  }

}
