<section name="userDocumentsSection1" class="">
  <div class="container-xl-fluid font-plus-jakarta-sans me-3 me-md-0">
    <!-- // Start First Row -->
    <div class="row">
      <div class="col-md-3">
        <app-side-navigation-bar [pageTitle]="'Shared Files'"></app-side-navigation-bar>
      </div>

      <div class="col-md-9 bg-light">
        <!-- // Start First Row Inner First Row -->
        <div class="row mt-0 mt-md-5 ps-4 pt-0 pt-md-5">
          <div class="col-md-8 d-none d-md-block">
            <h5 class="p-2 fw-bold">Shared Files</h5>
          </div>
          <!-- <div class="col-md-3 ms-5 d-flex justify-content-end"> -->

          <!-- Example single danger button -->
          <!-- <div class="btn-group">
                        <button type="button" class="btn btn-primary p-2 px-3 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        (click)="toggleAddNewDropdownContentDisplay()"
                            [style.display]="corporateUser?'block':'none'">
                            <img src="/assets/revamp/addNewButtonIcon.png" width="25" height="25">
                            &nbsp; Add New
                        </button>
                        <div class="dropdown-menu custom-margin-4rem me-5 border border-secondary-subtle p-2 px-3" [ngClass]="displayAddNewDropdownContent? 'show':''">
                        <a class="dropdown-item" (click)="redirectToAddNewClient()"
                        [style.display]="allocatedStorageSizeInGB>=1 && corporateInstitutionUser?'block':'none'">
                            <img src="/assets/revamp/newClientDropdownIconNew.png" width="13" height="16">
                            &nbsp; New Client
                        </a>
                        <div [style.display]="allocatedStorageSizeInGB>=1 && corporateInstitutionUser?'block':'none'" class="dropdown-divider"  [style.display]="allocatedStorageSizeInGB>=1 && corporateInstitutionUser?'block':'none'"></div>
                        <a class="dropdown-item" (click)="redirectToUploadNewFiles()">
                            <img src="/assets/revamp/fileUploadDropdownIconNew.png" width="13" height="16">
                            &nbsp; File Upload
                        </a>
                        </div>
                    </div> -->
          <!-- <button type="button" class="btn btn-primary p-2 px-3">
                        <img src="/assets/revamp/addNewButtonIcon.png" width="25" height="25">
                        &nbsp; Add New
                    </button> -->
          <!-- </div> -->
        </div>
        <!-- // End First Row Inner First Row -->

        <!-- // Start First Row Inner Second Row -->
        <div class="row">
          <div class="col-md-11" style="width: 97%">
            <!-- // Start Shared Files Box -->
            <div
              name="sharedFilesBox"
              class="bg-white rounded-2 ms-4 p-4 mt-4 mb-5 mb-md-0 custom-height-recentfilesbox"
            >
              <p class="ms-0 mb-2 mb-md-0 fw-bold fs-6 float-start">Shared Files</p>
              <!-- <p class="float-end text-primary fs-6 fw-medium" (mouseenter)="showPopup()" (mouseleave)="hidePopup()">Change Plan</p>
                        <div class="changePlanPopUp shadow-lg" [style.display]="isPopupVisible? 'block':'none'">
                            Please email your change plan request to support@legalbox.io. 
                            <br>Our team will get in touch with you soon.
                        </div> -->
              <hr
                class="clearfix custom-hr-width mt-0 ms-0 d-none d-md-block"
                style="clear: both"
              />
              <table class="table ms-0" [ngClass]="isSmallScreen?'':'table-borderless table-hover'">
                <thead class="">
                  <tr>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      Name &nbsp;<img
                        src="/assets/revamp/myDocumentsArrowUpIcon.png"
                        width="7"
                        height="7"
                      />
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      File Type
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      Size
                    </th>
                  </tr>
                </thead>
                <tbody class="custom-font-size-13">
                  <ng-container
                    *ngFor="let business of businessArray; index as i"
                  >
                    <tr>
                      <td colspan="isSmallScreen? 5:1"
                        class="align-middle"
                        (click)="
                          viewSpecificBusiness(
                            business.dataValues.id,
                            business.dataValues.companyName
                          )
                        "
                      >
                        <img
                          src="/assets/revamp/myDocumentsFolderIcon.png"
                          class="float-start"
                          [style.width]="isSmallScreen? '1.8rem':'1rem'"
                          [style.height]="isSmallScreen? '1.8rem':'1rem'"
                        />
                        <p class="d-inline-block ms-2">
                          <span [ngClass]="isSmallScreen? 'fw-bold':''">
                           {{ business.dataValues.companyName }}
                          </span>
                          
                          <!-- // Start Document Additional Info Small Screen -->
                          <span class="d-table-cell d-md-none fw-normal custom-font-size-10">
                           {{
                             formatBytes(
                               business.clientUsedStorageFromLawFirm *
                                 1024 *
                                 1024 *
                                 1024
                             )
                           }}
                         </span>
                         <!-- // End Document Additional Info Small Screen -->
                        </p>
                       
                      </td>
                      <td class="d-none d-md-table-cell text-secondary align-middle">Folder</td>
                      <td class="d-none d-md-table-cell text-secondary align-middle">
                        <div class="progress-stacked mt-1 custom-bg-lightBlue">
                          <div
                            class="progress"
                            role="progressbar"
                            aria-label="Segment one"
                            aria-valuenow="15"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            [style.width]="business.usedStoragePercentage"
                          >
                            <div
                              class="progress-bar custom-bg-mediumBlue"
                            ></div>
                          </div>
                        </div>

                        <p class="mb-0 mt-0 text-end">
                          {{
                            formatBytes(
                              business.clientUsedStorageFromLawFirm *
                                1024 *
                                1024 *
                                1024
                            )
                          }}
                        </p>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!-- // End Shared Files Box -->
          </div>
        </div>
        <!-- // End First Row Inner Second Row -->
      </div>
    </div>
    <!-- // End First Row -->
  </div>
</section>

<app-error-pop-up
  [(displaySessionMessage)]="displaySessionMessage"
  [messageToBeDisplayed]="messageToBeDisplayed"
  [errorType]="errorType"
></app-error-pop-up>
