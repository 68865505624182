import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  forgotPassUsername: string;
  changePassAuthToken: string;
  emailAdd: string;
  authtoken: string;
  newPassword: string;
  confNewPassword: string;
  hashPass: string;
  displayErrorModal = false;
  checkPasswordField = false;
  checkPasswordMatch = false;
  checkPasswordStrength = false;
  errorMessage: string;
  displayBackendErrorMessage = false;
  changedPassword = false;


  constructor(private router: Router, private userService: UserService, private activatedRoute: ActivatedRoute) {
    this.newPassword = "";
    this.confNewPassword = "";
  }

  onFocus() {
    this.checkPasswordMatch = false;
    this.checkPasswordField = false;
    this.checkPasswordStrength = false;
    this.displayBackendErrorMessage = false;
  }

  redirectToForgotPassword() {
    this.router.navigate(['ForgotPassword']);
  }

  clickChangePassword(): void {
    if (environment.debug) {
      console.log("Enter change pass click");
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    if (this.newPassword.trim() == "") {
      this.checkPasswordField = true;
    }

    if (this.confNewPassword.trim() == "") {
      this.checkPasswordField = true;
    }
    const strongPaswordRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    // Check password match
    if (this.newPassword.trim() == this.confNewPassword.trim()) {
      // Check password strength
      if (strongPaswordRegex.test(this.newPassword.trim())) {
        this.hashPass = this.newPassword.trim();
        this.userService.changePassword(this.forgotPassUsername, this.authtoken, this.hashPass).subscribe({
          next: async (event) => {
            if (event instanceof HttpResponse) {
              if (environment.debug) {
                console.log("Object.keys(event.body):" + Object.keys(event.body));
                console.log("Object Status:" + event.body.status);
                console.log("Object Message:" + event.body.message);
              }
              if (event.body.status == 500) {
                this.errorMessage = event.body.message;
                this.displayBackendErrorMessage = true;
              } else if (event.status == 200) {
                this.changedPassword = true;
                await delay(5000);
                this.router.navigate(['SignIn']);
              }
            }
          },
          error: (err) => {
            this.errorMessage = err.error.message;
            this.displayBackendErrorMessage = true;
          }
        })
      } else {
        this.checkPasswordStrength = true;
      }
    } else {
      this.checkPasswordMatch = true;
    }
  }


  ngOnInit(): void {
    this.forgotPassUsername = this.activatedRoute.snapshot.queryParamMap.get('username');
    this.changePassAuthToken = this.activatedRoute.snapshot.queryParamMap.get('authToken');

    this.userService.checkAuthChangePassword(this.forgotPassUsername, this.changePassAuthToken).subscribe({
      next: (event) => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("Object Status:" + event.body.status);
            console.log("Object Token:" + event.body.authToken);
          }
          if (event.body.status == 500) {
            this.errorMessage = event.body.message;
            this.displayErrorModal = true;
          } else {
            this.authtoken = event.body.authToken;
          }
        }
      },
      error: (err) => {
        // Response error from backend / Fail to send forgot password request
        this.errorMessage = err.error.message;
        this.displayErrorModal = true;
      }
    });

  }

}
