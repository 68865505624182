<section name="homeSection1" class="pb-5">
  <div class="container-xl pt-4 font-plus-jakarta-sans">
    <!-- // Start First Row -->
    <div class="row">
      <div
        class="col-lg-6 col-md-7 col-sm-8 col-10"
        [ngClass]="isSmallScreen ? 'mx-auto text-center' : 'mt-5'"
      >
        <!-- // Start First Row Inner First Row -->
        <div class="row">
          <div class="col-lg-12">
            <h1
              class="text-shadow-black letter-spacing-1 fw-bold"
              [ngClass]="
                isSmallScreen ? 'display-4' : 'display-4 line-height-4'
              "
            >
            Secure,
              <br />
            Accessible,
              <br>
            Confidential.
            <!-- <br>
            <span class="d-block mt-3 fw-normal display-5">Elevating File Storage </span> -->
            </h1>
            <p
              class="mt-4 text-secondary"
              [ngClass]="
                isSmallScreen ? 'fs-6' : 'fw-semibold fs-5 line-height-2'
              "
            >
              <small *ngIf="isSmallScreen" class="font-weight-bold">
                Discover a new level of control with our secure blockchain storage, 
                where you can easily share files for others to view.
              </small>
              <span *ngIf="!isSmallScreen">
                Discover a new level of control with our secure blockchain storage,
                where you can easily share files for others to view.
              </span>
            </p>
          </div>
        </div>
        <!-- // End First Row Inner First Row -->

        <button
          type="button"
          class="btn btn-sm btn-primary px-5 py-2 mt-4 rounded-2 fs-6"
          (click)="redirectToSignUpSelection()"
        >
          Get Started
        </button>
      </div>

      <div
        class="col-lg-5 col-md-4 col-sm-2 pb-5"
        [ngClass]="isSmallScreen ? 'd-none' : 'pl-5 ms-5 '"
      >
        <img
          src="/assets/revamp/heroBannerImgHome.png"
          class="img-responsive"
        />
      </div>

      <div
        class="col-lg-5 col-md-4 col-sm-2 pb-5"
        [ngClass]="isSmallScreen ? 'text-center mt-3 p-5' : 'd-none'"
      >
        <img
          src="/assets/revamp/heroBannerImgHome.png"
          class="img-responsive"
        />
      </div>
    </div>
    <!-- // End First Row -->
  </div>
</section>

<section name="homeSection2" class="bg-light-blue pt-5 pb-5">
  <div class="container-xl font-plus-jakarta-sans mb-5">
    <!-- // Start First Row -->
    <div class="row">
      <div class="col-lg-12 d-flex justify-content-center">
        <h1 class="fw-bold text-shadow-black letter-spacing-1 pt-5 text-center">
          <small *ngIf="isSmallScreen" class="font-weight-bold">
            Why Choose Us?
          </small>
          <span *ngIf="!isSmallScreen"> Why Choose Us? </span>
        </h1>
      </div>
    </div>
    <!-- // End First Row -->

    <!-- // Start Second Row -->
    <div class="row">
      <div
        class="col-lg-11 d-flex justify-content-center mx-auto"
        [ngClass]="isSmallScreen ? 'px-5' : ''"
      >
        <p
          class="line-height-2 mt-3 text-secondary text-center"
          [ngClass]="isSmallScreen ? 'fs-6' : 'fs-5 fw-semibold'"
        >
          <small *ngIf="isSmallScreen" class="font-weight-bold">
            Our solution is built on top of the most advanced security
            protocols, including encryption, multi-factor authentication, and
            automatic backups, ensuring that your data is always protected from
            threats.
          </small>
          <span *ngIf="!isSmallScreen">
            Our solution is built on top of the most advanced security
            protocols, including encryption, multi-factor authentication, and
            automatic backups, ensuring that your data is always protected from
            threats.
          </span>
        </p>
      </div>
    </div>
    <!-- // End Second Row -->

    <!-- // Start Third Row -->
    <div class="row mt-3">
      <div class="col-lg-4 mt-2 d-flex align-items-stretch">
        <div class="card rounded-5 border-0 py-5 px-4 mb-3">
          <img
            src="/assets/revamp/homeSection2Card1ImgNew.png"
            class="mx-auto mt-4"
            alt="..."
            width="70"
            height="63"
          />
          <div class="card-body text-center line-height-2">
            <h4 class="card-title fw-bold mt-3">Unmatched Security</h4>
            <p class="card-text mt-4 text-secondary line-height-1_5 mb-2">
              Your documents are now on the Blockchain for Tamper-Proof Assurance.
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 mt-2 align-items-stretch">
        <div class="card rounded-5 border-0 py-5 px-4 mb-3">
          <img
            src="/assets/revamp/homeSection2Card2ImgNew.png"
            class="mx-auto mt-4"
            alt="..."
            width="70"
            height="63"
          />
          <div class="card-body text-center line-height-2">
            <h4 class="card-title fw-bold mt-3">Seamless File Control</h4>
            <p class="card-text mt-4 text-secondary line-height-1_5 mb-2">
              Easily handle your files. Our simple interface uploads, organizes, and retrieves data seamlessly.
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 mt-2 d-flex align-items-stretch">
        <div class="card rounded-5 border-0 py-5 px-4 mb-3">
          <img
            src="/assets/revamp/homeSection2Card3ImgNew.png"
            class="mx-auto mt-4"
            alt="..."
            width="70"
            height="63"
          />
          <div class="card-body text-center">
            <h4 class="card-title fw-bold mt-3">Global Accessibility  </h4>
            <p class="card-text mt-4 text-secondary line-height-1_5 mb-2">
              Your files are retrievable from anywhere, at any time by you.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- // End Third Row -->
  </div>
</section>

<section name="homeSection3" class="bg-white pb-5 mb-5 pt-5">
  <div class="container-xl font-plus-jakarta-sans pb-5">
    <!-- // Start First Row -->
    <div class="row">
      <div class="col-lg-12 d-flex justify-content-center">
        <h1 class="fw-bold text-shadow-black letter-spacing-1 pt-5 text-center">
          <small *ngIf="isSmallScreen" class="font-weight-bold">
            Choose Your Plan
          </small>
          <span *ngIf="!isSmallScreen"> Choose Your Plan </span>
        </h1>
      </div>
    </div>
    <!-- // End First Row -->

    <!-- // Start Second Row -->
    <div class="row">
      <div
        class="col-lg-11 col-12 d-flex justify-content-center mx-auto"
        [ngClass]="isSmallScreen ? 'px-5' : ''"
      >
        <p
          class="line-height-2 mt-3 text-secondary text-center"
          [ngClass]="isSmallScreen ? 'fs-6' : 'fw-semibold fs-5'"
        >
          <small *ngIf="isSmallScreen" class="font-weight-bold">
            Choose from diverse plans of our Secure Blockchain Storage Solution,
            customizable to your organization's unique needs, whether for a
            small team or a premium solution.
          </small>
          <span *ngIf="!isSmallScreen">
            Choose from diverse plans of our Secure Blockchain Storage Solution,
            customizable to your organization's unique needs, whether for a
            small team or a premium solution.
          </span>
        </p>
      </div>
    </div>
    <!-- // End Second Row -->

    <!-- // Start Third Row -->
    <div class="row d-flex justify-content-center">
      <div class="col-lg-3 mt-4">
        <ul
          class="nav bg-white py-1 px-1 rounded-pill nav-fill border border-primary"
        >
          <li class="nav-item">
            <a
              class="nav-link active font-weight-bold py-3 rounded-pill"
              aria-current="page"
              (click)="displayCorporatePricingPlan()"
              role="button"
              [ngClass]="
                showCorporatePricingPlan
                  ? 'bg-primary text-white'
                  : 'bg-white text-dark'
              "
            >
              Corporate
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link font-weight-bold py-3 rounded-pill"
              (click)="displayIndividualPricingPlan()"
              role="button"
              [ngClass]="
                showIndividualPricingPlan
                  ? 'bg-primary text-white'
                  : 'bg-white text-dark'
              "
            >
              Individual
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- // End Third Row -->

    <!-- // Start Fourth Row -->
    <div class="row mt-5">
      <div
        class="col-md-3 d-flex align-items-stretch mt-4"
        [ngClass]="isSmallScreen ? '' : 'px-1'"
      >
        <div class="card px-1 pt-3 pb-5 bd-light-blue w-100 shadow">
          <div class="card-body letter-spacing-1">
            <h3 class="card-title fw-bold mt-1">Free</h3>
            <h3 class="card-title fw-bold mt-1">
              MYR
              <span class="text-shadow-black">0</span>
              <small class="fw-normal ml-2">/mo</small>
            </h3>
            <p class="card-text text-secondary">5 MB</p>

            <div class="d-grid gap-2">
              <a class="btn text-black btn-primary bd-none fw-semibold mt-5 rounded-2 bg-medium-blue"
                (click)="redirectToSignUpSelection()"
                >Get Started</a
              >
            </div>

            <!-- // Start Fourth Row First Card Inner First Row -->
            <div class="row text-secondary mt-5">
              <div class="col-1">
                <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
              </div>
              <div class="col-10">
                <p class="">5 MB storage</p>
              </div>
            </div>
            <!-- // End Fourth Row First Card Inner First Row -->

            <!-- // Start Fourth Row First Card Inner Second Row -->
            <div class="row text-secondary mt-1">
              <div class="col-1">
                <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
              </div>
              <div class="col-10">
                <p class="">Limited transfer</p>
              </div>
            </div>
            <!-- // End Fourth Row First Card Inner Second Row -->
          </div>
        </div>
      </div>

      <div
        class="col-md-3 d-flex align-items-stretch mt-4"
        [ngClass]="isSmallScreen ? '' : 'px-1'"
      >
        <div class="card px-1 pt-3 pb-5 bd-light-blue w-100 shadow">
          <div class="card-body letter-spacing-1">
            <h3 class="card-title fw-bold mt-1">Standard</h3>
            <h3 class="card-title fw-bold mt-1">
              MYR
              <span class="text-shadow-black">{{
                showCorporatePricingPlan
                  ? corporateBasicPlanPrice
                  : individualBasicPlanPrice
              }}</span>
              <small class="fw-normal ml-2">/mo</small>
            </h3>
            <p class="card-text text-secondary">
              {{showCorporatePricingPlan
                ? 'Up to 20 clients' : '~ 100 files'}}
            </p>
            <div class="d-grid gap-2">
              <a class="btn text-black btn-primary bd-none fw-semibold mt-5 rounded-2 bg-medium-blue"
              (click)="redirectToSignUpSelection()"
                >Buy Now</a
              >
            </div>

            <!-- // Start Fourth Row Second Card Inner First Row -->
            <div class="row text-secondary mt-5">
              <div class="col-1">
                <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
              </div>
              <div class="col-10">
                <p class="">
                  {{
                    showCorporatePricingPlan
                      ? corporateBasicPlanStorageSizeInGB
                      : individualBasicPlanStorageSizeInGB >= 1
                      ? individualBasicPlanStorageSizeInGB
                      : individualBasicPlanStorageSizeInGB * 1000
                  }}
                  {{
                    showCorporatePricingPlan
                      ? "G"
                      : individualBasicPlanStorageSizeInGB >= 1
                      ? "G"
                      : "M"
                  }}B storage
                </p>
              </div>
            </div>
            <!-- // End Fourth Row Second Card Inner First Row -->

            <!-- // Start Fourth Row Second Card Inner Second Row -->
            <div class="row text-secondary mt-1">
              <div class="col-1">
                <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
              </div>
              <div class="col-10">
                <p class="">Email and phone support</p>
              </div>
            </div>
            <!-- // End Fourth Row Second Card Inner Second Row -->
          </div>
        </div>
      </div>

      <div
        class="col-md-3 d-flex align-items-stretch mt-4"
        [ngClass]="isSmallScreen ? '' : 'px-1'"
      >
        <div class="card px-1 pt-3 pb-5 bd-light-blue w-100 shadow">
          <div class="card-body letter-spacing-1">
            <h3 class="card-title fw-bold mt-1">Advanced</h3>
            <h3 class="card-title fw-bold mt-1">
              MYR
              <span class="text-shadow-black">{{
                showCorporatePricingPlan
                  ? corporateAdvancedPlanPrice
                  : individualAdvancedPlanPrice
              }}</span>
              <small class="fw-normal ml-2">/mo</small>
            </h3>
            <p class="card-text text-secondary">
              {{showCorporatePricingPlan
                ? 'Up to 500 clients' : '~ 500 files'}}
              
            </p>
            <div class="d-grid gap-2">
              <a class="btn text-black btn-primary bd-none fw-semibold mt-5 rounded-2 bg-medium-blue"
              (click)="redirectToSignUpSelection()"
                >Buy Now</a
              >
            </div>

            <!-- // Start Fourth Row Third Card Inner First Row -->
            <div class="row text-secondary mt-5">
              <div class="col-1">
                <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
              </div>
              <div class="col-10">
                <p class="">
                  {{
                    showCorporatePricingPlan
                      ? corporateAdvancedPlanStorageSizeInGB
                      : individualAdvancedPlanStorageSizeInGB >= 1
                      ? individualAdvancedPlanStorageSizeInGB
                      : individualAdvancedPlanStorageSizeInGB * 1000
                  }}
                  {{
                    showCorporatePricingPlan
                      ? "G"
                      : individualAdvancedPlanStorageSizeInGB >= 1
                      ? "G"
                      : "M"
                  }}B storage
                </p>
              </div>
            </div>
            <!-- // End Fourth Row Third Card Inner First Row -->

            <!-- // Start Fourth Row Third Card Inner Second Row -->
            <div class="row text-secondary mt-1">
              <div class="col-1">
                <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
              </div>
              <div class="col-10">
                <p class="">Email and phone support</p>
              </div>
            </div>
            <!-- // End Fourth Row Third Card Inner Second Row -->
          </div>
        </div>
      </div>

      <div
        class="col-md-3 d-flex align-items-stretch mt-4"
        [ngClass]="isSmallScreen ? '' : 'px-1'"
      >
        <div class="card px-1 pt-3 pb-3 bd-light-blue w-100 shadow">
          <div class="card-body letter-spacing-1">
            <h3 class="card-title fw-bold mt-1">Premium</h3>
            <h3 class="card-title fw-bold mt-1">
              MYR
              <span class="text-shadow-black">{{
                showCorporatePricingPlan
                  ? corporatePremiumPlanPrice
                  : individualPremiumPlanPrice
              }}</span>
              <small class="fw-normal ml-2">/mo</small>
            </h3>
            <p class="card-text text-secondary">
              {{showCorporatePricingPlan
                ? 'Up to 1000 clients' : '~ 1000 files'}}
            </p>
            <div class="d-grid gap-2">
              <a
              (click)="redirectToSignUpSelection()"
                class="btn text-black btn-primary bd-none fw-semibold mt-5 rounded-2 bg-medium-blue"
                >Buy Now</a
              >
            </div>

            <!-- // Start Fourth Row Fourth Card Inner First Row -->
            <div class="row text-secondary mt-5">
              <div class="col-1">
                <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
              </div>
              <div class="col-10">
                <p class="">
                  {{
                    showCorporatePricingPlan
                      ? corporatePremiumPlanStorageSizeInGB
                      : individualPremiumPlanStorageSizeInGB
                  }}
                  GB storage
                </p>
              </div>
            </div>
            <!-- // End Fourth Row Fourth Card Inner First Row -->

            <!-- // Start Fourth Row Fourth Card Inner Second Row -->
            <div class="row text-secondary mt-1">
              <div class="col-1">
                <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
              </div>
              <div class="col-10">
                <p class="">Dedicated account manager</p>
              </div>
            </div>
            <!-- // End Fourth Row Fourth Card Inner Second Row -->

            <!-- // Start Fourth Row Fourth Card Inner Third Row -->
            <div class="row text-secondary mt-1">
              <div class="col-1">
                <img src="/assets/revamp/tickIconLatest.png" class="" width="20" height="20"/>
              </div>
              <div class="col-10">
                <p class="">First time set up and training provided</p>
              </div>
            </div>
            <!-- // End Fourth Row Fourth Card Inner Third Row -->
          </div>
        </div>
      </div>
    </div>
    <!-- // End Fourth Row -->

  </div>
</section>

<section name="homeSection4" class="bg-light-blue pt-5 pb-5">
  <div class="container-xl font-plus-jakarta-sans mb-5 pb-5">
    <!-- // Start First Row -->
    <div class="row">
      <div class="col-lg-12 d-flex justify-content-center">
        <h1 class="fw-bold text-shadow-black letter-spacing-1 pt-5 text-center">
          <small *ngIf="isSmallScreen" class="font-weight-bold">
            FAQ
          </small>
          <span *ngIf="!isSmallScreen"> Frequently Asked Questions </span>
        </h1>
      </div>
    </div>
    <!-- // End First Row -->

    <!-- // Start Second Row -->
    <div class="row">
      <div
        class="col-lg-11 d-flex justify-content-center mx-auto"
        [ngClass]="isSmallScreen ? 'px-5' : ''"
      >
        <p
          class="mt-3 text-secondary text-center"
          [ngClass]="isSmallScreen ? 'fs-6' : 'fw-semibold fs-5 line-height-2'"
        >
          <small *ngIf="isSmallScreen" class="font-weight-bold">
            Can’t find your answer? Contact us here at
            <span class="text-primary">60163380438</span> or email at
            <span class="text-primary">support@legalbox.io</span>
          </small>
          <span *ngIf="!isSmallScreen">
            Can’t find your answer? Contact us here at
            <span class="text-primary">60163380438</span> or email at
            <span class="text-primary">support@legalbox.io</span>
          </span>
        </p>
      </div>
    </div>
    <!-- // End Second Row -->

    <!-- // Start Third Row -->
    <div class="row mt-4">
      <div class="col-lg-6 mt-4">
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <!-- // Start Accordion Item 1 -->
          <div class="accordion-item rounded-2 shadow-sm" >
            <h2 class="accordion-header" >
              <button (click)="makeSpecificRouterLinkActive('showFaqItem1')"
                class="accordion-button bd-top-light-blue bd-left-light-blue" [ngClass]="getHeaderLinkValueFromKey('showFaqItem1')? 'rounded-top-2':'collapsed rounded-2'"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
               
                aria-controls="flush-collapseOne"
              >
                <img src="/assets/revamp/legalboxIconNew.png" width="20" height="20">
                <span class="ms-3 fw-semibold fs-5">How secure is LegalBox’s Blockchain Storage?</span>
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse" [ngClass]="getHeaderLinkValueFromKey('showFaqItem1') ? 'show':''"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body bd-left-light-blue">
                <p class="text-secondary ps-3 ms-3 me-3 text-wrap custom-text-justify lh-base">
                  At Legalbox, security is our top priority. 
                  Our Blockchain Storage solution utilizes state-of-the-art security protocols such as encryption,
                  multi-factor authentication, and automatic backups to ensure the safety of your data.
                  Additionally, we are compliant with several industry regulations including HIPAA, SOC 2, and PCI-DSS.
                  Our team of security experts is constantly monitoring and updating our systems to keep your data secure at all times.
                </p>
              </div>
            </div>
          </div>
          <!-- // End Accordion Item 1 -->

          <!-- // Start Accordion Item 2 -->
          <div class="accordion-item rounded-2 shadow-sm mt-4" >
            <h2 class="accordion-header" >
              <button (click)="makeSpecificRouterLinkActive('showFaqItem2')"
                class="accordion-button bd-top-light-blue bd-left-light-blue" [ngClass]="getHeaderLinkValueFromKey('showFaqItem2')? 'rounded-top-2':'collapsed rounded-2'"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
               
                aria-controls="flush-collapseOne"
              >
                <img src="/assets/revamp/legalboxIconNew.png" width="20" height="20">
                <span class="ms-3 fw-semibold fs-5">What happens if there is a security breach?</span>
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse" [ngClass]="getHeaderLinkValueFromKey('showFaqItem2') ? 'show':''"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body bd-left-light-blue">
                <p class="text-secondary ps-3 ms-3 me-3 text-wrap custom-text-justify lh-base">
                  In the unlikely event of a security breach, our team of experts will work quickly to identify and resolve the issue.
                   We will also notify you immediately and take steps to prevent further breaches.
                </p>
              </div>
            </div>
          </div>
          <!-- // End Accordion Item 2 -->

            <!-- // Start Accordion Item 3 -->
            <div class="accordion-item rounded-2 shadow-sm mt-4" >
              <h2 class="accordion-header" >
                <button (click)="makeSpecificRouterLinkActive('showFaqItem3')"
                  class="accordion-button bd-top-light-blue bd-left-light-blue" [ngClass]="getHeaderLinkValueFromKey('showFaqItem3')? 'rounded-top-2':'collapsed rounded-2'"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                 
                  aria-controls="flush-collapseOne"
                >
                  <img src="/assets/revamp/legalboxIconNew.png" width="20" height="20">
                  <span class="ms-3 fw-semibold fs-5">How do you comply with legal regulations?</span>
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse" [ngClass]="getHeaderLinkValueFromKey('showFaqItem3') ? 'show':''"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body bd-left-light-blue">
                  <p class="text-secondary ps-3 ms-3 me-3 text-wrap custom-text-justify lh-base">
                    LegalBox is compliant with several legal regulations and standards such as HIPAA, SOC 2, and PCI-DSS.
                    Our team of experts works closely with our clients to ensure that we meet their specific compliance needs and
                    standards.
                  </p>
                </div>
              </div>
            </div>
            <!-- // End Accordion Item 3 -->

        </div>
      </div>


      <div class="col-lg-6 mt-4">
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <!-- // Start Accordion Item 4 -->
          <div class="accordion-item rounded-2 shadow-sm" >
            <h2 class="accordion-header" >
              <button (click)="makeSpecificRouterLinkActive('showFaqItem4')"
                class="accordion-button bd-top-light-blue bd-left-light-blue" [ngClass]="getHeaderLinkValueFromKey('showFaqItem4')? 'rounded-top-2':'collapsed rounded-2'"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
               
                aria-controls="flush-collapseOne"
              >
                <img src="/assets/revamp/legalboxIconNew.png" width="20" height="20">
                <span class="ms-3 fw-semibold fs-5">How LegalBox ensure the security of my data?</span>
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse" [ngClass]="getHeaderLinkValueFromKey('showFaqItem4') ? 'show':''"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body bd-left-light-blue">
                <p class="text-secondary ps-3 ms-3 me-3 text-wrap custom-text-justify lh-base">
                 We utilize state-of-the-art security protocols such as encryption, multi-factor authentication,
                 and automatic backups to ensure the safety of your data. We also comply with several industry regulations
                 such as HIPAA, SOC 2, and PCI-DSS.
                </p>
              </div>
            </div>
          </div>
          <!-- // End Accordion Item 4 -->

            <!-- // Start Accordion Item 5 -->
            <div class="accordion-item rounded-2 shadow-sm mt-4" >
              <h2 class="accordion-header" >
                <button (click)="makeSpecificRouterLinkActive('showFaqItem5')"
                  class="accordion-button bd-top-light-blue bd-left-light-blue" [ngClass]="getHeaderLinkValueFromKey('showFaqItem5')? 'rounded-top-2':'collapsed rounded-2'"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                 
                  aria-controls="flush-collapseOne"
                >
                  <img src="/assets/revamp/legalboxIconNew.png" width="20" height="20">
                  <span class="ms-3 fw-semibold fs-5">Are my files encrypted in LegalBox's servers?</span>
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse" [ngClass]="getHeaderLinkValueFromKey('showFaqItem5') ? 'show':''"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body bd-left-light-blue">
                  <p class="text-secondary ps-3 ms-3 me-3 text-wrap custom-text-justify lh-base">
                    Yes, all files stored on our servers are encrypted to ensure maximum security.
                  </p>
                </div>
              </div>
            </div>
            <!-- // End Accordion Item 5 -->

              <!-- // Start Accordion Item 6 -->
          <div class="accordion-item rounded-2 shadow-sm mt-4" >
            <h2 class="accordion-header" >
              <button (click)="makeSpecificRouterLinkActive('showFaqItem6')"
                class="accordion-button bd-top-light-blue bd-left-light-blue" [ngClass]="getHeaderLinkValueFromKey('showFaqItem6')? 'rounded-top-2':'collapsed rounded-2'"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
               
                aria-controls="flush-collapseOne"
              >
                <img src="/assets/revamp/legalboxIconNew.png" width="20" height="20">
                <span class="ms-3 fw-semibold fs-5">Does LegalBox have a disaster recovery plan?</span>
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse" [ngClass]="getHeaderLinkValueFromKey('showFaqItem6') ? 'show':''"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body bd-left-light-blue">
                <p class="text-secondary ps-3 ms-3 me-3 text-wrap custom-text-justify lh-base">
                  Yes, we have a comprehensive disaster recovery plan in place to minimize data loss and downtime
                  in case of any incidents.
                </p>
              </div>
            </div>
          </div>
          <!-- // End Accordion Item 6 -->

        </div>
      </div>
    </div>
    <!-- // End Third Row -->
  </div>
</section>

<app-get-started></app-get-started>



