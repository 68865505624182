import { Component, OnInit } from '@angular/core';
import { PricingPlanService } from 'src/app/services/pricing-plan.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.css']
})
export class SelectPlanComponent implements OnInit {

  showCorporatePricingPlan: boolean = false;
  showIndividualPricingPlan: boolean = false;
  plusIconPath = "/assets/scratchImg/homeSection4PlusIcon.svg";
  minusIconPath = "/assets/scratchImg/homeSection4MinusIcon.svg";

  faqItem1IconPath = this.plusIconPath;
  faqItem2IconPath = this.plusIconPath;
  faqItem3IconPath = this.plusIconPath;
  faqItem4IconPath = this.plusIconPath;
  faqItem5IconPath = this.plusIconPath;
  faqItem6IconPath = this.plusIconPath;

  showFaqItem1 = false;
  showFaqItem2 = false;
  showFaqItem3 = false;
  showFaqItem4 = false;
  showFaqItem5 = false;
  showFaqItem6 = false;
  userId: any;
  pricingPlans: any;
  corporateBasicPlanPrice: any;
  corporateAdvancedPlanPrice: any;
  corporatePremiumPlanPrice: any;
  individualBasicPlanPrice: any;
  individualAdvancedPlanPrice: any;
  individualPremiumPlanPrice: any;
  freePlanPrice: any;
  corporateBasicPlanStorageSizeInGB: any;
  corporateAdvancedPlanStorageSizeInGB: any;
  corporatePremiumPlanStorageSizeInGB: any;
  individualBasicPlanStorageSizeInGB: any;
  individualAdvancedPlanStorageSizeInGB: any;
  individualPremiumPlanStorageSizeInGB: any;
  freePlanStorageSizeInGB: any;
  freePlanStorageSizeInMB: any;
  corporateBasicPlanId: any;
  freePlanId: any;
  corporateAdvancedPlanId: any;
  corporatePremiumPlanId: any;
  individualBasicPlanId: any;
  individualAdvancedPlanId: any;
  individualPremiumPlanId: any;

  constructor(private router: Router, private pricingPlanService: PricingPlanService, private userService: UserService,
    private tokenStorageService: TokenStorageService) {

  }

  ngOnInit(): void {
    this.showCorporatePricingPlan = false;
    this.showIndividualPricingPlan = false;
    let user = this.tokenStorageService.getUser();
    if (environment.debug) {
      console.log("User: ", user);
    }
    this.userId = user.userId;
    if (user.userType == "individual") {
      this.showIndividualPricingPlan = true;
    } else if (user.userType == "business") {
      this.showCorporatePricingPlan = true;
    }
    this.retrievePricingPlans();
    // this.getUser(history.state.userId, history.state.userUuid);
    // this.getUser("11", "dbfd1dee-eb42-4c0d-9e53-4b934ff9b4ca");
  }

  // getUser(userId, userUuid): void {
  //   this.userService.get(userId)
  //     .subscribe(
  //       returnedUser => {
  //         if(returnedUser.uuid == userUuid) { // start if statement to verify user's uuid    
  //           if(returnedUser.userType == "individual") {
  //             this.showIndividualPricingPlan = true;
  //           }
  //           else {
  //             this.showCorporatePricingPlan = true;
  //           }
  //           this.retrievePricingPlans();
  //         } // end if statement to verify user's uuid
  //         else { // start else statement to verify user's uuid
  //           alert("Please ensure you have clicked the email verification/account activation link before you are redirected to this page!");
  //         } // end else statement to verify user's uuid


  //       },
  //       error => {
  //         console.error(error);
  //       });
  // }

  retrievePricingPlans(): void {
    this.pricingPlanService.getAll()
      .subscribe(
        data => {
          this.pricingPlans = data;
          if (environment.debug) {
            console.log(data);
          }

          for (let i = 0; i < this.pricingPlans.length; i++) {
            if (this.pricingPlans[i].name == "Corporate Basic") {
              this.corporateBasicPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.corporateBasicPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.corporateBasicPlanId = this.pricingPlans[i].id;
            }
            if (this.pricingPlans[i].name == "Corporate Advanced") {
              this.corporateAdvancedPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.corporateAdvancedPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.corporateAdvancedPlanId = this.pricingPlans[i].id;
            }
            if (this.pricingPlans[i].name == "Corporate Premium") {
              this.corporatePremiumPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.corporatePremiumPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.corporatePremiumPlanId = this.pricingPlans[i].id;
            }
            if (this.pricingPlans[i].name == "Individual Basic") {
              this.individualBasicPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.individualBasicPlanPrice = parseFloat(this.individualBasicPlanPrice).toFixed(2);
              this.individualBasicPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.individualBasicPlanId = this.pricingPlans[i].id;
            }
            if (this.pricingPlans[i].name == "Individual Advanced") {
              this.individualAdvancedPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.individualAdvancedPlanPrice = parseFloat(this.individualAdvancedPlanPrice).toFixed(2);
              this.individualAdvancedPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.individualAdvancedPlanId = this.pricingPlans[i].id;
            }
            if (this.pricingPlans[i].name == "Individual Premium") {
              this.individualPremiumPlanPrice = this.pricingPlans[i].monthlyAmount;
              this.individualPremiumPlanPrice = parseFloat(this.individualPremiumPlanPrice).toFixed(2);
              this.individualPremiumPlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.individualPremiumPlanId = this.pricingPlans[i].id;
            }
            if (this.pricingPlans[i].name == "Free") {
              this.freePlanPrice = this.pricingPlans[i].monthlyAmount;
              this.freePlanStorageSizeInGB = this.pricingPlans[i].storageSizeInGB;
              this.freePlanStorageSizeInMB = parseFloat(this.freePlanStorageSizeInGB) * 1000;
              this.freePlanId = this.pricingPlans[i].id;
            }
          }
        },
        error => {
          console.error(error);
        });
  }

  displayCorporatePricingPlan() {
    this.showCorporatePricingPlan = true;
    this.showIndividualPricingPlan = false;
  }

  displayIndividualPricingPlan() {
    this.showCorporatePricingPlan = false;
    this.showIndividualPricingPlan = true;
  }

  selectPricingPlan(selectedPricingPlanId) {

    this.userService.selectPricingPlan(
      this.userId,
      selectedPricingPlanId,
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.message:" + event.body.message);
          }

          // this.message = event.body.message;

          if (this.freePlanId) { // start if statement retrieved pricing plans (e.g. freePlan)

            if (selectedPricingPlanId == this.freePlanId) { // start if statement selected free plan
              this.router.navigate(['UserDashboard']);
            } // end if statement selected free plan
            else { // start else statement selected free plan
              this.router.navigate(['Checkout'],
                {
                  state:
                  {
                    userId: this.userId,
                    selectedPricingPlanId: selectedPricingPlanId,
                  }
                }
              );
            } // end else statement selected free plan

          } // end if statement retrieved pricing plans (e.g. freePlan)


          // return event.body.message;
        }
      },
      err => {
        // this.progress = 0;
        // this.message = 'Could not create law firm user!';
        alert("Error in selecting plan: " + err);
        console.error(err)
        // return null;
      });
    // this.selectedFiles = undefined;
  }



}
