import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-faq',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '100%',
        opacity: 1,
      })),
      state('closed', style({
        height: 0,
        opacity: 0,
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('1s')
      ]),
    ]),
  ],
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  plusIconPath = "/assets/scratchImg/homeSection4PlusIcon.svg";
  minusIconPath = "/assets/scratchImg/homeSection4MinusIcon.svg";

  faqItem1IconPath = this.plusIconPath;
  faqItem2IconPath = this.plusIconPath;
  faqItem3IconPath = this.plusIconPath;
  faqItem4IconPath = this.plusIconPath;
  faqItem5IconPath = this.plusIconPath;
  faqItem6IconPath = this.plusIconPath;

  showFaqItem1 = false;
  showFaqItem2 = false;
  showFaqItem3 = false;
  showFaqItem4 = false;
  showFaqItem5 = false;
  showFaqItem6 = false;

  constructor() { 

  }

  ngOnInit(): void {
    this.toggleFaqItem1();
    this.toggleFaqItem2();
    this.toggleFaqItem3();
    this.toggleFaqItem4();
    this.toggleFaqItem5();
    this.toggleFaqItem6();
  }

  toggleFaqItem1() {
    this.showFaqItem1 = !this.showFaqItem1;
    if(this.faqItem1IconPath == this.plusIconPath) {
      this.faqItem1IconPath = this.minusIconPath;
    }
    else if(this.faqItem1IconPath == this.minusIconPath) {
      this.faqItem1IconPath = this.plusIconPath;
    }
    
  }

  toggleFaqItem2() {
    this.showFaqItem2 = !this.showFaqItem2;
    if(this.faqItem2IconPath == this.plusIconPath) {
      this.faqItem2IconPath = this.minusIconPath;
    }
    else if(this.faqItem2IconPath == this.minusIconPath) {
      this.faqItem2IconPath = this.plusIconPath;
    }
    
  }

  toggleFaqItem3() {
    this.showFaqItem3 = !this.showFaqItem3;
    if(this.faqItem3IconPath == this.plusIconPath) {
      this.faqItem3IconPath = this.minusIconPath;
    }
    else if(this.faqItem3IconPath == this.minusIconPath) {
      this.faqItem3IconPath = this.plusIconPath;
    }
    
  }

  toggleFaqItem4() {
    this.showFaqItem4 = !this.showFaqItem4;
    if(this.faqItem4IconPath == this.plusIconPath) {
      this.faqItem4IconPath = this.minusIconPath;
    }
    else if(this.faqItem4IconPath == this.minusIconPath) {
      this.faqItem4IconPath = this.plusIconPath;
    }
    
  }

  toggleFaqItem5() {
    this.showFaqItem5 = !this.showFaqItem5;
    if(this.faqItem5IconPath == this.plusIconPath) {
      this.faqItem5IconPath = this.minusIconPath;
    }
    else if(this.faqItem5IconPath == this.minusIconPath) {
      this.faqItem5IconPath = this.plusIconPath;
    }
  }

    
    toggleFaqItem6() {
      this.showFaqItem6 = !this.showFaqItem6;
      if(this.faqItem6IconPath == this.plusIconPath) {
        this.faqItem6IconPath = this.minusIconPath;
      }
      else if(this.faqItem6IconPath == this.minusIconPath) {
        this.faqItem6IconPath = this.plusIconPath;
      }
      
    }

}
