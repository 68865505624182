<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet"
    integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"
    integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL"
    crossorigin="anonymous"></script>
<div class="section mt-0 pt-3 mb-5 pb-5">
    <div class="container">
        <div class="row justify-content-center mt-3 mt-md-5 pt-5 ">
            <div class="col-md-4 text-center text-md-start">
                <h1 class="fw-bold h1color">Error 404</h1>
                <h1 class="fw-bold mb-5">Page Not Found</h1>
                <p class="mb-5">
                    The page you requested could not be found. <br>
                    We are working on it. Thank you for your patience.
                </p>
                <div class="row">
                    <div class="col-md-9">
                        <div class="d-grid gap-2">
                            <button class="btn btn-primary" (click)="goToHome()">
                                Back to Home
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5 mt-5 mt-md-0 mb-5">
                <img class="mx-auto w-100" src="/assets/scratchImg/404Image.svg" alt="404 Image">
            </div>
        </div>

    </div>
</div>