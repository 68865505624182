import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    if (environment.debug) {
      console.log("isSmallScreen from Home Component: " + this.isSmallScreen);
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
