import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-specific-client',
  templateUrl: './view-specific-client.component.html',
  styleUrls: ['./view-specific-client.component.css']
})
export class ViewSpecificClientComponent implements OnInit {
  routeParameterClientId: any;
  routeParameterClientFirstName: any;
  routeParameterClientLastName: any;
  displayAddNewDropdownContent = false;
  message: any;
  userId: any;
  retrievedLegalDocuments: any[] = [];
  extractedUserId: any;
  retrievedClientUser: any;
  disableFileUploadDropdownContent = false;
  showMoreOptions: boolean;
  displayDeleteMessage: boolean;
  documentTitle: string;
  dropdownDocumentID: string;
  documentID: any;
  tryingBCUpload: boolean;
  corporateUser = false;
  isPopupVisible: any;
  displayLoadingMessage: boolean;
  longPressDocumentID: any;

  clickDelYes: boolean;
  displayResponseMessage = false;
  responseType: any;
  responseMessageText: any;
  responseTypeColor: any;
  responseMessageIconImageURL: any;
  successMessageIconImageURL = "/assets/revamp/successGreenIconNew.png";
  errorMessageIconImageURL = "/assets/revamp/redExclamationMark.png";
  displaySessionMessage = false;
  messageToBeDisplayed = "";
  errorType = "";
  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    console.log("isSmallScreen from Home Component: " + this.isSmallScreen);
  }



  constructor(private router: Router, private _activatedRoute: ActivatedRoute, private userService: UserService,
    private tokenStorageService: TokenStorageService, private transactionService: TransactionService) { }

  ngOnInit(): void {
    this.displaySessionMessage = false;
    this.messageToBeDisplayed = "";
    this.errorType = "";
    this.clickDelYes = false;
    this.showMoreOptions = false;
    this.displayDeleteMessage = false;
    this.tryingBCUpload = false;
    this._activatedRoute.params.subscribe(parameter => {
      this.routeParameterClientId = parameter.clientId;
      this.routeParameterClientFirstName = parameter.clientFirstName;
      this.routeParameterClientLastName = parameter.clientLastName;
      // alert(this.routeParameterClientId);

    })
    let retrievedUser = this.tokenStorageService.getUser();
    this.userId = retrievedUser.userId;
    if (environment.debug) {
      console.log("retrievedUser.userType:" + retrievedUser);
    }
    if (retrievedUser.userType == "business" || retrievedUser.userType == "individual") {
      this.corporateUser = true;
    }
    this.retrieveUserIDByClientID(this.routeParameterClientId);
  }

  showPopup() {
    this.isPopupVisible = true;
  }

  hidePopup() {
    this.isPopupVisible = false;
  }

  onLongPress(event: Event, legalDocumentID: any): void {
    this.longPressDocumentID = legalDocumentID;
    console.log('Long press detected!', event);
    // Your logic for long press handling goes here
  }

  getUser(id): void {
    this.userService.get(id)
      .subscribe(
        data => {
          if (environment.debug) {
            console.log(data);
          }
          if (data.status == 403) {
            alert("Your session has expired. Please login again!");
            this.tokenStorageService.signOut();
            this.router.navigate(['SignIn']);
          }
          this.retrievedClientUser = data;
          if (this.retrievedClientUser.username == null) {
            alert("You are not allowed to upload files for a client that has not signed up yet!");
            this.disableFileUploadDropdownContent = true;
          }

        },
        error => {
          console.error(error);
        });
  }

  getUserLegalDocumentsList(userId, introId) {

    this.userService.getUserLegalDocumentsList(
      userId,
      introId,
      "false"
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.retrievedLegalDocuments:", event.body.retrievedLegalDocuments);
          }
          // this.message = event.body.message;
          this.retrievedLegalDocuments = event.body.retrievedLegalDocuments;
        }
      },
      err => {
        // this.progress = 0;
        // this.message = 'Could not create law firm user!';
        // alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  localFormatDate(_date) {
    let monthNumericForm = formatDate(_date, 'MM', 'en-US');
    let monthStringForm = this.monthNumberToString(monthNumericForm);
    // console.log("monthStringForm: " + monthStringForm);
    let date_Year = formatDate(_date, 'dd, yyyy', 'en-US');
    let final_date = monthStringForm + " " + date_Year;
    return final_date;
  }

  monthNumberToString(_month) {
    var returnMonth;

    switch (_month) {
      case "01": returnMonth = "January"; break;
      case "02": returnMonth = "February"; break;
      case "03": returnMonth = "March"; break;
      case "04": returnMonth = "April"; break;
      case "05": returnMonth = "May"; break;
      case "06": returnMonth = "June"; break;
      case "07": returnMonth = "July"; break;
      case "08": returnMonth = "August"; break;
      case "09": returnMonth = "September"; break;
      case "10": returnMonth = "October"; break;
      case "11": returnMonth = "November"; break;
      case "12": returnMonth = "December"; break;
    }

    return returnMonth;
  }

  /**
* format bytes
* @param bytes (File size in bytes)
* @param decimals (Decimals point)
*/

  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }


  toggleAddNewDropdownContentDisplay() {
    this.displayAddNewDropdownContent = !this.displayAddNewDropdownContent;
  }

  redirectToUploadNewFiles() {
    // this.router.navigate(['UploadNewFiles']);
    this.router.navigate(['UploadNewFiles'],
      {
        state:
        {
          origin: "viewSpecificClientComponent",
          isSharedbyID: this.userId,
          clientId: this.routeParameterClientId,
          clientFirstName: this.routeParameterClientFirstName,
          clientLastName: this.routeParameterClientLastName
        }
      }
    );
  }

  displayUserSpecificLegalDocument(_documentTitle) {

    this.userService.displayUserSpecificLegalDocument(
      this.extractedUserId,
      _documentTitle,
      "true",
      this.userId
    ).subscribe(
      event => {


        if (event.type === HttpEventType.UploadProgress) {
          // this.progress = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event):" + Object.keys(event));
            console.log("event.body.size: " + event.body.size);
            console.log("event.body.type: " + event.body.type);
          }

          let fileURL = window.URL.createObjectURL(event.body);
          if (environment.debug) {
            console.log("fileURL: " + fileURL);
          }
          // window.open(fileURL, '_self');
          window.open(fileURL, '_blank');
          // console.log("event.body.message:" + event.body.message);
          // this.message = event.body.message;



          // return event.body.message;
        }
      },
      err => {
        // this.progress = 0;
        // this.message = 'Could not create law firm user!';
        // alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  retrieveUserIDByClientID(clientId) {

    this.userService.retrieveUserIDByClientID(
      clientId
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.message:" + event.body.message);
          }
          this.message = event.body.message;
          this.extractedUserId = event.body.userId;
          this.getUser(this.extractedUserId);
          this.getUserLegalDocumentsList(this.extractedUserId, this.userId);

          //   this.router.navigate(['Checkout'],
          //   {
          //     state: 
          //     {
          //     userId: this.userId,
          //     selectedPricingPlanId: selectedPricingPlanId,
          //     }
          //   }
          // );

          // return event.body.message;
        }
      },
      err => {
        // this.progress = 0;
        // this.message = 'Could not create law firm user!';
        // alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  redirectToAddNewClient() {
    this.router.navigate(['AddNewClient']);
  }

  goBack() {
    this.router.navigate(['ViewAllClients']);
  }

  retrieveSmartContractFunctionTransactionHash(legalDocumentID, smartContractFunction) {

    this.transactionService.retrieveSmartContractFunctionTransactionHash(
      legalDocumentID,
      smartContractFunction,
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:" + event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            // console.log("event.body.message:" + event.body.message);
          }
          // this.message = event.body.message;
          let transaction = JSON.parse(JSON.stringify(event.body.transaction));
          if (environment.debug) {
            console.log("transaction: " + Object.keys(transaction));
          }
          let legalDocumentTransactionHashURL = environment.blockExplorerURL + transaction.transactionHash;
          window.open(legalDocumentTransactionHashURL, "_blank");

          // return event.body.message;
        }
      },
      err => {
        // this.progress = 0;
        // alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  redirectToBlockExplorerURL(legalDocumentID) {
    this.retrieveSmartContractFunctionTransactionHash(legalDocumentID, "storeLegalDocumentIPFSHash");
  }

  redirectToIPFSHashURL(legalDocumentCIDHash) {
    let ipfsHashURL = environment.pinataGateway + legalDocumentCIDHash;
    window.open(ipfsHashURL, "_blank");
  }

  dropdownOptions(docID) {
    this.dropdownDocumentID = docID;
    if (!this.showMoreOptions) {
      this.showMoreOptions = true;
    } else {
      this.showMoreOptions = false;
    }
  }

  cancelDel() {
    this.displayDeleteMessage = false;
  }

  deleteDocumentPopUp(docID: any, docTitle: string) {
    this.documentTitle = docTitle;
    this.documentID = docID;
    this.displayDeleteMessage = true;
  }

  archiveDocument() {
    // Archive the document
    this.displayLoadingMessage = true;
    if (environment.debug) {
      console.log("retrieved user: ", this.retrievedClientUser);
    }
    this.userService.archivedDocument(this.userId, this.retrievedClientUser.id, "true", this.documentID).subscribe({
      next: (event) => {
        if (event instanceof HttpResponse) {
          if (event.body.status == 500) {
            alert("Error: " + event.body.message);
          } else if (event.body.status == 404) {
            alert("Error: " + event.body.message);
          } else {
            this.displayDeleteMessage = false;
            this.responseTypeColor = "#0BB831";
            this.responseMessageIconImageURL = this.successMessageIconImageURL;
            this.responseType = "Success";
            this.responseMessageText = "File successfully deleted!";
            this.displayResponseMessage = true;
            this.displayLoadingMessage = false;
          }
        }
      },
      error: (err) => {
        // Response error from backend / Fail to send forgot password request
        this.responseTypeColor = "#EE4E55";
        this.responseMessageIconImageURL = this.errorMessageIconImageURL;
        this.responseType = "Error";
        this.responseMessageText = err.error.message;
        this.displayResponseMessage = true;
        this.displayLoadingMessage = false;
      }
    });

  }

  retryUploadToBlockchain(docID, docTitle, docDescription, docIPFSHash) {
    this.tryingBCUpload = true;
    this.userService.retryUploadToBlockchain(
      this.retrievedClientUser.id,
      docID,
      docTitle,
      docDescription,
      docIPFSHash).subscribe({
        next: (event) => {
          if (event instanceof HttpResponse) {
            if (event.body.status == 500) {
              alert("Error: " + event.body.message);
            } else if (event.body.status == 404) {
              alert("Error: " + event.body.message);
            } else {
              this.displayDeleteMessage = false;
              this.responseTypeColor = "#0BB831";
              this.responseMessageIconImageURL = this.successMessageIconImageURL;
              this.responseType = "Success";
              this.responseMessageText = "File successfully uploaded to Polygon";
              this.displayResponseMessage = true;
              this.displayLoadingMessage = false;
            }
          }
        },
        error: (err) => {
          this.responseTypeColor = "#EE4E55";
          this.responseMessageIconImageURL = this.errorMessageIconImageURL;
          this.responseType = "Error";
          this.responseMessageText = err.error.message;
          this.displayResponseMessage = true;
          this.displayLoadingMessage = false;
        }
      })
  }

  closeResponseMessage() {
    this.displayResponseMessage = false;
    const url = self ? this.router.url : '/ViewSpecificClient/' + this.routeParameterClientId + '/' + this.routeParameterClientFirstName + '/' + this.routeParameterClientLastName;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/${url}`]).then(() => {
        if (environment.debug) {
          console.log(`After navigation I am on:${this.router.url}`)
        }
      })
    });
  }

}
