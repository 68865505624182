import { Component, OnInit, HostBinding, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-all-clients',
  templateUrl: './view-all-clients.component.html',
  styleUrls: ['./view-all-clients.component.css']
})
export class ViewAllClientsComponent implements OnInit {
  displayAddNewDropdownContent = false;
  message: any;
  corporateUser = false;
  corporateInstitutionUser = false;
  finalSumOfClientStorageInGB: any;
  finalSumOfClientStorageInMB: any;
  finalSumOfCorporateInstitutionUsageInGB: any;
  finalSumOfCorporateInstitutionUsageInMB: any;
  storageBalanceInGB: any;
  storageBalanceInMB: any;
  corporateInstitutionStorageValue: any;
  corporateInstitutionStorageValueInPercentage: any;
  clientStorageValueInPercentage: any;
  messageToBeDisplayed = "";
  errorType = "";

  @HostBinding("style.--percentage")
  // set percentage of storage used here
  public allocatedStorageSizeInGB: any;
  public allocatedStorageSizeInMB: any;
  public usedStorageSizeInGB: any;
  public usedStorageSizeInMB: any;
  public value = '65%';
  public lawFirmStorageValue = '15%';
  public clientStorageValue = '50%';
  public usedStorage = this.value.split('%')[0] + ' GB';
  clientUsedStorageSizeInGB = 1;
  clientAllocatedStorageSizeInGB = 5;
  clientUsedStoragePercentage: any;
  userId: any;
  clientArray: any;
  retrievedUser: any;
  userAllocatedStorageSizeInGB: any;
  displaySessionMessage = false;
  isSmallScreen: boolean = window.innerWidth < 767; // Initial check

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 767;
    console.log("isSmallScreen from Home Component: " + this.isSmallScreen);
  }

  @HostBinding("attr.style")
  public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(`--percentage: ${this.value}`);
  }


  constructor(private router: Router, private sanitizer: DomSanitizer, private userService: UserService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    this.displaySessionMessage = false;
    this.messageToBeDisplayed = "";
    this.errorType = "";

    let retrievedUser = this.tokenStorageService.getUser();
    this.getUser(retrievedUser.userId);
  }

  getUser(id): void {
    this.userService.get(id)
      .subscribe(
        data => {
          if (environment.debug) {
            console.log(data);
          }
          if (data.status == 403) {
            alert("Your session has expired. Please login again!");
            this.tokenStorageService.signOut();
            this.router.navigate(['SignIn']);
          }
          this.retrievedUser = data;
          this.userAllocatedStorageSizeInGB = this.retrievedUser.allocatedStorageSizeInGB;

          if (this.retrievedUser.userType == "business") {
            this.corporateInstitutionUser = true;
            this.viewAllClients(this.retrievedUser.id, this.retrievedUser.usedStorageSizeInGB, this.retrievedUser.allocatedStorageSizeInGB);

          }

          this.allocatedStorageSizeInGB = data.allocatedStorageSizeInGB;
          this.usedStorageSizeInGB = data.usedStorageSizeInGB;

          if (this.allocatedStorageSizeInGB < 1) {
            this.allocatedStorageSizeInMB = this.allocatedStorageSizeInGB * 1000;
            this.allocatedStorageSizeInMB = parseFloat(this.allocatedStorageSizeInMB).toFixed(2);
            this.usedStorageSizeInMB = this.usedStorageSizeInGB * 1000;
            this.usedStorageSizeInMB = parseFloat(this.usedStorageSizeInMB).toFixed(2);
            this.value = ((this.usedStorageSizeInMB / this.allocatedStorageSizeInMB) * 100).toString();
            this.value = this.value + "%";
          }
          else if (this.allocatedStorageSizeInGB >= 1) {
            this.allocatedStorageSizeInGB = parseFloat(this.allocatedStorageSizeInGB).toFixed(2);
            this.usedStorageSizeInGB = parseFloat(this.usedStorageSizeInGB).toFixed(2);
            this.value = ((this.usedStorageSizeInGB / this.allocatedStorageSizeInGB) * 100).toString();
            this.value = this.value + "%";
          }


        },
        error => {
          console.error(error);
        });
  }

  /**
 * format bytes
 * @param bytes (File size in bytes)
 * @param decimals (Decimals point)
 */
  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  viewAllClients(userId, userUsedStorageSizeInGB, userAllocatedStorageSizeInGB) {

    this.userService.viewAllClients(
      userId,
    ).subscribe(
      event => {
        if (event instanceof HttpResponse) {
          if (environment.debug) {
            console.log("event:", event);
            console.log("Object.keys(event.body):" + Object.keys(event.body));
            console.log("event.body.message:" + event.body.message);
          }
          this.message = event.body.message;
          let clientArray = event.body.clientArray;
          this.clientArray = event.body.clientArray;
          if (environment.debug) {
            console.log("clientArray: " + clientArray);
          }
          // Start Calculating Per Client Storage

          for (let i = 0; i < this.clientArray.length; i++) {

            this.clientArray[i].indexValue = i;
            this.clientArray[i].usedStoragePercentage = (this.clientArray[i].usedStorageSizeInGB / this.clientArray[i].allocatedStorageSizeInGB) * 100;
            this.clientArray[i].usedStoragePercentage = this.clientArray[i].usedStoragePercentage.toString() + "%";


          }
          if (environment.debug) {
            console.log("clientArray[i]: " + Object.keys(this.clientArray[0]));
          }
          // End Calculating Per Client Storage


          let sumOfClientStorage = 0.00;
          if (environment.debug) {
            console.log("sumOfClientStorageBeforeLoop: " + sumOfClientStorage);
          }
          for (let i = 0; i < clientArray.length; i++) {
            if (environment.debug) {
              console.log("entered loop");
              console.log(clientArray[i]);
            }
            sumOfClientStorage = sumOfClientStorage + parseFloat(clientArray[i].allocatedStorageSizeInGB);
            if (environment.debug) {
              console.log("sumOfClientStorageAfterLoop " + i + ":" + sumOfClientStorage);
            }
          }
          if (environment.debug) {
            console.log("sumOfClientStorageAfterLoop: " + sumOfClientStorage);
          }
          let userAllocatedStorageSizeInMB = userAllocatedStorageSizeInGB * 1000;

          this.finalSumOfClientStorageInGB = sumOfClientStorage;
          this.finalSumOfCorporateInstitutionUsageInGB = parseFloat(userUsedStorageSizeInGB) - parseFloat(this.finalSumOfClientStorageInGB);
          this.storageBalanceInGB = userAllocatedStorageSizeInGB - this.finalSumOfCorporateInstitutionUsageInGB - this.finalSumOfClientStorageInGB;

          if (userAllocatedStorageSizeInGB < 1) { // start if statement
            this.finalSumOfCorporateInstitutionUsageInMB = this.finalSumOfCorporateInstitutionUsageInGB * 1000;
            this.finalSumOfCorporateInstitutionUsageInMB = parseFloat(this.finalSumOfCorporateInstitutionUsageInMB).toFixed(2);
            this.corporateInstitutionStorageValue = ((this.finalSumOfCorporateInstitutionUsageInMB / userAllocatedStorageSizeInMB) * 100).toString();
            this.corporateInstitutionStorageValueInPercentage = this.corporateInstitutionStorageValue + "%";

            this.finalSumOfClientStorageInMB = this.finalSumOfClientStorageInGB * 1000;
            this.finalSumOfClientStorageInMB = parseFloat(this.finalSumOfClientStorageInMB).toFixed(2);
            this.clientStorageValue = ((this.finalSumOfClientStorageInMB / userAllocatedStorageSizeInMB) * 100).toString();
            this.clientStorageValueInPercentage = this.clientStorageValue + "%";

            this.storageBalanceInMB = this.storageBalanceInGB * 1000;
            this.storageBalanceInMB = parseFloat(this.storageBalanceInMB).toFixed(2);
          } // end if statement
          else if (userAllocatedStorageSizeInGB >= 1) { // start else if statement
            this.finalSumOfCorporateInstitutionUsageInGB = parseFloat(this.finalSumOfCorporateInstitutionUsageInGB).toFixed(2);
            this.corporateInstitutionStorageValue = ((this.finalSumOfCorporateInstitutionUsageInGB / userAllocatedStorageSizeInGB) * 100).toString();
            this.corporateInstitutionStorageValueInPercentage = this.corporateInstitutionStorageValue + "%";

            this.finalSumOfClientStorageInGB = parseFloat(this.finalSumOfClientStorageInGB).toFixed(2);
            this.clientStorageValue = ((this.finalSumOfClientStorageInGB / userAllocatedStorageSizeInGB) * 100).toString();
            this.clientStorageValueInPercentage = this.clientStorageValue + "%";

            this.storageBalanceInGB = parseFloat(this.storageBalanceInGB).toFixed(2);
          } // end else if statement

          //  console.log("clientArray[i]: " + Object.keys(this.clientArray[0]));

          // return event.body.message;
        }
        else {
          if (environment.debug) {
            console.log("none from User Dashboard Component");
          }
          let userAllocatedStorageSizeInMB = userAllocatedStorageSizeInGB * 1000;

          this.finalSumOfClientStorageInGB = 0;
          this.finalSumOfCorporateInstitutionUsageInGB = parseFloat(userUsedStorageSizeInGB) - parseFloat(this.finalSumOfClientStorageInGB);
          this.storageBalanceInGB = userAllocatedStorageSizeInGB - this.finalSumOfCorporateInstitutionUsageInGB - this.finalSumOfClientStorageInGB;

          if (userAllocatedStorageSizeInGB < 1) { // start if statement
            this.finalSumOfCorporateInstitutionUsageInMB = this.finalSumOfCorporateInstitutionUsageInGB * 1000;
            this.finalSumOfCorporateInstitutionUsageInMB = parseFloat(this.finalSumOfCorporateInstitutionUsageInMB).toFixed(2);
            this.corporateInstitutionStorageValue = ((this.finalSumOfCorporateInstitutionUsageInMB / userAllocatedStorageSizeInMB) * 100).toString();
            this.corporateInstitutionStorageValueInPercentage = this.corporateInstitutionStorageValue + "%";

            this.finalSumOfClientStorageInMB = this.finalSumOfClientStorageInGB * 1000;
            this.finalSumOfClientStorageInMB = parseFloat(this.finalSumOfClientStorageInMB).toFixed(2);
            this.clientStorageValue = ((this.finalSumOfClientStorageInMB / userAllocatedStorageSizeInMB) * 100).toString();
            this.clientStorageValueInPercentage = this.clientStorageValue + "%";

            this.storageBalanceInMB = this.storageBalanceInGB * 1000;
            this.storageBalanceInMB = parseFloat(this.storageBalanceInMB).toFixed(2);
          } // end if statement
          else if (userAllocatedStorageSizeInGB >= 1) { // start else if statement
            this.finalSumOfCorporateInstitutionUsageInGB = parseFloat(this.finalSumOfCorporateInstitutionUsageInGB).toFixed(2);
            this.corporateInstitutionStorageValue = ((this.finalSumOfCorporateInstitutionUsageInGB / userAllocatedStorageSizeInGB) * 100).toString();
            this.corporateInstitutionStorageValueInPercentage = this.corporateInstitutionStorageValue + "%";

            this.finalSumOfClientStorageInGB = parseFloat(this.finalSumOfClientStorageInGB).toFixed(2);
            this.clientStorageValue = ((this.finalSumOfClientStorageInGB / userAllocatedStorageSizeInGB) * 100).toString();
            this.clientStorageValueInPercentage = this.clientStorageValue + "%";

            this.storageBalanceInGB = parseFloat(this.storageBalanceInGB).toFixed(2);
          } // end else if statement


        }
      },
      err => {
        // this.progress = 0;
        this.message = 'Could not create law firm user!';
        // alert(err.error.message);
        // return null;
      });
    // this.selectedFiles = undefined;
  }

  toggleAddNewDropdownContentDisplay() {
    this.displayAddNewDropdownContent = !this.displayAddNewDropdownContent;
  }

  viewSpecificClient(clientId, clientFirstName, clientLastName) {
    if (history.state.origin == 'UserArchiveComponent') {
      this.router.navigate(['ViewSpecificClientArchive/' + clientId + "/" + clientFirstName + "/" + clientLastName]);
    } else {
      this.router.navigate(['ViewSpecificClient/' + clientId + "/" + clientFirstName + "/" + clientLastName]);
    }

  }
  redirectToAddNewClient() {
    this.router.navigate(['AddNewClient']);
  }

}
