<section name="userDocumentsSection1" class="">
  <div class="container-xl-fluid font-plus-jakarta-sans me-3 me-md-0">
    <!-- // Start First Row -->
    <div class="row">
      <div class="col-md-3">
        <app-side-navigation-bar [pageTitle]="businessName"></app-side-navigation-bar>
      </div>

      <div class="col-md-9 bg-light">
        <!-- // Start First Row Inner First Row -->
        <div class="row mt-0 mt-md-5 ps-4 pt-0 pt-md-5">
          <div class="col-md-8 d-none d-md-block">
            <h5 class="p-2 fw-medium text-secondary float-start">
              Shared Files
            </h5>
            <img
              class="float-start mt-2"
              src="/assets/revamp/rightArrowIcon.png"
              width="24"
              height="24"
            />
            <h5 class="p-2 fw-bold float-start">{{ businessName }}</h5>
          </div>
        </div>
        <!-- // End First Row Inner First Row -->

        <!-- // Start First Row Inner Second Row -->
        <div class="row">
          <div class="col-md-11" style="width: 97%">
            <!-- // Start Recent Files Box -->
            <div
              name="recentFilesBox"
              class="bg-white rounded-2 ms-4 p-4 mt-4 mb-5 mb-md-0 custom-height-recentfilesbox"
            >
              <p class="ms-0 ms-md-4 mb-1 mb-md-0 fw-bold fs-6 float-start">{{isSmallScreen? businessName: 'Recent Files'}} </p>
              <!-- <p class="float-end text-primary fs-6 fw-medium" (mouseenter)="showPopup()" (mouseleave)="hidePopup()">Change Plan</p>
                        <div class="changePlanPopUp shadow-lg" [style.display]="isPopupVisible? 'block':'none'">
                            Please email your change plan request to support@legalbox.io. 
                            <br>Our team will get in touch with you soon.
                        </div> -->
              <hr
                class="clearfix custom-hr-width mt-0 ms-3 d-none d-md-block"
                style="clear: both"
              />
              <table class="table ms-0 ms-md-3" [ngClass]="isSmallScreen?'':'table-borderless table-hover'">
                <thead class="">
                  <tr>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      Name &nbsp;<img
                        src="/assets/revamp/myDocumentsArrowUpIcon.png"
                        width="7"
                        height="7"
                      />
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      File Type
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      Size
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      Last Modified
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      Transaction Hash
                    </th>
                    <th
                      scope="col"
                      class="text-secondary fw-normal custom-font-size-13 d-none d-md-table-cell"
                    >
                      IPFS Hash
                    </th>
                  </tr>
                </thead>
                <tbody class="custom-font-size-13">
                  <ng-container
                    *ngFor="
                      let legalDocument of retrievedLegalDocuments;
                      index as i
                    "
                  >
                    <tr>
                      <td colspan="isSmallScreen? 5:1"
                        class="align-middle custom-cursor"
                        (click)="
                          displayUserSpecificLegalDocument(legalDocument.title)
                        "
                      >
                        <img
                          src="/assets/revamp/myDocumentsFolderIcon.png"
                          class="float-start"
                          [style.width]="isSmallScreen? '1.8rem':'1rem'"
                          [style.height]="isSmallScreen? '1.8rem':'1rem'"
                        />
            
                       
                        
                        &nbsp; 
                        <p class="d-inline-block mb-md-0 ms-2" [ngClass]="isSmallScreen? 'fw-bold':''"> 
                          {{ isSmallScreen? legalDocument.title.substring(0, 22):legalDocument.title }}
                          <!-- // Start Document Additional Info Small Screen -->
                        <span class="d-table-cell d-md-none fw-normal custom-font-size-10">
                          {{ legalDocument.updatedAt | customDate }}
                          -
                          {{
                            formatBytes(
                              legalDocument.fileSizeInGB * 1000 * 1000 * 1000
                            )
                          }}
                        </span>
                        <!-- // End Document Additional Info Small Screen -->
                         </p>

                      </td>
                      <td class="d-none d-md-table-cell text-secondary align-middle">
                        .{{ legalDocument.fileExtension }}
                      </td>
                      <td class="d-none d-md-table-cell text-secondary align-middle">
                        {{
                          formatBytes(
                            legalDocument.fileSizeInGB * 1024 * 1024 * 1024
                          )
                        }}
                      </td>
                      <td class="d-none d-md-table-cell   text-secondary align-middle">
                        {{ localFormatDate(legalDocument.updatedAt) }}
                      </td>

                      <td
                        *ngIf="
                          legalDocument.legalDocumentIDInBlockchain != null
                        "
                      >
                        <button
                          class="btn bg-transparent ms-0 ms-md-4 p-0 p-md-1"
                          (click)="
                            $event.stopPropagation();
                            redirectToBlockExplorerURL(legalDocument.id)
                          "
                        >
                          <img
                            src="/assets/scratchImg/polygonIcon.png"
                            width="22"
                            height="22"
                          />
                        </button>
                      </td>

                      <!-- <td *ngIf="legalDocument.legalDocumentIDInBlockchain == null">
                                            <button class="btn bg-transparent ms-4" [disabled]="tryingBCUpload || !corporateUser"
                                                (click)="$event.stopPropagation();">
                                                <img src="/assets/scratchImg/polygonRefreshIcon.svg" width="22" height="22" [style.display]="tryingBCUpload? 'none':'block'">
                                                <img src="/assets/scratchImg/polygonUpload.gif" width="22" height="22" [style.display]="tryingBCUpload? 'block':'none'">
                                            </button>
                                        </td> -->

                      <td>
                        <button
                          class="btn bg-transparent ms-0 ms-md-2 p-0 p-md-1"
                          (click)="
                            $event.stopPropagation();
                            redirectToIPFSHashURL(legalDocument.CIDHash)
                          "
                        >
                          <img
                            src="/assets/revamp/myDocumentsIpfsIcon.png"
                            width="22"
                            height="22"
                          />
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!-- // End Recent Files Box -->
          </div>
        </div>
        <!-- // End First Row Inner Second Row -->
      </div>
    </div>
    <!-- // End First Row -->
  </div>
</section>

<app-error-pop-up
  [(displaySessionMessage)]="displaySessionMessage"
  [messageToBeDisplayed]="messageToBeDisplayed"
  [errorType]="errorType"
></app-error-pop-up>
