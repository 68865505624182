import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-pop-up',
  templateUrl: './error-pop-up.component.html',
  styleUrls: ['./error-pop-up.component.css']
})
export class ErrorPopUpComponent implements OnInit {
  @Input() displaySessionMessage: boolean;
  @Output() displaySessionMessageChange = new EventEmitter<boolean>();
  @Input() messageToBeDisplayed: string;
  @Input() errorType: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.displaySessionMessage) {
  //     this.displaySessionMessage = changes.displaySessionMessage.currentValue;
  //     console.log("changes.displaySessionMessage: " + changes.displaySessionMessage);
  //   }
  // }

  performAction() { 
    if(this.errorType == "sessionExpired") {
      this.router.navigate(['SignIn']);
    }
    else if(this.errorType == "genericError") {
      this.displaySessionMessageChange.emit(false);
      // this.displaySessionMessage = false;
    } 
    else if(this.errorType == "404Error") {
      this.router.navigate(['**']);
    }
    
  }

}
